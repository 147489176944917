<template>
  <div class="announcement-info">
    <h2>{{info.title}}</h2>
    <span><a-icon type="clock-circle" /> {{info.publishedTime}}</span>
    <div class="announcement-info-text" v-html="info.content"></div>
    <div class="announcement-info-annex" v-if="info.attachments != ''">
      <div v-for="el in info.attachments" :key="el.id">
        <p>
          <img alt="图片" src="@/assets/img/fujian.png" /><span>{{el.fileName}}</span>
        </p>
        <span @click="download(el.id)">下载</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getAnnouncementInfo } from "@/api/res-announcement";
export default {
    data(){
        return{
            info:'',
            id:''
        }
    },
    mounted(){
        this.id = this.$route.query.id
        this.loadData()
    },
    methods:{
        loadData(){
            getAnnouncementInfo(this.id).then((res)=>{
                this.info = res
            })
        },
        download(id) {
            window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${id}`);
        },
    }
};
</script>

<style lang="scss" scoped>
.announcement-info {
  margin: 24px auto 56px;
  background: #fff;
  width: 1280px;
  padding: 48px 96px;
  > h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 36px;
    color: #262626;
    text-align: center;
    margin-bottom: 24px;
  }
  > span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #262626;
    margin-bottom: 32px;
    > i {
      margin-right: 16px;
    }
  }
  .announcement-info-text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: #262626;
    margin-bottom: 24px;
  }
  .announcement-info-annex {
    border-top: 1px solid #eee;
    padding-top: 24px;
    > div {
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      &:last-child{
        margin-bottom: 0;
      }
      > p {
        display: flex;
        align-items: center;
        margin-right: 24px;
        > img {
          margin-right: 16px;
        }
        > span {
          line-height: 14px;
          max-width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      > span {
        cursor: pointer;
        margin-left: 16px;
        line-height: 14px;
        color: #ea0b06;
      }
    }
  }
}
</style>