<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="data"
      :pagination="false"
      row-key="id"
    >
      <div slot="bidPrice" slot-scope="text">￥{{ text }}万</div>
      <div slot="operate" slot-scope="text, record" class="jiebang-detail-body">
        <img v-if="record.isChosen" src="@/assets/img/yzb.png" />
        <span class="jiebang-detail" @click="jiebangDetail(record)">详情</span>
      </div>
    </a-table>
    <!-- 查看详情 -->
    <a-modal
      v-model="visible"
      v-if="bidInfo"
      title="查看详情"
      @cancel="visible = false"
      width="800px"
    >
      <div class="see-info-body">
        <div class="see-info-head">
          <span v-if="bidInfo.bidCompanyLogo">
            <v-img
              :attachment-id="bidInfo.bidCompanyLogo"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </span>
          <div v-else class="logoText">
            {{ bidInfo.bidCompanyName.slice(0, 4) }}
          </div>
          <div class="company-info">
            <strong>{{ bidInfo.bidCompanyName }}</strong>
            <p>
              <span><a-icon type="user" />{{ bidInfo.bidUserName }}</span>
              <span><a-icon type="mobile" />{{ bidInfo.bidContactPhone }}</span>
            </p>
          </div>
        </div>
        <div class="see-info-content-body">
          <div class="see-info-content-tab">
            <span :class="{ active: seeInfo == 0 }" @click="seeInfo = 0"
              >方案详情</span
            >
          </div>
          <div class="see-info-content">
            <div class="see-my-info" v-if="seeInfo == 0">
              <div class="see-plan-date">
                <div>
                  <p class="see-plan-title">预估成本</p>
                  <span>￥{{ bidInfo.bidPrice }}万元</span>
                </div>
                <div>
                  <p class="see-plan-title">提交时间</p>
                  <span>{{ bidInfo.bidTime }}</span>
                </div>
              </div>
              <div class="see-plan-info">
                <p class="see-plan-title">方案说明</p>
                <span v-html="bidInfo.bidBrief"></span>
              </div>
              <div class="see-plan-info">
                <p class="see-plan-title">相关附件</p>
                <div class="see-plan-annex">
                  <v-attachment
                    v-for="item in bidInfo.bidAttmIds"
                    :key="item"
                    :attachment-id="item"
                  />
                </div>
              </div>
            </div>
            <div v-if="seeInfo == 1">
              <div class="see-enterprise-info">
                <div>
                  <span>法定代表人</span>
                  <p>{{ bidInfo.company.contactPerson }}</p>
                </div>
                <div>
                  <span>经营状态</span>
                  <p>{{ bidInfo.company.businessStatus }}</p>
                </div>
                <div>
                  <span>成立日期</span>
                  <p>2018-10-19</p>
                </div>
                <div>
                  <span>注册资本</span>
                  <p>
                    {{ bidInfo.company.regCapital }}万{{
                      bidInfo.company.regCapitalUnit
                    }}
                  </p>
                </div>
                <div>
                  <span>企业规模</span>
                  <p>{{ bidInfo.company.companyScale }}</p>
                </div>
                <div>
                  <span>所属行业</span>
                  <p>{{ bidInfo.company.industry }}</p>
                </div>
                <div style="width: 100%">
                  <span>统一社会信用代码</span>
                  <p>{{ bidInfo.company.socialCreditCode }}</p>
                </div>
                <div style="width: 100%">
                  <span>经营范围</span>
                  <p>{{ bidInfo.company.businessScope }}</p>
                </div>
                <div style="width: 100%">
                  <span>企业简介</span>
                  <p>{{ bidInfo.company.introduction }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <a-button class="btn-gray" @click="visible = false">取 消</a-button>
        <a-button
          class="btn-red"
          @click="chooseBid"
          v-if="!bidInfo.isChosen && status === '6'"
          >选择TA
        </a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import VAttachment from "@/components/VAttachment";
import { chooseOrderBid, getSceneAllBid } from "@/api/cjkf/scn-scene";

const columns = [
  {
    title: "建设单位",
    dataIndex: "bidCompanyName",
    scopedSlots: { customRender: "bidCompanyName" },
  },
  {
    title: "报价",
    dataIndex: "bidPrice",
    scopedSlots: { customRender: "bidPrice" },
  },
  {
    title: "提交时间",
    dataIndex: "bidTime",
  },
  {
    title: "操作",
    dataIndex: "operate",
    scopedSlots: { customRender: "operate" },
  },
];
export default {
  props: ["id", "status"],
  components: {
    VAttachment,
  },
  data() {
    return {
      columns,
      data: [],
      visible: false,
      seeInfo: 0,
      sceneId: "",
      bidInfo: null,
      bidId: "",
      uploadInfo: [],
      attachmentIds: [],
    };
  },
  mounted() {
    this.sceneId = this.id;
    this.loadGetSceneAllBid();
  },
  methods: {
    loadGetSceneAllBid() {
      getSceneAllBid(this.sceneId).then((res) => {
        this.data = res;
        this.pageData = res;
      });
    },
    upload(val) {
      if (val) {
        this.uploadInfo.push(val.data);
        this.attachmentIds.push(val.data.attachmentId);
      }
    },
    delAnnex(index) {
      this.uploadInfo.splice(index, 1);
      this.attachmentIds.splice(index, 1);
    },
    jiebangDetail(info) {
      this.bidInfo = info;
      this.bidId = info.id;
      this.visible = true;
    },
    chooseBid() {
      const { bidId } = this;
      let _this = this;
      this.$confirm({
        title: "您确定选他吗?",
        onOk() {
          chooseOrderBid(bidId).then(() => {
            _this.$emit("choose-bid", true);
            _this.visible = false;
            _this.loadGetSceneAllBid();
          });
        },
        onCancel() {},
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.jiebang-detail {
  cursor: pointer;
  color: #ea0b06;
}

.logoText {
  width: 80px;
  height: 80px;
  background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
  border-radius: 4px;
  margin-right: 24px;
  color: #fff;
  font-size: 24px;
  padding: 7px 16px;
  line-height: 33px;
}

.see-info-body {
  .see-info-head {
    display: flex;
    margin-bottom: 24px;

    > span {
      width: 80px;
      height: 80px;
      margin-right: 24px;

      > img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    > .company-info {
      flex: 1;

      > strong {
        color: #262626;
        font-size: 20px;
        line-height: 20px;
        display: block;
        margin-bottom: 16px;
      }

      > p {
        display: flex;

        > span {
          display: flex;
          align-items: center;
          margin-right: 24px;
          color: #595959;
          line-height: 14px;

          > i {
            margin-right: 8px;
          }
        }
      }
    }
  }

  .see-info-content-body {
    .see-info-content-tab {
      background: #fafafa;
      border-bottom: 1px solid #f0f0f0;
      display: flex;

      > span {
        height: 48px;
        line-height: 48px;
        border-bottom: 2px solid transparent;
        margin-right: 40px;
        cursor: pointer;

        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }
      }
    }

    .see-info-content {
      padding: 20px 0;
    }
  }
}

.see-my-info {
  .see-plan-title {
    line-height: 16px;
    color: #8c8c8c;
    margin-bottom: 24px;
    padding-left: 12px;
    border-left: 4px solid #ea0b06;
  }

  .see-plan-date {
    display: flex;
    margin-bottom: 40px;

    > div {
      flex: 1;

      > span {
        display: block;
        line-height: 20px;
        color: #262626;
      }
    }
  }

  .see-plan-info {
    margin-bottom: 40px;

    > span {
      line-height: 24px;
      color: #262626;
    }

    .see-plan-annex {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        padding: 0 16px;
        margin-bottom: 16px;
        cursor: pointer;

        &:last-child {
          margin-bottom: 0;
        }

        > div {
          display: flex;
          align-items: center;

          > img {
            margin-right: 8px;
          }

          > p {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }

        > span {
          color: #ea0b06;
          cursor: pointer;
          display: none;
        }

        &:hover {
          background: #f5f5f5;

          > span {
            display: block;
          }
        }
      }
    }
  }
}

.jiebang-detail-body {
  position: relative;

  > img {
    display: block;
    max-width: 50px;
    position: absolute;
    left: -60px;
    top: -10px;
  }
}

.see-enterprise-info {
  display: flex;
  flex-wrap: wrap;
  border-top: 1px solid #f0f0f0;
  border-left: 1px solid #f0f0f0;

  > div {
    width: 50%;
    display: flex;

    > span {
      width: 140px;
      line-height: 32px;
      background: #ebf9f6;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      padding-left: 10px;
      color: #8c8c8c;
      font-size: 14px;
    }

    > p {
      flex: 1;
      padding: 0 10px;
      border-right: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      font-size: 14px;
      height: 32px;
      line-height: 32px;
      color: #262626;
    }

    &:nth-last-child(2) {
      > p {
        height: 72px;
        line-height: 22px;
        overflow: hidden;
      }
    }

    &:last-child {
      > p {
        height: 72px;
        line-height: 22px;
        overflow: hidden;
      }
    }
  }
}
</style>
