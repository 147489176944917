import { PortalRequest as request } from "@/utils/request";

// 用户中心活动列表
export const getActivity = (params, data) => {
    return request({
        url: "/activity-enroll/list/me",
        method: "post",
        params: params,
        data,
    });
};

// 课程关联活动的报名验证
export const verificationEnroll = ( data) => {
    return request({
        url: "/course-enroll/verification",
        method: "post",
        data,
    });
};

// 用户中心课程列表
export const getCourse = (params, data) => {
    return request({
        url: "/course-enroll/list/me",
        method: "post",
        params: params,
        data,
    });
};

// 前台活动列表
export const getActivityList = (params, data) => {
    return request({
        url: `/activity/list`,
        method: "post",
        params: params,
        data,
    });
};

// 前台课程列表
export const getCourseList = (params, data) => {
    return request({
        url: `/course/list`,
        method: "post",
        params: params,
        data,
    });
};



// 前台活动详情
export const getActivityInfo = (id) => {
    return request({
        url: `/activity/${id}`,
        method: "get",
    });
};

// 前台课程详情
export const getCourseInfo = (id) => {
    return request({
        url: `/course/${id}`,
        method: "get",
    });
};

// 前台活动报名
export const activitySignUp = (id,data) => {
    return request({
        url: `/activity-enroll/${id}/sign-up`,
        method: "post",
        data
    });
};

// 报名课程-根据课程id
export const courseSignUp = (data) => {
    return request({
        url: `/course-enroll/sign-up`,
        method: "post",
        data
    });
};

// 活动小程序吗
export const activityQrCode = (id) => {
    return request({
        url: `/activity/${id}/qrCode`,
        method: "get",
    });
};
