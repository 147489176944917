import {PortalRequest as request} from '@/utils/request'

/**
 * 分页查看问卷
 * @param pageParams
 * @param data
 * @returns {*}
 */
export const getQuestionnaireList = (pageParams, data) => {
  return request({
    url: '/questionnaire/list',
    method: 'post',
    params: pageParams,
    data
  })
}

/**
 * 问卷详情（包括段落、问题、选项、选项组）
 * @param id
 * @returns {*}
 */
export const getQuestionnaire = (id) => {
  return request({
    url: `/questionnaire/${id}`,
    method: 'get'
  })
}

/**
 * 问卷详情（只有问卷本身）
 * @param id
 * @returns {*}
 */
export const questionnaireDetail = (id) => {
  return request({
    url: `/questionnaire/${id}/detail`,
    method: 'get'
  })
}

/**
 * 更新问卷
 * @param data
 * @returns {*}
 */
export const updateQuestionnaire = (data) => {
  return request({
    url: `/questionnaire/${data.id}/update`,
    method: 'post',
    data
  })
}

/**
 * 创建模板问卷
 * @param data
 * @returns {*}
 */
export const createTemplate = (data) => {
  return request({
    url: `/questionnaire/template/create`,
    method: 'post',
    data
  })
}

/**
 * 删除问卷
 * @param id
 * @returns {*}
 */
export const deleteQuestionnaire = (id) => {
  return request({
    url: `/questionnaire/${id}/delete`,
    method: 'delete'
  })
}

/**
 * 上线问卷
 * @param id
 * @returns {*}
 */
export const publishQuestionnaire = (id) => {
  return request({
    url: `/questionnaire/${id}/publish`,
    method: 'get'
  })
}

/**
 * 下线问卷
 * @param id
 * @returns {*}
 */
export const closeQuestionnaire = (id) => {
  return request({
    url: `/questionnaire/${id}/close`,
    method: 'get'
  })
}

/**
 * 设为模板
 * @param id
 * @returns {*}
 */
// export const setTemplate = (id) => {
//   return request({
//     url: `/questionnaire/${id}/set-template`,
//     method: 'get'
//   })
// }

/**
 * 选择模板列表
 * @param id
 * @returns {*}
 */
// export const getTemplateList = () => {
//   return request({
//     url: '/questionnaire/template/list',
//     method: 'get'
//   })
// }

/**
 * 根据模板问卷id来新增问卷
 * @returns {*}
 * @param data
 */
// export const createQuestionnaire = (data) => {
//   return request({
//     url: `/questionnaire/${data.id}/create`,
//     method: 'post',
//     data
//   })
// }

/**
 * 分页查看段落列表
 * @returns {*}
 * @param pageParams
 * @param data
 */
// export const getSectionList = (pageParams, data) => {
//   return request({
//     url: '/section/list',
//     method: 'post',
//     params: pageParams,
//     data
//   })
// }

/**
 * 段落详情
 * @param id
 * @returns {*}
 */
// export const getSection = (id) => {
//   return request({
//     url: `/section/${id}`,
//     method: 'get'
//   })
// }

/**
 * 删除段落
 * @param id
 * @returns {*}
 */
// export const deleteSection = (id) => {
//   return request({
//     url: `/section/${id}/delete`,
//     method: 'delete'
//   })
// }

/**
 * 创建段落
 * @param data
 * @returns {*}
 */
// export const createSection = (data) => {
//   return request({
//     url: '/section/create',
//     method: 'post',
//     data
//   })
// }

/**
 * 更新段落
 * @param data
 * @returns {*}
 */
// export const updateSection = (data) => {
//   return request({
//     url: `/section/${data.id}/update`,
//     method: 'post',
//     data
//   })
// }

/**
 * 分页查看问题列表
 * @returns {*}
 * @param pageParams
 * @param data
 */
// export const getQuestionList = (pageParams, data) => {
//   return request({
//     url: '/question/list',
//     method: 'post',
//     params: pageParams,
//     data
//   })
// }

/**
 * 问题详情
 * @param id
 * @returns {*}
 */
// export const getQuestion = (id) => {
//   return request({
//     url: `/question/${id}`,
//     method: 'get'
//   })
// }

/**
 * 删除问题
 * @param id
 * @returns {*}
 */
// export const deleteQuestion = (id) => {
//   return request({
//     url: `/question/${id}/delete`,
//     method: 'delete'
//   })
// }

/**
 * 创建问题
 * @param data
 * @returns {*}
 */
// export const createQuestion = (data) => {
//   return request({
//     url: '/question/create',
//     method: 'post',
//     data
//   })
// }

/**
 * 更新问题
 * @param data
 * @returns {*}
 */
// export const updateQuestion = (data) => {
//   return request({
//     url: `/question/${data.id}/update`,
//     method: 'post',
//     data
//   })
// }

/**
 * 根据问卷id统计问题答复下各选择选项数量
 * @param id
 * @returns {*}
 */
// export const getStatistics = (id) => {
//   return request({
//     url: `/questionnaire/${id}/statistics`,
//     method: 'get'
//   })
// }

/**
 * 分页查看问卷答复列表
 * @returns {*}
 * @param pageParams
 * @param data
 */
// export const getResponseList = (pageParams, data) => {
//   return request({
//     url: '/response/list',
//     method: 'post',
//     params: pageParams,
//     data
//   })
// }

/**
 * 根据答复id获取问卷答复
 * @param id
 * @returns {*}
 */
// export const getResponse = (id) => {
//   return request({
//     url: `/response/${id}`,
//     method: 'get'
//   })
// }

/**
 * 根据问卷id获取新问卷答复
 * @param id
 * @returns {*}
 */
export const getNewResponse = (id,userid) => {
  return request({
    url: `questionnaire/${id}/${userid}/response`,
    method: 'get'
  })
}

/**
 * 创建问卷答复
 * @param data
 * @returns {*}
 */
export const createResponse = (data) => {
  return request({
    url: '/response/create',
    method: 'post',
    data
  })
}

/**
 * 更新问卷答复
 * @param data
 * @returns {*}
 */
// export const updateResponse = (data) => {
//   return request({
//     url: `/response/${data.id}/update`,
//     method: 'post',
//     data
//   })
// }
