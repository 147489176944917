<template>
  <div class="home-head">
    <div class="head-right" @click="home">
      <v-img
        :attachment-id="tenant.logoAttmId"
        :default-img="require('@/assets/img/cmrt.png')"
        v-if="tenant.logoAttmId"
      />
    </div>

    <div
      class="home-nav"
      :class="[isLogin ? 'login-home-nav' : 'noLogin-home-nav']"
    >
      <div class="home-nav-list">
        <template v-for="(el, index) in navigations">
          <router-link
            #default="{ href, route, navigate, isActive }"
            :exact="index == 0"
            :key="index"
            :to="el.navigationPath"
            active-class="active"
            custom
            tag="div"
          >
            <div @click="openPage(el.navigationPath)" v-if="el.isOpen">
              {{ el.navigationName }}
            </div>
            <div :class="[isActive && 'active']" @click="navigate" v-else>
              {{ el.navigationName }}
            </div>
          </router-link>
        </template>
      </div>
    </div>

    <div class="home-login">
      <div
        v-if="tenant.homePageType !== '2'"
        class="home-nav-search"
        :class="[isLogin ? 'login-search' : 'nav-search']"
      >
        <a-popover placement="left" v-model="popleft" trigger="click">
          <template slot="content">
            <a-popover
              placement="bottomLeft"
              class="input-pop"
              v-model="searchShow"
            >
              <template slot="content">
                <div class="home-nav-search-tag">
                  <div>
                    <p>历史搜索</p>
                    <div>
                      <span
                        :key="index"
                        @click="popularSearchBtn(item.title)"
                        v-for="(item, index) in searchDatas"
                        >{{ item.title }}
                      </span>
                    </div>
                  </div>
                  <div>
                    <p>热门搜索</p>
                    <div>
                      <span @click="popularSearchBtn('医疗')">医疗</span>
                      <span @click="popularSearchBtn('金融')">金融</span>
                      <span @click="popularSearchBtn('教育')">教育</span>
                    </div>
                  </div>
                </div>
              </template>
              <a-input
                class="modal-input"
                @blur="searchBlur"
                @pressEnter="onSearch"
                placeholder="请输入关键词"
                size="small"
                style="width: 240px"
                v-model="searchVal"
              >
                <span slot="suffix" @click="onSearch" style="cursor: pointer">
                  <img alt="图片" src="@/assets/img/search.png" />
                </span>
              </a-input>
            </a-popover>
            <a-icon type="close" @click="popleft = false" class="pop-icon" />
          </template>

          <!--          放大镜-->
          <img
            alt="图片"
            class="serch-img"
            src="@/assets/img/search.png"
            @click="popleft = true"
          />
        </a-popover>

        <a-popover
          class="serch-box"
          placement="bottomLeft"
          v-model="searchPopover"
        >
          <template slot="content">
            <div class="home-nav-search-tag">
              <div>
                <p>历史搜索</p>
                <div>
                  <span
                    :key="index"
                    @click="popularSearchBtn(item.title)"
                    v-for="(item, index) in searchDatas"
                    >{{ item.title }}
                  </span>
                </div>
              </div>
              <div>
                <p>热门搜索</p>
                <div>
                  <span @click="popularSearchBtn('医疗')">医疗</span>
                  <span @click="popularSearchBtn('金融')">金融</span>
                  <span @click="popularSearchBtn('教育')">教育</span>
                </div>
              </div>
            </div>
          </template>
          <a-input
            @blur="searchBlur"
            @focus="searchFocus"
            @pressEnter="onSearch"
            placeholder="请输入关键词"
            size="large"
            style="width: 240px"
            v-model="searchVal"
          >
            <span slot="suffix" @click="onSearch" style="cursor: pointer">
              <img alt="图片" src="@/assets/img/search.png" />
            </span>
          </a-input>
        </a-popover>
      </div>

      <template v-if="!userInfo">
        <span @click="$router.push('/login')">登录</span>
        <p @click="$router.push('/register')">立即注册</p>
      </template>
      <template v-else>
        <!--        <template v-if="companyList != ''">
          <a-dropdown
            :trigger="['click']"
            placement="bottomLeft"
            :getPopupContainer="
              (triggerNode) => {
                return triggerNode.parentNode;
              }
            "
          >
            <template slot="overlay">
              <div class="user-layout-header-enterprise">
                <p>企业切换</p>
                <div>
                  <div
                    v-for="(el,index) in companyList"
                    :key="el.id"
                    @click="toggleCompany(el.id, el,index)"
                  >
                    <span>{{ el.companyName.substring(0, 4) }}</span>
                    <div>
                      <span>{{ el.companyName }}</span>
                      <p>
                        <img
                          v-if="el.verifyStatus == 1"
                          src="@/assets/img/userinfo1.png"
                          alt="图片"
                        />
                        <img
                          v-else-if="el.verifyStatus == 2"
                          src="@/assets/img/userinfo4.png"
                          alt="图片"
                        />
                        <img
                          v-else-if="el.verifyStatus == 3"
                          src="@/assets/img/userinfo2.png"
                          alt="图片"
                        />
                        <img
                          v-else-if="el.verifyStatus == 4"
                          src="@/assets/img/userinfo3.png"
                          alt="图片"
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <span @click="(e) => e.preventDefault()" style="line-height:52px">{{ companyList[companyIndex].companyName }}<a-icon type="down"
            /></span>
          </a-dropdown>
        </template>-->

        <span @click="userCenter">控制台</span>
        <a-dropdown
          :trigger="['click']"
          overlay-class-name="dropdown-user-info"
          placement="bottomRight"
        >
          <div
            @click="(e) => e.preventDefault()"
            class="layout-header-user-info"
          >
            <span>
              <v-img
                :attachment-id="userInfo.profilePictureAttmId"
                :default-img="require('@/assets/img/defaultavatar.png')"
              />
            </span>
            <a-icon type="caret-down" />
          </div>
          <a-menu slot="overlay">
            <a-menu-item>
              <p @click="userInfoBtn" class="layout-header-user-btn">
                基本信息
                <!-- <span
                                  :class="{
                                    yrz: userInfo.verifyStatus == 3,
                                    drz: userInfo.verifyStatus == 1,
                                    wtg: userInfo.verifyStatus == 4,
                                  }"
                                  >{{
                                    userInfo.verifyStatus | dict("USER_VERIFY_STATUS")
                                  }}</span
                                > -->
              </p>
            </a-menu-item>
            <a-menu-item>
              <p @click="setUp" class="layout-header-user-btn">安全设置</p>
            </a-menu-item>
            <a-menu-item>
              <p
                @click="$router.push('/logout')"
                class="layout-header-user-btn quit-login"
              >
                退出
              </p>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { mapActions, mapGetters } from "vuex";
import { getTenantNavigations } from "@/api/common";
import { getConferenceToken } from "@/api/conference";

export default {
  components: {
    VImg,
  },
  computed: mapGetters(["userInfo", "tenant", "companyList", "isLogin"]),
  data() {
    return {
      navigations: [],
      searchVal: "",
      searchPopover: false,
      popleft: false,
      searchShow: false,
      searchDatas: [],
      companyIndex: 0,
    };
  },
  methods: {
    // 切换企业
    toggleCompany(id, el, index) {
      if (this.companyIndex != index) {
        this.$emit("input", false);
      }
      this.$store.commit("SET_COMPANY_ID", id);
      // this.$store.commit("SET_COMPANY_STATUS", el.verifyStatus);
      this.companyIndex = index;
    },
    onSearch() {
      let { searchVal } = this;
      if (!searchVal) {
        this.$message.warning("请输入搜索关键词！");
        return;
      }
      this.$router.push(`/searchInfo?val=${searchVal}`);
      this.searchVal = "";
    },
    popularSearchBtn(val) {
      this.$router.push(`/searchInfo?val=${val}`);
    },
    home() {
      this.$router.push("/");
    },
    setUp() {
      window.location.href = `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userSettings`;
    },
    userInfoBtn() {
      window.location.href = `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userInfo`;
    },
    userCenter() {
      const { tenant } = this;
      window.location.href = `${process.env.VUE_APP_ACCOUNT_PREFIX}?tenantId=${tenant.id}`;
    },
    loadNavigations() {
      const { tenant } = this;
      getTenantNavigations(tenant.id).then((list) => {
        this.navigations = list;
      });
    },
    openPage(href) {
      window.open(href);
    },
    searchFocus(e) {
      this.searchPopover = true;
    },
    searchBlur(e) {
      let _this = this;
      setTimeout(() => {
        _this.searchPopover = false;
      }, 300);
    },
    toConference() {
      getConferenceToken({}).then((res) => {
        window.open(
          `http://localhost:9527/third-login?sign=${encodeURIComponent(res)}`
        );
      });
    },
  },
  mounted() {
    if (localStorage.getItem("searchData")) {
      this.searchDatas = JSON.parse(localStorage.getItem("searchData"));
    }
    this.loadNavigations();
  },
};
</script>

<style lang="scss" scoped>
.home-head {
  background: #fff;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;

  > .head-right {
    cursor: pointer;
    height: 38px;
    display: flex;
    align-items: center;

    > img {
      max-height: 100%;
    }
  }

  .home-nav {
    position: absolute;
    left: 50%;
    margin-left: -640px;
    display: flex;
    align-items: center;

    .home-nav-list {
      display: flex;

      > div {
        color: #262626;
        margin-right: 30px;
        height: 52px;
        line-height: 52px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          border-color: #ea0b06;
          color: #ea0b06;
        }
        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .home-nav-search {
      position: absolute;
      right: 0px;
    }
  }

  .home-login {
    display: flex;
    align-items: center;
    font-size: 14px;

    > span {
      cursor: pointer;
      margin-right: 24px;
    }

    > p {
      cursor: pointer;
      background: #ea0b06;
      width: 96px;
      height: 75px;
      color: #fff;
      text-align: center;
      line-height: 75px;
    }
  }

  .layout-header-user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 75px;
    margin-right: 24px;

    > span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;

      > img {
        display: block;
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }
    }

    > i {
      color: #262626;
    }
  }
}

.layout-header-user-btn {
  margin-bottom: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;

  > span {
    font-size: 12px;
    cursor: pointer;

    &.drz {
      color: #faad14;
    }

    &.yrz {
      color: #52c41a;
    }

    &.wtg {
      color: #ea0b06;
    }
  }
}

.quit-login {
  justify-content: center;
  height: 46px;
  line-height: 46px;
}

.home-nav-search-tag {
  width: 308px;
  > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    > p {
      font-size: 14px;
      color: #8c8c8c;
      line-height: 12px;
      margin-bottom: 8px;
    }

    > div {
      display: flex;
      flex-wrap: wrap;

      > span {
        height: 32px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        padding: 0 16px;
        font-size: 12px;
        color: #262626;
        line-height: 30px;
        margin: 8px 8px 0 0;
        cursor: pointer;
      }
    }
  }
}
.user-layout-header-enterprise {
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
    0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);

  > p {
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    color: #000;
    padding: 0 16px;
  }

  > div {
    > div {
      padding: 12px 16px;
      display: flex;
      cursor: pointer;

      > span {
        width: 40px;
        height: 40px;
        background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
        border-radius: 4px;
        margin-right: 12px;
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        line-height: 16px;
      }

      > div {
        flex: 1;

        > span {
          display: block;
          font-size: 14px;
          color: #000000;
          line-height: 18px;
          margin-bottom: 5px;
        }

        > p {
          display: flex;

          > span {
            display: flex;
            align-items: center;
            padding: 0 6px;
            height: 18px;
            background: linear-gradient(90deg, #e6e8eb 0%, #ffffff 100%);
            border-radius: 2px;
            border: 1px solid #f0f1f2;
            font-size: 12px;
            color: #a0acba;

            > i {
              margin-right: 3px;
            }
          }
        }
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}

.home-nav-search {
  position: relative;
  margin-right: 20px;
}

.serch-img {
  cursor: pointer;
  display: none;
}

.modal-input {
  &::v-deep .ant-input {
    border: none;
  }
}

.input-pop::v-deep {
  .ant-popover-inner-content {
    padding: 0;
  }
}

.pop-icon {
  &:hover,
  &:active {
    color: #ea0b06;
  }
}

@media screen and (max-width: 1600px) {
  .home-head {
    .home-nav {
      margin-left: -35%;

      .home-nav-search {
        right: 0;
      }
    }
  }
}

.noLogin-home-nav {
  @media screen and (max-width: 1400px) {
    width: calc(100% - 400px);
  }
  @media screen and (min-width: 1400px) and (max-width: 1600px) {
    width: calc(100% - 500px);
  }

  @media screen and (min-width: 1600px) {
    width: calc(100% - 700px);
  }
}

.login-home-nav {
  @media screen and (max-width: 1600px) {
    width: calc(100% - 600px);
  }

  @media screen and (min-width: 1600px) {
    width: calc(100% - 700px);
  }
}

.nav-search {
  @media screen and (max-width: 1400px) {
    .serch-img {
      display: block;
    }
    .serch-box {
      display: none;
    }
  }
  @media (min-width: 1400px) {
    .serch-img {
      display: none;
    }
    .serch-box {
      display: block;
    }
  }
}

.login-search {
  @media screen and (max-width: 1600px) {
    .serch-img {
      display: block;
    }
    .serch-box {
      display: none;
    }
  }
  @media (min-width: 1600px) {
    .serch-img {
      display: none;
    }
    .serch-box {
      display: block;
    }
  }
}
</style>
<style>
.home-nav-search input {
  font-size: 14px;
  background: #f1f2f5;
  border-radius: 2px;
  border: none;
}

.home-nav-search .ant-input-suffix {
  color: #ea0b06;
}

.dropdown-user-info {
  /* width: 200px; */
  width: 170px;
  background: #2f2f2f;
}

.dropdown-user-info .ant-dropdown-menu {
  background-color: transparent;
}

.dropdown-user-info .ant-dropdown-menu-item,
.dropdown-user-info .ant-dropdown-menu-submenu-title {
  padding: 5px 16px;
}

.dropdown-user-info .ant-dropdown-menu-item:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.dropdown-user-info .ant-dropdown-menu-item:hover,
.dropdown-user-info .ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
</style>
