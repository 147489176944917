<template>
  <div class="treasury">
    <y-search
      :logo="treasuryLogo"
      type="找服务商"
      :codeData="codeData"
      v-model="dataInfo.companyName"
      @searchData="toSearch"
      @search="search"
    ></y-search>
    <div class="treasury-body">
      <!--      <a-breadcrumb separator=">" class="treasury-bread">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item href="">服务商库</a-breadcrumb-item>
      </a-breadcrumb>-->

      <div class="result" v-show="codeText">
        搜索结果> <span>"{{ codeText }}"</span>
      </div>

      <div class="body-content">
        <div style="width: 976px">
          <div class="service-filter">
            <div>
              <span>服务类型：</span>
              <div>
                <span
                  v-for="(item, index) in serviceType"
                  :key="index"
                  :class="{ active: serviceTypeId === item.id }"
                  @click="serviceTypeChange(item)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div>
              <span>所属行业：</span>
              <div>
                <span
                    v-for="(item, index) in industryList"
                    :key="index"
                    :class="{ active: industryId === item.itemValue }"
                    @click="industryTypeChange(item)"
                >{{ item.itemText }}</span
                >
              </div>
            </div>

            <div>
              <span>企业规模：</span>
              <div>
                <span
                  v-for="(item, index) in scaleType"
                  :key="index"
                  :class="{ active: scaleTypeId === item.id }"
                  @click="scaleTypeChange(item)"
                  >{{ item.name }}</span
                >
              </div>
            </div>
          </div>
          <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
            <div v-if="storeList && storeList.length > 0" class="body-left">
              <company-card
                v-for="(item, index) in storeList"
                :key="item.id"
                :val="item"
                :color-num="colorNum(index)"
              ></company-card>
            </div>
            <div v-else style="padding: 100px 0; display: block">
              <a-empty />
            </div>
          </a-skeleton>
          <div class="pageList">
            <pagination
              style="margin-top: 36px"
              :size="page.size"
              :total="page.total"
              :current="page.current"
              @changes="pageChange"
            />
          </div>
        </div>
        <div class="body-right">
          <div class="title">热门服务商</div>
          <div class="content">
            <div
              v-for="(item, index) in storeHotList"
              :key="item.id"
              @click="toDetail(item.id)"
            >
              <div class="imgBox" v-if="item.attmIds">
                <v-img
                  :attachment-id="item.attmIds"
                  :default-img="require('@/assets/img/kctp.png')"
                />
              </div>
              <div
                v-else
                class="logoText"
                :class="[`logo-color-${colorNum(index)}`]"
              >
                {{ item.name.slice(0, 1) }}
              </div>
              <div>
                <p>{{ item.name }}</p>
                <p>{{ item.corpComment }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import YSearch from "@/components/YSearch";
import companyCard from "@/page/treasury/components/companyCard";
import pagination from "../components/pagination.vue";
import treasuryLogo from "@/assets/img/treasury3.png";
import {
  getStorePageList,
  getStoreHot,
  getServiceTypeList,
  getStoreHotHome,
} from "@/api/treasury";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "index",
  data() {
    return {
      codeText: "",
      codeData: "",
      treasuryLogo,
      loading: false,
      page: {
        current: 1,
        size: 10,
      },
      dataInfo: {
        serviceType: "",
        companyScaleType: "",
        companyName: "",
        queryModel: "ISV_LIBRARY",
      },
      industryList:[
        {
          itemText:'全部',
          itemValue:'',
        }
      ], //所属行业
      storeList: [],
      storeHotList: [],
      scaleTypeId: "scale",
      serviceTypeId: "",
      industryId:'',
      serviceType: [
        {
          id: "",
          name: "全部",
          itemValue: "",
        },
      ],
      scaleType: [
        {
          id: "scale",
          name: "全部",
          value: "",
        },
        {
          id: "scale0",
          name: "1-10人",
          value: "1",
        },
        {
          id: "scale1",
          name: "11-50人",
          value: "2",
        },
        {
          id: "scale2",
          name: "51-100人",
          value: "3",
        },
        {
          id: "scale3",
          name: "101-1000人",
          value: "4",
        },
        {
          id: "scale4",
          name: "1001人以上",
          value: "5",
        },
      ],
    };
  },
  components: {
    YSearch,
    companyCard,
    VImg,
    pagination,
  },
  computed: {
    ...mapGetters(["dict"]),
    code() {
      return this.$route.params.code;
    },
  },
  created() {
    this.codeData = this.code;
    this.loadGetServiceTypeList();
  },
  mounted() {
    if (this.code) this.dataInfo.companyName = this.code;
    this.loadGetStorePageList();
    this.loadGetStoreHot();
    this.LoadGetStoreHotHome()
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadGetServiceTypeList() {
      getServiceTypeList().then((res) => {
        let other = {
          id: "-1",
          name: "其他",
        };
        this.serviceType = [...this.serviceType, ...res];
        this.serviceType.push(other);
      });
    },
    LoadGetStoreHotHome(){
      getStoreHotHome(-1).then(res=>{
        this.industryList=[...this.industryList,...res]
      })
    },
    search() {
      this.page.current = 1;
      this.loadGetStorePageList();
    },
    //按顺序取颜色
    colorNum(index) {
      let id = Number(index) + 1;
      if (id <= 4) {
        return id;
      } else {
        let num = id - parseInt(String(id / 4)) * 4;
        if (num === 0) num = 4;
        return num;
      }
    },
    toSearch(item) {
      (this.page = {
        current: 1,
        size: 10,
      }),
        (this.dataInfo.companyName = item.value);
      this.loadGetStorePageList();
    },
    loadGetStorePageList() {
      this.loading = true;
      this.codeText = this.dataInfo.companyName;
      getStorePageList(this.page, this.dataInfo).then((res) => {
        this.storeList = res.records;
        this.page.total = res.total;
        this.loading = false;
      });
    },
    loadGetStoreHot() {
      getStoreHot().then((res) => {
        this.storeHotList = res;
      });
    },

    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetStorePageList();
    },

    industryTypeChange(item){
        this.industryId=item.itemValue
        this.dataInfo.industryType=item.itemValue
        this.page = {
          current: 1,
          size: 10,
        };
        this.loadGetStorePageList();
    },
    scaleTypeChange(item) {
      this.scaleTypeId = item.id;
      this.dataInfo.companyScaleType = item.value;
      this.page = {
        current: 1,
        size: 10,
      };
      this.loadGetStorePageList();
    },
    serviceTypeChange(item) {
      this.serviceTypeId = item.id;
      if (item.id === "-1") {
        this.dataInfo.serviceType = "";
        this.dataInfo.noPushService = "1";
      } else {
        this.dataInfo.noPushService = null;
        this.dataInfo.serviceType = item.id;
      }

      this.page = {
        current: 1,
        size: 10,
      };
      this.loadGetStorePageList();
    },
    toDetail(id) {
      this.$router.push({
        path: "/treasury/provider",
        query: { id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.treasury {
  padding-bottom: 56px;
}
.treasury-body {
  width: 1280px;
  margin: 0 auto;
  > .treasury-bread {
    margin: 24px 0;
    &::v-deep {
      & > :last-child {
        > a {
          color: #262626;
        }
      }
    }
  }
  .result {
    margin-top: 24px;
  }
}

.body-content {
  display: flex;
  margin-top: 24px;
  > :first-child {
    margin-right: 22px;
  }
}

.service-filter {
  background: #fff;
  padding: 24px;

  > div {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 16px;

    > span {
      width: 70px;
      margin-right: 8px;
      line-height: 24px;
      white-space: nowrap;
    }

    > div {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      /* height: 24px;*/
      //overflow: hidden;

      &.active {
        height: auto;
      }

      > span {
        cursor: pointer;
        line-height: 22px;
        margin-bottom: 8px;
        height: 24px;
        border: 1px solid transparent;
        border-radius: 2px;
        padding: 0 16px;
        text-align: center;
        margin-right: 8px;

        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }
      }
    }

    > p {
      width: 80px;
      height: 24px;
      background: #f5f5f5;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 8px;

      > i {
        color: #8c8c8c;
        margin-left: 2px;
        transition: all 0.3s;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
      margin-bottom: 0;
    }
  }
}

.body-right {
  width: 280px;
  height: 599px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  > .title {
    height: 48px;
    padding-left: 16px;
    font-size: 14px;
    color: #262626;
    line-height: 48px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
  }
  > .content {
    padding: 32px 16px;
    > div {
      display: flex;
      cursor: pointer;
      margin-bottom: 24px;
      .logoText {
        width: 80px;
        height: 80px;
        text-align: center;
        border-radius: 4px;
        font-size: 48px;
        margin-right: 12px;
      }
      > .imgBox {
        width: 80px;
        height: 80px;
        border-radius: 4px;
        border: 1px solid #eeeeee;
        margin-right: 12px;
        display: flex;
        align-items: center;
        justify-content: center;
        > img {
          display: block;
          max-width: 100%;
          max-height: 100%;
        }
      }
      > :last-child {
        padding: 4px 0;
        width: 156px;
        > :first-child {
          font-size: 14px;
          color: #595959;
          line-height: 20px;
          margin-bottom: 10px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          word-break: break-all;
        }
        > :last-child {
          font-size: 12px;
          color: #8c8c8c;
          line-height: 20px;
          text-overflow: -o-ellipsis-lastline;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          line-clamp: 2;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
</style>
