<template>
  <div class="player-box">
    <d-player
      ref="player"
      style="width: 100%; height: 100%"
      :options="dplayerOptions"
    ></d-player>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "videoPlayer",
  props: {
    videoId: String,
  },
  data() {
    return {
      // 针对部分options属性进行配置
      dplayerOptions: {
        container: document.getElementById("player"), //播放器容器
        theme: "#b7daff", // 风格颜色，例如播放条，音量条的颜色
        loop: false, // 是否自动循环
        lang: "zh-cn", // 语言，'en', 'zh-cn', 'zh-tw'
        screenshot: false, // 是否允许截图（按钮），点击可以自动将截图下载到本地
        hotkey: true, // 是否支持热键，调节音量，播放，暂停等
        preload: "auto", // 自动预加载
        volume: 0.7, // 初始化音量
        video: {
          url: "", // 播放视频的路径
          defaultQuality: 0, // 默认是HD
        },
      },
    };
  },
  computed: {
    ...mapGetters(["settings"]),
  },
  created() {
    const { settings, videoId } = this;
    this.dplayerOptions.video.url = `${settings.OBS_PREFIX}/${videoId}`;
    /* this.$set(
      this.dplayerOptions.video,
      "url",
      `${settings.OBS_PREFIX}/${videoId}`
    );*/
    console.log(
      this.dplayerOptions.video,
      "this.dplayerOptions.video.url------"
    );
  },
};
</script>

<style scoped lang="scss">
.player-box {
  width: 952px;
  height: 535px;
  margin: 0 auto;
  /* display: flex;
  justify-content: center;
  align-content: center;*/
  ::v-deep {
    /*.dplayer-video {
      max-width: 952px;
      max-height: 535px;
    }*/
  }
}
</style>
