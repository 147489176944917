<template>
  <div class="provider-details">
    <div class="details-banner">
      <img
        class="banner-img"
        src="@/assets/img/provider_details_banner.png"
        alt=""
      />

      <div>
        <div class="banner-icon">
          <v-img
            v-if="companyInfo.attmIds"
            :attachment-id="companyInfo.attmIds"
            :defaultImg="require('@/assets/img/kctp.png')"
          />

          <!--          <div v-else class="logoText" :class="[`logo-color-${colorNum}`]">
            {{ logoText }}
          </div>-->
          <div v-else class="logoText">{{ logoText }}</div>
        </div>

        <div class="banner-con">
          <p>{{ companyInfo.name }}</p>
          <div class="banner-info" v-if="companyInfo.verifyStatus">
            <img src="@/assets/img/provider_details1.png" alt="" />
            <p>{{ companyStatus[companyInfo.verifyStatus] }}</p>
          </div>
          <div>
            <!--            <div>
              <span>所属地：</span>
              <p>{{companyInfo.address}}</p>
            </div>-->
            <div>
              <span>成立时间：</span>
              <p v-if="companyInfo">
                {{ companyInfo.createTime.slice(0, 10) }}
              </p>
            </div>
            <div>
              <span>所属行业：</span>
              <p>{{ companyInfo.industryType | dict("INDUSTRY") }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="details-body">
      <div class="company-info">
        <div class="list-top">
          <div></div>
          <p>公司档案</p>
        </div>

        <div class="info-body">
          <p class="info-title" style="margin-bottom: 16px">公司介绍</p>

          <p>{{ companyInfo.corpComment }}</p>

          <p class="info-title">工商信息</p>

          <div class="info-list">
            <div class="info-list-item">
              <div>
                <span>法定代表人：</span
                ><span>{{ companyInfo.contactPerson || "一" }}</span>
              </div>
              <div>
                <span>统一社会信用代码：</span
                ><span>{{ companyInfo.businessLicense || "一" }}</span>
              </div>
              <div>
                <span>企业规模：</span
                ><span v-if="companyInfo.companyScaleType">{{
                  companyInfo.companyScaleType | dict("COMPANY_SCALE_TYPE")
                }}</span>
                <span v-else>一</span>
              </div>
              <div>
                <span>成立时间：</span
                ><span>{{ companyInfo.establishmentDate || "一" }}</span>
              </div>
              <div>
                <span>所属领域：</span>
                <span
                  v-if="
                    companyInfo.corpField && companyInfo.corpField.length > 0
                  "
                >
                  <span
                    v-for="(el, index) in companyInfo.corpField"
                    :key="index"
                    >{{ el | dict("COMPANY_FIELD") }}
                    <span v-if="index !== companyInfo.corpField.length - 1"
                      >、</span
                    ></span
                  >
                </span>
                <span v-else>一</span>
              </div>
              <div>
                <span>服务行业：</span
                ><span v-if="companyInfo.industryType">{{
                  companyInfo.industryType | dict("INDUSTRY")
                }}</span>
                <span v-else>一</span>
              </div>
              <div>
                <span>公司类型：</span
                ><span
                  v-if="companyInfo.corpType && companyInfo.corpType.length > 0"
                >
                  <span v-for="(el, index) in companyInfo.corpType" :key="index"
                    >{{ el | dict("COMPANY_TYPE")
                    }}<span v-if="index !== companyInfo.corpType.length - 1"
                      >、</span
                    ></span
                  >
                </span>
                <span v-else>一</span>
              </div>
              <div>
                <span>公司网址：</span
                ><span>{{ companyInfo.website || "一" }}</span>
              </div>
            </div>
            <div class="business">
              <span>企业地址：</span
              ><span>{{ companyInfo.address || "一" }}</span>
            </div>
            <div class="business">
              <span>主营业务：</span
              ><span>{{ companyInfo.mainProducts || "一" }}</span>
            </div>
          </div>
        </div>
      </div>

      <div class="details-list">
        <div class="list-top">
          <div></div>
          <p>上架服务</p>
        </div>
        <div class="list-box">
          <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
            <div class="list-com" v-if="serviceList && serviceList.length > 0">
              <div
                class="item-box"
                v-for="item in serviceList"
                :key="item.id"
                @click="toService(item.id)"
              >
                <v-img
                  :attachment-id="item.serviceCover"
                  :defaultImg="require('@/assets/img/kctp.png')"
                />
                <div>
                  <div class="list-item-top">
                    <p>{{ item.serviceName }}</p>
                    <p>{{ item.summary }}</p>
                  </div>
                  <div>
                    <div v-if="item.pricingType == 1">
                      <span>￥{{ item.lowestPrice }}</span
                      ><span
                        >/{{ item.buyType | buyTypeDict("BUY_TYPE") }}</span
                      >
                    </div>
                    <div v-else class="price-sel">议价定制</div>
                    <div>
                      <img src="@/assets/img/provider_details2.png" alt="" />
                      <p>{{ item.viewed || 0 }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div
              v-else
              style="
                margin-bottom: 24px;
                padding: 100px 0;
                display: block;
                width: 100%;
                background-color: #ffffff;
              "
            >
              <a-empty />
            </div>
          </a-skeleton>
        </div>
      </div>

      <div class="pageList">
        <pagination
          style="text-align: right"
          v-if="page.total > 4"
          :size="page.size"
          :total="page.total"
          :current="page.current"
          @changes="pageChange"
        />
      </div>

      <div class="member">
        <div class="list-top" style="margin-bottom: 16px">
          <div></div>
          <p>核心成员</p>
        </div>
        <div class="serve-enterprise-yz" v-if="coreMemberList.length > 0">
          <swiper
            ref="swiperThumbs"
            class="swiper subswiper-box"
            :options="swiperOptionThumbs"
          >
            <swiper-slide
              v-for="(el, index) in coreMemberList"
              :key="el.id"
              class="enterprise-swiper-list"
              :class="[`swiper-list-${colorNum(index)}`]"
            >
              <div>{{ el.memberName.slice(0, 1) }}</div>
              <div>
                <div>{{ el.memberName }}</div>
                <div>{{ el.position }}</div>
              </div>
            </swiper-slide>
          </swiper>
          <div class="swiper-next swiper-icon">
            <a-icon type="right" style="color: #8c8c8c" />
          </div>
          <div class="swiper-prev swiper-icon">
            <a-icon type="left" style="color: #8c8c8c" />
          </div>
        </div>
        <div
          v-else
          style="padding: 16px 0; display: block; background-color: #ffffff"
        >
          <a-empty />
        </div>
      </div>

      <div class="certificate">
        <div class="list-top">
          <div></div>
          <p>资质证书</p>
        </div>
        <div class="certificate-box">
          <div class="title">专利证书</div>
          <a-skeleton
            active
            :loading="loadingProject"
            :paragraph="{ rows: 10 }"
          >
            <div class="certificate-box-item" v-if="patentList.length > 0">
              <div class="top-box" v-for="el in patentList" :key="el.id">
                <div class="img-box">
                  <v-img
                    class="img"
                    :attachment-id="el.certificateCoverId"
                    :defaultImg="require('@/assets/img/kctp.png')"
                  />
                </div>
                <div class="box-info">
                  <p>{{ el.patentName || "一" }}</p>
                  <div>
                    <span>专利号：</span>
                    <span>{{ el.patentNo || "一" }}</span>
                  </div>
                  <div>
                    <span class="time">颁发日期：</span>
                    <span>{{ el.issueDate }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              style="padding: 16px 0; display: block; background-color: #ffffff"
            >
              <a-empty />
            </div>
          </a-skeleton>
          <div class="title" style="margin-top: 24px">软件著作权</div>
          <a-skeleton
            active
            :loading="loadingProject"
            :paragraph="{ rows: 10 }"
          >
            <div class="certificate-box-item" v-if="workList.length > 0">
              <div class="bottom-box" v-for="el in workList" :key="el.id">
                <div class="img-box">
                  <v-img
                    class="img"
                    :attachment-id="el.certificateCoverId"
                    :defaultImg="require('@/assets/img/kctp.png')"
                  />
                </div>
                <div class="box-info">
                  <p>{{ el.patentName }}</p>
                  <div>
                    <span>专利号：</span>
                    <span>{{ el.patentNo || "一" }}</span>
                  </div>
                  <div>
                    <span>版本号：</span>
                    <span>{{ el.versionNo || "一" }}</span>
                  </div>
                  <div>
                    <span class="time">颁发日期：</span>
                    <span>{{ el.issueDate }}</span>
                  </div>
                </div>
              </div>
            </div>
            <div
              v-else
              style="padding: 16px 0; display: block; background-color: #ffffff"
            >
              <a-empty />
            </div>
          </a-skeleton>
          <pagination
            style="text-align: right; margin-top: 20px"
            v-if="pageWorkList.total > 5"
            :size="pageWorkList.size"
            :total="pageWorkList.total"
            :current="pageWorkList.current"
            @changes="pageWorkListChange"
          />
        </div>
      </div>

      <div>
        <div class="list-top">
          <div></div>
          <p>项目案例</p>
        </div>
        <div style="margin-top: 16px">
          <a-skeleton
            active
            :loading="loadingProject"
            :paragraph="{ rows: 10 }"
          >
            <div
              class="project-box"
              v-if="projectCaseList && projectCaseList.length > 0"
            >
              <project-card
                v-for="el in projectCaseList"
                :key="el.id"
                :val="el"
              ></project-card>
            </div>
            <div
              v-else
              style="
                padding: 100px 0;
                display: block;
                background-color: #ffffff;
              "
            >
              <a-empty />
            </div>
          </a-skeleton>
          <pagination
            style="margin-top: 20px; text-align: right"
            v-if="pageProject.total > 6"
            :size="pageProject.size"
            :total="pageProject.total"
            :current="pageProject.current"
            @changes="pageProjectChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { getCompanyInfo } from "@/api/res-company";
import { getServiceIsvInfo } from "@/api/service";
import { mapActions, mapGetters } from "vuex";
import { getServicePageList } from "@/api/service";
import pagination from "../components/pagination.vue";
import projectCard from "@/page/treasury/components/projectCard";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import {
  getCoreMemberList,
  getCertificateList,
  getProjectCaseList,
} from "@/api/companyInfo";
export default {
  name: "provider_details",
  components: {
    VImg,
    pagination,
    Swiper,
    SwiperSlide,
    projectCard,
  },
  data() {
    return {
      companyStatus: {
        1: "未认证",
        2: "认证中",
        3: "已通过认证",
        4: "认证失败",
      },
      logoText: "",
      companyInfo: "",
      serviceList: [],
      loading: false,
      loadingProject: false,
      loadingPatent: false,
      loadingWorkList: false,
      coreMemberList: [], //核心成员
      patentList: [], //专利
      workList: [], //软著
      projectCaseList: [], //项目案例
      page: {
        current: 1,
        size: 4,
      },
      //核心成员
      pageCore: {
        current: 1,
        size: 99,
      },
      //资质证书
      pageCertificate: {
        current: 1,
        size: 99,
      },
      pageWorkList: {
        current: 1,
        size: 5,
      },
      //项目案例
      pageProject: {
        current: 1,
        size: 6,
      },
      dataInfo: {},
      pid: "",
    };
  },
  computed: {
    ...mapGetters(["dict"]),
    id() {
      return this.$route.query.id;
    },
    swiperOptionThumbs() {
      return {
        spaceBetween: 24,
        slidesPerView: 5,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      };
    },
  },
  created() {
    this.LoadDictData(["COMPANY_FIELD"]);

    this.LoadDictData(["COMPANY_TYPE"]);

    this.LoadDictData(["BUY_TYPE"]);
  },
  mounted() {
    /* this.colorNum = this.id.slice(this.id.length - 1, this.id.length);
    this.pid = this.id.slice(0, this.id.length - 1);
    this.loadGetServiceIsvInfo(this.pid);*/

    this.loadGetServiceIsvInfo(this.id);

    this.loadGetServicePageList();

    this.LoadDictData(["INDUSTRY"]);
  },

  methods: {
    ...mapActions(["LoadDictData"]),
    //按顺序取颜色
    colorNum(index) {
      let id = Number(index) + 1;
      if (id <= 4) {
        return id;
      } else {
        let num = id - parseInt(String(id / 4)) * 4;
        if (num === 0) num = 4;
        return num;
      }
    },

    //核心成员
    loadGetCoreMemberList() {
      const { companyInfo, pageCore } = this;
      if (companyInfo.businessLicense) {
        let params = {
          businessLicense: companyInfo.businessLicense,
        };
        getCoreMemberList(pageCore, params).then((res) => {
          this.coreMemberList = res.records;
        });
      }
    },
    //资质证书
    loadGetCertificateList() {
      const { companyInfo, pageCertificate } = this;
      if (companyInfo.businessLicense) {
        let params = {
          businessLicense: companyInfo.businessLicense,
          certificateType: "1",
        };
        this.loadingPatent = true;
        getCertificateList(pageCertificate, params).then((res) => {
          this.patentList = res.records;
          this.loadingPatent = false;
        });
      }
    },

    loadGetWorkList() {
      const { companyInfo, pageWorkList } = this;
      if (companyInfo.businessLicense) {
        let data = {
          businessLicense: companyInfo.businessLicense,
          certificateType: "2",
        };
        this.loadingWorkList = true;
        getCertificateList(pageWorkList, data).then((res) => {
          this.workList = res.records;
          this.loadingWorkList = false;
          this.pageWorkList.total = res.total;
        });
      }
    },

    //项目案例
    loadGetProjectCaseList() {
      const { companyInfo, pageProject } = this;
      if (companyInfo.businessLicense) {
        let params = {
          businessLicense: companyInfo.businessLicense,
        };
        this.loadingProject = true;
        getProjectCaseList(pageProject, params).then((res) => {
          this.projectCaseList = res.records;
          this.pageProject.total = res.total;
          this.loadingProject = false;
        });
      }
    },

    //分页查询
    loadGetServicePageList() {
      this.dataInfo.serviceIsvId = this.id;
      this.loading = true;
      getServicePageList(this.page, this.dataInfo).then((res) => {
        this.serviceList = res.records;
        this.page.total = res.total;
        this.loading = false;
      });
    },

    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetServicePageList();
    },

    pageProjectChange(val) {
      this.pageProject.size = val.pageSize;
      this.pageProject.current = val.current;
      this.loadGetProjectCaseList();
    },

    pageWorkListChange(val) {
      this.pageWorkList.size = val.pageSize;
      this.pageWorkList.current = val.current;
      this.loadGetWorkList();
    },

    // 公司信息
    loadGetServiceIsvInfo(id) {
      getServiceIsvInfo(id).then((res) => {
        this.companyInfo = res;

        this.loadGetCoreMemberList();

        this.loadGetCertificateList();

        this.loadGetWorkList();

        this.loadGetProjectCaseList();
        if (!this.companyInfo.isvImg) {
          this.logoText = this.companyInfo.name.slice(0, 4);
        }
      });
    },

    toService(id) {
      this.$router.push({
        path: "/service/details",
        query: { id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.provider-details {
  width: 100%;
  background: #f7f8fa;
  padding-bottom: 56px;
}

.details-body {
  width: 1280px;
  margin: 0 auto;
}

.logoText {
  /* width: 130px;
  height: 130px;
  text-align: center;
  border-radius: 4px;
  font-size: 80px;*/

  width: 130px;
  height: 130px;
  background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
  border-radius: 4px;

  color: #fff;
  font-size: 40px;
  padding: 9px 25px;
  line-height: 50px;
  margin: 0 auto;
}

.details-banner {
  height: 230px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  > .banner-img {
    height: 230px;
    display: block;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
  }
  > div {
    width: 1280px;
    position: absolute;
    left: 50%;
    top: 50px;
    transform: translate(-50%);
    z-index: 10;
    display: flex;
    > .banner-icon {
      width: 130px;
      height: 130px;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
      }
    }
    > .banner-con {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      > p {
        font-size: 34px;
        color: #262626;
        line-height: 48px;
        margin-bottom: 12px;
      }
      > .banner-info {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        > img {
          display: block;
          width: 16px;
          height: 16px;
          margin-right: 8px;
        }
        > p {
          font-size: 16px;
          color: #262626;
          line-height: 21px;
        }
      }
      > :last-child {
        display: flex;
        > div {
          font-size: 14px;
          line-height: 25px;
          margin-right: 32px;
          display: flex;
          > span {
            color: #8c8c8c;
          }
          > p {
            color: #262626;
          }
        }
      }
    }
  }
}

.list-top {
  display: flex;
  align-items: center;
  > div {
    width: 3px;
    height: 15px;
    background: #ea0b06;
    margin-right: 8px;
  }
  > P {
    font-size: 18px;
    font-weight: bold;
    color: #262626;
    line-height: 28px;
  }
}

.details-list {
  width: 1280px;
  margin-top: 16px;
  //margin-bottom: 32px;
  .list-box {
    margin-top: 16px;

    .list-com {
      display: flex;
      flex-wrap: wrap;
      cursor: pointer;
      > :nth-child(2n) {
        &::before {
          content: "";
          position: absolute;
          left: 0;
          top: 32px;
          width: 1px;
          height: 143px;
          background-color: #f0f0f0;
        }
      }
    }
    .item-box {
      display: flex;
      width: 640px;
      padding: 32px;
      background: #ffffff;
      box-sizing: border-box;
      margin-bottom: 24px;
      position: relative;

      > img {
        display: block;
        width: 116px;
        height: 116px;
        margin-right: 32px;
      }
      > div {
        min-height: 143px;
        flex: 1;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        > .list-item-top {
          > :nth-child(1) {
            font-size: 16px;
            color: #262626;
            line-height: 25px;
            margin-bottom: 12px;
          }
          > :nth-child(2) {
            font-size: 12px;
            color: #666666;
            line-height: 18px;
            margin-bottom: 24px;
          }
        }

        > :nth-child(2) {
          display: flex;
          justify-content: space-between;
          > :nth-child(1) {
            > :first-child {
              font-size: 20px;
              color: #ea0b06;
            }
            > :last-child {
              font-size: 12px;
              color: #666666;
            }
          }
          > :nth-child(2) {
            display: flex;
            align-items: center;
            > img {
              display: block;
              width: 16px;
              height: 10px;
              margin-right: 6px;
            }
            > p {
              font-size: 14px;
              color: #d9d9d9;
            }
          }
        }
      }
      .price-sel {
        font-size: 20px;
        color: #ea0b06;
        line-height: 21px;
      }
    }
  }
}

.company-info {
  margin-top: 24px;
  > .info-body {
    background: #ffffff;
    padding: 24px;
    margin-top: 16px;
    > .info-title {
      font-size: 16px;
      color: #262626;
      line-height: 22px;
    }
    > :nth-child(2) {
      font-size: 14px;
      color: #262626;
      line-height: 25px;
      margin-bottom: 24px;
    }

    .info-list {
      > .info-list-item {
        display: flex;
        flex-wrap: wrap;
        > div {
          width: 476px;
          font-size: 14px;
          line-height: 25px;
          margin-top: 16px;
          > :first-child {
            color: #8c8c8c;
          }
          > :last-child {
            color: #262626;
          }
        }
      }
      > .business {
        font-size: 14px;
        line-height: 20px;
        margin-top: 16px;
        > :first-child {
          color: #8c8c8c;
        }
        > :last-child {
          color: #262626;
        }
      }
    }

    .list-item {
    }
  }
}

.member {
  margin-top: 8px;
  margin-bottom: 32px;
  .serve-enterprise-yz {
    height: 152px;
    padding-top: 24px;
    background: #ffffff;
    position: relative;
  }
  .subswiper-box {
    width: 1196px;
    margin: 0 auto;
    .enterprise-swiper-list {
      width: 220px;
      height: 104px;
      background: #fafafa;
      display: flex;
      padding: 24px;
      > :nth-child(1) {
        width: 56px;
        height: 56px;
        font-size: 28px;
        line-height: 56px;
        color: #ffffff;
        text-align: center;
        border-radius: 50%;
        margin-right: 16px;
      }
      > :nth-child(2) {
        margin-top: 4px;
        > :first-child {
          font-size: 18px;
          line-height: 20px;
          color: #262626;
          margin-bottom: 4px;
        }
        > :last-child {
          font-size: 14px;
          line-height: 20px;
          color: #8c8c8c;
        }
      }
    }
  }
  .swiper-icon {
    width: 26px;
    height: 104px;
    background: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }
  .swiper-next {
    position: absolute;
    right: 0;
    top: 24px;
  }
  .swiper-prev {
    position: absolute;
    left: 0;
    top: 24px;
  }
  .swiper-list-1 > :nth-child(1) {
    background: #37d2b3;
  }
  .swiper-list-2 > :nth-child(1) {
    background: #52affc;
  }
  .swiper-list-3 > :nth-child(1) {
    background: #febe7c;
  }
  .swiper-list-4 > :nth-child(1) {
    background: #bca0db;
  }
}

.certificate {
  margin-bottom: 32px;
  .certificate-box {
    padding: 24px;
    background: #ffffff;
    margin-top: 16px;
    .title {
      font-size: 16px;
      line-height: 28px;
      color: #262626;
    }
    .certificate-box-item {
      display: flex;
      flex-wrap: wrap;
      > :not(:nth-child(5n)) {
        margin-right: 24px;
      }
      > .top-box {
        min-height: 276px;
      }
      > .bottom-box {
        min-height: 300px;
      }
      > div {
        box-sizing: border-box;
        width: 227px;
        border: 1px solid #e6eff6;
        opacity: 1;
        border-radius: 0px;
        margin-top: 16px;
        > .img-box {
          height: 181px;
          display: flex;
          justify-content: center;
          align-items: center;
          box-sizing: border-box;
          > .img {
            display: block;
            /* width: 163px;
            height: 234px;*/
            max-width: 115px;
            max-height: 166px;
          }
        }
        > .box-info {
          height: calc(100% - 181px);
          padding: 12px 24px;
          background: #f0f7fc;
          > :first-child {
            font-size: 14px;
            line-height: 20px;
            color: #262626;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          > div {
            font-size: 12px;
            line-height: 17px;
            color: #8c8c8c;
            margin-top: 8px;
            display: flex;
            > .time {
              width: 80px;
            }
            > span {
              display: inline-block;
            }
            > :nth-child(1) {
              min-width: 48px;
            }
            > :nth-child(2) {
              width: 150px;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              word-break: break-all;
            }
          }
        }
      }
    }
  }
}

.project-box {
  display: flex;
  flex-wrap: wrap;
  > :not(:nth-child(3n)) {
    border-right: 1px solid #f0f0f0;
  }
  > :not(:nth-child(-n + 3)) {
    border-top: 1px solid #f0f0f0;
  }
  > :last-child {
    border-right: none;
  }
}
</style>
