<template>
  <div
    class="company-card"
    :class="{ 'card-height': !val.serServiceMarkets.length > 0 }"
  >
    <div class="card-top" @click="toDetail(val.id)">
      <div class="imgBox" v-if="val.isvImg">
        <v-img
          :attachment-id="val.isvImg"
          :default-img="require('@/assets/img/kctp.png')"
        />
      </div>
      <div v-else class="logo-text" :class="[`logo-color-${colorNum}`]">
        {{ val.name.slice(0, 1) }}
      </div>
      <div>
        <div class="info">
          <p>{{ val.name }}</p>
          <p>{{ val.corpComment }}</p>
        </div>
        <div class="time">
          <div>
            <p>成立时间：{{ val.establishmentDate }}</p>
            <p v-if="val.companyScaleType">
              企业规模：{{ val.companyScaleType | dict("COMPANY_SCALE_TYPE") }}
            </p>
            <p v-else>企业规模：{{ val.companyScaleTypeName }}</p>
          </div>
          <div v-if="val.website">
            企业网址：<a @click.stop="openUrl(val.website)">{{
              val.website
            }}</a>
          </div>
        </div>
      </div>
    </div>
    <div v-if="val.serServiceMarkets && val.serServiceMarkets.length > 0">
      <a-divider />
      <div class="card-bottom">
        <img src="@/assets/img/treasury1.png" alt="" />
        <div
          v-for="el in val.serServiceMarkets"
          :key="el.id"
          @click="toService(el.id)"
        >
          {{ el.serviceName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { mapGetters } from "vuex";

export default {
  name: "companyCard",
  props: ["val", "colorNum"],
  components: {
    VImg,
  },
  computed: {
    ...mapGetters(["dict"]),
  },
  methods: {
    toDetail(id) {
      this.$router.push({
        path: "/treasury/provider",
        query: { id },
      });
    },
    toService(id) {
      this.$router.push({
        path: "/service/details",
        query: { id },
      });
    },
    openUrl(url) {
      if (!url.includes("http://") || !url.includes("https://")) {
        url = "http://" + url;
      }
      window.open(url);
    },
  },
};
</script>

<style scoped lang="scss">
a {
  color: #4181fc;
}

.logo-text {
  width: 80px;
  height: 80px;
  text-align: center;
  border-radius: 4px;
  font-size: 48px;
  margin-right: 32px;
}

.company-card {
  width: 976px;
  height: 192px;
  padding: 24px 24px 16px;
  background: #ffffff;
  cursor: pointer;
  margin-top: 12px;
  position: relative;

  &::v-deep {
    .ant-divider-horizontal {
      margin: 12px 0;
    }
  }

  &:hover {
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
  }
}

.imgStatus {
  position: absolute;
  top: 0;
  right: 0;
}

.card-height {
  height: 151px;
}

.card-top {
  width: 100%;
  min-height: 103px;
  display: flex;

  > .imgBox {
    width: 80px;
    height: 80px;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    margin-right: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    > img {
      display: block;
      max-width: 100%;
      max-height: 100%;
    }
  }

  > :last-child {
    width: 816px;
    min-height: 103px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    > .info {
      > :first-child {
        font-size: 16px;
        color: #262626;
        line-height: 22px;
        margin-bottom: 11px;
      }

      > :last-child {
        font-size: 12px;
        color: #8c8c8c;
        line-height: 18px;
        margin-bottom: 16px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }

    > .time {
      display: flex;
      justify-content: space-between;

      > :first-child {
        display: flex;

        > p {
          font-size: 12px;
          color: #8c8c8c;
          line-height: 18px;
        }

        > :first-child {
          margin-right: 32px;
        }
      }

      > :last-child {
        font-size: 12px;
        color: #8c8c8c;
        line-height: 18px;
      }
    }
  }
}

.card-bottom {
  display: flex;
  padding-left: 113px;

  > img {
    display: block;
    width: 80px;
    height: 24px;
  }

  > div {
    width: 123px;
    height: 24px;
    padding: 0 5px;
    border-radius: 12px;
    border: 1px solid #d9d9d9;
    margin-left: 12px;
    font-size: 12px;
    color: #595959;
    line-height: 20px;
    text-align: center;
    cursor: pointer;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;

    &:hover {
      color: #ea0b06;
    }
  }
}
</style>
