<template>
  <div class="demand-slot">
    <div class="demand-select-slot">
      <p :class="{ active: sortKey === 'Default' }" @click="sortTab('Default')">
        综合排序
      </p>
      <p
        :class="{ active: sortKey === 'PublishTime' }"
        @click="sortTab('PublishTime')"
      >
        热度
        <span>
          <a-icon
            :class="{
              active: sortKey === 'PublishTime' && sortType === 'BidCountAsc',
            }"
            type="caret-up"
          />
          <a-icon
            :class="{
              active: sortKey === 'PublishTime' && sortType === 'BidCountDesc',
            }"
            type="caret-down"
          />
        </span>
      </p>
      <p :class="{ active: sortKey === 'Date' }" @click="sortTab('Date')">
        交付时间
        <span>
          <a-icon
            :class="{
              active: sortKey === 'Date' && sortType === 'CarryOutEndTimeAsc',
            }"
            type="caret-up"
          />
          <a-icon
            :class="{
              active: sortKey === 'Date' && sortType === 'CarryOutEndTimeDesc',
            }"
            type="caret-down"
          />
        </span>
      </p>
      <p :class="{ active: sortKey === 'Price' }" @click="sortTab('Price')">
        预算金额
        <span>
          <a-icon
            :class="{
              active: sortKey === 'Price' && sortType === 'FundAmountAsc',
            }"
            type="caret-up"
          />
          <a-icon
            :class="{
              active: sortKey === 'Price' && sortType === 'FundAmountDesc',
            }"
            type="caret-down"
          />
        </span>
      </p>
    </div>
    <div>
      共 <span style="color: #ea0b06"> {{ total }}</span> 项需求
    </div>
  </div>
</template>

<script>
export default {
  name: "demandSort",
  props: ["total"],
  data() {
    return {
      sortKey: "Default",
      sortType: null,
    };
  },
  methods: {
    // 排序
    sortTab(sortKey) {
      this.sortKey = sortKey;
      if (sortKey === "Default") {
        this.sortKey = "Default";
        this.sortType = "";
      } else if (sortKey === "Price") {
        this.sortType =
          this.sortType === "FundAmountDesc"
            ? "FundAmountAsc"
            : "FundAmountDesc";
      } else if (sortKey === "PublishTime") {
        this.sortType =
          this.sortType === "BidCountDesc" ? "BidCountAsc" : "BidCountDesc";
      } else if (sortKey === "Date") {
        this.sortType =
          this.sortType === "CarryOutEndTimeDesc"
            ? "CarryOutEndTimeAsc"
            : "CarryOutEndTimeDesc";
      }
      this.$emit("sortChange", this.sortType);
    },
  },
};
</script>

<style scoped lang="scss">
.demand-slot {
  height: 52px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 24px 0;
  padding: 0 24px;
  color: #8c8c8c;
  line-height: 20px;
}
.demand-select-slot {
  display: flex;
  align-items: center;

  > p {
    display: flex;
    align-items: center;
    margin-right: 24px;
    cursor: pointer;

    &.active {
      color: #ea0b06;
    }

    > span {
      margin-left: 6px;

      > i {
        display: block;
        font-size: 8px;
        color: #999999;

        &.active {
          color: #ea0b06;
        }
      }
    }
  }
}
</style>
