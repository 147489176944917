import { PortalRequest as request } from "@/utils/request";

// 首页专题列表
export const getHomeTopicList = (params) => {
  return request({
    url: "/topic/list-public",
    method: "get",
    params,
  });
};
// 专题详情
export const getTopicInfo = (id) => {
  return request({
    url: `/topic/${id}/info`,
    method: "get",
  });
};
// 专题成果列表
export const getTopicAchvList = (id, params) => {
  return request({
    url: `/topic/${id}/list-achv`,
    method: "get",
    params,
  });
};
// 专题需求列表
export const getTopicScenelist = (id, params, data) => {
  return request({
    url: `/topic/${id}/list-scene`,
    method: "post",
    params,
    data,
  });
};
