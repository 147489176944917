<template>
  <a-card class="questionnaire-box">
    <a-form-model
      :label-col="labelCol"
      :model="form"
      :rules="rules"
      :wrapper-col="wrapperCol"
      ref="responseForm"
    >
      <a-row>
        <a-col :span="24" class="top">
          <span class="title">
            <strong>{{ form.qstQuestionnaire.title }}</strong>
          </span>
        </a-col>
      </a-row>
      <a-row>
        <a-col :span="24">
          <span class="subtitle">{{ form.qstQuestionnaire.description }}</span>
        </a-col>
      </a-row>
      <a-row
        v-for="(sectionKey, sectionIndex) in form.qstQuestionnaire.qstSections"
        :key="sectionIndex"
      >
        <a-col :span="24" class="section">
          <span class="section-title"
            ><strong>{{ sectionKey.title }}</strong></span
          >
        </a-col>
        <a-row
          v-for="(questionKey, questionIndex) in sectionKey.qstQuestions"
          :key="questionIndex"
        >
          <a-col :span="24" class="question" v-if="questionKey.enableContent">
            <span class="question-title"><em v-if="questionKey.isMandatory" style="color:red">*</em> {{ questionKey.index }}、{{ questionKey.content }}</span>
          </a-col>
          <a-row>
            <a-col :span="24" class="option">
              <div>
                <span class="question-title" v-if="!questionKey.enableContent"><em v-if="questionKey.isMandatory" style="color:red">*</em> {{ questionKey.index }}、</span>
                <a-radio-group
                  :name="questionKey.id"
                  @change="selectOptionChange(sectionIndex, questionIndex)"
                  v-if="questionKey.questionType === '1'"
                  v-model="questionKey.selectRadioOption"
                >
                  <template v-for="optionKey in questionKey.qstOptions">
                    <a-radio :value="optionKey.id" :key="optionKey.id">
                      {{ optionKey.content }}
                      <span v-if="optionKey.isCustomInput == '1'">
                        <a-input
                          class="optionInput fill-blank-inp"
                          placeholder="请注明"
                          v-if="optionKey.id === questionKey.selectRadioOption"
                          v-model="optionKey.customInput"
                        />
                      </span>
                    </a-radio>
                  </template>
                </a-radio-group>
                <a-checkbox-group
                  :name="questionKey.id"
                  @change="selectOptionChange(sectionIndex, questionIndex)"
                  v-if="questionKey.questionType === '2'"
                  v-model="questionKey.selectCheckOption"
                >
                  <template v-for="optionKey in questionKey.qstOptions">
                    <span class="optionInputSpan" :key="optionKey.id">
                      <a-checkbox :value="optionKey.id">
                        {{ optionKey.content }}
                        <span v-if="optionKey.isCustomInput == '1'">
                          <a-input
                            class="optionInput fill-blank-inp"
                            placeholder="请注明"
                            v-if="
                              questionKey.selectCheckOption &&
                              questionKey.selectCheckOption.indexOf(
                                optionKey.id
                              ) != -1
                            "
                            v-model="optionKey.customInput"
                          />
                        </span>
                      </a-checkbox>
                    </span>
                  </template>
                </a-checkbox-group>
                <template v-if="questionKey.questionType === '3'">
                  <template
                    v-for="(
                      fillBlankKey, fillBlankIndex
                    ) in questionKey.qstFillBlanks"
                  >
                    {{ fillBlankKey.leftContent }}
                      <a-input
                        :key="fillBlankIndex"
                        class="fill-blank-inp"
                        placeholder="请输入"
                        :style="{'min-width': '100px','width':fillBlankKey.customInput != null?fillBlankKey.customInput.length * 16 + 'px':'100px','max-width': '300px'}"
                        v-model="fillBlankKey.customInput"
                      />
                      {{ fillBlankKey.rightContent }}
                      <template v-if="fillBlankKey.splitType=='2'">;</template>
                      <template v-if="fillBlankKey.splitType=='3'"><br :key="fillBlankIndex"></template>
                  </template>
                </template>
              </div>
            </a-col>
          </a-row>
        </a-row>
      </a-row>
      <a-row>
        <a-col :span="24" class="section">
          <span class="section-title"><strong>填报人信息</strong></span>
        </a-col>
      </a-row>
      <a-form-model-item
        label="填表单位"
        prop="submitUnitBy"
        ref="submitUnitBy"
      >
        <a-input placeholder="请填写填表单位" v-model="form.submitUnitBy" />
      </a-form-model-item>
      <a-form-model-item label="填表人" prop="submitBy" ref="submitBy">
        <a-input placeholder="请填写填表人" v-model="form.submitBy" />
      </a-form-model-item>
      <a-form-model-item label="填表职位" prop="position" ref="position">
        <a-input placeholder="请填写职位" v-model="form.position" />
      </a-form-model-item>
      <a-form-model-item label="联系信息" prop="contact" ref="contact">
        <a-input placeholder="请填写联系信息" v-model="form.contact" />
      </a-form-model-item>
      <a-form-model-item label="选择地区" prop="address" ref="address">
        <area-select
          placeholder="选择地区"
          v-model="form.address"
          :default-value="form.address"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 2 }">
        <a-button
          @click="handleSubmit"
          class="btn-red"
          v-if="form.qstQuestionnaire.publishStatus != 3"
          :disabled="subDisabled"
          >提交</a-button
        >
      </a-form-model-item>
    </a-form-model>
  </a-card>
</template>

<script>
import { getNewResponse, createResponse } from "@/api/questionnaire";
import areaSelect from "./areaselect";
export default {
  components: {
    areaSelect,
  },
  name: "responseForm",
  data() {
    return {
      labelCol: { span: 2 },
      wrapperCol: { span: 10 },
      form: {
        submitUnitBy: null,
        submitBy: null,
        position: null,
        contact: null,
        qstQuestionnaire: {},
        address: [],
      },
      rules: {
        submitUnitBy: [
          {
            required: true,
            message: "请输入填表单位",
            trigger: "blur",
          },
        ],
        submitBy: [
          {
            required: true,
            message: "请输入填表人",
            trigger: "blur",
          },
        ],
        position: [
          {
            required: true,
            message: "请输入职位",
            trigger: "blur",
          },
        ],
        contact: [
          {
            required: true,
            message: "请输入联系信息",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "选择地区",
            trigger: "change",
          },
        ],
      },
      subDisabled:false
    };
  },
  computed: {
    id() {
      return this.$route.query.id;
    },
    userid() {
      return this.$route.query.userId;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    loadData() {
      const { id, userid } = this;
      getNewResponse(id, userid).then((res) => {
        let index = 1;
        if (res.qstQuestionnaire.publishStatus == 3) {
          this.$notification["warning"]({
            message: "该问卷已结束!",
          });
        }
        res.qstQuestionnaire.qstSections.forEach((item) => {
          item.qstQuestions.forEach((questionItem) => {
            questionItem.index = index;
            index++;
          });
        });
        this.form = res;
      });
    },
    handleSubmit() {
      this.doSave();
    },
    doSave() {
      const { form } = this;
      let obj = form.qstQuestionnaire.qstSections
      for (let index = 0; index < obj.length; index++) {
        for (let e = 0; e < obj[index].qstQuestions.length; e++) {
          if(obj[index].qstQuestions[e].isMandatory){
            if(obj[index].qstQuestions[e].questionType == '1'){
              if(obj[index].qstQuestions[e].selectRadioOption == null || obj[index].qstQuestions[e].selectRadioOption == ''){
                this.$notification["warning"]({
                  message: '提示',
                  description:`请选择第${obj[index].qstQuestions[e].index}题`,
                });
                return false
              }
            }else if(obj[index].qstQuestions[e].questionType == '2'){
              if(obj[index].qstQuestions[e].selectCheckOption == null || obj[index].qstQuestions[e].selectCheckOption == ''){
                this.$notification["warning"]({
                  message: '提示',
                  description:`请选择第${obj[index].qstQuestions[e].index}题`,
                });
                return false
              }
            }else if(obj[index].qstQuestions[e].questionType == '3'){
              for (let j = 0; j < obj[index].qstQuestions[e].qstFillBlanks.length; j++) {
                if(obj[index].qstQuestions[e].qstFillBlanks[j].customInput == null || obj[index].qstQuestions[e].qstFillBlanks[j].customInput == ''){
                  this.$notification["warning"]({
                  message: '提示',
                  description:`请输入第${obj[index].qstQuestions[e].index}题填空`,
                });
                  return false
                }
              }
            }
          }
        }
      }
      this.$refs.responseForm.validate((valid) => {
        if (valid) {
          form.address = form.address.toString()
          createResponse({
            ...form,
          }).then(() => {
            this.$notification["success"]({
              message: '提示',
              description:`问卷已提交成功`,
            })
            this.subDisabled = true
          });
        } else {
          return false;
        }
      });
    },
    selectOptionChange(sectionIndex, questionIndex) {
      if (
        this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
          questionIndex
        ].qstOptions
      ) {
        this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
          questionIndex
        ].qstOptions.forEach((option) => {
          if (option.hasSubQuestion) {
            if (
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].selectRadioOption == option.id
            ) {
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].isShow = true;
            } else if (
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].selectCheckOption &&
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].selectCheckOption.indexOf(option.id) != -1
            ) {
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].isShow = true;
            } else {
              this.form.qstQuestionnaire.qstSections[sectionIndex].qstQuestions[
                questionIndex
              ].isShow = false;
            }
          }
        });
      }
    },
    //选择地区之后的回调
    selectArea(selectedArea) {
      this.form.address = selectedArea;
    },
  },
};
</script>

<style scoped>
.questionnaire-box{
  width: 1200px;
  margin: 24px auto;
}
.fill-blank-inp {
  border-radius: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  padding: 0 2px;
}
.top {
  text-align: center;
}

.title {
  font-size: 25px;
}
.subtitle{
  display: block;
  line-height: 22px;
  margin-top: 16px;
  padding:  0 16px;
}
.section {
  margin: 10px;
  padding: 10px;
}

.section-title {
  font-size: 18px;
}

.question {
  margin: 10px;
  padding: 5px;
}

.question-title {
  font-size: 15px;
}

.option {
  margin-left: 15px;
  padding: 5px;
}

.optionInputSpan {
  /* white-space: nowrap; */
}

.optionInput {
  width: 200px;
}
.questionnaire-box .ant-btn[disabled]{
  color: rgba(0, 0, 0, 0.25) !important;
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
</style>
