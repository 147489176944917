import { render, staticRenderFns } from "./scenesTopic.vue?vue&type=template&id=4accf699&scoped=true&"
import script from "./scenesTopic.vue?vue&type=script&lang=js&"
export * from "./scenesTopic.vue?vue&type=script&lang=js&"
import style0 from "./scenesTopic.vue?vue&type=style&index=0&id=4accf699&lang=scss&scoped=true&"
import style1 from "./scenesTopic.vue?vue&type=style&index=1&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4accf699",
  null
  
)

export default component.exports