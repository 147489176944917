<template>
  <div class="new-home-product">
    <strong>细致、精准、智能的国产化IoT产品设备适配方案</strong>
    <p>基于AIoT鸿蒙化替代升级服务，助您国产化升级，实现自主可控</p>
    <device/>
    <solution/>
    <serve/>
  </div>
</template>

<script>
import device from './components/device.vue'
import solution from './components/solution.vue'
import serve from './components/serve.vue'
export default {
    components:{
        device,
        solution,
        serve
    }
}
</script>

<style lang="scss" scoped>
.new-home-product{
    padding: 70px 0;
    background: url(../../assets/img/xmbg.png) no-repeat;
    background-size: 100% 100%;
    >strong{
        display: block;
        font-size: 32px;
        line-height: 45px;
        color: #262626;
        text-align: center;
        margin-bottom: 24px;
    }
    >p{
        font-size: 18px;
        line-height: 25px;
        color: #666;
        margin-bottom: 60px;
        text-align: center;
    }
}
</style>