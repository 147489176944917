<template>
  <div class="new-home-scenes">
    <strong>安全、可靠、数智化场景应用开放创新</strong>
    <p>助力政企需求释放、场景建设闭环、数字经济发展的综合运营服务</p>
    <div>
      <div>
        <div class="new-home-scenes-tab-title">
          <p>场景清单</p>
          <span @click="$router.push('/homeScenes/list')">查看更多 <img src="@/assets/img/ckgdicon.png" alt="图片"/></span>
        </div>
        <div class="new-home-scenes-table">
            <a-table :columns="scenesColumns" :data-source="scenesData" :pagination="false" :customRow="rowClick" :rowKey="(record,index)=>{return index}">
                <template slot="state" slot-scope="text">
                    <p class="scenes-state"><span></span>{{text | dict('SCENE_STATUS')}}</p>
                </template>
                <template slot="time" slot-scope="text">
                    {{text | moment("YYYY-MM-DD")}}
                </template>
            </a-table>
        </div>
      </div>
      <div>
        <div class="new-home-scenes-tab-title">
          <p>揭榜挂帅</p>
          <span @click="$router.push('/homeScenes/list')">查看更多 <img src="@/assets/img/ckgdicon.png" alt="图片"/></span>
        </div>
        <div class="new-home-scenes-table">
            <a-table :columns="tenderColumns" :data-source="tenderData" :pagination="false" :customRow="rowClick" :rowKey="(record,index)=>{return index}">
              <template slot="time" slot-scope="text">
                  {{text | moment("YYYY-MM-DD")}}
              </template>
            </a-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const scenesColumns = [
  {
    title: "榜单名称",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "状态",
    dataIndex: "sceneStatus",
    key: "sceneStatus",
    scopedSlots: { customRender: "state" },
  },
  {
    title: "发榜时间",
    dataIndex: "reviewedTime",
    key: "reviewedTime",
    scopedSlots: { customRender: "time" },
  },
  {
    title: "截止时间",
    key: "applyDueDate",
    dataIndex: "applyDueDate",
  },
];
const tenderColumns = [
    {
        title: "榜单名称",
        dataIndex: "sceneTitle",
        key: "sceneTitle",
    },
    {
        title: "揭榜单位",
        dataIndex: "bidCompanyName",
        key: "bidCompanyName",
    },
    {
        title: "揭榜时间",
        dataIndex: "bidTime",
        key: "bidTime",
        scopedSlots: { customRender: "time" },
    }
]
import { mapGetters } from "vuex";
import { getSceneList, getSceneBidList } from "@/api/cjkf/scn-scene";
export default {
  data() {
    return {
      scenesColumns,
      scenesData:[],
      tenderColumns,
      tenderData:[]
    };
  },
  computed: mapGetters(["dict"]),
  mounted(){
    this.loadData()
  },
  methods:{
    rowClick(record, index){
      return {
        on: {
          click: () => {
            let id = record.bidStatus?record.sceneId:record.id
            this.$router.push(`/homeScenes/detail?id=${id}`);
          },
        }
      };
    },
    loadData(){
      let page = {
        current:1,
        size:5
      }
      getSceneList(page,{sceneStatus:'6',orderField:'reviewed_time'}).then((res)=>{
        this.scenesData = res.records
      })
      getSceneBidList(page,{bidStatus:'2',orderField:'bid_time'}).then((res)=>{
        this.tenderData = res.records
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.new-home-scenes {
  padding: 70px 0;
  > strong {
    display: block;
    font-size: 32px;
    line-height: 45px;
    color: #262626;
    text-align: center;
    margin-bottom: 24px;
  }
  > p {
    font-size: 18px;
    line-height: 25px;
    color: #666;
    margin-bottom: 36px;
    text-align: center;
  }
  >div{
    display: flex;
    justify-content: space-between;
    width: 1280px;
    margin: 0 auto;
    >div{
        width: 628px;
        .new-home-scenes-tab-title{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 24px;
            >p{
                position: relative;
                font-size: 26px;
                line-height: 37px;
                color: #333333;
                padding-left: 16px;
                &::after{
                    content: '';
                    position: absolute;
                    top: 6px;
                    left: 0;
                    width: 4px;
                    height: 25px;
                    background: #EA0B06;
                }
            }
            >span{
                display: flex;
                align-items: center;
                font-size: 16px;
                line-height: 32px;
                color: #EA0B06;
                cursor: pointer;
                >img{
                    width: 12px;
                    margin-left: 10px;
                }
            }
        }
        .new-home-scenes-table{
            background: #FFFFFF;
            box-shadow: 0px 3px 14px rgba(0,0,0,0.0600);
            .scenes-state{
                display: flex;
                align-items: center;
                >span{
                    width: 6px;
                    height: 6px;
                    background: #57C22D;
                    border-radius: 50%;
                    margin-right: 8px;
                }
            }
        }
    }
  }
}
</style>
<style>
.new-home-scenes-table .ant-table-tbody > tr > td{
    padding: 20px 16px;
    border-color: #ECEFF4;
}
.new-home-scenes-table .ant-table-thead>tr{
    background: url(../../assets/img/cjtabbg@2x.png) no-repeat;
    background-size: 100% 100%;
}
.new-home-scenes-table .ant-table-thead > tr > th{
    background: none;
}
.new-home-scenes-table .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td{
    background: rgba(234, 11, 6, .03);
}
</style>