<template>
  <div class="menu-card" v-if="val.list">
    <div class="title">{{ val.name }}</div>
    <div class="list">
      <div v-for="el in val.list" :key="el.id" @click="toDetail(el.id)">
        {{ el.name }}
      </div>
    </div>
    <a-icon class="icon" type="right" />
  </div>
</template>

<script>
export default {
  name: "menu-card",
  props: ["val"],
  methods: {
    toDetail(id) {
      this.$router.push({
        name: "service",
        params: { l1Id: this.val.id, l2Id: id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.menu-card {
  padding: 12px 0 0px 16px;
  width: 240px;
  height: 102px;
  background: #ffffff;
  position: relative;
  overflow: hidden;
  &:hover {
    box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.1);
    > .icon {
      color: #ea0b06;
    }
  }
  > .icon {
    position: absolute;
    top: 16px;
    right: 16px;
    cursor: pointer;
  }

  > .title {
    font-size: 14px;
    color: #262626;
    line-height: 20px;
    margin-bottom: 18px;
  }
  > .list {
    display: flex;
    flex-wrap: wrap;
    > div {
      font-size: 12px;
      color: #8c8c8c;
      line-height: 17px;
      margin-bottom: 12px;
      cursor: pointer;
      margin-right: 16px;
      &:hover {
        color: #ea0b06;
      }
    }
  }
}
</style>
