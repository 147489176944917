<template>
  <div class="home-policy">
    <div class="home-policy-head">


      <img src="../../assets/img/zengc.gif" alt="" class="img">

      <div>
        <span>政策资讯</span>
        <p>汇集最新最全的IoT政策与资讯</p>
      </div>
    </div>
    <div class="home-policy-tab">
      <span :class="{ active: policyTab == 1 }" @click="policyTab = 1"
        >政策大厅</span
      >
      <span :class="{ active: policyTab == 2 }" @click="policyTab = 2"
        >行业资讯</span
      >
    </div>
    <div class="home-policy-list-box">
      <p>共 {{ page.total }} 项内容</p>
      <div :class="{'loading-style':loading}">
        <a-skeleton avatar :loading="loading" :paragraph="{ rows: 10 }">
          <div class="home-policy-list" v-if="policyList != ''">
            <div
              :key="index"
              @click="$router.push(`/policy/info?id=${item.id}`)"
              v-for="(item, index) in policyList"
            >
              <span>{{ item.newsName }}</span>
              <p>
                <span>{{ item.viewed ? item.viewed : 0 }}人浏览</span
                ><span>{{ item.newsDate }}</span>
              </p>
            </div>
          </div>
          <div v-else style="padding:100px 0;display:block">
            <a-empty />
          </div>
        </a-skeleton>
      </div>
      <pagination
        :current="page.current"
        :total="page.total"
        @changes="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { getNewsList } from "@/api/news";
import pagination from "../components/pagination.vue";

export default {
  components: {
    pagination,
  },
  data() {
    return {
      policyTab: 1,
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      policyList: [],
      loading:false
    };
  },
  watch: {
    policyTab() {
      this.loadGetNewsList();
    },
  },
  mounted() {
    if (this.$route.params.type) {
      this.policyTab = this.$route.params.type;
    }
    this.loadGetNewsList();
  },
  methods: {
    loadGetNewsList() {
      let data = {
        newsType: this.policyTab,
      };
      if(this.policyList == ''){
        this.loading = true
      }
      getNewsList(this.page, data).then((res) => {
        this.policyList = res.records;
        this.page.total = res.total;
        this.loading = false
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetNewsList();
    },
  },
};
</script>

<style lang="scss" scoped>
.home-policy {
  .home-policy-head {
    background: #fff;
    height: 320px;
    position: relative;
    z-index: 1;
    >.img{
      height: 100%;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
    }
    >div{
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 650px;
      margin-left: -640px;
      padding-top: 119px;
      z-index: 10;
      > span {
        line-height: 22px;
        font-size: 38px;
        display: block;
        font-weight: bold;
        color: #262626;
        margin-bottom: 24px;
      }

      > p {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
      }
    }

  }

  .home-policy-tab {
    padding: 8px 0;
    background: #fff;
    display: flex;
    justify-content: center;

    > span {
      height: 48px;
      line-height: 48px;
      border-bottom: 2px solid transparent;
      cursor: pointer;
      transition: all 0.3s;
      font-size: 18px;
      &:first-child {
        margin-right: 200px;
      }

      &.active {
        border-color: #EA0B06;
      }
    }
  }

  .home-policy-list-box {
    width: 1280px;
    margin: 24px auto 56px;

    > p {
      line-height: 12px;
      font-size: 14px;
      color: #8c8c8c;
      margin-bottom: 24px;
    }

    .home-policy-list {
      padding: 0 24px;
      background: #fff;
      margin-bottom: 40px;

      > div {
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 80px;
        cursor: pointer;

        &:last-child {
          border: none;
        }

        > span {
          max-width: 70%;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > p {
          font-size: 12px;
          color: #8c8c8c;

          > span {
            &:first-child {
              margin-right: 20px;
            }
          }
        }
      }
    }

    .home-policy-page {
      text-align: center;
    }
  }
}
</style>
