<template>
  <div class="service-card" :class="{ 'box-card': boxChange }">
    <v-img
      :attachment-id="val.serviceCover"
      :defaultImg="require('@/assets/img/kctp.png')"
    />
    <div>
      <div>
        <p class="serviceName">{{ val.serviceName }}</p>
        <p class="summary">{{ val.summary }}</p>
      </div>

      <div>
        <div class="company">
          <img src="@/assets/img/service_card1.png" alt="" />
          <span class="company-title">服务商：</span>
          <span class="company-com">{{ val.serviceIsvName }}</span>
        </div>

        <div class="company-bottom">
          <div v-if="val.pricingType == 1" class="price">
            <span>￥</span><span>{{ val.lowestPrice }}</span
            ><span>/{{ val.buyType | buyTypeDict("BUY_TYPE") }}</span>
          </div>
          <div v-else class="price-sel">议价定制</div>
          <div class="img">
            <img src="@/assets/img/provider_details2.png" alt="" />
            <p>{{ val.viewed || 0 }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "service_card",
  props: ["val", "boxChange"],
  components: { VImg },
  computed: mapGetters(["dict"]),
  data() {
    return {};
  },
  mounted() {},
  created() {},
  methods: {
    ...mapActions(["LoadDictData"]),
  },
};
</script>

<style scoped lang="scss">
.service-card {
  width: 411px;
  height: 200px;
  display: flex;
  cursor: pointer;
  background: #fff;
  padding: 24px 19px 24px 32px;
  &:hover {
    box-shadow: 0px 3px 12px 1px rgba(0, 0, 0, 0.1);
  }
  > img {
    display: block;
    width: 80px;
    height: 80px;
    border-radius: 4px;
    margin-right: 24px;
  }
  > div {
    width: 256px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .serviceName {
      font-size: 14px;
      color: #262626;
      line-height: 25px;
      margin-bottom: 12px;

      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    .summary {
      font-size: 12px;
      color: #999999;
      line-height: 18px;
      margin-bottom: 12px;

      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .company {
      margin-bottom: 24px;
      > img {
        margin-right: 8px;
      }
      > span {
        font-size: 12px;
        color: #4181fc;
        line-height: 18px;
        vertical-align: middle;
      }
      > .company-title {
        white-space: nowrap;
      }
      > .company-com {
        display: inline-block;
        width: 188px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
    .company-bottom {
      display: flex;
      justify-content: space-between;
      > .img {
        display: flex;
        align-items: center;
        > img {
          display: block;
          width: 16px;
          height: 10px;
          margin-right: 6px;
        }
        > p {
          font-size: 14px;
          color: #d9d9d9;
        }
      }
    }

    .price {
      > :nth-child(1) {
        font-size: 14px;
        color: #ea0b06;
        line-height: 21px;
      }
      > :nth-child(2) {
        font-size: 20px;
        color: #ea0b06;
        line-height: 21px;
      }
      > :nth-child(3) {
        font-size: 12px;
        color: #666666;
        line-height: 21px;
      }
    }
    .price-sel {
      font-size: 20px;
      color: #ea0b06;
      line-height: 21px;
    }
  }
}
.box-card {
  width: 416px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1);
  &:hover {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.2);
  }
  .summary {
    line-height: 20px;
  }
}
</style>
