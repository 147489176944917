<template>
  <a-modal
    @cancel="guanbi"
    title="我要投标"
    v-model="visibleBool"
    width="750px"
    :footer="null"
    class="modal"
  >
    <div class="company-body" v-show="!bidFormShow">
      <div class="company-list" v-for="item in companyList" :key="item.id">
        <div>
          <div v-if="item.companyLogoAttmId" class="img">
            <v-img
              :attachment-id="item.companyLogoAttmId"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </div>
          <div v-else class="logoText">{{ item.companyName.slice(0, 4) }}</div>
          <div class="info">
            <p>{{ item.companyName }}</p>
            <div>
              <span>成立时间：{{ item.establishmentDate }}</span>
              <span>所属行业：{{ item.industryType | dict("INDUSTRY") }}</span>
            </div>
          </div>
          <img
            src="@/assets/img/bidding.png"
            v-if="item.demandBidStatus === '1'"
            alt=""
          />
        </div>
        <a-button
          @click="unvile(item.id, item)"
          class="btn-red"
          v-if="item.demandStatus == '4' && !item.demandBidStatus"
          >投标</a-button
        >
        <a-button @click="detail(item.id)" v-if="item.demandBidStatus"
          >方案详情</a-button
        >
      </div>
    </div>

    <bid-form
      v-model="bidFormShow"
      :companyId="companyId"
      :demandId="demandId"
      :bidCompany="bidCompany"
      @demandBid="newChange"
    >
    </bid-form>

    <a-modal
      @cancel="guanbiDetail"
      title="投标方案"
      v-model="bidDetail"
      width="1000px"
      class="modal"
      :footer="null"
    >
      <programme-card
        :val="projectData"
        :companyName="companyName"
      ></programme-card>
    </a-modal>
  </a-modal>
</template>

<script>
import VImg from "@/components/VImg";

import { mapActions, mapGetters } from "vuex";
import bidForm from "@/page/demand/components/bidForm";
import programmeCard from "@/page/demand/components/programmeCard";
import { getDemandBidCompany, getDemandBidDetail } from "@/api/demand";
export default {
  name: "companyList",
  model: {
    prop: "visible",
    event: "change",
  },
  props: ["visible", "companyList", "demandId", "companyName"],
  components: {
    VImg,
    bidForm,
    programmeCard,
  },
  data() {
    return {
      seePlanShow: false,
      visibleBool: false,
      bidFormShow: false,
      sceneId: "",
      companyId: "",
      bidCompany: "",
      submitSceneData: {
        bidPrice: undefined,
        bidBrief: "",
        bidAttmIds: [],
      },
      projectData: "",
      uploadInfo: [],
      bidDetail: false,
    };
  },
  watch: {
    visible(val) {
      this.visibleBool = val;
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    this.sceneId = this.$route.query.id;
    this.LoadDictData(["INDUSTRY"]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),

    guanbi() {
      this.visibleBool = false;
      this.bidFormShow = false;
      this.$emit("change", false);
      this.submitSceneData = {
        bidPrice: undefined,
        bidBrief: "",
        bidAttmIds: [],
      };
    },
    guanbiDetail() {
      this.bidDetail = false;
    },
    //投标
    unvile(id, item) {
      this.bidFormShow = true;
      this.companyId = id;
      this.bidCompany = item.companyName;
      console.log(item, "item------");
    },
    detail(id) {
      this.bidDetail = true;
      this.companyId = id;
      getDemandBidDetail(this.demandId, id).then((res) => {
        this.projectData = res;
      });
    },
    newChange() {
      this.$emit("dataChange");
      getDemandBidCompany(this.demandId).then((res) => {
        this.companyList = res;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  &::v-deep {
    .ant-modal-body {
      padding: 0;
    }
  }
}

.company-body {
  height: 451px;
  overflow-y: auto;
  > :not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.logoText {
  width: 64px;
  height: 64px;
  background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
  border-radius: 4px;
  margin-right: 24px;
  color: #fff;
  font-size: 20px;
  padding: 4px 12px;
  line-height: 28px;
}

.company-list {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  > :first-child {
    display: flex;
    > .img {
      width: 64px;
      height: 64px;
      border-radius: 4px;
      margin-right: 24px;
      display: flex;
      justify-content: center;
      align-items: center;
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }
    }
    > .info {
      > p {
        font-size: 16px;
        color: #262626;
        margin-bottom: 16px;
      }
      > div {
        font-size: 14px;
        color: #8c8c8c;
        > :first-child {
          margin-right: 20px;
        }
      }
    }
    > img {
      display: block;
      height: 64px;
      margin-left: 10px;
    }
  }
  > :last-child {
    margin-top: 16px;
  }
}

.form {
  padding: 24px;
}
</style>
