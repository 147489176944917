<template>
  <div class="new-home-consult">
    <div>
      <strong>与生态伙伴共创自主创新未来</strong>
      <p>
        为政企用户提供学习、交流、共创的自主创新生态，智能伴行数字化转型升级！
      </p>
      <a-button class="btn-red" @click="$router.push('/activity')">进入培训课程</a-button>
      <div>
        <div class="consult-swiper-list">
          <div>
            <span><img src="@/assets/img/keceng.png" alt="图片" /> 在线课程</span>
            <template v-for="(el,index) in courseList">
              <p :key="el.id" v-if="index < 3" @click="$router.push(`/activity/courseInfo?id=${el.id}`)">【课程】{{el.courseName}}</p>
            </template>
          </div>
        </div>
        <div class="consult-swiper-list">
          <div>
            <span><img src="@/assets/img/keceng.png" alt="图片" /> 实训课程</span>
            <template v-for="(el,index) in courseList">
              <p :key="el.id" v-if="index > 2" @click="$router.push(`/activity/courseInfo?id=${el.id}`)">【课程】{{el.courseName}}</p>
            </template>
          </div>
        </div>
        <div class="consult-swiper-list">
          <div>
            <span><img src="@/assets/img/zxkcicon.png" alt="图片" /> 活动大厅</span>
            <p v-for="el in activityList" :key="el.id" @click="$router.push(`/activity/activityInfo?id=${el.id}`)">【活动】{{el.activityName}}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getActivityList, getCourseList } from "@/api/activity";
export default {
  data() {
    return {
      activityList: [],
      courseList: [],
    };
  },
  mounted() {
    this.loadgetCourseList();
    this.loadGetActivityList();
  },
  methods: {
    loadgetCourseList() {
      let page = {
        current: 1,
        size: 6,
      };
      getCourseList(page, {}).then((res) => {
        this.courseList = res.records;
      });
    },
    loadGetActivityList() {
      let page = {
        current: 1,
        size: 3,
      }
      getActivityList(page, {}).then((res) => {
        this.activityList = res.records;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-home-consult {
  padding: 110px 0 80px;
  background: url(../../assets/img/cxbg.jpg) no-repeat;
  background-size: 100% 100%;
  > div {
    width: 1280px;
    margin: 0 auto;
    > strong {
      display: block;
      font-size: 32px;
      line-height: 45px;
      color: #262626;
      margin-bottom: 24px;
    }
    > p {
      font-size: 16px;
      line-height: 22px;
      color: #666;
      margin-bottom: 24px;
    }
    > div {
      margin-top: 110px;
      height: 246px;
      background: #ffffff;
      border-radius: 4px;
      position: relative;
      display: flex;
      .consult-swiper-list {
        padding: 32px;
        position: relative;
        width: 33.3%;
        &::after {
          content: "";
          position: absolute;
          top: 32px;
          bottom: 32px;
          right: -1px;
          width: 1px;
          background: #e6e6e6;
        }
        &:last-child{
          &::after{
            display: none;
          }
        }
        > div {
          height: 100%;
          > span {
            display: flex;
            align-items: center;
            font-size: 18px;
            line-height: 25px;
            color: #262626;
            margin-bottom: 30px;
            > img {
              width: 28px;
              margin-right: 12px;
            }
          }
          > p {
            font-size: 16px;
            line-height: 22px;
            color: #262626;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-bottom: 14px;
            cursor: pointer;
            transition: all 0.3s;
            &:last-child {
              margin-bottom: 0;
            }
            &:hover {
              color: #ea0b06;
            }
          }
        }
      }
    }
  }
}
</style>
