<template>
  <div class="service-box">
    <div class="box-content">
      <p>丰富、可靠、可信的专业服务</p>
      <div @click="toService">查看全部服务 ></div>
      <div class="content-box">
        <service-card
          class="service-card"
          v-for="item in serviceList"
          :key="item.id"
          :boxChange="true"
          @click.native="toDetails(item.id)"
          :val="item"
        ></service-card>
      </div>
    </div>
  </div>
</template>

<script>
import serviceCard from "@/page/service/components/service_card";
import { getServicePageList } from "@/api/service";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "service",
  data() {
    return {
      serviceList: [],
      page: {
        current: 1,
        size: 9,
      },
    };
  },
  created() {
    this.LoadDictData(["BUY_TYPE"]);
  },
  mounted() {
    this.loadGetServicePageList();
  },
  components: {
    serviceCard,
  },

  methods: {
    ...mapActions(["LoadDictData"]),
    //分页查询
    loadGetServicePageList() {
      getServicePageList(this.page, {}).then((res) => {
        this.serviceList = res.records;
      });
    },
    toDetails(id) {
      this.$router.push({
        path: "/service/details",
        query: { id },
      });
    },
    toService() {
      this.$router.push("/service");
    },
  },
};
</script>

<style scoped lang="scss">
.service-box {
  width: 100%;
  height: 902px;
  background: #ffffff;
}
.box-content {
  width: 1280px;
  margin: 0 auto;
  padding: 70px 0 48px 0;
  > p {
    font-size: 32px;
    font-weight: 500;
    color: #262626;
    line-height: 45px;
    text-align: center;
    margin-bottom: 24px;
  }
  > :nth-child(2) {
    text-align: center;
    font-size: 18px;
    color: #262626;
    line-height: 25px;
    cursor: pointer;
    margin-bottom: 14px;
    &:hover {
      color: #ea0b06;
    }
  }
  .content-box {
    display: flex;
    flex-wrap: wrap;
    > .service-card {
      margin-top: 16px;
    }
    > :not(:nth-child(3n)) {
      margin-right: 16px;
    }
  }
}
</style>
