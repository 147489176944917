<template>
  <div class="search-box" :class="{ 'top-border': type }">
    <div
      class="search-content"
      :class="[tabShow ? 'bigHeight' : 'smallHeight']"
    >
      <div class="search-logo" v-if="logo">
        <img :src="logo" alt="" />
      </div>
      <div class="search-right">
        <div>
          <div class="search-tab" v-if="tabShow">
            <label
              v-for="(item, index) in tabList"
              :key="index"
              @click="selectType(item)"
              :class="{ 'text-color': selectName === item.text }"
              >{{ item.text }}
              <input name="tab" :checked="item.bool" type="radio"
            /></label>
          </div>
          <a-input-search
            class="search-input"
            style="width: 810px"
            v-model="code"
            :placeholder="placeholder"
            enter-button="搜索"
            size="large"
            :maxLength="20"
            :allowClear="true"
            @search="onSearch"
          />
          <div class="search-hot">
            <div v-if="titleShow">
              <img src="@/assets/img/hot.png" alt="" />
              <div>热门搜索：</div>
            </div>
            <div>
              <div
                v-for="(item, index) in hotList"
                :key="index"
                @click="searchData(item)"
              >
                {{ item.value }}
              </div>
            </div>
          </div>
        </div>
        <a-button class="search-btn" @click="userCenter">免费发布需求</a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { getHotList } from "@/api/common";
import { mapGetters } from "vuex";
export default {
  name: "YSearch",
  model: {
    prop: "codeData",
    event: "change",
  },
  props: {
    logo: {
      type: String,
    },
    tabShow: {
      type: Boolean,
      default: false,
    },
    codeData: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      code: "",
      tabList: [
        { text: "找服务", bool: true },
        { text: "找服务商", bool: false },
        { text: "找需求", bool: false },
      ],
      hotService: [],
      hotDemand: [],
      hotTreasury: [],
      selectName: "找服务",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "companyList"]),
    hotList() {
      if (this.selectName === "找需求") {
        return this.hotDemand;
      } else if (this.selectName === "找服务商") {
        return this.hotTreasury;
      } else {
        return this.hotService;
      }
    },
    titleShow() {
      if (this.selectName === "找需求") {
        return this.hotDemand.length > 0;
      } else if (this.selectName === "找服务商") {
        return this.hotTreasury.length > 0;
      } else {
        return this.hotService.length > 0;
      }
    },
    placeholder() {
      if (this.selectName === "找需求") {
        return "搜索您想要的需求";
      } else if (this.selectName === "找服务商") {
        return "搜索您想要的服务商";
      } else {
        return "搜索您想要的服务";
      }
    },
  },
  watch: {
    code(val) {
      this.$emit("change", val);
      if (val === "") {
        this.$emit("search");
      }
    },
    codeData(val) {
      this.code = val;
    },
  },
  mounted() {
    if (this.type) {
      this.selectName = this.type;
    }
    getHotList(1).then((res) => {
      this.hotDemand = res;
    });
    getHotList(2).then((res) => {
      this.hotTreasury = res;
    });
    getHotList(3).then((res) => {
      this.hotService = res;
    });
  },
  methods: {
    searchData(data) {
      this.$emit("searchData", data, this.selectName);
    },
    onSearch() {
      if (this.code) {
        this.$emit("search");
        if (this.selectName === "找服务") {
          this.$router.push({
            name: "service",
            params: { code: this.code },
          });
        }
        if (this.selectName === "找服务商") {
          this.$router.push({
            name: "treasury",
            params: { code: this.code },
          });
        }
        if (this.selectName === "找需求") {
          this.$router.push({
            name: "demand",
            params: { code: this.code },
          });
        }
      }
    },

    userCenter() {
      if (this.userInfo) {
        if (this.companyList && this.companyList.length > 0) {
          window.open(
            `${process.env.VUE_APP_ACCOUNT_PREFIX}/demandManage/demand`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.VUE_APP_ACCOUNT_PREFIX}/?type=1`,
            "_blank"
          );
        }
      } else {
        this.$router.push("/login");
      }
    },
    selectType(item) {
      this.selectName = item.text;
    },
  },
};
</script>

<style scoped lang="scss">
.bigHeight {
  height: 166px;
  .search-btn {
    margin-top: 32px;
  }
}
.smallHeight {
  height: 133px;
}

.top-border {
  border-top: 1px solid #e2e8f0;
}

.search-box {
  width: 100%;
  background: #ffffff;

  .search-content {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .search-right {
    display: flex;
    > :first-child {
      .search-tab {
        > label {
          display: inline-block;
          width: 90px;
          height: 32px;
          font-size: 14px;
          color: #8c8c8c;
          line-height: 32px;
          text-align: center;
          cursor: pointer;
          input {
            display: none;
          }
        }
        > .text-color {
          background: #ea0b06;
          color: #ffffff;
        }
        > label:has(:checked) {
          background: #ea0b06;
          color: #ffffff;
        }
      }

      .search-input::v-deep {
        .ant-input {
          border: 2px solid #ea0b06;
          border-radius: 0 !important;
          &:hover,
          &:focus {
            border-right-width: 2px !important;
          }
        }
        .ant-input-lg {
          font-size: 14px;
        }

        .ant-btn {
          background: #ea0b06;
          border-color: #ea0b06;
          border-radius: 0 !important;
          font-size: 14px;
          &:active,
          &:hover {
            color: #ffffff !important;
          }
        }
      }
      .search-hot {
        margin-top: 12px;
        display: flex;
        height: 17px;
        > :first-child {
          display: flex;
          align-items: center;
          > img {
            display: block;
            height: 17px;
            margin-right: 8px;
          }
          > div {
            font-size: 12px;
            color: #8c8c8c;
            line-height: 17px;
          }
        }
        > :last-child {
          display: flex;
          max-width: 728px;
          overflow: hidden;
          > div {
            font-size: 12px;
            color: #8c8c8c;
            line-height: 17px;
            margin-right: 24px;
            cursor: pointer;
            &:hover {
              color: #ea0b06;
            }
          }
        }
      }
    }
    > .search-btn {
      width: 128px;
      height: 40px;
      margin-left: 16px;
      font-size: 16px;
    }
  }
}
</style>
