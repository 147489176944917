<template>
  <div>
    <YSearch type="找需求" v-model="name" @searchData="toSearch"></YSearch>
    <div class="detail-body">
      <a-breadcrumb separator=">" style="margin: 24px 0">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item
          ><router-link to="/demand">需求大厅</router-link></a-breadcrumb-item
        >
        <a-breadcrumb-item href="">{{ detailData.name }}</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="detail-content">
        <div class="content-left">
          <div class="left-body">
            <div class="detail-info">
              <div class="info-left">
                <div>
                  <img src="@/assets/img/tender.png" alt="" />
                  <p>{{ detailData.name }}</p>
                </div>
                <div>
                  <ul>
                    <li>
                      需求编号：<span>{{ detailData.id }}</span>
                    </li>
                    <li>
                      发布时间：<span>{{ detailData.createTime }}</span>
                    </li>
                    <li>
                      需求分类：<span v-if="detailData.demandTypeLevel == '2'"
                        >{{ detailData.demandTypeParentName }}>{{
                          detailData.demandTypeName
                        }}</span
                      ><span v-else>{{ detailData.demandTypeName }}</span>
                    </li>
                  </ul>
                  <ul>
                    <li>
                      需求单位：<span>{{
                        detailData.companyName || detailData.purUnitName
                      }}</span>
                    </li>
                    <li>
                      实施周期：<span
                        >{{ detailData.carryOutStartTime }}~{{
                          detailData.carryOutEndTime
                        }}</span
                      >
                    </li>
                    <li>
                      投标截止时间：<span>{{ detailData.demandEndTime }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="info-right">
                <a-popover placement="rightTop">
                  <template slot="content">
                    <fen-xiang :val="detailData"></fen-xiang>
                  </template>
                  <a-button> <v-icon type="icon-fenxiang" />分享</a-button>
                </a-popover>

                <p>预算金额</p>
                <div>￥{{ detailData.fundAmount }}</div>
              </div>
            </div>
          </div>
          <div>
            <a-tabs default-active-key="1" @change="callback">
              <a-tab-pane key="1">
                <span slot="tab">
                  <v-icon type="icon-xiangqing" />
                  需求详情
                </span>
                <demand-info
                  :describe="detailData.demandDescribe"
                  :ids="detailData.applyingAttmIds"
                ></demand-info>
              </a-tab-pane>
              <a-tab-pane key="2" v-if="isDemandCer">
                <span slot="tab">
                  <v-icon type="icon-zhongbiao" />
                  投标记录
                </span>
                <div class="table-card">
                  <record-table
                    ref="recordTable"
                    :demandId="id"
                    :companyName="detailData.companyName"
                    :demandStatus="detailData.demandStatus"
                    @bidChange="loadGetDemandDetail"
                  ></record-table>
                </div>
              </a-tab-pane>
            </a-tabs>
          </div>
        </div>
        <div class="content-right" v-if="detailData.isReadonly == false">
          <div>需求联系人</div>
          <div>
            <div v-if="bidIngBool || isDemandCer">
              <p>
                联系人：<span v-if="detailData.contactPerson">{{
                  detailData.contactPerson
                }}</span>
              </p>
              <p>
                联系方式：<span>{{ detailData.contactPhone }}</span>
              </p>
            </div>
            <div v-else>
              <p>
                联系人：<span v-if="detailData.contactPerson">{{
                  detailData.contactPerson.replace(/^(.).*(.)$/, "$1*$2")
                }}</span>
              </p>

              <p>
                联系方式：<span>{{
                  detailData.contactPhone | capitalize
                }}</span>
              </p>
            </div>

            <div v-if="!isDemandCer">
              <a-button
                class="btn"
                :class="[
                  bidIngBool || btnText === '我要投标' ? 'btn-red' : 'btn-dis',
                ]"
                :disabled="!(bidIngBool || btnText === '我要投标')"
                @click="bid"
                >{{ btnText }}</a-button
              >
              <a-divider dashed v-if="!bidIngBool" />
              <div class="info" v-if="!bidIngBool">
                <a-icon type="info-circle" style="color: #ea0b06" />
                投标后方可查看完整的联系方式
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <company-list
      v-model="jiebangShow"
      :companyList="companyList"
      :demandId="id"
      :companyName="detailData.companyName"
      sceneStatus="6"
      @dataChange="loadGetDemandBidCompany"
    >
    </company-list>
  </div>
</template>

<script>
import YSearch from "@/components/YSearch";
import VIcon from "@/components/VIcon";
import RecordTable from "@/page/demand/components/recordTable";
import demandInfo from "@/page/demand/components/demandInfo";
import { getDemandDetail, getDemandBidCompany } from "@/api/demand";
import companyList from "@/page/demand/components/companyList";
import { mapGetters } from "vuex";
import fenXiang from "@/page/demand/components/fenxiang";
export default {
  name: "detail",
  components: {
    YSearch,
    VIcon,
    RecordTable,
    demandInfo,
    companyList,
    fenXiang,
  },
  data() {
    return {
      detailData: "",
      jiebangShow: false,
      companyList: [],
      bidIngBool: false, //是否投过标
      biding: false, //是否中标
      name: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "companyId", "companyInfo"]),
    id() {
      return this.$route.query.id;
    },
    isDemandCer() {
      if (this.userInfo) {
        return this.userInfo.id == this.detailData.creator;
      } else {
        return false;
      }
    },
    btnText() {
      //投过标
      if (this.bidIngBool) {
        //已中标
        if (this.biding) {
          return "已中标";
        } else {
          return "我要投标";
        }
        //未投标
      } else {
        if (this.detailData.demandStatus === "5") {
          return "投标已截止";
        } else if (this.detailData.demandStatus === "6") {
          return "已定标";
        } else {
          return "我要投标";
        }
      }
    },
  },

  mounted() {
    this.loadGetDemandDetail();
    this.loadGetDemandBidCompany();
  },
  methods: {
    loadGetDemandDetail() {
      getDemandDetail(this.id).then((res) => {
        this.detailData = res;
      });
    },

    loadGetDemandBidCompany() {
      getDemandBidCompany(this.id).then((res) => {
        this.companyList = res;
        this.bidIngBool = res.some((el) => {
          return el.demandBidStatus;
        });
        this.biding = res.some((res) => {
          return res.demandBidStatus === "1";
        });
      });
    },
    bid() {
      if (!this.userInfo) {
        this.$message.warning("您还未登录！");
        return;
      }

      if (this.companyList.length == 0) {
        this.$message.warning("您必须是企业所有者且企业认证通过！");
        return;
      }

      this.jiebangShow = true;
    },

    toSearch(item) {
      this.$router.push({
        name: "demand",
        params: { code: item.value },
      });
    },
    callback() {},
  },
};
</script>

<style scoped lang="scss">
.detail-content {
  display: flex;
  justify-content: space-between;
}

.left-body {
  position: relative;
}

.detail-body {
  width: 1280px;
  margin: 0 auto;
}

.content-left {
  width: 976px;
  > :first-child {
    width: 976px;
    min-height: 192px;
    background: #ffffff;
    padding: 22px 24px 32px 24px;
  }
  .detail-info {
    display: flex;
    > .info-left {
      > :first-child {
        margin-top: 10px;
        display: flex;
        > img {
          display: block;
          width: 42px;
          height: 24px;
          margin-right: 10px;
          margin-top: 2px;
        }
        > p {
          font-size: 20px;
          color: rgba(0, 0, 0, 0.85);
          line-height: 28px;
        }
      }
      > :last-child {
        display: flex;
        margin-top: 18px;
        ul {
          width: 410px;
        }
        li {
          font-size: 14px;
          color: #262626;
          line-height: 22px;
          margin-bottom: 8px;
          > span {
            color: #595959;
          }
        }
      }
    }
    > .info-right {
      position: absolute;
      top: 24px;
      right: 24px;
      > :first-child {
        img {
          display: inline-block;
          margin-right: 4px;
        }
        float: right;
      }
      > p {
        margin-top: 56px;
        font-size: 14px;
        color: #8c8c8c;
        line-height: 22px;
        text-align: right;
      }
      > div {
        float: right;
        font-size: 20px;
        color: rgba(234, 11, 6, 0.85);
        line-height: 28px;
      }
    }
  }
  > :last-child {
    width: 100%;
    margin-top: 24px;
    background: #ffffff;
    margin-bottom: 56px;
    &::v-deep {
      .ant-tabs-bar {
        margin-bottom: 0;
      }
    }
    .table-card {
      padding: 24px;
    }
  }
}

.content-right {
  width: 280px;
  max-height: 243px;
  background: linear-gradient(180deg, #ffffff 0%, #ffffff 100%);
  > :first-child {
    padding: 16px;
    font-size: 14px;
    color: #262626;
    line-height: 20px;
    border-bottom: 1px solid #e8e8e8;
  }
  > :last-child {
    padding: 16px;
    p {
      font-size: 12px;
      color: #262626;
      line-height: 20px;
      margin-bottom: 8px;
      > span {
        color: #595959;
      }
    }
    .btn {
      margin-top: 8px;
      width: 248px;
      height: 40px;
    }
    &::v-deep {
      .ant-divider-horizontal {
        margin: 15px 0;
      }
    }

    .info {
      padding-left: 12px;
      background: rgba(234, 11, 6, 0.05);
      border-radius: 4px;
      font-size: 12px;
      color: #ea0b06;
      line-height: 20px;
    }
  }
}
</style>
