<template>
  <div class="menu-info">
    <div class="info-top">
      <div class="top-banner">
        <a-carousel autoplay>
          <div
            class="banner-item"
            v-for="item in advertise"
            :key="item.adAttmId"
            @click="toadvertise(item.adHref)"
          >
            <v-img
              :attachment-id="item.adAttmId"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </div>
        </a-carousel>
      </div>
      <div class="top-user">
        <div class="user">
          <v-img
            v-if="!userInfo"
            :default-img="require('@/assets/img/defaultavatar.png')"
          />
          <v-img
            v-else
            :attachment-id="userInfo.profilePictureAttmId"
            :default-img="require('@/assets/img/defaultavatar.png')"
          />
          <p>Hi 欢迎来到中山云上软件云园</p>
          <div class="btn-box" v-if="!userInfo">
            请 <span @click="$router.push('/login')">登录</span> 或
            <span @click="$router.push('/register')">注册</span>
          </div>
        </div>

        <div class="notice">
          <a-tabs default-active-key="1">
            <a-tab-pane key="1" tab="园区公告">
              <div
                class="text"
                v-for="el in list"
                @click="noticeInfo(el.id)"
                :key="el.id"
              >
                <i></i>
                <p>{{ el.title }}</p>
              </div>
            </a-tab-pane>
            <a-tab-pane key="2" tab="最新资讯" force-render>
              <div
                class="text"
                v-for="el in newList"
                @click="noticeNewInfo(el.id)"
                :key="el.id"
              >
                <i></i>
                <p>{{ el.newsName }}</p>
              </div>
            </a-tab-pane>
          </a-tabs>
        </div>
      </div>
    </div>
    <div class="info-bottom">
      <div @click="toItem('/service')">
        <p>服务市场</p>
      </div>
      <div @click="toItem('/treasury')">
        <p>服务商库</p>
      </div>
      <div @click="toItem('/demand')">
        <p>需求大厅</p>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { mapGetters } from "vuex";
import { getAdvertiseList } from "@/api/advertise";
import { getAnnouncementList, getNewsList } from "@/api/news";
export default {
  name: "menuInfo",
  computed: mapGetters(["userInfo", "tenant", "companyList"]),
  components: {
    VImg,
  },
  data() {
    return {
      advertise: [],
      list: [],
      newList: [],
    };
  },
  mounted() {
    this.loadGetAdvertise();
    this.loadData();
  },

  methods: {
    loadGetAdvertise() {
      let params = {
        adType: "HOME_BANNER",
        validStatus: "1",
      };

      getAdvertiseList(params).then((res) => {
        let data = res.records;
        data.forEach((item) => {
          if (item.validStatus == 1) {
            this.advertise.push(item);
          }
        });
        if (this.advertise.length == 0) {
          this.advertise = [
            {
              adAttmId: "",
              validStatus: "1",
            },
          ];
        }
      });
    },
    loadData() {
      getAnnouncementList(
        { current: 1, size: 4 },
        { tenantId: this.tenant.id }
      ).then((res) => {
        this.list = res.records;
      });
      getNewsList({ current: 1, size: 4 }, {}).then((res) => {
        this.newList = res.records;
      });
    },
    toadvertise(url) {
      if (url) {
        window.open(url);
      }
    },
    noticeInfo(id) {
      this.$router.push({
        path: "/announcement/info",
        query: { id },
      });
    },
    noticeNewInfo(id) {
      this.$router.push({
        path: "/policy/info",
        query: { id },
      });
    },
    toItem(url) {
      this.$router.push(url);
    },
  },
};
</script>

<style scoped lang="scss">
.info-top {
  display: flex;
}

.top-banner {
  width: 752px;
  height: 312px;
  .banner-item {
    width: 752px;
    height: 312px;
    > img {
      display: block;
      width: 752px;
      height: 312px;
    }
  }
}
.top-user {
  /* padding: 16px 16px;*/
  width: 240px;
  height: 312px;
  background: #ffffff;
  box-sizing: border-box;
  margin-left: 24px;
  > .user {
    padding-top: 24px;
    height: 150px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
    > img {
      display: block;
      max-width: 60px;
      max-height: 60px;
      margin: 0 auto 6px;
    }
    > p {
      font-size: 12px;
      color: #595959;
      line-height: 20px;
      text-align: center;
      margin-bottom: 4px;
    }
    > .btn-box {
      font-size: 12px;
      color: #595959;
      line-height: 20px;
      text-align: center;
      > span {
        cursor: pointer;
        color: #ea0b06;
      }
    }
  }
  &::v-deep {
    .ant-divider-horizontal {
      margin: 0;
    }
  }
  > .notice {
    padding: 0 16px;
    .text {
      font-size: 12px;
      color: #8c8c8c;
      line-height: 17px;
      display: flex;
      align-items: center;
      margin-top: 8px;
      cursor: pointer;
      > i {
        display: inline-block;
        width: 3px;
        height: 3px;
        background: #ea0b06;
        margin-right: 7px;
      }
      > p {
        width: 198px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        word-break: break-all;
      }
    }
    &::v-deep {
      .ant-tabs-bar {
        margin-bottom: 4px;
      }
      .ant-tabs-tab {
        padding: 12px 0 8px 0;
        margin-right: 16px;
        font-size: 14px;
        color: #262626;
        line-height: 20px;
      }
      .ant-tabs-nav .ant-tabs-tab:hover {
        color: #ea0b06;
      }
      .ant-tabs-nav .ant-tabs-tab-active {
        color: #ea0b06;
      }
      .ant-tabs-bar {
        border-bottom: none;
      }
    }
  }
}

.info-bottom {
  display: flex;
  margin-top: 16px;
  > div {
    width: 328px;
    height: 100px;
    margin-right: 16px;
    cursor: pointer;
    > P {
      font-size: 18px;
      color: #ffffff;
      line-height: 100px;
      margin-left: 100px;
    }
  }
  > :nth-child(1) {
    background: url("../../../assets/img/menuInfo1.png") no-repeat;
    background-size: cover;
  }
  > :nth-child(2) {
    background: url("../../../assets/img/menuInfo2.png") no-repeat;
    background-size: cover;
  }
  > :nth-child(3) {
    background: url("../../../assets/img/menuInfo3.png") no-repeat;
    background-size: cover;
    margin-right: 0;
  }
}
</style>
