<template>
  <div class="home-solution">


    <banner-swiper
    :banner="advertise.banner"
    :textinfo="textinfo"
    >

    </banner-swiper>


    <div class="home-replace-content-box">
      <div class="home-replace-content">
        <div class="home-replace-filter">
          <div>
            <span>方案类型：</span>
            <div>
              <span :class="{'active':solutionType == ''}" @click="solutionType = ''">全部</span>
              <span :class="{'active':solutionType == 1}" @click="solutionType = 1">鸿蒙方案</span>
              <span :class="{'active':solutionType == 2}" @click="solutionType = 2">数字化转型方案</span>
            </div>
          </div>
          <div>
            <span>所有分类：</span>
            <div>
              <span
                v-for="(item, index) in solutionSortList"
                :key="index"
                :class="{ active: solutionSortId == item.id }"
                @click="solutionSort(item.id, index)"
                >{{ item.name }}</span
              >
            </div>
          </div>
          <div>
            <span>二级分类：</span>
            <div :class="{ active: filterTwoShow }" >
              <span
                :class="{ active: solutionSortTwoId == item.id }"
                v-for="(item, index) in solutionSortTwoList"
                :key="index"
                @click="solutionSortTwo(item.id, item.pid, index)"
                >{{ item.name }}</span
              >
            </div>
            <p @click="filterTwoShow = !filterTwoShow" v-show="solutionSortShow==0">
              {{ filterTwoShow ? "收起" : "展开"
              }}<a-icon :type="filterTwoShow ? 'up' : 'down'" />
            </p>
          </div>
          <!-- <div>
            <span>三级分类：</span>
            <div :class="{ active: filterThreeShow }">
              <span
                :class="{ active: solutionSortThreeId == item.id }"
                v-for="(item, index) in solutionSortThreeList"
                :key="index"
                @click="solutionSortThree(item.id)"
                >{{ item.name }}</span
              >
            </div>
            <p @click="filterThreeShow = !filterThreeShow">
              {{ filterThreeShow ? "收起" : "展开"
              }}<a-icon :type="filterThreeShow ? 'up' : 'down'" />
            </p>
          </div> -->
          <div>
            <span>方案热度：</span>
            <div>
              <span :class="{'active':marketingId == ''}" @click="marketingId = ''">全部</span>
              <span :class="{'active':marketingId == 2}" @click="marketingId = 2">推荐</span>
              <span :class="{'active':marketingId == 3}" @click="marketingId = 3">火热</span>
            </div>
          </div>
        </div>


        <!--    广告-->
        <div class="notif_img" v-if="advertise.notif" @click="toadvertise(advertise.notif.adHref)">
          <v-img
              class="img"
              v-if="advertise.notif"
              :attachment-id="advertise.notif.adAttmId"
              :default-img="require('@/assets/img/photo.png')"
          />
        </div>


        <div :class="{'loading-style':loading}">
          <a-skeleton avatar active :loading="loading" :paragraph="{ rows: 10 }">
            <div class="home-solution-list">
              <template v-if="solutionList != ''">
                <div
                  v-for="(item, index) in solutionList"
                  :key="index"
                  @click="solutionInfo(item.id,item.hotType)"
                >
                  <div class="home-solution-list-img">
                    <span v-if="item.marketing == 2">推荐</span>
                    <span v-if="item.marketing == 3">HOT</span>
                    <v-img class="img" :attachment-id="item.solutionImg" :default-img="require('@/assets/img/cmrt.png')" />
                  </div>
                  <div class="home-solution-list-info">
                    <p class="home-solution-list-title">{{ item.name }}</p>
<!--                    <span>{{item.solSlogan}}</span>-->

                    <div class="taglist">
                      <div v-for="(item,index) in item.tag" :key="index">{{item}}</div>
                    </div>

                    <p class="home-solution-list-desc">{{ item.solutionDiscription }}</p>
                    <div class="btn-right"><span>查看详情 ></span></div>
                  </div>
                </div>
              </template>
              <div v-else style="padding:100px 0;display:block">
                <a-empty />
              </div>
            </div>
          </a-skeleton>
        </div>
        <div class="home-replace-page">
          <pagination
            :total="page.total"
            :current="page.current"
            @changes="pageChange"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSolutionList, getCategoryList } from "@/api/solution";
import {getAdvertiseList} from "@/api/advertise";
import pagination from "../components/pagination.vue";
import VImg from "@/components/VImg";
import BannerSwiper from "@/components/BannerSwiper";
export default {
  components: {
    pagination,
    VImg,
    'banner-swiper':BannerSwiper
  },
  data() {
    return {
      solutionSortId: '',
      solutionSortIndex: "",
      solutionSortTwoId: '',
      solutionSortThreeId: 0,
      solutionSortShow:0, //展开收起判断
      filterTwoShow: false,
      filterThreeShow: false,
      solutionSortList: [
        {
          id: "",
          name: "全部",
          pid: "",
        },
      ],
      solutionSortTwoList: [
        {
          id: "",
          name: "全部",
          pid: "",
        },
      ],
      solutionSortThreeList: [
        {
          id: "",
          name: "全部",
          pid: "0",
        },
      ],
      solutionList: [],
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      categoryData: [],
      categoryId:'',
      marketingId:'',
      loading:false,
      notM0:'',
      notHm:'',
      solutionType:'',
      advertise:{
        banner:[],
        notif:'',
      },
      textinfo:{
        title:'解决方案',
        text:'好用、精选、场景化的行业创新解决方案',
        type:'solution',
        height:320,
      }

    };
  },
  watch:{
    marketingId:function(val){
      this.page.current = 1
      this.loadGetSolutionList();
    },
    solutionType:function(val){
      if(val == 1){
        this.notHm = ''
        this.notM0 = 1
      }else if(val == 2){
        this.notHm = 1
        this.notM0 = ''
      }else{
        this.notHm = ''
        this.notM0 = ''
      }
      this.page.current = 1
      this.loadGetSolutionList();
    }
  },
  created() {
    this.loadGetAdvertise();
  },
  mounted() {
    if(this.$route.query.id){
      this.solutionSortTwoId = this.$route.query.id
      this.categoryId = this.$route.query.id
    }
    if(this.$route.params.id){
      this.solutionSortId = this.$route.params.id
      this.solutionSortTwoId = this.$route.params.subid
      this.categoryId = this.$route.params.subid
    }
    this.loadGetSolutionCategory();
    this.loadGetSolutionList();
  },
  methods: {

    loadGetAdvertise(){
      let params={
        adType:'SOLUTION_BANNER',
        validStatus:'1',
      }

      getAdvertiseList(params).then((res)=>{
        console.log(res,'====广告solution====')
        let data=res.records
        data.forEach(item=>{
          if(item.adType==='SOLUTION_BANNER' && item.validStatus==1){
            this.advertise.banner.push(item)
          }else if(item.adType==='SOLUTION_NOTIF_BANNER' && item.validStatus==1){
            this.advertise.notif=item
          }
        })

        console.log(this.advertise,'advertise')


      })
    },




    // 类别列表
    loadGetSolutionCategory() {
      let id = this.$route.query.id
      getCategoryList('1').then((res) => {
        let data = res;
        this.categoryData = data;
        this.solutionSortList = [...this.solutionSortList, ...data];
        data.forEach((element) => {
          element.children.forEach((item) => {
            this.solutionSortTwoList.push(item);
            if(id == item.id){
              this.solutionSortId = element.id
            }
            item.children.forEach((subitem) => {
              this.solutionSortThreeList.push(subitem);
            });
          });
        });
      });
    },
    // 选择一级类别
    solutionSort(id, index) {
      let { solutionSortList, categoryData } = this;
      this.solutionSortShow=index
      this.solutionSortId = id;
      this.categoryId = id
      this.solutionSortIndex = index;
      this.page.current = 1
      this.solutionSortTwoList = [
        {
          id: "",
          name: "全部",
          pid: "",
        },
      ];
      // this.solutionSortThreeList = [
      //   {
      //     id: "",
      //     name: "全部",
      //     pid: "",
      //   },
      // ];
      if (index != 0) {
        let data = solutionSortList[index].children;
        data.forEach((item) => {
          this.solutionSortTwoList.push(item);
          // item.children.forEach((subitem) => {
          //   this.solutionSortThreeList.push(subitem);
          // });
        });
      } else {
        categoryData.forEach((element) => {
          element.children.forEach((item) => {
            this.solutionSortTwoList.push(item);
            // item.children.forEach((subitem) => {
            //   this.solutionSortThreeList.push(subitem);
            // });
          });
        });
      }
      this.loadGetSolutionList();
    },
    // 选择二级类别
    solutionSortTwo(id, pid, index) {
      console.log(index)
      this.solutionSortTwoId = id;
      this.page.current = 1
      if(id != ''){
        this.solutionSortId = pid
        this.categoryId = id
      }else{
        this.categoryId = this.solutionSortId
      }
      // this.solutionSortThreeList = [
      //   {
      //     id: "",
      //     name: "全部",
      //     pid: "",
      //   },
      // ];
      // categoryData.forEach((element) => {
      //   if(id == ''){
      //     if(solutionSortId == ''){
      //       element.children.forEach((item) => {
      //         item.children.forEach((subitem) => {
      //           this.solutionSortThreeList.push(subitem);
      //         });
      //       });
      //     }else{
      //       if(element.id == solutionSortId){
      //         element.children.forEach((item) => {
      //           item.children.forEach((subitem) => {
      //             this.solutionSortThreeList.push(subitem);
      //           });
      //         });
      //       }
      //     }
      //   }else{
      //     if (element.id == pid) {
      //       element.children[index - 1].children.forEach((subitem) => {
      //         this.solutionSortThreeList.push(subitem);
      //       });
      //     }
      //   }
      // });
      this.loadGetSolutionList();
    },
    // 选择三级
    solutionSortThree(id){
      this.solutionSortThreeId = id;
      this.categoryId = id
      this.loadGetSolutionList();
    },
    // 解决方案列表
    loadGetSolutionList() {
      let { page,categoryId,marketingId,solutionList,notHm,notM0 } = this;
      let data = {
        status: "",
        marketing: marketingId,
        tag: "",
        category:categoryId,
        notHm,
        notM0
      };
      if(solutionList == ''){
        this.loading = true
      }
      getSolutionList(page, data).then((res) => {

        this.solutionList = res.records;

        this.solutionList.forEach(item=>{
          if(item.tag){
            item.tag = item.tag.split(',').slice(0,3)
          }
        })


        this.page.total = res.total;
        this.loading = false
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetSolutionList();
    },
    solutionInfo(id,type) {
      // type  1 常规方案，2 3 鸿蒙方案， 4 小红书方案 5 普通方案 6数字方案
      // 1 ， 2 3，4 5，6
      if(type == 2 || type == 3){
        this.$router.push({
          path: "/solution/info",
          query: { id },
        });
      }else if(type == 1){
        this.$router.push({
          path: "/solution/internet_things",
          query: { id },
        });
      }else if(type == 4 || type == 5){
        this.$router.push({
          path: "/solution/ordinary_program",
          query: { id },
        });
      }else if(type == 6){
        this.$router.push({
          path: "/solution/digitizing",
          query: { id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-solution {


  .notif_img{
   display: flex;
    justify-content: center;
    margin-top: 24px;
    >.img{
      width: 1280px;
      height: 100px;
    }
  }
  .home-replace-content-box {
    padding: 24px 0 56px;
    display: flex;
    align-items: flex-start;
    width: 1280px;
    margin: 0 auto;
    // .home-replace-sort {
    //   width: 200px;
    //   background: #fff;
    //   margin-right: 24px;
    //   > p {
    //     height: 48px;
    //     border-bottom: 1px solid #f0f0f0;
    //     line-height: 48px;
    //     padding: 0 24px;
    //     font-size: 16px;
    //     font-weight: bold;
    //   }
    //   > div {
    //     max-height: 600px;
    //     overflow-y: auto;
    //     padding: 8px 0;
    //     > p {
    //       height: 32px;
    //       padding-left: 20px;
    //       border-left: 4px solid transparent;
    //       line-height: 32px;
    //       margin-bottom: 8px;
    //       transition: all 0.3s;
    //       cursor: pointer;
    //       &:last-child {
    //         margin-bottom: 0;
    //       }
    //       &.active {
    //         border-color: #EA0B06;
    //       }
    //     }
    //   }
    // }
    .home-replace-content {
      width: 100%;
      .home-replace-filter {
        background: #fff;
        padding: 24px;
        > div {
          display: flex;
          padding-bottom: 8px;
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 16px;
          > span {
            width: 70px;
            margin-right: 8px;
            line-height: 24px;
          }
          > div {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            height: 32px;
            overflow: hidden;
            &.active {
              height: auto;
            }
            > span {
              cursor: pointer;
              line-height: 22px;
              margin-bottom: 8px;
              height: 24px;
              border: 1px solid transparent;
              border-radius: 2px;
              padding: 0 16px;
              text-align: center;
              margin-right: 8px;
              &.active {
                border-color: #EA0B06;
                color: #EA0B06;
              }
            }
          }
          > p {
            width: 80px;
            height: 24px;
            background: #f5f5f5;
            border-radius: 2px;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            margin-left: 8px;
            > i {
              color: #8c8c8c;
              margin-left: 2px;
              transition: all 0.3s;
            }
          }
          &:last-child {
            padding-bottom: 0;
            border: none;
            margin-bottom: 0;
            > div {
              > span {
                margin-bottom: 0;
              }
            }
          }
        }
      }
      .home-solution-list {
        padding: 0 24px 24px;
        background: #fff;
        margin-top: 24px;
        margin-bottom: 40px;
        > div {
          padding: 24px 0;
          display: flex;
          border-bottom: 1px solid #f0f0f0;
          cursor: pointer;
          .home-solution-list-img {
            width: 246px;
            height: 138px;
            margin-right: 24px;
            position: relative;
            > img {
              width: 100%;
              height: 100%;
              display: block;
              border: 1px solid #EEEEEE;
            }
            > span {
              background: url(../../assets/img/kcicon.png) no-repeat;
              background-size: 100% 100%;
              min-width: 52px;
              height: 32px;
              text-align: center;
              line-height: 32px;
              color: #fff;
              position: absolute;
              top: -4px;
              left: 8px;
            }
          }
          .home-solution-list-info {
            flex: 1;
            padding-top: 8px;
            .home-solution-list-title {
              font-size: 18px;
              line-height: 24px;
              margin-bottom: 8px;
            }
            .home-solution-list-desc {
              font-size: 12px;
              color: #999;
              line-height: 20px;
              max-height: 40px;
              overflow: hidden;
              margin-bottom: 10px;
            }
            >.taglist{
              display: flex;
              justify-content: flex-start;
              margin-bottom: 8px;
              >div{
                background: #F6F6F6;
                border-radius: 3px;
                padding: 2.6px 6px 1px;
                margin-right: 8px;
                font-size: 12px;
                line-height: 17px;
                font-weight: 500;
                color: #666666;
              }
            }
            > .btn-right {
              text-align: right;
              > span {
                color: #EA0B06;
                cursor: pointer;
                display: inline-block;
                line-height: 14px;
              }
            }
          }
        }
      }
      .home-replace-page {
        text-align: center;
      }
    }
  }
}
</style>
