import { PortalRequest as request } from "@/utils/request";

/**
 * 平台公告详情
 * @param data
 * @returns {AxiosPromise}
 */
export const getAnnouncementInfo = (id) => {
  return request({
    url: `/res-announcement/info/${id}`,
    method: "get",
  });
};