import { PortalRequest as request } from "@/utils/request";

//需求大厅分页查询
export const getDemandPageList = (params, data) => {
  return request({
    url: `/dmd-demand/page-list-serve`,
    method: "post",
    params: params,
    data,
  });
};

/**
 * 企业明星榜
 * @param data
 * @returns {*}
 */
export const getStarList = (data) => {
  return request({
    url: `/dmd-demand/list-star-company`,
    method: "post",
    data,
  });
};

/**
 * 最新成交
 * @param data
 * @returns {*}
 */
export const getLatestList = (data) => {
  return request({
    url: `/dmd-demand/list-latest-bid`,
    method: "post",
    data,
  });
};

//需求大厅列表
export const getTypeList = () => {
  return request({
    url: `/dmd-demand-type/tree-data`,
    method: "get",
  });
};

/**
 * 需求大厅详情页
 * @returns {*}
 */
export const getDemandDetail = (id) => {
  return request({
    url: `/dmd-demand/${id}`,
    method: "get",
  });
};

/**
 * 企业列表
 * @param demandId
 * @returns {*}
 */
export const getDemandBidCompany = (demandId) => {
  return request({
    url: `dmd-demand/bid-user-company-list/${demandId}`,
    method: "post",
  });
};

/**
 * 投标
 * @param data
 * @returns {*}
 */
export const demandSubmit = (data) => {
  return request({
    url: `/dmd-demand/bid/submit`,
    method: "post",
    data,
  });
};

/**
 * 投标方案详情
 * @returns {*}
 * @param demandId 需求id
 * @param bidCompanyId 公司id
 */
export const getDemandBidDetail = (demandId, bidCompanyId) => {
  return request({
    url: `/dmd-demand/bid/${demandId}/${bidCompanyId}`,
    method: "get",
  });
};

/**
 * 投标记录列表
 * @param params
 * @param data
 * @returns {*}
 */
export const getDemandProjectList = (params, data) => {
  return request({
    url: `/dmd-demand/page-list-bid`,
    method: "post",
    params: params,
    data,
  });
};

export const selectProject = (bidId) => {
  return request({
    url: `/dmd-demand/bid/${bidId}/choose`,
    method: "post",
  });
};

export const getDemandBidInfo = () => {
  return request({
    url: `yunyuan/bid-latest-today`,
    method: "get",
  });
};
