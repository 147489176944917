<template>
    <video ref="videoPlayer" class="video-js videos" :poster="poster"  :style="{width: width,height: height}" controls loop muted  autoplay ></video>
</template>

<script>
import videojs from 'video.js'
import 'video.js/dist/video-js.css'
export default {
  name: "VideoPlayer",
  props: {
    options: {
      type: Object,
      default() {
        return {};
      },
    },
    poster:{
      type:String
    },
    width:{
      type:String,
      default:'1920px'
    },
    height:{
      type:String,
      default:'680px'
    }
  },
  data() {
    return {
      player: null,
      isMobile:false
    };
  },
  mounted() {
    this.player = videojs(
        this.$refs.videoPlayer,
        this.options,
        function onPlayerReady() {
          console.log("onPlayerReady", this);
        }
    );
    if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)){
      this.isMobile = true
      document.querySelector('.videos').style.width = this.width
      document.querySelector('.videos').style.height = this.height
    }else{
      this.isMobile = false
    }
  },
  beforeDestroy() {
    if (this.player) {
      this.player.dispose();
    }
  },
};
</script>
<style>


.ant-modal {
  top: 0px !important;
}
.ant-modal-content{
  margin-top: 20px;
}
.vjs-control-bar,.vjs-modal-dialog-content,.vjs-loading-spinner,.vjs-error-display,.vjs-modal-dialog,.vjs-text-track-display{
  display: none;
}

</style>
