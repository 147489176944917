<template>
  <div class="new-home-product-serve">
    <strong>丰富、个性、智能化精品应用助力企业降本增效</strong>
    <p>携手1000+生态伙伴为提供丰富应用服务，交替全程监督，加速政企数字化转型</p>
    <div>
      <div class="new-home-product-serve-tag">
        <p>应用服务推荐</p>
        <div
          :class="{ active: productTagIndex == 1 }"
          @mouseenter="productTagIndex = 1"
        >
          <span></span>
          <div>
            <p>私有化部署系统</p>
            <span>安全、可靠的企业应用服务</span>
          </div>
        </div>
        <div
          :class="{ active: productTagIndex == 2 }"
          @mouseenter="productTagIndex = 2"
        >
          <span></span>
          <div>
            <p>标准化应用工具</p>
            <span>服务G端用户的政策驱动型应用场景</span>
          </div>
        </div>
        <div
          :class="{ active: productTagIndex == 3 }"
          @mouseenter="productTagIndex = 3"
        >
          <span></span>
          <div>
            <p>个性化定制服务</p>
            <span>服务B端行业用户的生产驱动型应用场景</span>
          </div>
        </div>
      </div>
      <div class="new-home-product-serve-content" v-show="productTagIndex == 1">
        <p>私有化部署系统助力大型政企客户数字化转型</p>
        <span>基于鸿联联创智能生产平台提供的全生命周期软件项目交付管理</span>
        <div class="new-home-product-serve-pt">
          <div @click="openurl('https://www.gitok.com/platform/yuansheng')">
            <span
              ><img
                src="@/assets/img/yunpt.png"
                alt="图片"
              />云原生应用平台</span
            >
            <p>
              <i></i> 采用全球最新Service Mesh架构，核心系统具备完全自主知识产权
            </p>
            <p><i></i> 融合国际领先社区先进技术理念</p>
          </div>
          <div @click="openurl(' https://www.gitok.com/platform/code')">
            <span
              ><img src="@/assets/img/didm.png" alt="图片" />低代码平台</span
            >
            <p>
              <i></i> 一云多端，支持web/App/小程序/后端API等，满足所有开发需求
            </p>
            <p><i></i> 拖拽式搭建，快速实现应用开发</p>
          </div>
        </div>
        <div class="new-home-product-serve-subpt">
          <div @click="openurl('https://www.gitok.com/platform')">
            <span
              ><img src="@/assets/img/hhypt.png" alt="图片" />混合云平台</span
            >
            <p>
              专为多云环境打造，屏蔽底层差异，建立标准化资源服务面向多云和云原生，“零运维”交付
            </p>
          </div>
          <div>
            <span
              ><img src="@/assets/img/sjpt.png" alt="图片" />大数据平台</span
            >
            <p>
              提供可视化的全链路开发能力，挖掘数据资产整合丰富的领域模型，具备数据治理能力，保护数据安全
            </p>
          </div>
          <div @click="openurl('https://www.gitok.com/platform/serve')">
            <span><img src="@/assets/img/wfw.png" alt="图片" />微服务</span>
            <p>
              针对微服务架构差异性大、开发困难等问题，提供自研微服务架构，帮助企业搭建适于其业务和现状的微服务应用
            </p>
          </div>
        </div>
      </div>
      <div class="new-home-product-serve-content" v-show="productTagIndex == 2">
        <p>私有化部署系统助力大型政企客户数字化转型</p>
        <span>基于鸿联联创智能生产平台提供的全生命周期软件项目交付管理</span>
        <div class="new-home-product-serve-pt">
          <template v-for="(el, index) in saasList">
            <div
              :key="el.id"
              v-if="index < 2"
              class="new-home-serve-saas"
              @click="serveInfo(el.id)"
            >
              <span
                ><v-img
                  :attachment-id="el.serviceCover"
                  :defaultImg="require('@/assets/img/kctp.png')"
                />{{ el.serviceName }}</span
              >
              <div>
                <div>{{ el.summary }}</div>
                <p v-if="el.pricingType == 1">
                  ￥{{ el.lowestPrice
                  }}<span>/{{ el.buyType | buyTypeDict("BUY_TYPE") }}</span>
                </p>
                <p v-else>议价定制</p>
              </div>
            </div>
          </template>
        </div>
        <div class="new-home-product-serve-subpt">
          <template v-for="(el, index) in saasList">
            <div
              :key="el.id"
              v-if="index > 1"
              class="new-home-serve-saas-footer"
              @click="serveInfo(el.id)"
            >
              <span>{{ el.serviceName }}</span>
              <p>{{ el.summary }}</p>
              <div v-if="el.pricingType == 1">
                ￥{{ el.lowestPrice
                }}<span>/{{ el.buyType | buyTypeDict("BUY_TYPE") }}</span>
              </div>
              <div v-else>议价定制</div>
            </div>
          </template>
        </div>
      </div>
      <div class="new-home-product-serve-content" v-show="productTagIndex == 3">
        <p>私有化部署系统助力大型政企客户数字化转型</p>
        <span>基于鸿联联创智能生产平台提供的全生命周期软件项目交付管理</span>
        <div class="new-home-product-serve-pt">
          <template v-for="(el, index) in list">
            <div
              :key="el.id"
              v-if="index < 2"
              class="new-home-serve-saas"
              @click="serveInfo(el.id)"
            >
              <span
                ><v-img
                  :attachment-id="el.serviceCover"
                  :defaultImg="require('@/assets/img/kctp.png')"
                />{{ el.serviceName }}</span
              >
              <div>
                <div>{{ el.summary }}</div>
                <p v-if="el.pricingType == 1">
                  ￥{{ el.lowestPrice
                  }}<span>/{{ el.buyType | buyTypeDict("BUY_TYPE") }}</span>
                </p>
                <p v-else>议价定制</p>
              </div>
            </div>
          </template>
        </div>
        <div class="new-home-product-serve-subpt">
          <template v-for="(el, index) in list">
            <div
              :key="el.id"
              v-if="index > 1"
              class="new-home-serve-saas-footer"
              @click="serveInfo(el.id)"
            >
              <span>{{ el.serviceName }}</span>
              <p>{{ el.summary }}</p>
              <div v-if="el.pricingType == 1">
                ￥{{ el.lowestPrice
                }}<span>/{{ el.buyType | buyTypeDict("BUY_TYPE") }}</span>
              </div>
              <div v-else>议价定制</div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getServicePageList } from "@/api/service";
import VImg from "@/components/VImg";
import { mapGetters, mapActions } from "vuex";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      productTagIndex: 1,
      saasList: [],
      list: [],
    };
  },
  computed: mapGetters(["dict"]),
  created() {
    this.LoadDictData(["BUY_TYPE"]);
  },
  mounted() {
    this.loadData();
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadData() {
      getServicePageList({ current: 1, size: 5 }, { deliverType: "2" }).then(
        (res) => {
          this.saasList = res.records;
        }
      );
      getServicePageList({ current: 1, size: 5 }, { deliverType: "3" }).then(
        (res) => {
          this.list = res.records;
        }
      );
    },
    serveInfo(id) {
      this.$router.push({
        path: "/service/details",
        query: { id },
      });
    },
    openurl(url) {
      window.open(url);
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes cardFadeImg {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.new-home-product-serve {
  > strong {
    display: block;
    font-size: 32px;
    line-height: 45px;
    color: #262626;
    text-align: center;
    margin-bottom: 24px;
  }
  > p {
    font-size: 18px;
    line-height: 25px;
    color: #666;
    margin-bottom: 36px;
    text-align: center;
  }
  > div {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);
    .new-home-product-serve-tag {
      width: 284px;
      height: 490px;
      background: url(../../../assets/img/swtagbg.jpg) no-repeat;
      background-size: 100% 100%;
      > p {
        height: 80px;
        font-size: 24px;
        font-weight: bold;
        line-height: 80px;
        color: #333333;
        padding: 0 20px;
      }
      > div {
        height: 73px;
        display: flex;
        align-items: center;
        position: relative;
        cursor: pointer;
        transition: height 0.5s;
        > span {
          background: url(../../../assets/img/swszbg.png) no-repeat;
          background-size: auto 100%;
          height: 100%;
          z-index: 1;
          width: 100%;
          position: absolute;
          animation: cardFadeImg 0.5s ease;
          display: none;
        }
        > div {
          padding: 0 20px;
          position: relative;
          z-index: 2;
          > p {
            font-size: 18px;
            line-height: 25px;
            color: #262626;
          }
          > span {
            display: none;
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            margin-top: 12px;
          }
        }
        &.active {
          height: 140px;
          border-bottom: 0;
          > span {
            display: block;
          }
          > div {
            > p {
              font-size: 24px;
              line-height: 32px;
              font-weight: bold;
              position: relative;
              &::after {
                content: "";
                position: absolute;
                width: 4px;
                height: 21px;
                background: #ea0b06;
                top: 6px;
                left: -20px;
              }
            }
            > span {
              display: block;
            }
          }
        }
      }
    }
    .new-home-product-serve-content {
      flex: 1;
      background: #fff;
      padding: 24px;
      > p {
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
        color: #262626;
      }
      > span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: #666666;
        margin: 12px 0 24px;
      }
      .new-home-product-serve-pt {
        border: 1px solid #e8e8e8;
        display: flex;
        margin-bottom: 24px;
        > div {
          flex: 1;
          padding: 30px 22px;
          position: relative;
          cursor: pointer;
          &:first-child {
            &::after {
              content: "";
              position: absolute;
              right: 0;
              top: 23px;
              width: 1px;
              height: 113px;
              background: #e8e8e8;
            }
          }
          > span {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            color: #262626;
            margin-bottom: 26px;
            > img {
              width: 28px;
              margin-right: 12px;
            }
          }
          > p {
            font-size: 14px;
            line-height: 18px;
            color: #666666;
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            &:last-child {
              margin-bottom: 0;
            }
            > i {
              width: 2px;
              height: 2px;
              background: #ea0b06;
              margin-right: 16px;
            }
          }
        }
        .new-home-serve-saas {
          cursor: pointer;
          > span {
            margin-bottom: 12px;
            > img {
              width: 50px;
              height: 50px;
            }
          }
          > div {
            display: flex;
            justify-content: space-between;
            align-items: center;
            > div {
              flex: 1;
              font-size: 14px;
              line-height: 20px;
              color: #666666;
              max-height: 40px;
              overflow: hidden;
            }
            > p {
              display: flex;
              align-items: center;
              margin-left: 30px;
              font-size: 20px;
              line-height: 28px;
              color: #ea0b06;
              > span {
                font-size: 12px;
                line-height: 16px;
                color: #666666;
                margin-left: 2px;
              }
            }
          }
        }
      }
      .new-home-product-serve-subpt {
        display: flex;
        cursor: pointer;
        > div {
          padding: 30px 24px;
          border: 1px solid #e8e8e8;
          width: 300px;
          margin-right: 24px;
          transition: all 0.3s;
          &:hover {
            box-shadow: 0px 0px 14px rgba(0, 0, 0, 0.06);
          }
          &:last-child {
            margin-right: 0;
          }
          > span {
            display: flex;
            align-items: center;
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            color: #262626;
            margin-bottom: 26px;
            > img {
              width: 28px;
              margin-right: 12px;
            }
          }
          > p {
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            word-break: break-all;
          }
        }
        .new-home-serve-saas-footer {
          cursor: pointer;
          padding: 30px 24px 18px;
          > span {
            display: block;
            font-size: 18px;
            font-weight: bold;
            line-height: 25px;
            color: #262626;
            margin-bottom: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > p {
            font-size: 14px;
            line-height: 20px;
            color: #666666;
            max-height: 40px;
            overflow: hidden;
            margin-bottom: 16px;
          }
          > div {
            display: flex;
            align-items: center;
            font-size: 20px;
            line-height: 28px;
            color: #ea0b06;
            > span {
              font-size: 12px;
              line-height: 16px;
              color: #666666;
              margin-left: 2px;
            }
          }
        }
      }
    }
  }
}
</style>
