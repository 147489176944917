<template>
  <div class="new-home-result">
    <strong>应用成果</strong>
    <p>来自各行各业的最佳实践，值得选择</p>
    <img :class="{active : resultTagIndex == 0}" src="@/assets/img/cg1.jpg" alt="图片" />
    <img :class="{active : resultTagIndex == 1}" src="@/assets/img/cg2.jpg" alt="图片" />
    <img :class="{active : resultTagIndex == 2}" src="@/assets/img/cg3.jpg" alt="图片" />
    <img :class="{active : resultTagIndex == 3}" src="@/assets/img/cg5.jpg" alt="图片" />
    <img :class="{active : resultTagIndex == 4}" src="@/assets/img/cg6.jpg" alt="图片" />
    <img :class="{active : resultTagIndex == 5}" src="@/assets/img/cg7.jpg" alt="图片" />
    <div class="new-home-result-content">
      <div class="new-home-result-tag">
        <span
          v-for="(el, index) in resultTag"
          :key="index"
          :class="{ active: sceneTypeId == el.id }"
           @click="sceneType(el.id,index)"
          >{{ el.name }}</span
        >
        <span @click="$router.push('/result')">查看更多 <a-icon type="right" /></span>
      </div>
      <div class="new-home-result-info">
        <strong>场景介绍</strong>
        <p>{{achievementList.contents}}</p>
        <div class="new-home-result-info-lable" v-if="achievementList.tagList != ''">
          <span v-for="(item,index) in achievementList.tagList" :key="index">{{item.tag}}</span>
        </div>
        <strong>成果示范</strong>
        <div class="new-home-result-info-list">
          <div v-for="item in achievementList.acvAchievements" :key="item.id" @click="$router.push(`/result/info?id=${item.id}`)">
            <div>
              <p>{{item.acvName}}</p>
              <span><a-icon type="arrow-right" /></span>
            </div>
            <v-img :attachment-id="item.coverAttmId" :default-img="require('@/assets/img/kctp.png')" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSceneList, getSceneInfo } from "@/api/achievement";
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      resultTag: [],
      achievementList:'',
      resultTagIndex: 0,
      sceneTypeId:''
    };
  },
  mounted() {
    this.loadgetSceneList()
  },
  methods: {
    sceneType(id,index){
      this.sceneTypeId = id
      this.resultTagIndex = index
      this.loadgetSceneInfo()
    },
    loadgetSceneList() {
      let page = {
        current: 1,
        size: 6,
      };
      getSceneList(page).then((res) => {
        this.resultTag = res.records;
        this.sceneTypeId = res.records[0].id;
        this.loadgetSceneInfo();
      });
    },
    loadgetSceneInfo() {
      getSceneInfo(this.sceneTypeId).then((res) => {
        this.achievementList = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-home-result {
  padding: 70px 0;
  position: relative;
  height: 722px;
  > strong {
    display: block;
    font-size: 32px;
    line-height: 45px;
    color: #fff;
    text-align: center;
    margin-bottom: 24px;
    position: relative;
    z-index: 3;
  }
  > p {
    font-size: 18px;
    line-height: 25px;
    color: #fff;
    margin-bottom: 36px;
    text-align: center;
    position: relative;
    z-index: 3;
  }
  > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: ease .5s;
    &.active{
        opacity: 1;
    }
  }
  &::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.7) 0%,
      rgba(0, 0, 0, 0) 100%
    );
  }
  .new-home-result-content {
    width: 1280px;
    margin: 0 auto;
    position: relative;
    z-index: 3;
    .new-home-result-tag {
      padding: 0 56px;
      display: flex;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
      > span {
        height: 38px;
        border-bottom: 3px solid transparent;
        margin-right: 110px;
        font-size: 16px;
        line-height: 22px;
        color: #ffffff;
        transition: all 0.3s;
        cursor: pointer;
        white-space: nowrap;
        &:last-child {
          margin-right: 0;
        }
        &.active {
          border-color: #d44c55;
        }
        &:hover {
          color: #d44c55;
        }
      }
    }
    .new-home-result-info {
      padding-top: 70px;
      > strong {
        display: block;
        font-size: 20px;
        font-weight: bold;
        line-height: 28px;
        color: #ffffff;
        margin-bottom: 16px;
      }
      > p {
        font-size: 14px;
        line-height: 24px;
        color: #ffffff;
        width: 714px;
        margin-bottom: 26px;
      }
      .new-home-result-info-lable {
        display: flex;
        margin-bottom: 40px;
        > span {
          height: 30px;
          background: rgba(255, 255, 255, 0.3);
          border-radius: 4px;
          line-height: 30px;
          padding: 0 14px;
          font-size: 16px;
          color: #ffffff;
          margin-right: 12px;
        }
      }
      .new-home-result-info-list {
        display: flex;
        > div {
          width: 160px;
          height: 90px;
          border-radius: 4px;
          overflow: hidden;
          margin-right: 12px;
          position: relative;
          cursor: pointer;
          > img {
            display: block;
            width: 100%;
            height: 100%;
          }
          > div {
            opacity: 0;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 2;
            backdrop-filter: blur(20px);
            padding: 20px 12px 0;
            transition: all 0.3s;
            > p {
              font-size: 12px;
              line-height: 20px;
              color: #ffffff;
            }
            > span {
              font-size: 12px;
              text-align: right;
              display: block;
              color: #ffffff;
            }
          }
          &:hover {
            > div {
              opacity: 1;
            }
          }
        }
      }
    }
  }
}
</style>