<template>
  <div class="home-layout">
    <div v-if="tenant.homePageType === '1'">
      <home-head v-model="isShow" />
      <div class="home-layout-content" v-if="isShow">
        <router-view :key="$route.fullPath" />
      </div>
      <home-footer />
    </div>
    <div v-else>
      <home-head v-model="isShow" v-show="homeChange" />
      <div class="home-layout-content" v-if="isShow">
        <router-view :key="$route.fullPath" />
      </div>
      <home-footer />
    </div>
  </div>
</template>

<script>
import homeHead from "./head.vue";
import homeFooter from "./homeFooter.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    homeHead,
    homeFooter,
  },
  data() {
    return {
      isShow: true,
      homeChange: true,
    };
  },
  computed: {
    ...mapGetters(["tenant"]),
  },

  created() {
    if (this.$route.path === "/" && this.tenant.homePageType === "2") {
      this.homeChange = false;
      console.log(this.homeChange, "this.homeChangethis.homeChange");
    }
  },
  mounted() {},
  watch: {
    isShow: function (val) {
      this.isShow = val;
      setTimeout(() => {
        this.isShow = true;
      }, 100);
    },
    $route(to, from) {
      if (to.path === "/") {
        this.homeChange = false;
      } else {
        this.homeChange = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-layout {
  background: #f7f8fa;
  min-height: 100%;
  .home-layout-content {
    min-height: 100vh;
  }
}
</style>
