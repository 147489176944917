import { getCurrentTenant } from "@/api/common";

export default {
  state: {
    tenant: null,
  },
  mutations: {
    SET_TENANT(state, tenant) {
      state.tenant = tenant;
    },
  },
  actions: {
    LoadCurrentTenant({ commit }) {
      return new Promise((resolve) => {
        getCurrentTenant().then((data) => {
          commit("SET_TENANT", data);
          resolve(data);
        });
      });
    },
  },
};
