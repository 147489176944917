<template>
  <a-modal
    @cancel="guanbi"
    title="投标方案"
    v-model="bidBool"
    width="1000px"
    class="modal"
  >
    <div class="modal-box">
      <p class="title">
        <a-icon
          type="exclamation-circle"
          theme="filled"
          style="color: #faad14"
        />
        您正在以<span>{{ bidCompany }}</span
        >身份投标
      </p>
      <a-form-model
        ref="ruleForm"
        :model="form"
        :rules="rules"
        :label-col="labelCol"
        :wrapper-col="wrapperCol"
      >
        <a-form-model-item ref="bidPrice" label="方案报价" prop="bidPrice">
          <a-input-number
            style="width: 574px"
            v-model="form.bidPrice"
            placeholder="请填写报价金额（正数，保留两位小数）"
            maxLength="11"
            :min="0"
            :max="99999999999"
            @blur="
              () => {
                $refs.bidPrice.onFieldBlur();
              }
            "
          />
        </a-form-model-item>

        <a-form-model-item ref="bidTime" label="实施周期" prop="bidTime">
          <a-range-picker
            style="width: 574px"
            v-model="form.bidTime"
            format="YYYY-MM-DD"
            @blur="
              () => {
                $refs.bidTime.onFieldBlur();
              }
            "
          />
        </a-form-model-item>

        <a-form-model-item ref="bidTime" label="交付阶段" prop="bidTime">
          <div class="bidSteps">
            <div class="list">
              <div>阶段名称</div>
              <div>交付时间</div>
              <div>交付内容</div>
            </div>
            <div>
              <div
                v-for="(el, index) in form.dmdDemandBidSteps"
                :key="index"
                class="list"
              >
                <a-input placeholder="请输入阶段名称" v-model="el.stepName" />
                <div style="height: 32px" class="data-picker">
                  <v-date-picker
                    v-model="el.stepTime"
                    maxLength="20"
                    placeholder="请选择交付时间"
                  />
                </div>
                <a-textarea
                  placeholder="请填写交付内容"
                  v-model="el.stepContent"
                  auto-size
                />
                <img
                  src="@/assets/img/bidDelete.png"
                  alt=""
                  @click="delSteps(index)"
                />
              </div>
            </div>
            <a-button @click="addSteps">添加交付阶段</a-button>
          </div>
        </a-form-model-item>

        <a-form-model-item ref="bidAttmIds" label="应标附件" prop="bidAttmIds">
          <v-multi-upload
            :icon="true"
            file-type-code="2022"
            :disabled="uploadBool"
            v-model="form.bidAttmIds"
          />
          <p class="text">
            可上传5个3M以内的JPG，PNG，GIF，DOC，DOCX，XLS，XLSX，PDF，RAR，ZIP文件
          </p>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div slot="footer">
      <a-button @click="guanbi" class="btn-gray">取 消</a-button>
      <a-button @click="submitBid" class="btn-red" :loading="loading"
        >提 交</a-button
      >
    </div>
  </a-modal>
</template>

<script>
import VDatePicker from "@/components/VDatePicker";
import VMultiUpload from "@/components/VMultiUpload";
import moment from "moment";
import { demandSubmit } from "@/api/demand";
export default {
  name: "bidForm",
  props: ["formBool", "demandId", "companyId", "bidCompany"],
  model: {
    prop: "formBool",
    event: "change",
  },
  components: {
    VDatePicker,
    VMultiUpload,
  },
  data() {
    return {
      bidBool: false,
      loading: false,
      form: {
        bidPrice: "",
        bidTime: [],
        bidAttmIds: [],
        dmdDemandBidSteps: [
          {
            demandId: this.demandId,
            stepName: "",
            stepTime: null,
            stepContent: "",
          },
        ],
      },

      labelCol: { span: 2 },
      wrapperCol: { span: 22 },
      rules: {
        bidPrice: [
          { required: true, message: "请填写报价金额", trigger: "blur" },
        ],
        bidTime: [
          { required: true, message: "请填写实施周期", trigger: "blur" },
        ],
        bidAttmIds: [
          { required: true, message: "请上传附件", trigger: "blur" },
        ],
        dmdDemandBidSteps: [
          { required: true, message: "请填写交付阶段", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    uploadBool() {
      return this.form.bidAttmIds.length >= 5;
    },
  },
  watch: {
    formBool(val) {
      this.bidBool = val;
    },
  },
  methods: {
    guanbi() {
      this.bidBool = false;
      this.$emit("change", false);
      this.form = {
        bidPrice: "",
        bidTime: "",
        bidAttmIds: [],
        dmdDemandBidSteps: [
          {
            demandId: this.demandId,
            stepName: "",
            stepTime: null,
            stepContent: "",
          },
        ],
      };
    },
    submitBid() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          this.form.bidStartTime = moment(this.form.bidTime[0]).format(
            "YYYY-MM-DD"
          );
          this.form.bidEndTime = moment(this.form.bidTime[1]).format(
            "YYYY-MM-DD"
          );

          for (let item of this.form.dmdDemandBidSteps) {
            for (let el in item) {
              if (!item[el]) {
                this.$message.warning("交付阶段内容不能为空！");
                return;
              }
            }
          }

          this.loading = true;

          this.form.bidCompanyId = this.companyId;
          this.form.demandId = this.demandId;
          demandSubmit(this.form).then(() => {
            this.guanbi();
            this.$emit("demandBid");
            this.$message.success("提交成功");
            this.loading = false;
          });
        }
      });
    },
    addSteps() {
      let item = {
        demandId: this.demandId,
        stepName: "",
        stepTime: null,
        stepContent: "",
      };
      this.form.dmdDemandBidSteps.push(item);
    },
    delSteps(index) {
      if (index !== 0) {
        this.form.dmdDemandBidSteps.splice(index, 1);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-box {
  padding: 24px 24px 72px;
  .title {
    font-size: 20px;
    color: #262626;
    line-height: 22px;
    margin-bottom: 32px;

    > span {
      color: #ea0b06;
    }
  }
  .bidSteps {
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    padding: 16px;
    .list {
      display: flex;
      > :nth-child(1),
      > :nth-child(2) {
        width: 204px;
        margin-right: 16px;
      }
      > :nth-child(3) {
        width: 338px;
        margin-right: 24px;
      }
      > img {
        display: block;
        width: 28px;
        height: 28px;
        cursor: pointer;
      }
    }
    > :nth-child(2) {
    }
  }
  .text {
    font-size: 14px;
    color: #595959;
    line-height: 22px;
    margin-top: 8px;
  }
  .data-picker {
    &::v-deep {
      .ant-calendar-picker {
        display: block;
      }
    }
  }
}
</style>
