<template>
  <div class="scenes-topic-body">
    <div class="scenes-topic-banner">
      <img src="@/assets/img/cjbanner.jpg" />
      <div>
        <strong>{{ topicInfo.topicName }}</strong>
        <p>{{ topicInfo.description }}</p>
      </div>
    </div>
    <div class="scenes-topic-tag-body">
      <div>
        <p>标签云</p>
        <div>
          <wordcloud
            :color="myColors"
            :data="defaultWords"
            :fontSize="[16, 40]"
            :rotate="tagRotate"
            :showTooltip="false"
            :wordClick="wordClickHandler"
            :wordPadding="10"
            font="Arial"
            fontScale="n"
            nameKey="labelName"
            spiral="rectangular"
            valueKey="labelCode"
          >
          </wordcloud>
        </div>
      </div>
      <div>
        <p>趋势图</p>
        <div>
          <div id="echarts" style="width: 100%; height: 344px"></div>
        </div>
      </div>
    </div>
    <div class="scenes-topic-list-body">
      <div class="scenes-topic-head-search">
        <div>
          <span>专题需求</span>
          <p>
            <span :class="{ active: stateCode == '' }" @click="stateCode = ''"
              >全部</span
            >
            <span
              :class="{ active: stateCode == item.code }"
              :key="index"
              @click="stateCode = item.code"
              v-for="(item, index) in stateData"
              >{{ item.text }}</span
            >
          </p>
        </div>
        <a-input-search
          @search="searchScenes"
          enter-button
          placeholder="请输入关键词搜索"
          style="width: 400px"
          v-model="scenesVal"
        />
      </div>
      <p>共 {{ pages.total }} 项需求</p>
      <div class="scenes-topic-table">
        <a-table
          :columns="columns"
          :data-source="scenesData"
          :pagination="pages"
          @change="tablePage"
        >
          <div slot="fundAmount" slot-scope="text">￥{{ text }}</div>
          <div slot="statusName" slot-scope="text, record">
            <span class="my-scenes-status">
              <i class="yans" v-if="record.statusName == '揭榜中'"></i>
              <i class="fb" v-else-if="record.statusName == '已定帅'"></i>
              <i class="btg" v-else-if="record.statusName == '已终止'"></i>
              <i v-else></i>
              {{ record.statusName }}
            </span>
          </div>
          <div slot="operating" slot-scope="text, record">
            <div class="my-scenes-operating">
              <span @click="taskDetail(record)">详情</span>
            </div>
          </div>
        </a-table>
      </div>
    </div>
    <div class="scenes-topic-footer">
      <div class="scenes-topic-footer-head">
        <p>专题成果</p>
        <span @click="changeAchv"><a-icon type="sync" />换一批</span>
      </div>
      <div class="scenes-topic-footer-list">
        <div
          :key="item.id"
          @click="resultsDetail(item.id)"
          v-for="item in achvList"
        >
          <span>
            <images
              :id="
                item.coverAttachment ? item.coverAttachment.attachmentId : ''
              "
            />
          </span>
          <p>{{ item.sceneName }}</p>
          <div v-if="item.rewardPass">
            ￥<span>{{ item.rewardAmount }}</span
            >万奖励金
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { getTopicStatistic } from "@/api/common";

const columns = [
  {
    title: "需求名称",
    dataIndex: "sceneName",
    key: "sceneName",
    ellipsis: true,
    width: 260,
  },
  {
    title: "场景领域",
    dataIndex: "fieldLevelOneName",
    key: "fieldLevelOneName",
  },
  {
    title: "发布单位",
    dataIndex: "ownerName",
    key: "ownerName",
  },
  {
    title: "发布时间",
    dataIndex: "updateTime",
    key: "updateTime",
  },
  // {
  //   title: "预估成本(万元)",
  //   dataIndex: "fundAmount",
  //   key: "fundAmount",
  //   scopedSlots: { customRender: "fundAmount" },
  //   width: 160,
  // },
  {
    title: "状态",
    dataIndex: "statusName",
    key: "statusName",
    scopedSlots: { customRender: "statusName" },
  },
  {
    title: "操作",
    dataIndex: "operating",
    key: "operating",
    width: 160,
    scopedSlots: { customRender: "operating" },
  },
];
import wordcloud from "vue-wordcloud";
import {
  getTopicAchvList,
  getTopicInfo,
  getTopicScenelist,
} from "@/api/cjkf/topic";
import { commonCode } from "@/api/cjkf/scene";
import echarts from "@/components/chart/echarts";
import images from "@/page/components/image";

export default {
  components: {
    wordcloud,
    images,
  },
  data() {
    return {
      tagRotate: { from: 0, to: 0, numOfOrientation: 0 },
      myColors: ["#5B8FF9", "#78D3F8", "#7262FD", "#F6BD16", "#61DDAA"],
      defaultWords: [],
      columns,
      scenesData: [],
      pages: {
        showSizeChanger: true,
        showQuickJumper: true,
        current: 1,
        total: 0,
        showTotal: (total) => `共 ${total} 项`,
      },
      page: {
        current: 1,
        size: 10,
      },
      scenesVal: "",
      topicId: this.$route.query.id,
      topicInfo: "",
      stateData: [],
      stateCode: "",
      achvPage: {
        current: 1,
        size: 5,
        totalPage: 0,
      },
      achvList: [],
    };
  },
  watch: {
    stateCode: function (val) {
      this.page.current = 1;
      this.loadgetTopicScenelist();
    },
  },
  mounted() {
    this.myEcharts();
    this.loadgetTopicInfo();
    this.loadgetTopicScenelist();
    this.loadState();
    this.loadgetTopicAchvList();
  },
  methods: {
    loadgetTopicInfo() {
      getTopicInfo(this.topicId).then((res) => {
        this.topicInfo = res;
        this.defaultWords = res.labelList;
      });
    },
    loadgetTopicScenelist() {
      let data = {
        status: this.stateCode,
        keywords: this.scenesVal,
      };
      getTopicScenelist(this.topicId, this.page, data).then((res) => {
        this.scenesData = res.records;
        this.pages.total = res.total;
      });
    },
    loadState() {
      let obj = {
        type: "WORKFLOW",
        precode: "SYS02",
      };
      commonCode(obj).then((res) => {
        this.stateData = res;
      });
    },
    loadgetTopicAchvList() {
      getTopicAchvList(this.topicId, this.achvPage).then((res) => {
        this.achvList = res.records;
        this.achvPage.totalPage = res.pages;
      });
    },
    changeAchv() {
      let achvPage = this.achvPage;
      if (achvPage.current == achvPage.totalPage) {
        this.$message.warning("暂无更多数据!");
        return;
      }
      this.achvPage.current = achvPage.current + 1;
    },
    wordClickHandler(name, value, vm) {
      console.log("wordClickHandler", name, value, vm);
    },
    myEcharts() {
      getTopicStatistic(this.topicId).then((res) => {
        const times = res.map((e) => e.text);
        const scenes = res.map((e) => e.sceneCount);
        const achvs = res.map((e) => e.achvCount);
        var myChart = echarts.init(document.getElementById("echarts"));
        var option = {
          tooltip: {
            trigger: "axis",
          },
          color: ["#8B4CFF", "#FFA842"],
          legend: {
            orient: "horizontal",
            right: "20px",
            top: "20px",
          },
          grid: {
            top: 60,
            left: 40,
            right: 30,
            bottom: 40,
          },
          xAxis: [
            {
              type: "category",
              boundaryGap: false,
              data: times,
              axisTick: {
                show: false,
              },
              axisLine: {
                lineStyle: {
                  type: "solid",
                  color: "#bbb",
                },
              },
            },
          ],
          yAxis: [
            {
              type: "value",
            },
          ],
          series: [
            {
              symbol: "none",
              name: "需求",
              type: "line",
              smooth: true,
              data: scenes,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(139, 76, 255, 0.08)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#ffffff", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
            {
              symbol: "none",
              name: "成果",
              type: "line",
              smooth: true,
              data: achvs,
              areaStyle: {
                color: {
                  type: "linear",
                  x: 0,
                  y: 0,
                  x2: 0,
                  y2: 1,
                  colorStops: [
                    {
                      offset: 0,
                      color: "rgba(255, 168, 66, 0.08)", // 0% 处的颜色
                    },
                    {
                      offset: 1,
                      color: "#ffffff", // 100% 处的颜色
                    },
                  ],
                },
              },
            },
          ],
        };
        myChart.setOption(option);
      });
    },
    searchScenes() {
      this.loadgetTopicScenelist();
    },
    taskDetail(e) {
      this.$router.push({
        path: "/homeScenes/detail",
        query: { id: e.id },
      });
    },
    tablePage(e) {
      this.page.current = e;
      this.pages.current = e;
      this.loadgetTopicScenelist();
    },
    resultsDetail(id) {
      this.$router.push({
        path: "/homeResults/detail",
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.scenes-topic-body {
  padding-bottom: 80px;

  .scenes-topic-banner {
    margin-bottom: 24px;
    position: relative;

    > img {
      max-width: 100%;
      display: block;
    }

    > div {
      position: absolute;
      width: 1200px;
      left: 50%;
      margin-left: -600px;
      top: 0;
      bottom: 0;
      padding-top: 48px;

      > strong {
        display: block;
        font-size: 64px;
        font-family: FZZDHJW;
        font-weight: bold;
        color: #ffffff;
        line-height: 64px;
        text-shadow: 3px 5px 0px #0e5dff;
        margin-bottom: 36px;
      }

      > p {
        line-height: 22px;
        color: #fff;
        width: 600px;
        text-align: justify;
        max-height: 140px;
        overflow-x: auto;
      }
    }
  }

  .scenes-topic-tag-body {
    width: 1200px;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;

    > div {
      width: 588px;
      background: #fff;

      > p {
        height: 56px;
        padding: 0 16px;
        line-height: 56px;
        border-bottom: 1px solid #f0f0f0;
        font-size: 16px;
      }

      > div {
        height: 344px;
      }
    }
  }

  .scenes-topic-list-body {
    width: 1200px;
    margin: 24px auto 0;
    background: #fff;

    .scenes-topic-head-search {
      display: flex;
      justify-content: space-between;
      border-bottom: 1px solid #f0f0f0;
      padding: 0 16px;
      height: 56px;
      align-items: center;

      > div {
        display: flex;
        align-items: center;

        > span {
          line-height: 20px;
          font-size: 16px;
          padding-right: 16px;
          border-right: 2px solid #f0f0f0;
        }

        > p {
          display: flex;

          > span {
            margin-left: 40px;
            cursor: pointer;
            position: relative;
            transition: all 0.3s;
            line-height: 14px;

            &.active {
              &::after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: -14px;
                width: 28px;
                height: 2px;
                background: #1abc9c;
              }
            }
          }
        }
      }
    }

    > p {
      padding: 0 24px;
      margin: 16px 0;
      line-height: 14px;
      color: #8c8c8c;
    }

    .scenes-topic-table {
      padding: 0 24px;
    }
  }

  .scenes-topic-footer {
    width: 1200px;
    margin: 24px auto 0;
    background: #fff;

    .scenes-topic-footer-head {
      display: flex;
      padding: 0 16px;
      height: 56px;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #f0f0f0;

      > p {
        font-size: 16px;
      }

      > span {
        color: #8c8c8c;
        display: flex;
        align-items: center;
        cursor: pointer;

        > i {
          margin-right: 6px;
        }
      }
    }

    .scenes-topic-footer-list {
      padding: 24px;
      display: flex;

      > div {
        width: 210px;
        margin-right: 25px;
        cursor: pointer;

        &:last-child {
          margin-right: 0;
        }

        > span {
          display: block;
          height: 120px;

          > img {
            display: block;
            width: 100%;
            height: 100%;
          }
        }

        > p {
          line-height: 14px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin: 16px 0;
        }

        > div {
          color: #1abc9c;
          font-size: 12px;
          line-height: 12px;
          display: flex;
          align-items: flex-end;

          > span {
            font-size: 20px;
            line-height: 20px;
            font-weight: bold;
            margin: 0 2px;
          }
        }
      }
    }
  }
}

.my-scenes-status {
  display: flex;
  align-items: center;

  > i {
    width: 6px;
    height: 6px;
    background: #bfbfbf;
    border-radius: 50%;
    margin-right: 8px;

    &.yus {
      background: #faad14;
    }

    &.yans {
      background: #52c41a;
    }

    &.fb {
      background: #1890ff;
    }

    &.btg {
      background: #ff4d4f;
    }
  }
}

.my-scenes-operating {
  display: flex;

  > span {
    cursor: pointer;
    color: #1abc9c;
    margin-right: 20px;
    white-space: nowrap;

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
<style>
.wordCloud {
  height: 100% !important;
}
</style>
