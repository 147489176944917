<template>
  <a-upload
    :action="action"
    :file-list="fileList"
    :headers="headers"
    name="file"
    :disabled="disabled"
    :before-upload="beforeUpload"
    @change="handleChange"
  >
    <slot>
      <a-button v-if="icon" icon="upload" :disabled="disabled"
        >上传文件</a-button
      >
      <a v-else>
        <a-icon type="cloud-upload" />
        选择文件
      </a>
    </slot>
  </a-upload>
</template>

<script>
import storage from "store";
import { ACCESS_TOKEN } from "@/store/mutation-types";
import { getAttachments } from "@/api/common";

export default {
  name: "VMultiUpload",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    fileTypeCode: {
      type: String,
      required: true,
    },
    icon: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    headers() {
      const token = storage.get(ACCESS_TOKEN);
      return {
        Authorization: `Bearer ${token}`,
      };
    },
    action() {
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/upload/${this.fileTypeCode}`;
    },
  },
  watch: {
    value(val, old) {
      console.log(`${old}===>${val}`);
      if (val !== old) {
        this.loadData();
      }
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    handleChange(info) {
      this.fileList = info.fileList;
      if (
        info.fileList.filter((e) => {
          return e.status !== "done";
        }).length
      ) {
        return;
      }
      const attachmentIds = info.fileList
        .filter((e) => e.status === "done")
        .map((e) => e.response.data.id);
      this.$emit("input", attachmentIds);
      this.$emit("change", attachmentIds);
    },
    loadData() {
      const { value } = this;
      if (value && value.length) {
        getAttachments(value).then((data) => {
          this.fileList = data.map((e) => {
            return {
              uid: e.id,
              name: e.fileName,
              status: "done",
              // url: `${VUE_APP_API_PATH}/file/${e.attachmentId}/download`,
              response: {
                data: {
                  ...e,
                },
              },
            };
          });
          console.log(this.fileList);
        });
      } else {
        this.fileList = [];
      }
    },
    beforeUpload(file) {
      return new Promise((resolve, reject) => {
        const isLt2M = file.size / 1024 / 1024 < 3;
        if (!isLt2M) {
          this.$message.error("附件大小不能超过3MB!");
          return reject(false);
        }
        return resolve(true);
      });
    },
  },
};
</script>
