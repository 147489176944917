<template>
  <div class="demand">
    <YSearch
      :logo="demandLogo"
      type="找需求"
      :codeData="codeData"
      v-model="dataInfo.name"
      @searchData="toSearch"
      @search="search"
    ></YSearch>
    <div class="demand-body">
      <!--      <a-breadcrumb separator=">" class="demand-bread">
        <a-breadcrumb-item>首页</a-breadcrumb-item>
        <a-breadcrumb-item href="">需求大厅</a-breadcrumb-item>
      </a-breadcrumb>-->
      <div class="result" v-show="codeText">
        搜索结果> <span>"{{ codeText }}"</span>
      </div>
      <div class="demand-content">
        <div class="content-left">
          <div class="service-filter">
            <div>
              <span>一级分类：</span>
              <div>
                <span
                  v-for="(item, index) in oneType"
                  :key="index"
                  :class="{ active: oneTypeId === item.value }"
                  @click="oneTypeChange(item)"
                  >{{ item.label }}</span
                >
              </div>
            </div>

            <div>
              <span>二级分类：</span>
              <div>
                <span
                  v-for="(item, index) in twoType"
                  :key="index"
                  :class="{ active: twoTypeId === item.value }"
                  @click="twoTypeChange(item)"
                  >{{ item.label }}</span
                >
              </div>
            </div>

            <div>
              <span>需求状态：</span>
              <div style="height: 32px">
                <span
                  v-for="item in demandState"
                  :key="item.id"
                  :class="{ active: demandStateId === item.id }"
                  @click="demandStateChange(item)"
                  >{{ item.name }}</span
                >
              </div>
            </div>

            <div>
              <span>预算金额：</span>
              <div>
                <span
                  v-for="item in priceType"
                  :key="item.id"
                  @click="priceTypeChange(item)"
                  :class="{ active: priceTypeId === item.id }"
                >
                  {{ item.name }}
                </span>
              </div>
            </div>

            <div>
              <span>发布时间：</span>
              <div>
                <span
                  v-for="item in dateType"
                  :key="item.id"
                  @click="dateTypeChange(item)"
                  :class="{ active: dateTypeId === item.id }"
                >
                  {{ item.name }}
                </span>
              </div>
            </div>
          </div>
          <demand-sort
            :total="page.total"
            @sortChange="sortChange"
          ></demand-sort>
          <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
            <div
              v-if="demandList && demandList.length > 0"
              class="service-list"
            >
              <demand-card
                @click.native="toDetails(item.id)"
                v-for="item in demandList"
                :key="item.id"
                :val="item"
              ></demand-card>
            </div>
            <div v-else style="padding: 100px 0; display: block">
              <a-empty />
            </div>
          </a-skeleton>
          <div class="pageList">
            <pagination
              style="margin-top: 36px"
              :size="page.size"
              :total="page.total"
              :current="page.current"
              @changes="pageChange"
            />
          </div>
        </div>
        <div class="content-right">
          <div v-if="starList && starList.length > 0">
            <img src="@/assets/img/demand-list.png" alt="" />
            <div
              style="cursor: pointer"
              v-for="(item, index) in starList"
              :key="index"
              @click="toProvider(item.isvId)"
            >
              <div :class="`num-${index + 1}`">{{ index + 1 }}</div>
              <div>{{ item.bidCompanyName }}</div>
            </div>
          </div>
          <div
            v-if="latestList && latestList.length > 0"
            style="padding-bottom: 24px"
          >
            <div>最新成交</div>
            <div class="list-item" v-for="item in latestList" :key="item.id">
              <div style="cursor: pointer" @click="toDetails(item.demandId)">
                <div>
                  <p>
                    <span>{{ item.updateTime.slice(0, 10) }}</span>
                    <span>{{ item.bidCompanyName }}</span>
                    <span>中标</span>
                  </p>
                </div>
                <div>
                  <p>{{ item.demandName }}</p>
                  <p>¥{{ item.bidPrice }}</p>
                </div>
                <a-divider dashed style="margin: 12px 0 0 0" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import YSearch from "@/components/YSearch";
import demandCard from "@/page/demand/components/demandCard";
import demandSort from "@/page/demand/components/demandSort";
import pagination from "../components/pagination.vue";
import demandLogo from "@/assets/img/demandLogo.png";
import {
  getDemandPageList,
  getTypeList,
  getStarList,
  getLatestList,
} from "@/api/demand";
export default {
  name: "index",
  components: {
    YSearch,
    demandCard,
    demandSort,
    pagination,
  },
  data() {
    return {
      codeText: "",
      codeData: "",
      demandLogo,
      companyList: [],
      demandList: [],
      tabList: [],
      page: {
        current: 1,
        size: 10,
      },
      loading: false,
      dataInfo: {
        demandTypeIdByServer: "",
        demandStatus: "",
        minFundAmount: "",
        maxFundAmount: "",
        createTimeRangeType: "",
        sort: "",
        name: "",
      },
      oneTypeId: "",
      twoTypeId: "",
      demandStateId: "demand",
      priceTypeId: "price",
      dateTypeId: "date",
      oneType: [
        {
          id: "",
          label: "全部",
          value: "",
        },
      ],
      twoType: [
        {
          id: "",
          label: "全部",
          value: "",
        },
      ],
      demandState: [
        {
          id: "demand",
          name: "全部",
          pid: "",
        },
        {
          id: "demand0",
          name: "投标中",
          pid: "4",
        },

        {
          id: "demand2",
          name: "已定标",
          pid: "6",
        },
        {
          id: "demand1",
          name: "已截止",
          pid: "5",
        },
      ],
      priceType: [
        {
          id: "price",
          name: "全部",
          price: "",
        },
        {
          id: "price0",
          name: "3000内",
          price: "",
        },
        {
          id: "price1",
          name: "3000～1万",
          price: "",
        },
        {
          id: "price2",
          name: "1万～5万",
          price: "",
        },
        {
          id: "price3",
          name: "5万以上",
          price: "",
        },
      ],
      dateType: [
        {
          id: "date",
          name: "全部",
          date: "",
        },
        {
          id: "date0",
          name: "近一周",
          date: "1",
        },
        {
          id: "date1",
          name: "近半个月",
          date: "2",
        },
        {
          id: "date2",
          name: "近一个月",
          date: "3",
        },
        {
          id: "date3",
          name: "大于二个月",
          date: "4",
        },
      ],
      starList: [],
      latestList: [],
      listAll: [],
    };
  },
  created() {
    if (this.code) {
      this.codeData = this.code;
    }
  },
  mounted() {
    if (this.code) this.dataInfo.name = this.code;
    this.loadGetDemandPageList();
    this.loadGetTypeList();
    this.loadGetStarList();
    this.loadGetLatestList();
  },
  computed: {
    code() {
      return this.$route.params.code;
    },
  },
  methods: {
      toProvider(isvId){
        if(isvId){
            this.$router.push(`/treasury/provider?id=${isvId}`)
        }
      },
    search() {
      this.page.current = 1;
      this.loadGetDemandPageList();
    },
    toSearch(item) {
      this.page.current = 1;
      this.dataInfo.name = item.value;
      this.loadGetDemandPageList();
    },
    //分页查询
    loadGetDemandPageList() {
      this.codeText = this.dataInfo.name;
      this.loading = true;
      getDemandPageList(this.page, this.dataInfo).then((res) => {
        this.loading = false;
        this.demandList = res.records;
        this.page.total = res.total;
      });
    },
    loadGetStarList() {
      getStarList().then((res) => {
        this.starList = res;
      });
    },
    loadGetLatestList() {
      getLatestList().then((res) => {
        this.latestList = res;
      });
    },
    //类型列
    loadGetTypeList() {
      getTypeList().then((res) => {
        this.listAll = res;
        res.forEach((item) => {
          this.twoType = [...this.twoType, ...item.children];
        });
        this.tabList = this.twoType;
        this.oneType = [...this.oneType, ...res];
      });
    },
    //一级分类筛选
    oneTypeChange(item) {
      this.page = {
        current: 1,
        size: 10,
      };
      this.oneTypeId = item.value;
      this.twoTypeId = "";
      this.dataInfo.demandTypeIdByServer = item.value;
      this.loadGetDemandPageList();
      let ary = [
        {
          id: "",
          label: "全部",
          value: "",
        },
      ];
      if (item.value) {
        this.twoType = [...ary, ...item.children];
      } else {
        this.twoType = this.tabList;
      }
    },
    //二级分类筛选
    twoTypeChange(item) {
      this.twoTypeId = item.value;
      this.dataInfo.demandTypeIdByServer = item.value;
      this.someOneType(item.value);
      this.page = {
        current: 1,
        size: 10,
      };
      this.loadGetDemandPageList();
    },

    //判断1级
    someOneType(id) {
      for (let res of this.listAll) {
        let has = res.children.some((el) => {
          return el.value === id;
        });
        if (has) {
          this.oneTypeId = res.value;
          return;
        }
      }
    },

    //需求状态
    demandStateChange(item) {
      this.demandStateId = item.id;
      this.dataInfo.demandStatus = item.pid;
      this.page = {
        current: 1,
        size: 10,
      };
      this.loadGetDemandPageList();
    },

    //预算金额
    priceTypeChange(item) {
      this.priceTypeId = item.id;
      switch (item.id) {
        case "price":
          this.dataInfo.minFundAmount = "";
          this.dataInfo.maxFundAmount = "";
          break;
        case "price0":
          this.dataInfo.minFundAmount = "0";
          this.dataInfo.maxFundAmount = 3000;
          break;
        case "price1":
          this.dataInfo.minFundAmount = 3001;
          this.dataInfo.maxFundAmount = 10000;
          break;
        case "price2":
          this.dataInfo.minFundAmount = 10001;
          this.dataInfo.maxFundAmount = 50000;
          break;
        case "price3":
          this.dataInfo.minFundAmount = 50001;
          this.dataInfo.maxFundAmount = "";
          break;
      }
      this.page = {
        current: 1,
        size: 10,
      };
      this.loadGetDemandPageList();
    },
    //发布时间
    dateTypeChange(item) {
      this.dateTypeId = item.id;
      this.dataInfo.createTimeRangeType = item.date;
      this.page = {
        current: 1,
        size: 10,
      };
      this.loadGetDemandPageList();
    },

    sortChange(val) {
      this.dataInfo.sort = val;
      this.loadGetDemandPageList();
    },

    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetDemandPageList();
    },

    toDetails(id) {
      this.$router.push({
        path: "/demand/detail",
        query: { id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.demand {
  width: 100%;
  padding-bottom: 56px;
}
.demand-body {
  width: 1280px;
  margin: 0 auto;
  > .demand-bread {
    margin: 24px 0;
    &::v-deep {
      & > :last-child {
        > a {
          color: #262626;
        }
      }
    }
  }
  .result {
    margin-top: 24px;
  }
}

.demand-content {
  display: flex;
  justify-content: space-between;
  margin-top: 24px;
}

.content-left {
  width: 976px;
}

.service-filter {
  background: #fff;
  padding: 24px 24px 16px 24px;

  > div {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 16px;

    > span {
      width: 70px;
      margin-right: 8px;
      line-height: 24px;
      white-space: nowrap;
    }

    > div {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      /* height: 24px;
      overflow: hidden;*/

      &.active {
        height: auto;
      }

      > span {
        cursor: pointer;
        line-height: 22px;
        margin-bottom: 8px;
        height: 24px;
        border: 1px solid transparent;
        border-radius: 2px;
        padding: 0 16px;
        text-align: center;
        margin-right: 8px;

        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }
      }
    }

    > p {
      width: 80px;
      height: 24px;
      background: #f5f5f5;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 8px;

      > i {
        color: #8c8c8c;
        margin-left: 2px;
        transition: all 0.3s;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
      margin-bottom: 0;
    }
  }
}
.content-right {
  > :first-child {
    width: 280px;
    position: relative;
    background: #ffffff;
    padding-bottom: 20px;
    > :not(:first-child, :last-child) {
      & ::after {
        content: "";
        width: 280px;
        height: 1px;
        background: #e8e8e8;
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
    > div {
      display: flex;
      height: 48px;
      padding: 12px 24px;
      position: relative;
      > :first-child {
        width: 23px;
        height: 23px;
        background: #999999;
        border-radius: 50%;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        line-height: 23px;
        margin-right: 12px;
      }
      > :last-child {
        font-size: 12px;
        color: #262626;
        line-height: 24px;
      }
      .num-1 {
        background: #ea3f40;
      }
      .num-2 {
        background: #ff6a00;
      }
      .num-3 {
        background: #ffbb00;
      }
    }
  }
  > :last-child {
    background: #ffffff;
    margin-top: 24px;
    > :first-child {
      height: 48px;
      font-size: 14px;
      color: #262626;
      line-height: 48px;
      padding-left: 16px;
      box-sizing: border-box;
      border-bottom: 1px solid #e8e8e8;
    }
    > .list-item {
      padding: 12px 16px 0px;

      > div {
        > :first-child {
          width: 248px;
          max-height: 50px;
          background: #f9f9f9;
          padding: 8px;
          > p {
            position: relative;
            font-size: 12px;
            color: #8c8c8c;
            line-height: 17px;
            &::after {
              content: "";
              width: 2px;
              height: 13px;
              background: #d9d9d9;
              position: absolute;
              top: 3px;
              left: -8px;
            }
            > :nth-child(1) {
              color: #595959;
              margin-right: 8px;
            }
            > :nth-child(2) {
              color: #8c8c8c;
              margin-right: 8px;
            }
            > :nth-child(3) {
              color: #ea0b06;
              white-space: nowrap;
            }
          }
        }
        > :nth-child(2) {
          display: flex;
          justify-content: space-between;
          margin-top: 12px;
          > p {
            font-size: 12px;
            line-height: 17px;
          }
          > :first-child {
            width: 180px;
            color: #595959;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            word-break: break-all;
          }
          > :last-child {
            color: #ea0b06;
          }
        }
      }
    }
  }
}
</style>
