import Vue from "vue";
import VueRouter from "vue-router";
import VueMeta from "vue-meta";

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.use(VueRouter);
Vue.use(VueMeta);
const routes = [
  {
    path: "/register",
    name: "register",
    component: () => import("@/page/register"),
  },
  {
    path: "/forgetPassword",
    name: "forgetPassword",
    component: () => import("@/page/forgetPassword"),
  },
  {
    path: "/newPassword",
    name: "newPassword",
    component: () => import("@/page/forgetPassword/newPassword"),
  },
  {
    path: "/emailCode",
    name: "emailCode",
    component: () => import("@/page/forgetPassword/email"),
  },
  {
    path: "/develop",
    name: "develop",
    component: () => import("@/page/develop"),
  },
  {
    path: "/",
    name: "layout",
    component: () => import("@/page/layout"),
    children: [
      /*  {
        path: "/",
        name: "home",
        component: () => import("@/page/home"),
      },*/

      {
        path: "/",
        name: "newHome",
        component: () => import("@/page/homeList"),
      },
      {
        path: "/treasury",
        name: "treasury",
        component: () => import("@/page/treasury"),
      },
      {
        path: "/demand",
        name: "demand",
        component: () => import("@/page/demand"),
      },
      {
        path: "/demand/detail",
        name: "demandDetail",
        component: () => import("@/page/demand/detail"),
      },
      {
        path: "/solution",
        name: "solution",
        component: () => import("@/page/solution"),
      },
      {
        path: "/solution/info",
        name: "solutionInfo",
        component: () => import("@/page/solution/info"),
      },
      {
        path: "/solution/ordinary_program",
        name: "ordinary_program",
        component: () => import("@/page/solution/ordinary_program"),
      },
      {
        path: "/solution/internet_things",
        name: "internet_things",
        component: () => import("@/page/solution/internet_things"),
      },
      {
        path: "/shiXun",
        name: "shiXun",
        component: () => import("@/page/shiXun"),
      },
      {
        path: "/gitIot",
        name: "gitIot",
        component: () => import("@/page/gitIot"),
      },
      {
        path: "/solution/digitizing",
        name: "digitizing",
        component: () => import("@/page/solution/digitizing"),
      },
      {
        path: "/replace",
        name: "replace",
        component: () => import("@/page/replace"),
      },
      {
        path: "/replace/info",
        name: "replaceInfo",
        component: () => import("@/page/replace/info"),
      },
      {
        path: "/replace/productInfo/:type",
        name: "productInfo",
        component: () => import("@/page/replace/productInfo"),
      },
      {
        path: "/result",
        name: "result",
        component: () => import("@/page/result"),
      },
      {
        path: "/result/info",
        name: "resultInfo",
        component: () => import("@/page/result/info"),
      },
      {
        path: "/activity",
        name: "activity",
        component: () => import("@/page/activity"),
      },
      {
        path: "/activity/courseInfo",
        name: "courseInfo",
        component: () => import("@/page/activity/courseInfo"),
      },
      {
        path: "/activity/activityInfo",
        name: "activityInfo",
        component: () => import("@/page/activity/activityInfo"),
      },
      {
        path: "/policy",
        name: "policy",
        component: () => import("@/page/policy"),
      },
      {
        path: "/policy/info",
        name: "policyInfo",
        component: () => import("@/page/policy/info"),
      },
      {
        path: "/searchInfo",
        name: "searchInfo",
        component: () => import("@/page/searchInfo"),
      },
      {
        path: "/questionnaire",
        name: "questionnaire",
        component: () => import("@/page/questionnaire"),
      },
      {
        path: "/protocol",
        name: "protocol",
        component: () => import("@/page/protocol"),
      },
      {
        path: "/protocol/serve",
        name: "protocolServe",
        component: () => import("@/page/protocol/serve"),
      },
      {
        path: "/protocol/tripartite",
        name: "tripartite",
        component: () => import("@/page/protocol/tripartite"),
      },
      {
        path: "/service",
        name: "service",
        component: () => import("@/page/service/index"),
      },
      {
        path: "/treasury/provider",
        name: "provider_details",
        component: () => import("@/page/service/provider_details"),
      },
      {
        path: "/service/details",
        name: "service_details",
        component: () => import("@/page/service/service_details"),
      },
      {
        path: "/announcement/info",
        name: "announcementInfo",
        component: () => import("@/page/announcement/info"),
      },
      {
        path: "/homeScenes",
        name: "homeScenes",
        redirect: "/homeScenes/list",
        component: () => import("@/page/homeScenes"),
        children: [
          {
            path: "/homeScenes/list",
            name: "list",
            component: () => import("@/page/homeScenes/list"),
          },
          {
            path: "/homeScenes/historyList",
            name: "historyList",
            component: () => import("@/page/homeScenes/historyList"),
          },
          {
            path: "/homeScenes/detail",
            name: "homeScenesDetail",
            component: () => import("@/page/homeScenes/detail"),
          },
          {
            path: "/homeScenes/scenesTopic",
            name: "scenesTopic",
            component: () => import("@/page/homeScenes/scenesTopic"),
          },
        ],
      },
    ],
  },
  // {
  //   path: "/userLayout",
  //   component: () => import("@/page/userLayout"),
  //   children: [
  //     {
  //       path: "/userLayout",
  //       name: "userHome",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userHome"),
  //     },
  //     {
  //       path: "/userLayout/userScenes",
  //       name: "userScenes",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userScenes"),
  //     },
  //     {
  //       path: "/userLayout/userScenes/info",
  //       name: "userScenesInfo",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userScenes/info"),
  //     },
  //     {
  //       path: "/userLayout/userScenes/add",
  //       name: "userScenesAdd",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userScenes/addScenes"),
  //     },
  //     {
  //       path: "/userLayout/userInteractive",
  //       name: "userInteractive",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userInteractive"),
  //     },
  //     {
  //       path: "/userLayout/userConsult",
  //       name: "userConsult",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userConsult"),
  //     },
  //     {
  //       path: "/userLayout/userCollect",
  //       name: "userCollect",
  //       meta: {
  //         type: "info",
  //       },
  //       component: () => import("@/page/userCollect"),
  //     },
  //     {
  //       path: "/userLayout/userMessage",
  //       name: "userMessage",
  //       meta: {
  //         type: "settings",
  //       },
  //       component: () => import("@/page/userMessage"),
  //     },
  //     {
  //       path: "/userLayout/userInfo",
  //       name: "userInfo",
  //       component: () => import("@/page/userInfo"),
  //       redirect: "/userLayout/userInfo/info",
  //       children: [
  //         {
  //           path: "/userLayout/userInfo/info",
  //           name: "userInfoDetail",
  //           meta: {
  //             type: "settings",
  //           },
  //           component: () => import("@/page/userInfo/info"),
  //         },
  //         {
  //           path: "/userLayout/userInfo/enterpriseInfo",
  //           name: "userEnterpriseInfo",
  //           meta: {
  //             type: "settings",
  //           },
  //           component: () => import("@/page/userInfo/enterpriseInfo"),
  //         },
  //         {
  //           path: "/userLayout/userInfo/personal",
  //           name: "userInfoPersonal",
  //           meta: {
  //             type: "settings",
  //           },
  //           component: () => import("@/page/userInfo/personal"),
  //         },
  //         {
  //           path: "/userLayout/userInfo/enterprise",
  //           name: "userInfoEnterprise",
  //           meta: {
  //             type: "settings",
  //           },
  //           component: () => import("@/page/userInfo/enterprise"),
  //         },
  //         {
  //           path: "/userLayout/userInfo/associationEnterprise",
  //           name: "associationEnterprise",
  //           meta: {
  //             type: "settings",
  //           },
  //           component: () => import("@/page/userInfo/associationEnterprise"),
  //         },
  //         {
  //           path: "/userLayout/userInfo/member",
  //           name: "userMember",
  //           meta: {
  //             type: "settings",
  //           },
  //           component: () => import("@/page/userInfo/member"),
  //         },
  //       ],
  //     },
  //     {
  //       path: "/userLayout/userSettings",
  //       name: "userSettings",
  //       meta: {
  //         type: "settings",
  //       },
  //       component: () => import("@/page/userSettings"),
  //     },
  //     {
  //       path: "/userLayout/myScenes",
  //       name: "myScenes",
  //       redirect: "/userLayout/myScenes/list",
  //       component: () => import("@/page/myScenes"),
  //       meta: { permission: ["user"], type: "info" },
  //       children: [
  //         {
  //           path: "/userLayout/myScenes/list",
  //           name: "list",
  //           component: () => import("@/page/myScenes/list"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //         {
  //           path: "/userLayout/myScenes/add",
  //           name: "myScenesAdd",
  //           component: () => import("@/page/myScenes/apply"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //         {
  //           path: "/userLayout/myScenes/edit/:id",
  //           name: "myScenesEdit",
  //           component: () => import("@/page/myScenes/apply"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //         {
  //           path: "/userLayout/myScenes/detail/:id",
  //           name: "myScenesDetail",
  //           component: () => import("@/page/myScenes/detail"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //       ],
  //     },
  //     {
  //       path: "/userLayout/myBangDan",
  //       name: "myBangDan",
  //       redirect: "/userLayout/myBangDan/faBang",
  //       component: () => import("@/page/myBangDan"),
  //       meta: { permission: ["user"], type: "info" },
  //       children: [
  //         {
  //           path: "/userLayout/myBangDan/faBang",
  //           name: "faBang",
  //           component: () => import("@/page/myBangDan/faBang"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //         {
  //           path: "/userLayout/myBangDan/jieBang",
  //           name: "jieBang",
  //           component: () => import("@/page/myBangDan/jieBang"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //         {
  //           path: "/userLayout/myBangDan/invite",
  //           name: "invite",
  //           component: () => import("@/page/myBangDan/invite"),
  //           meta: { permission: ["user"], type: "info" },
  //         },
  //       ],
  //     },
  //   ],
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
