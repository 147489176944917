<template>
  <div class="home-policy-info">
    <div class="home-policy-info-head">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item href="">
          <router-link to="/policy">政策资讯</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="home-policy-info-box">
      <div class="home-policy-info-content">
        <div class="home-policy-info-title">
          <p>{{ newInfo.newsName }}</p>
          <div>
            <p>
              <span v-if="newInfo.newsSource"
                >来源：{{ newInfo.newsSource }}</span
              >
              <span>发布单位：{{ newInfo.newsUnit }}</span>
              <span>发布时间：{{ newInfo.newsDate }}</span>
            </p>
            <div>
              分享至：
              <img alt="图片" @click="shareToQQ()" src="@/assets/img/QQ.png" />
              <img
                alt="图片"
                @click="shareToMicroblog()"
                src="@/assets/img/weibo.png"
              />
              <img
                alt="图片"
                @click="shareToKj()"
                src="@/assets/img/kongjian.png"
              />
            </div>
          </div>
        </div>
        <!-- 政策 -> 申报奖励 -->
        <template
          v-if="newInfo.newsType === '1' && newInfo.newsSubtype === '2'"
        >
          <div class="home-policy-info-text">
            <div>
              <span>优惠内容</span>
              <div>{{ newInfo.rewardContent }}</div>
            </div>
            <div>
              <span>最高奖励</span>
              <div>评选最优者最高奖励{{ newInfo.rewardMax }}万元</div>
            </div>
            <div>
              <span>申报条件</span>
              <div>{{ newInfo.applyCondition }}</div>
            </div>
            <div>
              <span>申报材料</span>
              <div v-html="newInfo.applyMaterial"></div>
            </div>
            <div>
              <span>申报流程</span>
              <div>{{ newInfo.applyMethod }}</div>
            </div>
            <div>
              <span>申报链接</span>
              <p>{{ newInfo.applyLink }}</p>
            </div>
            <div>
              <span>政策依据</span>
              <div>{{ newInfo.rewardPolicy }}</div>
            </div>
            <div>
              <span>申报截止时间</span>
              <div>{{ newInfo.applyDueTime }}</div>
            </div>
          </div>
        </template>
        <!-- 政策 -> 一般发文 or 资讯 -->
        <template
          v-if="
            (newInfo.newsType === '1' && newInfo.newsSubtype === '1') ||
            newInfo.newsType === '2'
          "
        >
          <div
            class="home-policy-info-text"
            style="padding: 30px 0"
            v-html="newInfo.content"
          ></div>
        </template>
        <div class="home-result-info-annex" v-if="newInfo.attachments">
          <span>其他说明材料</span>
          <div :key="item" v-for="item in newInfo.attachments">
            <p>
              <img alt="图片" src="@/assets/img/fujian.png" />
              <span>{{ item.fileName }}</span>
            </p>
            <div>
              <span @click="download(item.id)">下载</span>
            </div>
          </div>
        </div>
      </div>
      <div class="home-policy-recommend">
        <p>推荐阅读</p>
        <div>
          <div
            :data-id="item.id"
            :key="item.id"
            @click="$router.push(`/policy/info?id=${item.id}`)"
            v-for="item in policyList"
          >
            <span><img alt="图片" src="@/assets/img/icon1.png" /></span>
            <div>
              <p>{{ item.newsName }}</p>
              <span>{{ item.newsDate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { getNewsInfo, getNewsList } from "@/api/news";

let url = document.location; //获取当前网页地址

export default {
  data() {
    return {
      newInfo: "",
      page: {
        current: 1,
        size: 4,
        total: 0,
      },
      policyList: [],
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadData();
    this.loadGetNewsList();
  },
  methods: {
    loadData() {
      getNewsInfo(this.id).then((res) => {
        this.newInfo = res;
      });
    },
    loadGetNewsList() {
      getNewsList(this.page, {
        newsType: "1",
        orderField: "is_on_top",
      }).then((res) => {
        this.policyList = res.records;
      });
    },
    download(id) {
      if (!this.isLogin) {
        this.$message.warning("您还未登录！");
        return;
      }
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${id}`);
    },
    // 分享
    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.newInfo.newsName);
      window.open(_shareUrl, "_blank");
    },
    shareToMicroblog() {
      var sharesinastring =
        "http://service.weibo.com/share/share.php?title=" +
        encodeURIComponent(`「${this.newInfo.newsName}」 点这里${url}`);
      window.open(sharesinastring, "_blank");
    },
    shareToKj() {
      let { newInfo } = this;
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
          url
        )}&sharesource=qzone&title=${newInfo.newsName}`
      );
    },
  },
  watch: {
    id() {
      this.loadData();
    },
  },
};
</script>

<style lang="scss" scoped>
.home-policy-info {
  width: 1280px;
  margin: 0 auto;
  padding: 24px 0 56px;

  .home-policy-info-head {
    margin-bottom: 24px;
  }

  .home-policy-info-box {
    display: flex;
    align-items: flex-start;

    .home-policy-info-content {
      width: 1000px;
      background: #fff;
      padding: 0 24px;
      min-height: 1089px;

      .home-policy-info-title {
        padding: 24px 0;
        border-bottom: 1px solid #f0f0f0;

        > p {
          font-size: 24px;
          line-height: 32px;
          margin-bottom: 30px;
        }

        > div {
          display: flex;
          align-items: center;
          justify-content: space-between;

          > p {
            font-size: 12px;
            display: flex;
            color: #8c8c8c;

            > span {
              line-height: 16px;
              max-width: 400px;
              margin-right: 30px;
              &:last-child {
                margin-right: 0;
              }
            }
          }

          > div {
            display: flex;
            align-items: center;

            > img {
              margin-left: 16px;
              cursor: pointer;
            }
          }
        }
      }

      .home-policy-info-text {
        > div {
          margin-top: 32px;

          > span {
            display: inline-block;
            height: 32px;
            padding: 0 16px;
            background: #f5f5f5;
            line-height: 32px;
            font-size: 16px;
            font-weight: bold;
            margin-bottom: 20px;
          }

          > div {
            font-size: 14px;
            line-height: 22px;
            text-align: justify;
          }
        }
      }

      .home-policy-info-page {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #f0f0f0;
        height: 78px;
        margin-top: 50px;

        > p {
          line-height: 13px;
          font-size: 14px;
          color: #8c8c8c;
          display: flex;
          align-items: center;

          > span {
            cursor: pointer;
            transition: all 0.3s;
            max-width: 320px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;

            &:hover {
              color: #ea0b06;
            }
          }
        }
      }
    }

    .home-policy-recommend {
      flex: 1;
      margin-left: 24px;
      background: #fff;

      > p {
        height: 56px;
        padding: 0 16px;
        font-size: 16px;
        line-height: 56px;
        border-bottom: 1px solid #f0f0f0;
      }

      > div {
        padding: 0 16px 24px;

        > div {
          display: flex;
          margin-top: 24px;
          cursor: pointer;

          > span {
            margin-right: 20px;
          }

          > div {
            flex: 1;

            > p {
              font-size: 14px;
              line-height: 22px;
              margin-bottom: 14px;
              transition: all 0.3s;
            }

            > span {
              display: block;
              line-height: 9px;
              font-size: 12px;
              color: #8c8c8c;
            }
          }

          &:hover {
            > div {
              > p {
                color: #ea0b06;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.home-policy-info-head .ant-breadcrumb a:hover {
  color: #ea0b06;
}
.home-policy-info-text img {
  max-width: 100%;
}
</style>
