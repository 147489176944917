<template>
  <div class="information-box">
    <div class="box-content">
      <p>最全、最新的平台政策资讯</p>
      <div @click="$router.push('/policy')">了解更多平台资讯 ></div>
      <div class="content-box">
        <div>
          <div class="text">
            <div>精彩看点</div>
            <p v-if="policyOne[0] && policyOne[0].summary">
              {{ policyOne[0].summary }}
            </p>
          </div>
          <a-button
            class="btn-red"
            @click="$router.push(`/policy/info?id=${policyOne[0].id}`)"
            >查看详情</a-button
          >
        </div>
        <div>
          <information-card
            v-for="el in policyList"
            :key="el.id"
            :val="el"
          ></information-card>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import informationCard from "@/page/yunyuanHome/components/informationCard";
import { getNewsList } from "@/api/news";
export default {
  name: "informationBox",
  components: {
    informationCard,
  },
  data() {
    return {
      page: {
        current: 1,
        size: 4,
      },
      info: {},
      policyOne: "",
      policyList: [],
    };
  },

  mounted() {
    this.loadGetNewsList();
  },
  methods: {
    loadGetNewsList() {
      getNewsList(this.page, this.info).then((res) => {
        this.policyList = res.records;
        this.policyOne = this.policyList.splice(0, 1);
      });
    },
  },
};
</script>

<style scoped lang="scss">
.information-box {
  width: 100%;
  height: 854px;
  background: url("../../assets/img/informationBg1.png") no-repeat;
  background-size: cover;
}
.box-content {
  width: 1280px;
  margin: 0 auto;
  padding: 71px 0 48px 0;
  > p {
    font-size: 32px;
    font-weight: 500;
    color: #262626;
    line-height: 45px;
    text-align: center;
    margin-bottom: 24px;
  }
  > :nth-child(2) {
    text-align: center;
    font-size: 18px;
    color: #262626;
    line-height: 25px;
    cursor: pointer;
    margin-bottom: 14px;
    &:hover {
      color: #ea0b06;
    }
  }
}
.content-box {
  display: flex;
  > :first-child {
    width: 364px;
    height: 580px;
    margin-right: 24px;
    background: url("../../assets/img/informationBg2.png") no-repeat;
    background-size: cover;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px 0 0 20px;
    padding: 236px 24px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > .text {
      > :first-child {
        font-size: 28px;
        color: #262626;
        line-height: 40px;
        text-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
        margin-bottom: 24px;
      }
      > p {
        font-size: 14px;
        color: #262626;
        line-height: 24px;
        text-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
      }
    }
    > button {
      width: 104px;
      height: 40px;
      margin-left: 84px;
    }
  }
  > :last-child {
    flex: 1;
  }
}
</style>
