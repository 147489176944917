<template>
  <div
    class="demandCard"
    @click="toProvide(`${val.id}`)"
    @mouseenter="show = true"
    @mouseleave="show = false"
  >
    <div class="card-top">
      <div class="img-box" v-if="val.isvImg">
        <v-img
          :attachment-id="val.isvImg"
          :default-img="require('@/assets/img/kctp.png')"
        />
      </div>
      <div class="logo-text" :class="[`logo-color-${colorNum}`]" v-else>
        {{ val.name.slice(0, 1) }}
      </div>
      <div class="company-name">{{ val.name }}</div>
      <p class="company-text">{{ val.corpComment }}</p>
    </div>
    <div class="card-bottom">
      <div>成立时间：{{ val.establishmentDate }}</div>
      <div>
        企业规模：{{ val.companyScaleType | dict("COMPANY_SCALE_TYPE") }}
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
export default {
  name: "demandCard",
  props: ["val", "colorNum"],
  data() {
    return {
      show: false,
    };
  },
  components: {
    VImg,
  },
  computed: {
    ...mapGetters(["dict"]),
  },
  methods: {
    toProvide(id) {
      this.$router.push({
        path: "/treasury/provider",
        query: { id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.logo-text {
  width: 64px;
  height: 64px;
  text-align: center;
  border-radius: 4px;
  font-size: 38px;
  margin: 0 auto;
}

.logo-color-1 {
  background: #e1f8f4;
  border: 1px solid #37d2b3;
  color: #3dd3b5;
}

.logo-color-2 {
  background: #e5f3fe;
  border: 1px solid #52affc;
  color: #52affc;
}

.logo-color-3 {
  background: #fff5eb;
  border: 1px solid #febe7c;
  color: #febe7c;
}

.logo-color-4 {
  background: #f5f1fa;
  border: 1px solid #bca0db;
  color: #bca0db;
}

.demandCard {
  width: 336px;
  height: 223px;
  background: #ffffff;
  border-radius: 4px;
  padding: 24px 24px 12px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  position: relative;

  .img-box {
    width: 64px;
    height: 64px;
    background: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    > img {
      display: block;
      max-height: 100%;
      max-width: 100%;
    }
  }
  .company-name {
    font-size: 16px;
    font-weight: 500;
    color: #262626;
    line-height: 24px;
    text-align: center;
    margin: 12px 0 8px 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }
  .company-text {
    font-size: 12px;
    color: #595959;
    line-height: 20px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-bottom: 10px;
  }
  .card-top {
    height: 158px;
    box-sizing: border-box;
    border-bottom: 1px solid #e8e8e8;
  }
  .card-bottom {
    width: 288px;
    position: absolute;
    left: 24px;
    bottom: 12px;
    display: flex;
    justify-content: space-between;
    font-size: 12px;
    color: #8c8c8c;
    line-height: 18px;
  }
  > .img-jiao {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 60px;
    height: 60px;
  }
}
</style>
