<template>
  <div class="new-home-banner">
    <!--    <div class="hly-home-banner-list">
        <img alt="图片" src="@/assets/img/banner@2x.jpg" />
    </div>-->
    <a-carousel
      v-if="advertise.length"
      autoplay
      :autoplaySpeed="5000"
      :beforeChange="bannerafterChange"
    >
      <div
        v-for="(el, index) in advertise"
        :key="index"
        class="hly-home-banner-list"
        @click="toadvertise(el.adHref)"
      >
        <v-img :attachment-id="el.adAttmId" :lazy="false"/>
      </div>
    </a-carousel>
    <div
      class="hly-home-banner-content"
      :style="{ 'opacity': bannerInfo ? 1 : 0, 'zIndex': bannerInfo ? '2' : '-2' }"
    >
      <div class="hly-home-banner-webname">
        <img alt="图片" src="@/assets/img/jxw.png" />
        {{ tenant.simpleName }}
      </div>
      <p>致力基于平台打造自主创新AIoT生态提供「端到端」的国产化替代全栈服务</p>
      <a-button class="btn-red" size="large" @click="consultShow = true"
        >立即咨询</a-button
      >
    </div>
    <div class="hly-home-banner-lable">
      <div v-for="el in advertiseList" :key="el.id">
        <div>
          <v-img
            :attachment-id="el.adAttmId"
            :default-img="require('@/assets/img/banner7.jpg')"
          />
          <span>{{ el.title }}</span>
        </div>
        <p>{{ el.description }}</p>
        <span @click="adUrl(el.adHref)">点击查看 <a-icon type="right" /></span>
      </div>
    </div>
    <consult-form :show="consultShow" v-model="consultShow" />
  </div>
</template>

<script>
import { getTenantAds } from "@/api/common";
import { mapGetters } from "vuex";
import VImg from "@/components/VImg";
import consultForm from "@/components/consultForm";
import { getAdvertiseList } from "@/api/advertise";
export default {
  name: "HomeBanner",
  components: { VImg, consultForm },
  data() {
    return {
      adList: [],
      searchName: "",
      bannerInfo: true,
      consultShow: false,
      advertiseList: "",
      advertise: [],
    };
  },
  computed: {
    ...mapGetters(["tenant"]),
    list() {
      const { adList } = this;
      const arr = [...adList];
      for (let i = 0; i < 1 - adList.length; i++) {
        arr.push({});
      }
      return arr;
    },
  },
  methods: {
    loadGetAdvertise() {
      let params = {
        adType: "HOME_BANNER",
        validStatus: "1",
      };

      getAdvertiseList(params).then((res) => {
        let data = res.records;
        data.forEach((item) => {
          if (item.validStatus == 1) {
            this.advertise.push(item);
          }
        });
        if (this.advertise.length == 0) {
          this.advertise = [
            {
              adAttmId: "",
              validStatus: "1",
            },
          ];
        }
      });
    },
    bannerafterChange(from, to) {
      if (to == 0) {
        this.bannerInfo = true;
      } else {
        this.bannerInfo = false;
      }
      console.log(from,'from===')
      console.log(to,'to====')
    },
    toadvertise(url) {
      if (url) {
        window.open(url);
      }
    },
    loadData() {
      const { tenant } = this;
      getTenantAds(tenant.id).then((list) => {
        this.adList = list;
      });
    },
    loadAdData() {
      let data = {
        adType: "HOME_BANNER_RECOMMEND_ICON",
        validStatus: "1",
      };
      getAdvertiseList(data).then((list) => {
        this.advertiseList = list.records;
      });
    },
    adUrl(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    this.loadData();
    this.loadAdData();
    this.loadGetAdvertise();
  },
};
</script>

<style lang="scss" scoped>
.new-home-banner {
  position: relative;
  height: 560px;
  .hly-home-banner-list {
    height: 560px;
    display: block !important;
    position: relative;
    z-index: 1;
    > img {
      position: absolute;
      height: 100%;
      max-width: 100%;
      display: block;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
    }
  }
  .hly-home-banner-content {
    position: absolute;
    width: 1280px;
    top: 0;
    left: 50%;
    bottom: 0;
    z-index: 2;
    margin-left: -640px;
    padding-top: 139px;
    transition: all 0.3s;
    > p {
      font-size: 18px;
      line-height: 23px;
      color: #262626;
      margin-bottom: 56px;
    }
    > button {
      width: 136px;
    }
    .hly-home-banner-webname {
      display: flex;
      margin-bottom: 24px;
      align-items: center;
      color: #262626;
      font-size: 38px;
      font-weight: bold;
      line-height: 53px;
      color: #262626;
      > img {
        margin-right: 10px;
        width: 52px;
      }
    }
  }
  .hly-home-banner-lable {
    position: absolute;
    width: 1280px;
    left: 50%;
    bottom: -96px;
    z-index: 2;
    margin-left: -640px;
    background: #ffffff;
    box-shadow: 0px 3px 14px rgba(0, 0, 0, 0.06);
    display: flex;
    > div {
      flex: 1;
      padding: 20px 24px;
      cursor: pointer;
      position: relative;
      transition: all 0.5s;
      &::after {
        content: "";
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 20px;
        width: 1px;
        background: #e6e6e6;
      }
      &:last-child {
        border: 0;
        &::after {
          display: none;
        }
      }
      > div {
        display: flex;
        align-items: center;
        margin-bottom: 14px;
        > img {
          width: 32px;
          margin-right: 8px;
        }
        > span {
          max-width: 230px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 18px;
          line-height: 25px;
          color: #262626;
        }
      }
      > p {
        max-width: 270px;
        font-size: 16px;
        line-height: 22px;
        color: #666666;
        margin-bottom: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      > span {
        display: flex;
        width: 120px;
        height: 31px;
        background: #f8f8fa;
        border-radius: 4px;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        color: #ea0b06;
        transition: all 0.3s;
        padding-left: 24px;
        > i {
          transition: all 0.3s;
        }
      }
      &:hover {
        background: url(../../assets/img/xz@2x.png) no-repeat;
        background-size: 100% 100%;
        > span {
          color: #fff;
          background: #ea0b06;
          > i {
            margin-left: 4px;
          }
        }
      }
    }
  }
}
</style>
