<template>
  <div class="home-scenes-body" style="margin: 0">

    <banner-swiper
        :banner="advertise.banner"
        :textinfo="textinfo"
        class="listBanner"
    >
    </banner-swiper>


    <div class="home-scenes-content">
      <div class="home-scenes-list-content-body">
        <!-- <p>场景需求清单</p> -->
        <!-- <span @click="historyList">往期场景 >></span> -->
        <div class="home-scenes-filter-body">
          <div>
            <span>状态：</span>
            <div>
              <span
                :class="{ active: !condition.sceneStatus }"
                @click="condition.sceneStatus = ''"
                >全部</span
              >
              <span
                v-for="item in ['6', '7', '8']"
                :key="item"
                :class="{ active: condition.sceneStatus === item }"
                @click="condition.sceneStatus = item"
                >{{ item | dict("SCENE_STATUS") }}</span
              >
            </div>
          </div>
          <div>
            <span>场景领域：</span>
            <div :style="{ height: fieldShow ? 'auto' : '24px' }">
              <span
                :class="{ active: !condition.field }"
                @click="condition.field = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in dict['SCENE_FIELD']"
                :key="index"
                :class="{ active: condition.field === item.itemValue }"
                @click="condition.field = item.itemValue"
                >{{ item.itemText }}</span
              >
            </div>
            <p @click="fieldShow = !fieldShow">
              {{ fieldShow ? "收起" : "展开" }}
              <a-icon :type="fieldShow ? 'up' : 'down'" />
            </p>
          </div>
          <div>
            <span>应用行业：</span>
            <div
              class="list-show"
              :style="{ height: industryShow ? 'auto' : '24px' }"
            >
              <span
                :class="{ active: !condition.industry }"
                @click="condition.industry = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in dict['SCENE_INDUSTRY']"
                :key="index"
                :class="{ active: condition.industry === item.itemValue }"
                @click="condition.industry = item.itemValue"
                >{{ item.itemText }}</span
              >
            </div>
<!--            <p @click="industryShow = !industryShow">
              {{ industryShow ? "收起" : "展开" }}
              <a-icon :type="industryShow ? 'up' : 'down'" />
            </p>-->
          </div>
          <div>
            <span>技术类别：</span>
            <div class="list-show" :style="{ height: skillShow ? 'auto' : '24px' }">
              <span
                :class="{ active: !condition.skill }"
                @click="condition.skill = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in dict['SCENE_SKILL']"
                :key="index"
                :class="{ active: condition.skill === item.itemValue }"
                @click="condition.skill = item.itemValue"
                >{{ item.itemText }}</span
              >
            </div>
            <p @click="skillShow = !skillShow">
              {{ skillShow ? "收起" : "展开" }}
              <a-icon :type="skillShow ? 'up' : 'down'" />
            </p>
          </div>
        </div>

        <!--    广告-->
        <div class="notif_img" v-if="advertise.notif" @click="toadvertise(advertise.notif.adHref)">
          <v-img
              class="img"
              v-if="advertise.notif"
              :attachment-id="advertise.notif.adAttmId"
              :default-img="require('@/assets/img/photo.png')"
          />
        </div>

        <div class="home-scenes-list-body">
          <div class="home-scenes-list-search">
            <div>
              <span>共 {{ page.total }} 项需求</span>
              <p
                :class="{ active: sortKey === 'Default' }"
                @click="sortTab('Default')"
              >
                综合排序
              </p>
              <p
                :class="{ active: sortKey === 'PublishTime' }"
                @click="sortTab('PublishTime')"
              >
                发布时间
                <span>
                  <a-icon
                    :class="{
                      active: sortKey === 'PublishTime' && sortType === 'Asc',
                    }"
                    type="caret-up"
                  />
                  <a-icon
                    :class="{
                      active: sortKey === 'PublishTime' && sortType === 'Desc',
                    }"
                    type="caret-down"
                  />
                </span>
              </p>
              <p
                :class="{ active: sortKey === 'Price' }"
                @click="sortTab('Price')"
              >
                金额
                <span>
                  <a-icon
                    :class="{
                      active: sortKey === 'Price' && sortType === 'Asc',
                    }"
                    type="caret-up"
                  />
                  <a-icon
                    :class="{
                      active: sortKey === 'Price' && sortType === 'Desc',
                    }"
                    type="caret-down"
                  />
                </span>
              </p>
            </div>
            <a-input-search
              style="width: 360px"
              placeholder="请输入关键词搜索"
              enter-button
              v-model="condition.title"
              @search="searchScenes"
            />
          </div>
          <div class="home-scenes-list-body">
            <template v-if="sceneListData && sceneListData.length">
              <div
                class="home-scenes-list"
                v-for="item in sceneListData"
                :key="item.id"
                @click="scenesDetail(item.id)"
              >
                <div class="home-scenes-list-info">
                  <span>
                    <v-img :attachment-id="item.coverAttmId" />
                  </span>
                  <div>
                    <strong
                      v-if="
                        condition.title &&
                        item.title.indexOf(condition.title) !== -1
                      "
                    >
                      <span>{{
                        item.title.substring(
                          0,
                          item.title.indexOf(condition.title)
                        )
                      }}</span>
                      <span style="color: red">{{ condition.title }}</span>
                      <span>{{
                        item.title.substring(
                          item.title.indexOf(condition.title) +
                            condition.title.length,
                          item.title.length
                        )
                      }}</span>
                    </strong>
                    <strong v-else>{{ item.title }}</strong>
                    <div>
                      <p>需求单位：{{ item.companyName }}</p>
                      <p>发布时间：{{ item.previewedTime | moment('YYYY-MM-DD')}}</p>
                      <p>截止日期：{{ item.applyDueDate }}</p>
                    </div>
                    <p v-html="item.description"></p>
                  </div>
                </div>
                <div class="home-scenes-list-price">
                  <p><span>￥</span>{{ item.fundAmount }}<span>万</span></p>
                  <span>意向金额</span>
                </div>
              </div>
              <div class="home-dynamic-page">
                <a-pagination
                  @change="scenePage"
                  show-quick-jumper
                  :default-page-size="5"
                  :default-current="page.current"
                  :total="page.total"
                  :show-total="(total) => `共 ${total} 项`"
                />
              </div>
            </template>
            <div v-else style="padding: 50px 0">
              <a-empty>
                <img slot="image" src="@/assets/img/cjsh.png" />
              </a-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { sceneList } from "@/api/cjkf/scn-scene";
import VImg from "@/components/VImg";
import { mapActions, mapGetters } from "vuex";
import {getAdvertiseList} from "@/api/advertise";
import BannerSwiper from "@/components/BannerSwiper";

export default {
  components: {
    VImg,
    'banner-swiper':BannerSwiper
  },
  data() {
    return {
      condition: {
        title: "",
        sceneStatus: "",
        field: "",
        industry: "",
        skill: "",
        sort: "",
      },
      fieldShow: false,
      industryShow: false,
      skillShow: false,
      page: {
        size: 5,
        current: 1,
        total: 0,
      },
      sceneListData: [],
      sortKey: "Default",
      sortType: "",
      advertise:{
        banner:[],
        notif:'',
      },
      textinfo:{
        title:'场景清单',
        text:'助力政企需求释放、场景建设闭环、数字经济发展的综合运营服务',
        type:'list',
        height:320,
      }
      // backgroundImg: "",
      // backgroundImgUrl: "",
      // scenePublicity: [],
    };
  },
  computed: {
    ...mapGetters(["dict"]),
    // scenePublicityImgs() {
    //   const { scenePublicity } = this;
    //   return scenePublicity.filter(
    //     (e) => e.attachment && e.attachment.fileType !== "mp4"
    //   );
    // },
    // scenePublicityVideo() {
    //   const { scenePublicity } = this;
    //   return scenePublicity.filter(
    //     (e) => e.attachment && e.attachment.fileType === "mp4"
    //   )[0];
    // },
    ...mapGetters(["settings"]),
  },
  created() {
    this.loadGetAdvertise();
  },
  mounted() {
    if (this.$route.query.code) {
      this.condition.skill = this.$route.query.code;
    }
    this.LoadDictData(["SCENE_SKILL", "SCENE_INDUSTRY", "SCENE_FIELD"]);
    this.loadSceneList();
   // this.loadGetAdvertise();
  },
  watch: {
    condition: {
      handler() {
        this.page.current = 1;
        this.loadSceneList();
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadGetAdvertise(){
      let params={
        adType:'SCENE_BANNER',
        validStatus:'1',
      }

      getAdvertiseList(params).then((res)=>{
        console.log(res,'====广告====')
        let data=res.records
        data.forEach(item=>{
          if(item.adType==='SCENE_BANNER' && item.validStatus==1){
            this.advertise.banner.push(item)
          }else if(item.adType==='SCENE_NOTIF_BANNER' && item.validStatus==1){
            this.advertise.notif=item
          }
        })




      })
    },



    toadvertise(url){
      if(url){
        window.open(url)
      }
    },



    // 列表
    loadSceneList() {
      sceneList(this.page, this.condition).then((res) => {
        this.page.total = res.total;
        this.sceneListData = res.records;
      });
    },
    scenePage(e) {
      this.page.current = e;
      this.loadSceneList();
    },
    searchScenes() {
      this.page.current = 1;
      this.loadSceneList();
    },
    sortTab(sortKey) {
      if (sortKey === "Default") {
        this.sortKey = "Default";
        this.sortType = "";
      } else if (this.sortKey === sortKey && this.sortType === "Desc") {
        this.sortType = "Asc";
      } else if (this.sortKey === sortKey && this.sortType === "Asc") {
        this.sortType = "Desc";
      } else {
        this.sortKey = sortKey;
        this.sortType = "Asc";
      }
      this.condition.sort = `${this.sortKey}${this.sortType}`;
    },
    // historyList() {
    //   this.$router.push("/homeScenes/historyList");
    // },
    scenesDetail(id) {
      this.$router.push({
        path: "/homeScenes/detail",
        query: { id: id },
      });
    },
    handleBannerClick(el) {
      if (el.link) {
        window.open(`${el.link}`);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.home-scenes-body {
  .listBanner{
    margin-bottom: 24px;
  }


  .notif_img{
    display: flex;
    justify-content: center;
   /* margin-top: 24px;
    margin-bottom: 24px;*/
    padding: 24px 0;
    >.img{
      width: 1200px;
      height: 100px;
    }
  }


  .home-scenes-content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 80px;
    .home-scenes-head-body {
      display: flex;
      justify-content: space-between;
      padding-top: 64px;
      margin-bottom: 50px;

      .home-scenes-head-info {
        flex: 1;
        margin-right: 80px;

        > p {
          font-size: 34px;
          line-height: 34px;
          font-weight: bold;
          color: #ffffff;
          line-height: 30px;
          text-shadow: 0px 5px 0px #0009bb;
          margin-bottom: 32px;
        }

        > strong {
          display: block;
          font-size: 64px;
          color: #ffffff;
          line-height: 64px;
          text-shadow: -3px 1px 0px #1ed2ff, 7px 0px 0px #0009bb;
          margin-bottom: 40px;
        }

        > div {
          line-height: 22px;
          color: #fff;
          margin-bottom: 30px;
        }

        > span {
          width: 120px;
          height: 32px;
          border: 1px solid rgba(255, 255, 255, 0.4);
          line-height: 30px;
          text-align: center;
          color: #fff;
          display: block;
          cursor: pointer;
        }
      }
    }

    .home-scenes-list-content-body {
      width: 100%;
      background: #fff;
      position: relative;

      > span {
        position: absolute;
        width: 120px;
        height: 32px;
        border: 1px solid #8c8c8c;
        line-height: 30px;
        text-align: center;
        color: #262626;
        display: block;
        cursor: pointer;
        top: 20px;
        right: 24px;
      }

      > p {
        width: 246px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        background: url(../../assets/img/scenestitlebg.png);
        color: #fff;
        font-size: 24px;
        margin: 0 auto;
        transform: translateY(-28px);
      }

      .home-scenes-filter-body {
        padding: 24px;
        border-bottom: 1px solid #f0f0f0;

        > div {
          display: flex;
          padding-bottom: 8px;
          border-bottom: 1px solid #f0f0f0;
          margin-bottom: 16px;
          &:last-child{
            margin-bottom: 0;
            border-bottom: 0;
            padding-bottom: 0;
          }
          > span {
            width: 64px;
            height: 32px;
            line-height: 32px;
            white-space: nowrap;
            text-align: right;
            margin-right: 10px;
          }

          > p {
            width: 80px;
            height: 24px;
            line-height: 24px;
            text-align: center;
            margin-left: 16px;
            cursor: pointer;
            background: #f5f5f5;
          }

          > div {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;

            > span {
              line-height: 22px;
              margin-bottom: 8px;
              height: 24px;
              padding: 0 16px;
              cursor: pointer;
              border: 1px solid transparent;
              border-radius: 2px;
              &.active {
                border-color: #EA0B06;
                color: #EA0B06;
              }
            }
          }
        }
      }

      .home-scenes-list-body {
        padding: 16px 24px;

        .home-scenes-list-search {
          display: flex;
          justify-content: space-between;
          align-items: center;

          > div {
            display: flex;
            align-items: center;

            > span {
              color: #8c8c8c;
              line-height: 14px;
              margin-right: 16px;
              padding-right: 16px;
              border-right: 1px solid #f0f0f0;
            }

            > p {
              color: #595959;
              line-height: 14px;
              display: flex;
              align-items: center;
              margin-right: 24px;
              cursor: pointer;

              &.active {
                color: #EA0B06;
              }

              > span {
                margin-left: 6px;

                > i {
                  display: block;
                  font-size: 8px;
                  color: #d9d9d9;

                  &.active {
                    color: #EA0B06;
                  }
                }
              }
            }
          }
        }

        .home-scenes-list-body {
          .home-scenes-list {
            border-bottom: 1px solid #f0f0f0;
            padding: 32px 0;
            display: flex;
            justify-content: space-between;
            cursor: pointer;

            .home-scenes-list-info {
              width: 100%;
              display: flex;

              > span {
                width: 210px;
                height: 120px;
                margin-right: 24px;

                > img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }

              > div {
                flex: 1;

                > strong {
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: bold;
                  display: block;
                  margin-bottom: 16px;
                }

                > div {
                  display: flex;
                  margin-bottom: 20px;

                  > p {
                    color: #595959;
                    margin-right: 24px;
                    line-height: 14px;

                    &:last-child {
                      margin-right: 0;
                    }

                    > span {
                      color: #EA0B06;
                    }
                  }
                }

                > p {
                  color: #8c8c8c;
                  line-height: 20px;
                  max-height: 60px;
                  overflow: hidden;
                  text-align: justify;
                }
              }
            }

            .home-scenes-list-price {
              display: none;

              > p {
                display: flex;
                align-items: flex-end;
                color: #EA0B06;
                font-size: 24px;
                font-weight: bold;
                line-height: 24px;
                margin-bottom: 12px;

                > span {
                  font-size: 14px;
                  line-height: 14px;
                }
              }

              > span {
                font-size: 12px;
                line-height: 12px;
                color: #8c8c8c;
                text-align: right;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.home-dynamic-page {
  text-align: right;
  margin-top: 24px;
}

.home-scenes-list-search .ant-btn {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: #8c8c8c !important;
}

.home-scenes-carousel-body {
  width: 560px;
  height: 320px;
}

.home-news-carousel-list {
  width: 560px;
  height: 320px;
}

.home-news-carousel-list > img {
  max-height: 320px;
  max-width: 100%;
}
</style>
