<template>
  <div class="yy-menu">
    <div style="width: 100%; background: #ffffff">
      <div class="menu-title">
        <div>服务市场分类</div>
        <div>
          <span @click="goPath('/service')">服务市场</span>
          <span @click="goPath('/treasury')">服务商库</span>
          <span @click="goPath('/demand')">需求大厅</span>
          <span @click="goPath('/policy')">政策资讯</span>
        </div>
      </div>
    </div>
    <div class="menu-body">
      <div class="menu-content">
        <div class="menu-left">
          <div
            v-for="el in serviceType"
            :key="el.id"
            @mouseenter="centerShowShowBtn(el, true)"
            @mouseleave="centerShowShowBtn(el, false)"
          >
            <menu-card :val="el"></menu-card>
          </div>
          <div class="text">
            没有找到需要的产品或服务？
            <span @click="consultShow = true">定制需求</span>
          </div>
        </div>
        <div class="menu-right">
          <menu-info></menu-info>
        </div>
        <div
          class="item-card"
          v-show="centerShow"
          @mouseenter="showCard(true)"
          @mouseleave="showCard(false)"
        >
          <div class="title">{{ listData.name }}</div>
          <div class="list">
            <div
              v-for="el in listData.list"
              :key="el.id"
              @click="toDetail(listData.id, el.id)"
            >
              {{ el.name }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <consult-form :show="consultShow" v-model="consultShow" />
  </div>
</template>

<script>
import consultForm from "@/components/consultForm";
let isShow = false;
import menuCard from "@/page/yunyuanHome/components/menu-card";
import menuInfo from "@/page/yunyuanHome/components/menuInfo";
import { getServiceLv1List, getServiceLv2List } from "@/api/service";
export default {
  name: "menu",
  components: {
    menuCard,
    menuInfo,
    consultForm,
  },
  data() {
    return {
      serviceType: [],
      centerShow: false,
      listData: [],
      consultShow: false,
    };
  },
  created() {
    this.loadGetServiceLv1List();
  },
  methods: {
    goPath(path) {
      this.$router.push(path);
    },
    userCenter() {
      window.open(`${process.env.VUE_APP_ACCOUNT_PREFIX}`, "_blank");
    },
    loadGetServiceLv1List() {
      getServiceLv1List().then((res) => {
        let data = res.slice(0, 4);
        for (let i in data) {
          data[i].list = [];
        }
        this.serviceType = data;
        this.loadGetServiceLv2List();
      });
    },
    async loadGetServiceLv2List() {
      for (let i = 0; i < this.serviceType.length; i++) {
        await getServiceLv2List(this.serviceType[i].id).then((res) => {
          this.serviceType[i].list = res;
        });
      }
    },

    centerShowShowBtn(el, is) {
      isShow = is;
      if (!is) {
        setTimeout(() => {
          this.centerShow = isShow;
        }, 100);
      } else {
        this.listData = el;
        this.centerShow = is;
      }
    },

    showCard(is) {
      isShow = is;
      if (!is) {
        setTimeout(() => {
          this.centerShow = isShow;
        }, 100);
      } else {
        this.centerShow = is;
      }
    },
    toDetail(l1Id, id) {
      this.$router.push({
        name: "service",
        params: { l1Id: l1Id, l2Id: id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.yy-menu {
  width: 100%;
  .menu-title {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    background: #ffffff;
    > :first-child {
      width: 240px;
      height: 40px;
      background: #ea0b06;
      font-size: 14px;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
      margin-right: 24px;
    }
    > :last-child {
      > span {
        display: inline-block;
        width: 104px;
        height: 40px;
        text-align: center;
        font-size: 14px;
        color: #595959;
        line-height: 40px;
        cursor: pointer;
        box-sizing: border-box;
        &:hover {
          color: #ea0b06;
          border-bottom: 1px solid #ea0b06;
        }
      }
    }
  }
  > .menu-body {
    width: 100%;
    height: 456px;
    background: #f7f8fa;
    > .menu-content {
      width: 1280px;
      margin: 0 auto;
      display: flex;
      position: relative;
    }
  }
}

.menu-left {
  width: 240px;
  height: 456px;
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
}

.text {
  width: 240px;
  height: 48px;
  background: #ffffff;
  font-size: 12px;
  color: #8c8c8c;
  line-height: 48px;
  text-align: center;
  box-sizing: border-box;
  border-top: 1px solid #e8e8e8;
  > span {
    cursor: pointer;
    color: #ea0b06;
  }
}

.menu-right {
  padding: 12px 0 16px 24px;
}

.item-card {
  width: 640px;
  height: 456px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 2px;
  position: absolute;
  top: 0;
  left: 240px;
  padding: 32px 24px;
  > .title {
    font-size: 14px;
    font-weight: 500;
    color: #262626;
    line-height: 20px;
    margin-bottom: 16px;
  }
  > .list {
    display: flex;
    flex-wrap: wrap;
    > div {
      cursor: pointer;
      font-size: 12px;
      color: #8c8c8c;
      line-height: 17px;
      margin-right: 40px;
      margin-bottom: 12px;
      &:hover {
        color: #ea0b06;
      }
    }
  }
}
</style>
