import { PortalRequest as request } from "@/utils/request";

// 分页查看成果
export const getAchievementList = (params, data) => {
    return request({
        url: "/achievement/page-list",
        method: "post",
        params: params,
        data,
    });
};

// 获取成果信息
export const getAchievementInfo = (id) => {
    return request({
        url: `/achievement/${id}`,
        method: "get",
    });
};

// 分页获取场景列表
export const getSceneList = (params) => {
    return request({
        url: `/category/page-list`,
        method: "post",
        params,
    });
};

// 根据场景id获取场景详情
export const getSceneInfo = (id) => {
    return request({
        url: `/category/${id}/detail`,
        method: "get",
    });
};