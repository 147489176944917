import { PortalRequest as request } from "@/utils/request";

//服务商库分页查询
export const getStorePageList = (params, data) => {
  return request({
    url: `/srt-service-store/page-list`,
    method: "post",
    params: params,
    data,
  });
};

/**
 * 详情页热门商库
 * @param data
 * @returns {*}
 */
export const getStoreHot = () => {
  return request({
    url: `/srt-service-store/page-list-top`,
    method: "post",
  });
};

/**
 * 首页热门商库
 * @param data
 * @returns {*}
 */
export const getStoreHotHome = (pageSize) => {
  return request({
    url: `/srt-service-store/${pageSize}/list-industry-type-dict`,
    method: "get",
  });
};

/**
 * 获取已上架服务一级分类
 * @param data
 * @returns {*}
 */
export const getServiceTypeList = () => {
  return request({
    url: `/srt-service-store/service-type-list`,
    method: "get",
  });
};
