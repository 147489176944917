<template>
  <div class="news-home-footer" v-if="!isLogin">
    <p>立即注册，即刻开启您的<span>鸿联云</span>之旅</p>
    <a-button class="btn-red" size="large" @click="$router.push('/register')">立即体验<a-icon type="right" /></a-button>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: mapGetters(["isLogin"]),
}
</script>

<style lang="scss" scoped>
.news-home-footer{
    padding: 60px;
    background: url(../../assets/img/ads-bg@2x.png) no-repeat;
    background-size: 100% 100%;
    >p{
        font-size: 32px;
        line-height: 45px;
        color: #262626;
        text-align: center;
        margin-bottom: 32px;
        >span{
            color: #EA0B18;
        }
    }
    >button{
        display: block;
        margin: 0 auto;
        width: 156px;
        height: 44px;
    }
}
</style>