<template>
  <div class="home-develop">
    <div class="home-develop-bg">
      <dev-head />
      <div class="home-develop-box">
        <div class="home-develop-title">
          <p>OpenHarmony开发板</p>
          <span
            >OpenHarmony是由开放原子开源基金会（OpenAtom
            Foundation）孵化及运营的开源项目，目标是面向全场景、全连接、全智能时代，基于开源的方式，搭建一个智能终端设备操作系统的框架和平台，促进万物互联产业的繁荣发展。OpenHarmony
            Demoboard（开发板）是为嵌入式开发者或学习者学习和开发OpenHarmony设计和定制的开发电路板，包括包括中央处理器、存储器、输入设备、输出设备、数据通路/总线和外部资源接口等一系列硬件组件。</span
          >
          <a-button @click="consult">立即咨询</a-button>
        </div>
        <div class="home-develop-system">
          <carousel
            title="轻量系统"
            grade="01"
            desc="面向MCU类处理器例如Arm Cortex-M、RISC-V 32位的设备，硬件资源极其有限，支持的设备最小内存为128KiB，可以提供多种轻量级网络协议，轻量级的图形框架，以及丰富的IOT总线读写部件等。可支撑的产品如智能家居领域的连接类模组、传感器设备、穿戴类设备等。"
            :bg="require('@/assets/img/devtype1.jpg')"
          />
        </div>
        <div class="home-develop-system">
          <carousel
            title="小型系统"
            grade="02"
            desc="面向应用处理器例如Arm Cortex-A的设备，支持的设备最小内存为1MiB，可以提供更高的安全能力、标准的图形框架、视频编解码的多媒体能力。可支撑的产品如智能家居领域的IP Camera、电子猫眼、路由器以及智慧出行域的行车记录仪等。"
            :bg="require('@/assets/img/devtype2.jpg')"
          />
        </div>
        <div class="home-develop-system">
          <carousel
            title="标准系统"
            grade="03"
            desc="面向应用处理器例如Arm Cortex-A的设备，支持的设备最小内存为128MiB，可以提供增强的交互能力、3D GPU以及硬件合成能力、更多控件以及动效更丰富的图形能力、完整的应用框架。可支撑的产品如高端的冰箱显示屏。"
            :bg="require('@/assets/img/devtype3.jpg')"
          />
        </div>
        <div class="home-develop-skill">
          <div class="home-develop-skill-title">
            <p>核心技术优势</p>
            <span>OpenHarmony：面向未来的全场景物联网智能终端操作系统</span>
          </div>
          <div class="home-develop-skill-info">
            <div>
              <img alt="图片" src="@/assets/img/devjg.png" />
              <p>分布式架构</p>
              <span
                >软总线技术<br />WiFi/蓝牙/5G等连接<br />实现跨终端无缝协同</span
              >
            </div>
            <div>
              <img alt="图片" src="@/assets/img/devnh.png" />
              <p>内核级安全</p>
              <span>微内核架构<br />微内核可信执行环境<br />5+层级安全</span>
            </div>
            <div>
              <img alt="图片" src="@/assets/img/devipc.png" />
              <p>高性能IPC技术</p>
              <span
                >微内核进程快速响应<br />运行效率大幅提升<br />实现系统天生流畅</span
              >
            </div>
            <div>
              <img alt="图片" src="@/assets/img/devxs.png" />
              <p>确定延时</p>
              <span>高效负载资源分配<br />分类+分级保障策略<br />稳定快速</span>
            </div>
          </div>
          <p class="home-develop-skill-desc">
            智能互联、统一设备语言，打造<span>简洁、流畅、连续、安全可靠</span>的全场景交互体验
          </p>
        </div>
      </div>
    </div>
    <dev-footer />
  </div>
</template>

<script>
import devHead from "./components/head.vue";
import carousel from "./components/carousel.vue";
import devFooter from "../layout/homeFooter.vue";
import { mapGetters } from "vuex";
export default {
  components: {
    devHead,
    carousel,
    devFooter,
  },
  data() {
    return {};
  },
  computed: mapGetters(["isLogin"]),
  methods: {
    consult() {
      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }
      this.$router.push({
        name: "userScenesAdd",
        params: {
          type: "add",
          info: "",
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-develop {
  min-height: 100%;
  .home-develop-bg {
    background: url(../../assets/img/devbg.jpg) no-repeat;
    background-size: 100%;
  }
  .home-develop-box {
    width: 1280px;
    margin: 0 auto;
    .home-develop-title {
      margin-top: 130px;
      width: 640px;
      color: #fff;
      margin-bottom: 100px;
      > p {
        font-size: 48px;
        line-height: 66px;
        margin-bottom: 16px;
      }
      > span {
        display: block;
        font-size: 14px;
        font-weight: 300;
        line-height: 20px;
        height: 144px;
        overflow: hidden;
        text-align: justify;
      }
      > button {
        margin-top: 36px;
        width: 140px;
        height: 44px;
        background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
        color: #fff !important;
        font-size: 18px;
        border: none;
        border-radius: 0 !important;
      }
    }
    .home-develop-skill {
      margin-top: 12px;
      .home-develop-skill-title {
        text-align: center;
        color: #fff;
        background: url(../../assets/img/devx.png) center 20px no-repeat;
        margin-bottom: 102px;
        > p {
          font-size: 30px;
          line-height: 42px;
          margin-bottom: 30px;
        }
        > span {
          font-size: 18px;
          line-height: 24px;
          font-weight: 300;
          display: block;
        }
      }
      .home-develop-skill-info {
        display: flex;
        justify-content: space-between;
        > div {
          width: 302px;
          height: 290px;
          background: #f7f8ff;
          border-radius: 8px;
          padding: 94px 56px 0;
          position: relative;
          text-align: center;
          > img {
            position: absolute;
            left: 50%;
            top: -65px;
            margin-left: -75px;
          }
          > p {
            font-size: 26px;
            line-height: 36px;
            margin-bottom: 24px;
            color: #333;
          }
          > span {
            display: block;
            font-size: 20px;
            color: #666;
            line-height: 28px;
            font-weight: 300;
          }
        }
      }
      .home-develop-skill-desc {
        padding: 40px 0;
        display: flex;
        align-items: flex-end;
        justify-content: center;
        font-size: 24px;
        color: #fff;
        line-height: 36px;
        > span {
          color: #f13a2f;
          font-size: 28px;
        }
      }
    }
  }
}
</style>
