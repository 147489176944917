<template>
  <div class="programme-card">
    <div>
      <ul>
        <li>
          供应商：<span>{{ val.bidCompanyName }}</span>
        </li>
        <li>
          需求单位：<span>{{ companyName }}</span>
        </li>
      </ul>
      <ul>
        <li>
          方案报价：<span class="price">￥{{ val.bidPrice }}</span>
        </li>
      </ul>
    </div>
    <a-divider />
    <p class="title">交付阶段</p>
    <a-table
      :columns="columns"
      :data-source="val.dmdDemandBidSteps"
      :pagination="false"
    >
    </a-table>

    <p class="title" style="margin-top: 32px">应标附件</p>

    <service-attachment
      v-for="(el, index) in val.bidAttmIds"
      :key="index"
      :attachment-id="el"
    />
  </div>
</template>

<script>
const columns = [
  {
    title: "交付阶段",
    dataIndex: "stepName",
    key: "stepName",
  },
  {
    title: "交付时间",
    dataIndex: "stepTime",
    key: "stepTime",
  },
  {
    title: "交付内容",
    dataIndex: "stepContent",
    key: "stepContent",
  },
];

import serviceAttachment from "@/page/service/components/serviceAttachment";
export default {
  name: "programmeCard",
  props: ["val", "companyName"],
  components: {
    serviceAttachment,
  },
  data() {
    return {
      columns,
    };
  },
};
</script>

<style scoped lang="scss">
.programme-card {
  padding: 32px 24px 24px 24px;
  > :first-child {
    display: flex;
    > :first-child {
      margin-right: 100px;
    }
    li {
      font-size: 14px;
      color: #262626;
      line-height: 22px;
      margin-bottom: 16px;
      > span {
        color: #595959;
      }
      > .price {
        font-size: 14px;
        color: #ea0b06;
        line-height: 22px;
      }
    }
  }
  &::v-deep {
    .ant-divider-horizontal {
      margin: 16px 0 32px 0;
    }
  }

  .title {
    font-size: 14px;
    font-weight: bold;
    color: #262626;
    line-height: 22px;
    margin-bottom: 16px;
  }
}
</style>
