import { PortalRequest as request } from "@/utils/request";

// 分页查看资讯
export const getNewsList = (params, data) => {
  return request({
    url: "/news/page-list",
    method: "post",
    params: params,
    data,
  });
};

// 获取资讯信息
export const getNewsInfo = (id) => {
  return request({
    url: `/news/${id}`,
    method: "get",
  });
};

// 分页查看我的咨询
export const getConsultList = (params, data) => {
  return request({
    url: "/consult/page-list",
    method: "post",
    params: params,
    data,
  });
};

// 获取用户咨询信息
export const getConsultInfo = (id) => {
  return request({
    url: `/consult/${id}`,
    method: "get",
  });
};

// 新增用户咨询
export const getConsultCreateService = (data) => {
  return request({
    url: "/consult/create-service",
    method: "post",
    data,
  });
};

// 新增用户咨询
export const getConsultCreate = (data) => {
  return request({
    url: "/consult/create",
    method: "post",
    data,
  });
};

// 公告
export const getAnnouncementList = (params, data) => {
  return request({
    url: "/res-announcement/page-list",
    method: "post",
    params,
    data,
  });
};
