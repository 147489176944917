<template>
  <div class="attachment">
    <img src="@/assets/img/service_details3.png" alt="" />
    <p>{{ info.fileName }}</p>
    <a :href="src" :download="info.fileName">下载</a>
  </div>
</template>

<script>
import { getAttachment } from "@/api/common";
import { downloadFile } from "@/api/cjkf/scene";

export default {
  props: {
    attachmentId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      info: {
        fileName: "",
      },
    };
  },
  computed: {
    src() {
      const { attachmentId } = this;
      return `${process.env.VUE_APP_API_PORTAL_URL}/file/download/${attachmentId}`;
    },
  },
  mounted() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.info = await getAttachment(this.attachmentId);
    },
    downloadAnnex() {
      downloadFile(this.attachmentId).then((res) => {
        if (!res) {
          return;
        }
        let url = window.URL.createObjectURL(new Blob([res]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", this.info.fileName);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>
<style lang="less" scoped>
.attachment {
  display: flex;
  margin-bottom: 32px;

  > img {
    margin-right: 10px;
  }

  > :nth-child(2) {
    font-size: 14px;
    color: #262626;
    line-height: 20px;
    margin-right: 24px;
  }

  > :nth-child(3) {
    font-size: 14px;
    color: #ea0b06;
    line-height: 20px;
    cursor: pointer;
  }
}
</style>
