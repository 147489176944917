<template>
  <div class="shi-xun-box">
    <div class="shi-xun-banner">
      <div>
        <h1>鸿联物联网实训平台</h1>
        <p>教学+仿真+实训一体化</p>
        <a-button class="btn-red" size="large" @click="() => $router.push('/login')">立即体验</a-button>
      </div>
    </div>
    <div class="shi-xun-anchor">
      <a-anchor :affix="false" :target-offset="targetOffset">
        <a-anchor-link href="#shi-xun-introduce" title="平台介绍" />
        <a-anchor-link href="#shi-xun-features" title="产品特点"/>
        <a-anchor-link href="#shi-xun-system" title="系统组成" />
        <a-anchor-link href="#shi-xun-scene" title="应用场景"/>
        <a-anchor-link href="#shi-xun-case" title="成功案例" />
      </a-anchor>
    </div>
    <div id="shi-xun-introduce">
        <introduce/>
    </div>
    <div id="shi-xun-features">
        <features/>
    </div>
    <div id="shi-xun-system">
        <system/>
    </div>
    <div id="shi-xun-scene">
        <scene/>
    </div>
    <div id="shi-xun-case">
        <caseBox/>
    </div>
  </div>
</template>

<script>
import introduce from './introduce.vue'
import features from './features.vue'
import system from './system.vue'
import scene from './scene.vue'
import caseBox from './case.vue'
export default {
    components:{
        introduce,
        features,
        system,
        scene,
        caseBox
    },
    data() {
      return {
        targetOffset: undefined,
      };
    },
    mounted() {
      this.targetOffset = 200;
    },
};
</script>

<style lang="scss" scoped>
.shi-xun-box {
  background: #fff;
  .shi-xun-banner {
    height: 480px;
    background: url(../../assets/img/shixunbanner.jpg) no-repeat;
    background-size: 100% 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    > div {
      width: 1264px;
      > h1 {
        font-size: 40px;
        color: rgba(0, 0, 0, 0.85);
        line-height: 56px;
        margin-bottom: 16px;
      }
      > p {
        font-size: 24px;
        color: rgba(0, 0, 0, 0.65);
        line-height: 33px;
        margin-bottom: 40px;
      }
      > button {
        width: 136px;
      }
    }
  }
  .shi-xun-anchor{
    background: #fff;
    >div{
        width: 1264px;
        margin: 0 auto;
        padding: 0;
        ::v-deep{
            .ant-anchor{
                padding: 0;
                display: flex;
                .ant-anchor-ink{
                    display: none;
                }
                .ant-anchor-link{
                    flex: 1;
                    padding: 0;
                    display: flex;
                    justify-content: center;
                    >a{
                        height: 72px;
                        line-height: 72px;
                        font-size: 16px;
                        color: rgba(0,0,0,0.85);
                        border-bottom: 2px solid transparent;
                        transition: all .3s;
                        &:hover{
                            color: #EA0B06;
                            border-color: #EA0B06;
                        }
                    }
                    &.ant-anchor-link-active{
                      >a{
                        color: #EA0B06;
                        border-color: #EA0B06;
                      }
                    }
                }
            }
        }
    }
  }
}
</style>