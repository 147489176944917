<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="projectList"
      :loading="loading"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <template slot="bidCompanyName" slot-scope="text, recode">
        <div class="service-provide">
          <v-img
            class="logo"
            v-if="recode.bidCompanyLogoAttmId"
            :attachment-id="recode.bidCompanyLogoAttmId"
            :defaultImg="require('@/assets/img/kctp.png')"
          />
          <div v-else class="logoText">
            {{ recode.bidCompanyName.slice(0, 4) }}
          </div>
          <div>
            <div>
              <div>{{ text }}</div>
              <img src="@/assets/img/certified.png" alt="" />
            </div>
            <p>企业规模：{{ recode.bidCompanyScaleTypeName }}</p>
          </div>
        </div>
      </template>
      <template slot="bidPrice" slot-scope="text">
        <p class="price">￥{{ text }}</p>
      </template>
      <template slot="createTime" slot-scope="text, recode">
        <div class="time">
          <p>{{ text }}</p>
          <img
            v-if="recode.bidStatus === '1'"
            src="@/assets/img/bidding.png"
            alt=""
          />
        </div>
      </template>
      <template slot="operate" slot-scope="text, recode">
        <p class="operate">
          <span @click="showProject(recode.bidCompanyId)">查看方案</span>
          <span
            class="select"
            @click="select(recode.id)"
            v-if="demandStatus === '4'"
            >选TA</span
          >
        </p>
      </template>
    </a-table>
    <a-modal
      @cancel="guanbiDetail"
      title="投标方案"
      v-model="bidDetail"
      width="1000px"
      class="modal"
      :footer="null"
    >
      <programme-card
        :val="projectData"
        :companyName="companyName"
      ></programme-card>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "服务商",
    dataIndex: "bidCompanyName",
    key: "bidCompanyName",
    scopedSlots: { customRender: "bidCompanyName" },
  },
  {
    title: "方案报价",
    dataIndex: "bidPrice",
    key: "bidPrice",
    scopedSlots: { customRender: "bidPrice" },
  },
  {
    title: "申请时间",
    dataIndex: "createTime",
    key: "createTime",
    width: 330,
    scopedSlots: { customRender: "createTime" },
  },
  {
    title: "操作",
    key: "operate",
    dataIndex: "operate",
    scopedSlots: { customRender: "operate" },
  },
];

import VImg from "@/components/VImg";
import {
  getDemandProjectList,
  getDemandBidDetail,
  selectProject,
} from "@/api/demand";
import programmeCard from "@/page/demand/components/programmeCard";
export default {
  name: "recordTable",
  components: {
    programmeCard,
    VImg,
  },
  props: ["demandId", "companyName", "demandStatus"],
  data() {
    return {
      columns,
      loading: false,
      bidDetail: false,
      page: {
        current: 1,
        size: 10,
      },
      dataInfo: {
        demandId: "",
      },
      pagination: {
        showSizeChanger: true,
        showQuickJumper: true,
        showTotal: (total) => `共 ${total} 项`,
        total: 0,
      },
      projectList: [],
      projectData: "",
    };
  },
  mounted() {
    this.loadGetDemandProjectList();
  },

  methods: {
    handleTableChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetDemandProjectList();
    },
    loadGetDemandProjectList() {
      this.dataInfo.demandId = this.demandId;
      getDemandProjectList(this.page, this.dataInfo).then((res) => {
        this.projectList = res.records;
        this.page.total = res.total;
      });
    },
    showProject(id) {
      this.bidDetail = true;
      getDemandBidDetail(this.demandId, id).then((res) => {
        this.projectData = res;
      });
    },
    select(id) {
      selectProject(id).then(async () => {
        this.$emit("bidChange");
        this.loadGetDemandProjectList();
        this.$message.success("操作成功");
      });
    },
    guanbiDetail() {
      this.bidDetail = false;
    },
  },
};
</script>

<style scoped lang="scss">
.service-provide {
  display: flex;
  > .logo {
    display: block;
    width: 56px;
    height: 56px;
    background: #ffffff;
    border-radius: 4px;
    border: 1px solid #eeeeee;
    margin-right: 17px;
  }
  > .logoText {
    width: 56px;
    height: 56px;
    background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
    border-radius: 4px;
    margin-right: 17px;
    color: #fff;
    font-size: 16px;
    padding: 6px 12px;
    line-height: 22px;
  }
  :last-child {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > div {
      display: flex;
      align-items: center;
      > div {
        font-size: 14px;
        color: #262626;
        line-height: 22px;
        margin-right: 8px;
      }
      > img {
        display: block;
      }
    }
    > p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 22px;
    }
  }
}

.price {
  font-size: 14px;
  color: #ea0b06;
  line-height: 22px;
}

.time {
  display: flex;
  align-items: center;
  > p {
    margin-right: 40px;
  }
}

.operate {
  font-size: 14px;
  color: #ea0b06;
  line-height: 22px;
  > span {
    cursor: pointer;
  }
  > :first-child {
    position: relative;
    padding-right: 8px;
  }
  > .select {
    padding-left: 9px;
    &::before {
      content: "";
      position: absolute;
      top: 2px;
      left: 0;
      width: 1px;
      height: 14px;
      background: #e9e9e9;
    }
  }
}
</style>
