<template>
  <div class="dev-home-head">
    <div class="home-head">
      <span @click="home">
        <img alt="图片" src="@/assets/img/devlogo1.png" />
        <img alt="图片" class="xx" src="@/assets/img/logoxx.png" />
        <img alt="图片" src="@/assets/img/devlogo3.png" />
      </span>
      <div class="home-login">
        <template v-if="!userInfo">
          <span @click="$router.push('/login')">登录</span>
          <p @click="$router.push('/register')">立即注册</p>
        </template>
        <template v-else>
          <span @click="$router.push('/')">回到鸿联云</span>
          <a-dropdown
            :trigger="['click']"
            placement="bottomRight"
            overlay-class-name="dropdown-user-info"
          >
            <div
              @click="(e) => e.preventDefault()"
              class="layout-header-user-info"
            >
              <span>
                <v-img
                  :attachment-id="userInfo.profilePictureAttmId"
                  :default-img="require('@/assets/img/defaultavatar.png')"
                />
              </span>
              <a-icon type="caret-down" />
            </div>
            <a-menu slot="overlay">
              <a-menu-item>
                <p class="layout-header-user-btn" @click="userInfoBtn">
                  基本信息
                  <span>待实名认证</span>
                </p>
              </a-menu-item>
              <a-menu-item>
                <p class="layout-header-user-btn" @click="setUp">安全设置</p>
              </a-menu-item>
              <a-menu-item>
                <p
                  class="layout-header-user-btn quit-login"
                  @click="$router.push('/logout')"
                >
                  退出
                </p>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { mapActions, mapGetters } from "vuex";
export default {
  components: {
    VImg,
  },
  computed: mapGetters(["userInfo", "tenant"]),
  data() {
    return {};
  },
  methods: {
    home() {
      this.$router.push("/");
    },
    setUp() {
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userSettings`,
        "_blank"
      );
    },
    userInfoBtn() {
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userInfo`,
        "_blank"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.dev-home-head {
  .home-head {
    background: transparent;
    padding-left: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    height: 72px;
    > span {
      cursor: pointer;
      height: 47px;
      display: flex;
      align-items: center;
      background: url(../../../assets/img/logobg.png) no-repeat;
      padding: 0 12px;
      > img {
        max-height: 100%;
        &.xx {
          margin: 0 8px;
        }
      }
    }
    .home-login {
      display: flex;
      align-items: center;
      font-size: 14px;
      color: #fff;
      > span {
        cursor: pointer;
        margin-right: 24px;
      }
      > p {
        cursor: pointer;
        background: #ea0b06;
        width: 96px;
        height: 72px;
        color: #fff;
        text-align: center;
        line-height: 72px;
      }
    }
    .layout-header-user-info {
      display: flex;
      align-items: center;
      cursor: pointer;
      height: 56px;
      margin-right: 24px;
      > span {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 10px;
        > img {
          display: block;
          height: 40px;
          width: 40px;
          border-radius: 20px;
        }
      }
      > i {
        color: #fff;
      }
    }
  }
}
.layout-header-user-btn {
  margin-bottom: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: space-between;
  > span {
    color: #52c41a;
    font-size: 12px;
    cursor: pointer;
  }
}
.quit-login {
  justify-content: center;
  height: 46px;
  line-height: 46px;
}
</style>
<style>
.home-nav-search input {
  font-size: 14px;
  background: #f1f2f5;
  border-radius: 2px;
  border: none;
}
.home-nav-search .ant-input-suffix {
  color: #ea0b06;
}
.dropdown-user-info {
  width: 200px;
  background: #2f2f2f;
}
.dropdown-user-info .ant-dropdown-menu {
  background-color: transparent;
}
.dropdown-user-info .ant-dropdown-menu-item,
.dropdown-user-info .ant-dropdown-menu-submenu-title {
  padding: 5px 16px;
}
.dropdown-user-info .ant-dropdown-menu-item:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}
.dropdown-user-info .ant-dropdown-menu-item:hover,
.dropdown-user-info .ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
</style>
