<template>
  <div class="demandCard">
    <div class="demandCard-content">
      <div>
        <p>{{ val.name }}</p>
        <p>{{ val.summary || val.demandDescribe }}</p>
      </div>
      <div>
        <div class="company">
          <img src="@/assets/img/service_card1.png" alt="" />
          <p>需求单位：</p>
          <p>{{ val.companyName || val.purUnitName }}</p>
        </div>
        <a-divider style="margin: 12px 0" />
        <div>
          <div v-if="val.demandTypeLevel && val.demandTypeLevel === '1'">
            类型：{{ val.demandTypeName }}
          </div>
          <div v-if="val.demandTypeLevel && val.demandTypeLevel === '2'">
            类型：{{ val.demandTypeParentName }}/{{ val.demandTypeName }}
          </div>
          <div v-if="!val.demandTypeLevel"></div>
          <div>
            已有 <span>{{ val.bidCount }}人</span> 投标｜{{
              val.createTime ? val.createTime.slice(0, 10) : ""
            }}发布
          </div>
        </div>
      </div>
    </div>
    <div class="demandCard-price">
      <div>
        ￥ <span>{{ val.fundAmount }}</span>
      </div>
      <div v-if="val.fundAmountStatus" class="text">可议价</div>
    </div>
    <img
      v-if="val.demandStatus === '6'"
      class="img"
      src="@/assets/img/yidinbiao.png"
      alt=""
    />
    <img
      v-if="val.demandStatus === '5'"
      class="img"
      src="@/assets/img/yijiezi.png"
      alt=""
    />
    <img
      v-if="val.demandStatus === '4'"
      class="img"
      src="@/assets/img/toubiaozhong.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "demandCard",
  props: ["val"],
};
</script>

<style scoped lang="scss">
.demandCard {
  width: 976px;
  min-height: 204px;
  padding: 24px;
  display: flex;
  background: #ffffff;
  position: relative;
  cursor: pointer;
  margin-bottom: 12px;
  &:hover {
    box-shadow: 0px 3px 12px 0px rgba(0, 0, 0, 0.1);
  }
  > .demandCard-content {
    width: 728px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    > :first-child {
      > :first-child {
        font-size: 16px;
        color: #262626;
        line-height: 22px;
        margin-bottom: 11px;
      }
      > :last-child {
        font-size: 12px;
        color: #8c8c8c;
        line-height: 18px;

        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    > :last-child {
      > .company {
        display: flex;
        align-items: center;
        > img {
          display: block;
          width: 12px;
          height: 12px;
          margin-right: 8px;
        }
        > p {
          font-size: 12px;
          color: #4181fc;
          line-height: 18px;
        }
      }
      > :last-child {
        font-size: 12px;
        color: #8c8c8c;
        line-height: 18px;
        display: flex;
        justify-content: space-between;
        > :last-child {
          > span {
            color: #ea0b06;
          }
        }
      }
    }
  }
  > .demandCard-price {
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > :first-child {
      color: #ea0b06;
      font-size: 14px;
      > span {
        font-size: 20px;
      }
    }
    > .text {
      margin-left: 12px;
    }
  }
  > .img {
    position: absolute;
    top: 0;
    right: 0;
  }
}
</style>
