<template>
  <div>
    <p class="detail-content-title"><span>关键词</span></p>
    <div class="detail-keywords">
      <span v-for="(item, index) in info.sceneKeywords" :key="index">{{
        item
      }}</span>
    </div>
    <p class="detail-content-title"><span>场景简介</span></p>
    <div style="padding-left: 16px" v-html="info.description"></div>
    <p class="detail-content-title" style="margin-top: 24px">
      <span>场景建设需求</span>
    </p>
    <div style="padding-left: 16px" v-html="info.requirement"></div>
    <p class="detail-content-title" style="margin-top: 24px">
      <span>场景配套资源</span>
    </p>
    <div style="padding-left: 16px" v-html="info.resource"></div>
    <div class="more-dynamic-detail-content-annex">
      <p class="detail-content-title"><span>相关附件</span></p>
      <div>
        <v-attachment
          v-for="item in info.reviewAttmIds"
          :key="item"
          :attachment-id="item"
        />
      </div>
    </div>
    <p class="detail-content-title" style="margin-top: 24px">
      <span>关联政策</span>
    </p>
    <div style="padding-left: 16px">
      <p v-for="item in info.newsList" :key="item" class="detail-content-policy-list" @click="$router.push(`/policy/info?id=${item.newsId}`)">{{item.newsName}}</p>
    </div>
  </div>
</template>

<script>
import { downloadFile } from "@/api/cjkf/scene";
import { mapGetters } from "vuex";
import VAttachment from "@/components/VAttachment";
export default {
  components:{
    VAttachment
  },
  props: ["info"],
  data() {
    return {};
  },
  computed: mapGetters(["userInfo"]),
  mounted() {},
  methods: {
    downloadAnnex(id, name) {
      if (!this.userInfo) {
        this.$message.warning("您还未登录！");
        return;
      }
      downloadFile(id).then((res) => {
        if (!res) {
          return;
        }
        let url = window.URL.createObjectURL(new Blob([res]));
        let link = document.createElement("a");
        link.style.display = "none";
        link.href = url;
        link.setAttribute("download", name);
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.detail-content-title {
  font-size: 16px;
  line-height: 16px;
  position: relative;
  margin-bottom: 36px;
  > span {
    display: block;
    line-height: 16px;
    position: relative;
    z-index: 2;
  }
  &::after {
    content: "";
    position: absolute;
    width: 64px;
    height: 8px;
    background: #EA0B06;
    left: 0;
    bottom: -4px;
  }
}
.more-dynamic-detail-content-annex {
  margin-top: 24px;
  > div {
    > div {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      padding: 0 16px;
      margin-bottom: 16px;
      cursor: pointer;
      &:last-child {
        margin-bottom: 0;
      }
      > div {
        display: flex;
        align-items: center;
        > img {
          margin-right: 8px;
        }
        > p {
          max-width: 400px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
      > span {
        color: #EA0B06;
        cursor: pointer;
        display: none;
      }
      &:hover {
        background: #f5f5f5;
        > span {
          display: block;
        }
      }
    }
  }
}
.detail-keywords {
  margin-bottom: 24px;
  padding-left: 16px;
  display: flex;
  flex-wrap: wrap;
  > span {
    height: 32px;
    background: rgba(185,56,56,.2);
    border: 1px solid rgba(185,56,56,.5);
    border-radius: 16px;
    margin-right: 16px;
    padding: 0 16px;
    color: #EA0B06;
    line-height: 30px;
  }
}
.detail-content-policy-list{
  cursor: pointer;
  margin-bottom: 6px;
  &:hover{
    color: #EA0B06;
  }
}
</style>
