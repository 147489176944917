<template>
  <div class="home-scenes-body">
    <div>
      <div class="scenes-history-list">
        <span>往期场景：</span>
        <a-select style="width: 480px" v-model="batchVal">
          <a-select-option
            v-for="item in batchData"
            :key="item.id"
            :value="item.id"
          >
            {{ item.batchName }}
          </a-select-option>
        </a-select>
      </div>
      <div class="home-scenes-list-content-body">
        <div class="home-scenes-filter-body">
          <div>
            <span>状态：</span>
            <div>
              <span :class="{ active: stateCode == '' }" @click="stateCode = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in stateData"
                :key="index"
                :class="{ active: stateCode == item.code }"
                @click="stateCode = item.code"
                >{{ item.text }}</span
              >
              <!--              <span-->
              <!--                :class="{ active: stateCode == 'EXPIRED' }"-->
              <!--                @click="stateCode = 'EXPIRED'"-->
              <!--                >已过期</span-->
              <!--              >-->
            </div>
          </div>
          <div>
            <span>场景领域：</span>
            <div :style="{ height: fieldShow ? 'auto' : '48px' }">
              <span :class="{ active: fieldCode == '' }" @click="fieldCode = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in fieldData"
                :key="index"
                :class="{ active: fieldCode == item.code }"
                @click="fieldCode = item.code"
                >{{ item.text }}</span
              >
            </div>
            <p @click="fieldShow = !fieldShow">
              {{ fieldShow ? "收起" : "展开"
              }}<a-icon :type="fieldShow ? 'up' : 'down'" />
            </p>
          </div>
          <div>
            <span>应用行业：</span>
            <div
              class="list-show"
              :style="{ height: industryShow ? 'auto' : '48px' }"
            >
              <span
                :class="{ active: industryCode == '' }"
                @click="industryCode = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in industryData"
                :key="index"
                :class="{ active: industryCode == item.code }"
                @click="industryCode = item.code"
                >{{ item.text }}</span
              >
            </div>
            <p @click="industryShow = !industryShow">
              {{ industryShow ? "收起" : "展开"
              }}<a-icon :type="industryShow ? 'up' : 'down'" />
            </p>
          </div>
          <div :style="{ height: skillShow ? 'auto' : '32px' }">
            <span>技术类别：</span>
            <div class="list-show">
              <span :class="{ active: skillCode == '' }" @click="skillCode = ''"
                >全部</span
              >
              <span
                v-for="(item, index) in skillData"
                :key="index"
                :class="{ active: skillCode == item.code }"
                @click="skillCode = item.code"
                >{{ item.text }}</span
              >
            </div>
            <p @click="skillShow = !skillShow">
              {{ skillShow ? "收起" : "展开"
              }}<a-icon :type="skillShow ? 'up' : 'down'" />
            </p>
          </div>
        </div>
        <div class="home-scenes-list-body">
          <div class="home-scenes-list-search">
            <div>
              <span>共 {{ page.total }} 项需求</span>
              <p :class="{ active: sortTabIndex == 0 }" @click="sortTab(0)">
                综合排序
              </p>
              <p :class="{ active: sortTabIndex == 1 }" @click="sortTab(1)">
                发布时间
                <span>
                  <a-icon
                    :class="{ active: sortTime == 'PublishTimeDesc' }"
                    type="caret-up"
                  />
                  <a-icon
                    :class="{ active: sortTime == 'PublishTimeAsc' }"
                    type="caret-down"
                  />
                </span>
              </p>
              <!-- <p :class="{ active: sortTabIndex == 2 }" @click="sortTab(2)">
                金额
                <span>
                  <a-icon
                    :class="{ active: sortPrice == 'PriceDesc' }"
                    type="caret-up"
                  />
                  <a-icon
                    :class="{ active: sortPrice == 'PriceAsc' }"
                    type="caret-down"
                  />
                </span>
              </p> -->
            </div>
            <a-input-search
              style="width: 360px"
              placeholder="请输入关键词搜索"
              enter-button
              v-model="scenesVal"
              @search="searchScenes"
            />
          </div>
          <div class="home-scenes-list-body">
            <template v-if="sceneListData != ''">
              <div
                class="home-scenes-list"
                v-for="item in sceneListData"
                :key="item.id"
                @click="scenesDetail(item.id)"
              >
                <div class="home-scenes-list-info">
                  <span>
                    <images
                      :id="
                        item.coverAttachment
                          ? item.coverAttachment.attachmentId
                          : ''
                      "
                    />
                  </span>
                  <div>
                    <strong>{{ item.title }}</strong>
                    <div>
                      <p>需求单位：{{ item.ownerName }}</p>
                      <p>发布时间：{{ item.publishTime }}</p>
                      <p>截止日期：{{ item.applyDueDate }}</p>
                    </div>
                    <p v-html="item.description"></p>
                  </div>
                </div>
                <div class="home-scenes-list-price">
                  <p><span>￥</span>{{ item.fundAmount }}<span>万</span></p>
                  <span>意向金额</span>
                </div>
              </div>
              <div class="home-dynamic-page">
                <a-pagination
                  @change="scenePage"
                  show-quick-jumper
                  :default-current="page.current"
                  :total="page.total"
                  :show-total="(total) => `共 ${total} 项`"
                />
              </div>
            </template>
            <div v-else style="padding: 50px 0">
              <a-empty>
                <img slot="image" src="@/assets/img/cjsh.png" />
              </a-empty>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { batchList, sceneList, commonCode } from "@/api/cjkf/scene";
import images from "@/page/components/image";
export default {
  components: {
    images,
  },
  data() {
    return {
      fieldShow: false,
      industryShow: false,
      skillShow: false,
      stateCode: "",
      fieldCode: "",
      industryCode: "",
      scenesVal: "",
      batchVal: "",
      stateData: [],
      fieldData: [],
      industryData: [],
      batchData: [],
      skillData: [],
      skillCode: "",
      page: {
        size: 10,
        current: 1,
        total: 0,
      },
      sortType: "Default",
      sceneListData: [],
      sortTabIndex: 0,
      sortTime: "",
      sortPrice: "",
    };
  },
  watch: {
    stateCode: function () {
      this.page.current = 1;
      this.loadSceneList();
    },
    fieldCode: function () {
      this.page.current = 1;
      this.loadSceneList();
    },
    industryCode: function () {
      this.page.current = 1;
      this.loadSceneList();
    },
    skillCode: function () {
      this.page.current = 1;
      this.loadSceneList();
    },
    batchVal: function () {
      this.page.current = 1;
      this.loadSceneList();
    },
  },
  mounted() {
    this.loadState();
    this.loadfField();
    this.loadIndustry();
    this.loadSkill();
    this.loadBatchList();
  },
  methods: {
    loadState() {
      let obj = {
        type: "WORKFLOW",
        precode: "SYS02",
      };
      commonCode(obj).then((res) => {
        this.stateData = res;
      });
    },
    loadfField() {
      let obj = {
        type: "FIELD",
        precode: "000",
      };
      commonCode(obj).then((res) => {
        this.fieldData = res;
      });
    },
    loadIndustry() {
      let obj = {
        type: "INDUSTRY",
        precode: "000",
      };
      commonCode(obj).then((res) => {
        this.industryData = res;
      });
    },
    loadSkill() {
      let obj = {
        type: "SKILL",
        precode: "000",
      };
      commonCode(obj).then((res) => {
        this.skillData = res;
      });
    },
    loadBatchList() {
      batchList().then((res) => {
        this.batchData = res;
        this.batchVal = res[0].id;
      });
    },
    // 列表
    loadSceneList() {
      let obj = {
        batch: this.batchVal,
        status: this.stateCode,
        field: this.fieldCode,
        industry: this.industryCode,
        skill: this.skillCode,
        keywords: this.scenesVal,
        sort: this.sortType,
      };
      sceneList(this.page, obj).then((res) => {
        this.page.total = res.total;
        this.sceneListData = res.records;
      });
    },
    scenePage(e) {
      this.page.current = e;
      this.loadSceneList();
    },
    searchScenes() {
      this.page.current = 1;
      this.loadSceneList();
    },
    sortTab(index) {
      this.sortTabIndex = index;
      if (index == 1) {
        if (this.sortTime == "") {
          this.sortTime = "PublishTimeDesc";
        } else if (this.sortTime == "PublishTimeDesc") {
          this.sortTime = "PublishTimeAsc";
        } else {
          this.sortTime = "PublishTimeDesc";
        }
        this.sortType = this.sortTime;
      }
      if (index == 2) {
        if (this.sortPrice == "") {
          this.sortPrice = "PriceDesc";
        } else if (this.sortPrice == "PriceDesc") {
          this.sortPrice = "PriceAsc";
        } else {
          this.sortPrice = "PriceDesc";
        }
        this.sortType = this.sortPrice;
      }
      this.loadSceneList();
    },
    scenesDetail(id) {
      this.$router.push({
        path: "/homeScenes/detail",
        query: { id },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.home-scenes-body {
  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 24px 0 80px;
    .home-scenes-list-content-body {
      width: 100%;
      background: #fff;
      .home-scenes-filter-body {
        padding: 24px;
        border-bottom: 1px solid #f0f0f0;
        > div {
          display: flex;
          > span {
            width: 64px;
            height: 32px;
            line-height: 32px;
            white-space: nowrap;
            text-align: right;
            margin-right: 10px;
          }
          > p {
            width: 80px;
            height: 32px;
            line-height: 32px;
            text-align: center;
            margin-left: 16px;
            cursor: pointer;
            background: #f5f5f5;
          }
          > div {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            overflow: hidden;
            > span {
              height: 32px;
              line-height: 32px;
              padding: 0 18px;
              margin-bottom: 16px;
              cursor: pointer;
              &.active {
                background: #1abc9c;
                color: #fff;
              }
            }
          }
        }
      }
      .home-scenes-list-body {
        padding: 16px 24px;
        .home-scenes-list-search {
          display: flex;
          justify-content: space-between;
          align-items: center;
          > div {
            display: flex;
            align-items: center;
            > span {
              color: #8c8c8c;
              line-height: 14px;
              margin-right: 16px;
              padding-right: 16px;
              border-right: 1px solid #f0f0f0;
            }
            > p {
              color: #595959;
              line-height: 14px;
              display: flex;
              align-items: center;
              margin-right: 24px;
              cursor: pointer;
              &.active {
                color: #1abc9c;
              }
              > span {
                margin-left: 6px;
                > i {
                  display: block;
                  font-size: 8px;
                  color: #d9d9d9;
                  &.active {
                    color: #1abc9c;
                  }
                }
              }
            }
          }
        }
        .home-scenes-list-body {
          .home-scenes-list {
            border-bottom: 1px solid #f0f0f0;
            padding: 32px 0;
            display: flex;
            justify-content: space-between;
            cursor: pointer;
            .home-scenes-list-info {
              width: 100%;
              display: flex;
              > span {
                width: 210px;
                height: 120px;
                margin-right: 24px;
                > img {
                  width: 100%;
                  height: 100%;
                  display: block;
                }
              }
              > div {
                flex: 1;
                > strong {
                  font-size: 16px;
                  line-height: 16px;
                  font-weight: bold;
                  display: block;
                  margin-bottom: 16px;
                }
                > div {
                  display: flex;
                  margin-bottom: 20px;
                  > p {
                    color: #595959;
                    margin-right: 24px;
                    line-height: 14px;
                    &:last-child {
                      margin-right: 0;
                    }
                    > span {
                      color: #1abc9c;
                    }
                  }
                }
                > p {
                  color: #8c8c8c;
                  line-height: 20px;
                  max-height: 60px;
                  overflow: hidden;
                  text-align: justify;
                }
              }
            }
            .home-scenes-list-price {
              display: none;
              > p {
                display: flex;
                align-items: flex-end;
                color: #1abc9c;
                font-size: 24px;
                font-weight: bold;
                line-height: 24px;
                margin-bottom: 12px;
                > span {
                  font-size: 14px;
                  line-height: 14px;
                }
              }
              > span {
                font-size: 12px;
                line-height: 12px;
                color: #8c8c8c;
                text-align: right;
                display: block;
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.home-dynamic-page {
  text-align: right;
  margin-top: 24px;
}
.home-scenes-list-search .ant-btn {
  background: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
  color: #8c8c8c !important;
}
.scenes-history-list {
  margin-bottom: 24px;
}
.scenes-history-list .ant-select-selection {
  background: none;
}
</style>
