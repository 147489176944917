<template>
  <div class="home-head">
    <span @click="home" class="title"> Hi，欢迎来到中山软件园平台 </span>

    <div class="home-login">
      <template v-if="!userInfo">
        <span @click="$router.push('/login')">登录</span>
        <p @click="$router.push('/register')">立即注册</p>
      </template>
      <template v-else>
        <span @click="userCenter">控制台</span>
        <a-dropdown
          :trigger="['click']"
          overlay-class-name="dropdown-user-info"
          placement="bottomRight"
        >
          <div
            @click="(e) => e.preventDefault()"
            class="layout-header-user-info"
          >
            <span>
              <v-img
                :attachment-id="userInfo.profilePictureAttmId"
                :default-img="require('@/assets/img/defaultavatar.png')"
              />
            </span>
            <a-icon type="caret-down" />
          </div>
          <a-menu slot="overlay">
            <a-menu-item>
              <p @click="userInfoBtn" class="layout-header-user-btn">
                基本信息
                <!-- <span
                                  :class="{
                                    yrz: userInfo.verifyStatus == 3,
                                    drz: userInfo.verifyStatus == 1,
                                    wtg: userInfo.verifyStatus == 4,
                                  }"
                                  >{{
                                    userInfo.verifyStatus | dict("USER_VERIFY_STATUS")
                                  }}</span
                                > -->
              </p>
            </a-menu-item>
            <a-menu-item>
              <p @click="setUp" class="layout-header-user-btn">安全设置</p>
            </a-menu-item>
            <a-menu-item>
              <p
                @click="$router.push('/logout')"
                class="layout-header-user-btn quit-login"
              >
                退出
              </p>
            </a-menu-item>
          </a-menu>
        </a-dropdown>
      </template>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { mapGetters } from "vuex";
import { getTenantNavigations } from "@/api/common";
import { getConferenceToken } from "@/api/conference";

export default {
  name: "head",
  components: {
    VImg,
  },
  computed: mapGetters(["userInfo", "tenant", "companyList"]),
  data() {
    return {
      navigations: [],
      searchVal: "",
      searchPopover: false,
      searchDatas: [],
      companyIndex: 0,
    };
  },
  methods: {
    // 切换企业
    toggleCompany(id, el, index) {
      this.$store.commit("SET_COMPANY_ID", id);
      this.$store.commit("SET_COMPANY_STATUS", el.verifyStatus);
      this.companyIndex = index;
    },
    onSearch() {
      let { searchVal } = this;
      if (!searchVal) {
        this.$message.warning("请输入搜索关键词！");
        return;
      }
      this.$router.push(`/searchInfo?val=${searchVal}`);
      this.searchVal = "";
    },
    popularSearchBtn(val) {
      this.$router.push(`/searchInfo?val=${val}`);
    },
    home() {
      this.$router.push("/");
    },
    setUp() {
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userSettings`,
        "_blank"
      );
    },
    userInfoBtn() {
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userInfo`,
        "_blank"
      );
    },
    // userCenter() {
    //   window.open(`${process.env.VUE_APP_ACCOUNT_PREFIX}`, "_blank");
    // },
    userCenter() {
      const { tenant } = this;
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}?tenantId=${tenant.id}`,
        "_blank"
      );
    },
    loadNavigations() {
      const { tenant } = this;
      getTenantNavigations(tenant.id).then((list) => {
        this.navigations = list;
      });
    },
    openPage(href) {
      window.open(href);
    },
    searchFocus(e) {
      this.searchPopover = true;
    },
    searchBlur(e) {
      let _this = this;
      setTimeout(() => {
        _this.searchPopover = false;
      }, 300);
    },
    toConference() {
      getConferenceToken({}).then((res) => {
        window.open(
          `http://localhost:9527/third-login?sign=${encodeURIComponent(res)}`
        );
      });
    },
  },
  mounted() {
    if (localStorage.getItem("searchData")) {
      this.searchDatas = JSON.parse(localStorage.getItem("searchData"));
    }
    this.loadNavigations();
  },
};
</script>

<style lang="scss" scoped>
.home-head {
  background: #fff;
  padding-left: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  box-sizing: border-box;
  border-bottom: 1px solid #e2e8f0;
  height: 75px;
  > title {
    cursor: pointer;
    font-size: 14px;
    color: #262626;
    line-height: 20px;
  }

  .home-nav {
    width: 1280px;
    position: absolute;
    left: 50%;
    margin-left: -640px;
    display: flex;
    align-items: center;

    .home-nav-list {
      display: flex;

      > div {
        color: #262626;
        margin-right: 30px;
        height: 52px;
        line-height: 52px;
        border-bottom: 2px solid transparent;
        cursor: pointer;
        transition: all 0.3s;
        &:hover {
          border-color: #ea0b06;
          color: #ea0b06;
        }
        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }

    .home-nav-search {
      position: absolute;
      right: 0px;
    }
  }

  .home-login {
    display: flex;
    align-items: center;
    font-size: 14px;

    > span {
      cursor: pointer;
      margin-right: 24px;
    }

    > p {
      cursor: pointer;
      background: #ea0b06;
      width: 96px;
      height: 75px;
      color: #fff;
      text-align: center;
      line-height: 75px;
    }
  }

  .layout-header-user-info {
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 75px;
    margin-right: 24px;

    > span {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 10px;

      > img {
        display: block;
        height: 40px;
        width: 40px;
        border-radius: 20px;
      }
    }

    > i {
      color: #262626;
    }
  }
}

.layout-header-user-btn {
  margin-bottom: 0;
  line-height: 30px;
  display: flex;
  align-items: center;
  color: #fff;
  justify-content: center;

  > span {
    font-size: 12px;
    cursor: pointer;

    &.drz {
      color: #faad14;
    }

    &.yrz {
      color: #52c41a;
    }

    &.wtg {
      color: #ea0b06;
    }
  }
}

.quit-login {
  justify-content: center;
  height: 46px;
  line-height: 46px;
}

.home-nav-search-tag {
  width: 308px;

  > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    > p {
      font-size: 14px;
      color: #8c8c8c;
      line-height: 12px;
      margin-bottom: 8px;
    }

    > div {
      display: flex;
      flex-wrap: wrap;

      > span {
        height: 32px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        padding: 0 16px;
        font-size: 12px;
        color: #262626;
        line-height: 30px;
        margin: 8px 8px 0 0;
        cursor: pointer;
      }
    }
  }
}
.user-layout-header-enterprise {
  width: 300px;
  background: #ffffff;
  box-shadow: 0px 12px 48px 16px rgba(0, 0, 0, 0.03),
    0px 9px 28px 0px rgba(0, 0, 0, 0.05), 0px 6px 16px -8px rgba(0, 0, 0, 0.08);

  > p {
    line-height: 40px;
    border-bottom: 1px solid #eee;
    font-size: 12px;
    color: #000;
    padding: 0 16px;
  }

  > div {
    > div {
      padding: 12px 16px;
      display: flex;
      cursor: pointer;

      > span {
        width: 40px;
        height: 40px;
        background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
        border-radius: 4px;
        margin-right: 12px;
        color: #fff;
        font-size: 12px;
        padding: 4px 8px;
        line-height: 16px;
      }

      > div {
        flex: 1;

        > span {
          display: block;
          font-size: 14px;
          color: #000000;
          line-height: 18px;
          margin-bottom: 5px;
        }

        > p {
          display: flex;

          > span {
            display: flex;
            align-items: center;
            padding: 0 6px;
            height: 18px;
            background: linear-gradient(90deg, #e6e8eb 0%, #ffffff 100%);
            border-radius: 2px;
            border: 1px solid #f0f1f2;
            font-size: 12px;
            color: #a0acba;

            > i {
              margin-right: 3px;
            }
          }
        }
      }

      &:hover {
        background: #fafafa;
      }
    }
  }
}
@media screen and (max-width: 1600px) {
  .home-head {
    .home-nav {
      width: 70%;
      margin-left: -35%;

      .home-nav-search {
        right: 0;
      }
    }
  }
}
</style>
<style>
.home-nav-search input {
  font-size: 14px;
  background: #f1f2f5;
  border-radius: 2px;
  border: none;
}

.home-nav-search .ant-input-suffix {
  color: #ea0b06;
}

.dropdown-user-info {
  /* width: 200px; */
  width: 170px;
  background: #2f2f2f;
}

.dropdown-user-info .ant-dropdown-menu {
  background-color: transparent;
}

.dropdown-user-info .ant-dropdown-menu-item,
.dropdown-user-info .ant-dropdown-menu-submenu-title {
  padding: 5px 16px;
}

.dropdown-user-info .ant-dropdown-menu-item:last-child {
  border-top: 1px solid rgba(0, 0, 0, 0.16);
}

.dropdown-user-info .ant-dropdown-menu-item:hover,
.dropdown-user-info .ant-dropdown-menu-submenu-title:hover {
  background-color: rgba(255, 255, 255, 0.08);
}
</style>
