<template>
  <div class="register-box">
    <login-head title="账号注册" />
    <div class="register-content">
      <div class="register-from">
        <div class="register-title">
          <span>鸿联云账号注册</span>
          <p>已有账号，<span @click="$router.push('/login')">去登录 ></span></p>
        </div>
        <div class="register-from-box">
          <div>
            <template v-if="!isRegister">
              <div class="register-from-tab">
                <span
                  v-for="(el, index) in dict['REGISTER_TYPE']"
                  :key="index"
                  :class="{ active: registerType === el.itemValue }"
                  @click="registerType = el.itemValue"
                  >{{ el.itemText }}</span
                >
              </div>
              <div class="register-from-info">
                <template v-if="registerType === '1'">
                  <div>
                    <a-input
                      size="large"
                      v-model="mobileData.mobile"
                      placeholder="请输入手机号"
                    />
                  </div>
                  <div>
                    <a-input-password
                      size="large"
                      v-model="mobileData.password"
                      placeholder="请输入6~20位密码，至少含字母和数字，不能包含空格"
                    />
                  </div>
                  <div>
                    <a-input-password
                      size="large"
                      v-model="mobileData.confirmPassword"
                      placeholder="请确认密码"
                    />
                  </div>
                  <div>
                    <a-input
                      size="large"
                      v-model="mobileData.code"
                      placeholder="请输入短信验证码"
                    >
                      <template #suffix>
                        <v-count-down :trigger="() => sendMobileCode()">
                          <template #default>获取验证码</template>
                          <template #loading="scope"
                            >{{ scope.seconds }}秒后重新发送</template
                          >
                        </v-count-down>
                      </template>
                    </a-input>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <a-input
                      size="large"
                      v-model="emailData.email"
                      placeholder="请输入邮箱"
                    />
                  </div>
                  <div>
                    <a-input-password
                      size="large"
                      v-model="emailData.password"
                      placeholder="请输入6~20位密码，至少含字母和数字，不能包含空格"
                    />
                  </div>
                  <div class="num-code">
                    <a-input
                      size="large"
                      style="width: 355px"
                      v-model="emailData.emailVerification"
                      placeholder="验证码"
                    />
                    <div @click="refreshCode">
                      <verification :identifyCode="identifyCode" />
                    </div>
                  </div>
                  <div>
                    <a-input
                      size="large"
                      v-model="emailData.code"
                      placeholder="请输入邮箱验证码"
                    >
                      <template #suffix>
                        <v-count-down :trigger="() => sendEmailCode()">
                          <template #default>获取验证码</template>
                          <template #loading="scope"
                            >{{ scope.seconds }}秒后重新发送</template
                          >
                        </v-count-down>
                      </template>
                    </a-input>
                  </div>
                </template>
              </div>
              <a-button
                v-if="registerType === '1'"
                size="large"
                class="btn-red register-btn"
                :style="{ opacity: '1' }"
                @click="registerMobile"
                >注 册</a-button
              >
              <a-button
                v-else
                size="large"
                class="btn-red register-btn"
                :style="{ opacity: '1' }"
                @click="registerEmail"
                >注 册</a-button
              >
              <div class="register-protocol">
                <a-checkbox v-model="registerProtocol">阅读并同意</a-checkbox>
                <span @click="$router.push('/protocol/serve')"
                  >《鸿联云平台服务协议》</span
                >、
                <span @click="$router.push('/protocol')"
                  >《鸿联云法律声明及隐私权政策》</span
                >
              </div>
            </template>
            <template v-else>
              <div class="register-success">
                <img alt="图片" src="@/assets/img/zhucechenggong.png" />
                <p>注册成功</p>
                <div>
                  <span @click="$router.push('/')">返回首页</span>
                  <span @click="$router.push('/login')">登录用户</span>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="register-footer-protocol">
        <!--        <div>
          <span @click="$router.push('/protocol/serve')">鸿联云帐号用户协议</span>
          <span @click="$router.push('/protocol')"
            >关于鸿联云帐号与隐私的声明</span
          >
&lt;!&ndash;          <span>常见问题</span>&ndash;&gt;
        </div>-->
        <p>Copyright © 鸿联云, All Rights Reserved.</p>
      </div>
    </div>
  </div>
</template>

<script>
import loginHead from "@/page/components/loginHead.vue";
import verification from "@/page/components/verification.vue";
import { mapGetters } from "vuex";
import { setUserRegisterEmail, setUserRegisterMobile } from "@/api/register";
import { sendEmail, sendMobile } from "@/api/common";
import VCountDown from "@/components/VCountDown";
export default {
  components: {
    loginHead,
    verification,
    VCountDown,
  },
  data() {
    return {
      mobileData: {
        mobile: "",
        code: "",
        password: "",
        confirmPassword: "",
      },
      emailData: {
        email: "",
        code: "",
        password: "",
        emailVerification: "",
      },
      registerProtocol: false,
      registerType: "1",
      identifyCodes: "0123456789qwerasdfzxcv",
      identifyCode: "89qw",
      isRegister: false,
    };
  },
  computed: mapGetters(["dict", "userInfo"]),
  methods: {
    login() {
      this.$router.push("/login");
    },
    // 获取手机验证码
    sendMobileCode() {
      const { mobile } = this.mobileData;
      if (!mobile || mobile.length < 11) {
        this.$message.warning("手机号输入有误");
        return;
      }
      sendMobile(mobile, "register").then(() => {
        this.$message.success("发送成功！");
      });
    },
    // 获取邮箱验证码
    sendEmailCode() {
      const { email } = this.emailData;
      if (!email) {
        this.$message.warning("邮箱输入有误");
        return;
      }
      sendEmail(email).then((code) => {
        this.$notification.success({
          message: "提示",
          description: `您的登录验证码为:${code}`,
          onClick: () => {
            this.emailData.code = code;
          },
        });
      });
    },
    // 注册
    registerMobile() {
      const { mobile, code, password, confirmPassword } = this.mobileData;
      if (password.length < 6 || password.length > 20) {
        this.$message.warning("密码长度输入有误");
        return;
      }
      if (!mobile || mobile.length < 11) {
        this.$message.warning("手机号输入有误");
        return;
      }
      if (confirmPassword.length < 6 || confirmPassword.length > 20) {
        this.$message.warning("确认密码输入有误");
        return;
      }
      if (password !== confirmPassword) {
        this.$message.warning("密码输入不一致");
        return;
      }
      if (!code) {
        this.$message.warning("请输入验证码");
        return;
      }
      if (!this.registerProtocol) {
        this.$message.warning("请先阅读及勾选协议");
        return;
      }
      setUserRegisterMobile({ mobile, code, password }).then(() => {
        this.$message.success("注册成功");
        this.isRegister = true;
      });
    },
    // 注册
    registerEmail() {
      const { email, code, password, emailVerification } = this.emailData;
      let { identifyCode } = this;
      if (password.length < 6 || password.length > 20) {
        this.$message.warning("密码长度输入有误");
        return;
      }
      if (!email) {
        this.$message.warning("邮箱输入有误");
        return;
      }
      if (emailVerification !== identifyCode) {
        this.$message.warning("数字验证码输入有误");
        return;
      }
      if (!code) {
        this.$message.warning("请输入验证码");
        return;
      }
      if (!this.registerProtocol) {
        this.$message.warning("请先阅读及勾选协议");
        return;
      }
      setUserRegisterEmail({ email, code, password }).then(() => {
        this.$message.success("注册成功");
        this.isRegister = true;
      });
    },
    // 验证码处理
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
    // 刷新验证码
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.obtain-code {
  color: #ea0b06;
  cursor: pointer;
}
.register-box {
  height: 100%;
  background: #f7f8fa;
  .register-content {
    height: calc(100% - 64px);
    padding: 24px 0;
    .register-from {
      background: #fff;
      width: 960px;
      margin: 0 auto;
      border-radius: 4px;
      .register-title {
        padding: 0 24px;
        height: 56px;
        border-bottom: 1px solid #f0f0f0;
        display: flex;
        align-items: center;
        justify-content: space-between;
        > span {
          font-size: 18px;
        }
        > p {
          > span {
            color: #ea0b06;
            cursor: pointer;
          }
        }
      }
      .register-from-box {
        min-height: 584px;
        padding: 56px 0;
        > div {
          width: 480px;
          margin: 0 auto;
          .register-from-tab {
            padding: 4px;
            background: #f5f5f5;
            border-radius: 2px;
            display: flex;
            margin-bottom: 56px;
            > span {
              height: 40px;
              flex: 1;
              text-align: center;
              line-height: 40px;
              font-size: 16px;
              color: #8c8c8c;
              cursor: pointer;
              border-radius: 2px;
              transition: all 0.3s;
              &.active {
                background: #fff;
                color: #ea0b06;
              }
            }
          }
          .register-from-info {
            margin-bottom: 56px;
            > div {
              margin-bottom: 24px;
              &:last-child {
                margin-bottom: 0;
              }
            }
          }
          .register-btn {
            width: 100%;
            font-size: 14px;
            margin-bottom: 24px;
          }
        }
      }
      .register-success {
        text-align: center;
        > img {
          display: block;
          margin: 0 auto 40px;
        }
        > p {
          line-height: 19px;
          font-size: 20px;
          margin-bottom: 16px;
        }
        > span {
          display: block;
          line-height: 13px;
          font-size: 14px;
          color: #8c8c8c;
          margin-bottom: 56px;
        }
        > div {
          display: flex;
          > span {
            flex: 1;
            text-align: center;
            height: 40px;
            background: #ffffff;
            border: 1px solid #ea0b06;
            border-radius: 2px;
            cursor: pointer;
            color: #ea0b06;
            line-height: 40px;
            font-size: 14px;
            &:first-child {
              margin-right: 16px;
            }
          }
        }
      }
    }
    .register-footer-protocol {
      margin-top: 40px;
      > div {
        display: flex;
        justify-content: center;
        > span {
          line-height: 13px;
          font-size: 12px;
          color: #bfbfbf;
          margin-right: 12px;
          padding-right: 12px;
          border-right: 1px solid #bfbfbf;
          cursor: pointer;
          &:last-child {
            margin: 0;
            border: 0;
          }
        }
      }
      > p {
        line-height: 13px;
        font-size: 12px;
        color: #bfbfbf;
        margin-top: 16px;
        text-align: center;
      }
    }
  }
}
.num-code {
  display: flex;
  justify-content: space-between;
  > div {
    cursor: pointer;
  }
}
</style>
<style>
.register-protocol {
  display: flex;
  font-size: 12px;
  color: #595959;
}
.register-protocol > span {
  color: #ea0b06;
  cursor: pointer;
}
</style>
