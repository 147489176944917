<template>
  <div class="service-details">
    <div class="top"><span>服务市场 > </span><span>服务详情</span></div>
    <div class="details-body">
      <div class="details-box">
        <div class="details-con">
          <v-img
            :attachment-id="serviceInfo.serviceCover"
            :defaultImg="require('@/assets/img/kctp.png')"
          />

          <div>
            <div>
              <p>{{ serviceInfo.serviceName }}</p>
              <a-button class="btn-red" @click="focusProduct">
                {{ isFocus ? "已关注" : "关注" }}
              </a-button>
            </div>

            <p>{{ serviceInfo.summary }}</p>

            <div>
              <div v-if="serviceInfo.pricingType == 1">
                <span>服务价格：</span><span>￥</span><span>{{ price }}</span>
              </div>
              <div v-else>
                <span>服务价格：</span
                ><span style="font-size: 24px">议价定制</span>
              </div>
            </div>

            <div class="details-con-type">
              <div>
                <span>服务分类：</span
                ><span>{{ serviceInfo.serviceTypeName }}</span>
              </div>
              <div>
                <span>交付方式：</span
                ><span>{{
                  serviceInfo.deliverType | dict("DELIVER_TYPE")
                }}</span>
              </div>
            </div>

            <div class="product-type">
              <div v-if="serviceInfo && serviceInfo.pricingType == 1">
                <p class="title-text">套餐版本：</p>
                <div class="info-item">
                  <div
                    :class="{ active: serviceType === item }"
                    v-for="item in serviceInfo.comboVersion.split(',')"
                    :key="item.id"
                    @click="selectType(item)"
                  >
                    {{ item }}
                  </div>
                </div>
              </div>

              <div v-if="serviceInfo && serviceInfo.pricingType == 1">
                <p class="title-text">购买时长：</p>
                <div
                  class="data-item"
                  :class="{ active: serviceHour === item }"
                  v-for="item in serviceInfo.buyHour.split(',')"
                  :key="item.id"
                  @click="selectHour(item)"
                >
                  {{ item }}
                </div>
              </div>

              <div>
                <p class="title-text">购买方式：</p>
                <div class="active data-item">
                  {{ serviceInfo.buyType | dict("BUY_TYPE") }}
                </div>
              </div>
            </div>

            <a-button
              class="btn-red btn"
              @click="formShow = true"
              style="margin-left: 32px"
            >
              立即咨询
            </a-button>
          </div>
        </div>

        <div class="details-introduce">
          <div class="details-introduce-title">
            <p
              v-if="serviceInfo.productDetail"
              :class="{ active: tabIndex === 'service0' }"
              @click="setFloorClick('service0')"
            >
              产品详情
            </p>
            <p
              v-if="
                serviceInfo.userGuidesAttmId &&
                serviceInfo.userGuidesAttmId.length > 0
              "
              :class="{ active: tabIndex === 'service1' }"
              @click="setFloorClick('service1')"
            >
              使用指南
            </p>
            <p
              :class="{ active: tabIndex === 'service2' }"
              @click="setFloorClick('service2')"
            >
              交付区域
            </p>
            <p
              v-if="serviceInfo && serviceInfo.afterSalesSupport"
              :class="{ active: tabIndex === 'service3' }"
              @click="setFloorClick('service3')"
            >
              售后支持
            </p>
          </div>
          <div class="details-introduce-con">
            <p v-if="serviceInfo.productDetail" class="title" id="service0">
              产品详情
            </p>

            <div
              v-if="serviceInfo.productDetail"
              class="details"
              v-html="serviceInfo.productDetail"
            ></div>

            <p
              class="title"
              id="service1"
              v-if="
                serviceInfo.userGuidesAttmId &&
                serviceInfo.userGuidesAttmId.length > 0
              "
            >
              使用指南
            </p>

            <service-attachment
              v-for="el in serviceInfo.userGuidesAttmId"
              :key="el"
              :attachment-id="el"
            />

            <p class="title" id="service2">交付区域</p>

            <p class="city">{{ area.replace(/,/g, "、") }}</p>

            <p
              v-if="serviceInfo && serviceInfo.afterSalesSupport"
              class="title"
              id="service3"
            >
              售后支持
            </p>

            <div
              v-if="serviceInfo && serviceInfo.afterSalesSupport"
              class="info"
              v-html="serviceInfo.afterSalesSupport"
            ></div>
          </div>
        </div>
      </div>

      <div class="details-right">
        <div class="top">
          <div class="text">
            <span>服务商：</span><span>{{ serviceInfo.serviceIsvName }}</span>
          </div>

          <div class="text">
            <span>企业认证：</span
            ><span>{{ companyStatus[companyInfo.verifyStatus] }}</span>
          </div>

          <div class="com">{{ companyInfo.corpComment }}</div>

          <div class="button" v-if="companyInfo.id">
            <img src="@/assets/img/service_details2.png" alt="" />
            <span @click="toCompany(companyInfo.id)">企业详情</span>
          </div>
        </div>

        <div class="bottom">
          <div>相关推荐</div>
          <div class="bottom-item">
            <div
              v-for="item in serviceInfo.serServiceMarkets"
              :key="item.id"
              @click="toService(item.id)"
            >
              <v-img
                :attachment-id="item.serviceCover"
                :defaultImg="require('@/assets/img/kctp.png')"
              />
              <div>
                <p>{{ item.serviceName }}</p>
                <div v-if="item.pricingType == 1">
                  <span>￥</span><span>{{ item.lowestPrice }}</span
                  ><span>/{{ item.buyType | buyTypeDict("BUY_TYPE") }}</span>
                </div>
                <div v-else>
                  <span>议价定制</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <service-form
        :show="formShow"
        v-model="formShow"
        :name="serviceName"
        :serviceId="serviceInfo.id"
      ></service-form>
    </div>
  </div>
</template>

<script>
import { getAttachment } from "@/api/common";
import { downloadFile } from "@/api/cjkf/scene";
import VImg from "@/components/VImg";
import { mapActions, mapGetters } from "vuex";
import { getServiceInfo, getServiceIsvInfo } from "@/api/service";
import { getAddFocus, getIsFocus } from "@/api/collect";
import service_form from "@/page/service/components/service_form";
import serviceAttachment from "@/page/service/components/serviceAttachment";
export default {
  name: "service_details",
  components: {
    VImg,
    serviceAttachment,
    "service-form": service_form,
  },
  data() {
    return {
      companyStatus: {
        1: "未认证",
        2: "认证中",
        3: "已认证",
        4: "认证失败",
      },
      tabIndex: "service0",
      isFocus: false,
      serviceInfo: "",
      companyInfo: "",
      typeList: [], //套餐列表
      serviceType: "", //套餐版本
      serviceHour: "", //套餐时长
      price: "",
      area: "",
      info: "",
      serviceName: "",
      src: [],
      formShow: false,
    };
  },
  mounted() {
    this.LoadDictData(["DELIVER_TYPE"]);
    this.LoadDictData(["BUY_TYPE"]);
    this.loadGetServiceInfo(this.id);
    this.loadgetIsFocus();
  },
  computed: {
    ...mapGetters(["isLogin", "dict", "tenant"]),
    id() {
      return this.$route.query.id;
    },
  },

  methods: {
    ...mapActions(["LoadDictData"]),

    loadGetServiceInfo(id) {
      getServiceInfo(id).then((res) => {
        this.loadGetServiceIsvInfo(res.serviceIsvId);
        console.log(res, "res---------------");
        this.serviceInfo = res;

        if (this.serviceInfo.afterSalesSupport) {
          this.serviceInfo.afterSalesSupport = this.richText(
            this.serviceInfo.afterSalesSupport
          );
        }

        if (this.serviceInfo.productDetail) {
          this.serviceInfo.productDetail = this.richText(
            this.serviceInfo.productDetail
          );
        }

        this.area =
          res.deliverArea === "1" ? "全国范围" : res.deliverTenantName;

        if (this.serviceInfo.userGuidesAttmId.length > 0) {
          this.src = this.serviceInfo.userGuidesAttmId.map((item) => {
            return `${process.env.VUE_APP_API_PORTAL_URL}/file/download/${item}`;
          });
        }

        if (res.pricingConfigJson) {
          this.typeList = JSON.parse(res.pricingConfigJson);

          // 最小价格
          let data = this.typeList.filter((item) => {
            return item.price === res.lowestPrice;
          });

          this.serviceType = data[0].comboVersion;

          this.serviceHour = data[0].buyHour;

          this.price = data[0].price;
        }

        this.serviceName = res.serviceName;
      });
    },

    //富文本处理
    richText(text) {
      const regex = new RegExp("<img", "gi");

      return text.replace(
        regex,
        `<img style="display:block;max-width: 912px;margin: 0 auto"`
      );
    },

    loadGetServiceIsvInfo(id) {
      getServiceIsvInfo(id).then((res) => {
        this.companyInfo = res;
      });
    },

    // 选择版本
    selectType(item) {
      this.serviceType = item;

      let data = this.typeList.filter((item) => {
        return (
          item.buyHour === this.serviceHour &&
          item.comboVersion === this.serviceType
        );
      });

      this.price = data[0].price;
    },

    // 选择时长
    selectHour(item) {
      this.serviceHour = item;

      let data = this.typeList.filter((item) => {
        return (
          item.buyHour === this.serviceHour &&
          item.comboVersion === this.serviceType
        );
      });
      this.price = data[0].price;
    },

    toCompany(id) {
      this.$router.push({
        path: "/treasury/provider",
        query: { id },
      });
    },

    toService(id) {
      this.$router.push({
        path: "/service/details",
        query: { id },
      });
    },

    loadgetIsFocus() {
      getIsFocus(this.id).then((res) => {
        this.isFocus = res;
      });
    },

    // 关注
    focusProduct() {
      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }

      let { serviceInfo, isFocus } = this;

      let data = {
        recordId: serviceInfo.id,
        coverAttmId: serviceInfo.serviceCover,
        productName: serviceInfo.serviceName,
        focusType: "6",
      };

      if (isFocus) {
        let _this = this;
        this.$confirm({
          title: "您确定要取消收藏吗?",
          onOk() {
            getAddFocus(data).then((res) => {
              _this.$message.success("取消成功！");
              _this.loadgetIsFocus();
            });
          },
          onCancel() {},
          class: "test",
        });
      } else {
        getAddFocus(data).then(() => {
          this.$message.success("关注成功！");
          this.loadgetIsFocus();
        });
      }
    },

    setFloorClick(id) {
      this.tabIndex = id;
      document.getElementById(id).scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>

<style scoped lang="scss">
.service-details {
  width: 1280px;
  margin: 0 auto;
  padding-bottom: 56px;

  > .top {
    margin: 16px 0 15px 0;
    font-size: 14px;
    line-height: 25px;

    > :first-child {
      color: #8c8c8c;
    }

    > :last-child {
      color: #262626;
    }
  }
}

.details-body {
  display: flex;

  > .details-box {
    margin-right: 24px;
  }

  .details-con {
    width: 976px;
    min-height: 633px;
    display: flex;
    background: #ffffff;
    margin-bottom: 24px;

    > img {
      display: block;
      width: 120px;
      height: 120px;
      border: 1px solid #eeeeee;
      border-radius: 4px;
      margin-top: 31px;
      margin-left: 32px;
    }

    > div {
      padding: 29px 32px 32px;

      > :nth-child(1) {
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;

        > p {
          font-size: 18px;
          color: #262626;
          line-height: 25px;
        }
      }

      > p {
        font-size: 14px;
        color: #666666;
        line-height: 21px;
        margin-bottom: 16px;
      }

      > :nth-child(3) {
        width: 760px;
        height: 100px;
        background: url("../../assets/img/service_details1.png") no-repeat;

        > div {
          margin-left: 32px;
          padding-top: 27px;

          > :nth-child(1) {
            font-size: 14px;
            color: #666666;
            margin-right: 24px;
          }

          > :nth-child(2) {
            font-size: 14px;
            color: #ea0b06;
            margin-right: 6px;
          }

          :nth-child(3) {
            font-size: 24px;
            color: #ea0b06;
          }
        }
      }

      > .details-con-type {
        box-sizing: border-box;
        padding: 24px 0 24px 32px;
        border-bottom: solid 1px #f0f0f0;

        > :first-child {
          margin-bottom: 24px;
        }

        > div {
          line-height: 20px;

          > :first-child {
            font-size: 14px;
            color: #666666;
            margin-right: 8px;
          }

          > :last-child {
            font-size: 14px;
            color: #262626;
          }
        }
      }

      > .product-type {
        padding: 24px 0 24px 32px;

        > :nth-child(1) {
          margin-bottom: 18px;
        }

        > :nth-child(2) {
          margin-bottom: 35px;
        }

        > div {
          display: flex;
          > p {
            width: 70px;
            font-size: 14px;
            color: #666666;
            margin-right: 8px;
          }
          > .info-item {
            width: 650px;
            display: flex;
            flex-wrap: wrap;
            > div {
              padding: 7px 24px;
              border: 1px solid #999999;
              font-size: 14px;
              color: #262626;
              margin-right: 16px;
              cursor: pointer;
              margin-bottom: 10px;
            }
            > .active {
              border-color: #ea0b06;
              color: #ea0b06;
            }
          }
          .title-text {
            line-height: 37px;
          }
          .data-item {
            padding: 7px 24px;
            border: 1px solid #999999;
            font-size: 14px;
            color: #262626;
            margin-right: 16px;
            cursor: pointer;
          }

          .active {
            border-color: #ea0b06;
            color: #ea0b06;
          }

          .disable {
            border-color: #f0f0f0;
            cursor: not-allowed;
          }
        }
      }

      > .btn {
        width: 128px;
        height: 40px;
      }
    }
  }

  .details-introduce {
    width: 976px;
    background: #ffffff;

    > .details-introduce-title {
      height: 56px;
      display: flex;
      padding-left: 31px;
      border-bottom: 1px solid #f0f0f0;

      > p {
        box-sizing: border-box;
        font-size: 16px;
        color: #262626;
        line-height: 56px;
        margin-right: 40px;
        cursor: pointer;
      }

      > .active {
        color: #ea0b06;
        border-bottom: 2px solid #ea0b06;
      }
    }

    > .details-introduce-con {
      padding: 32px;

      > .title {
        font-weight: bold;
        font-size: 16px;
        color: #262626;
        line-height: 22px;
        margin-bottom: 16px;
      }

      > .details {
        margin-bottom: 32px;
        img {
          max-width: 912px;
        }
      }

      > .down {
        display: flex;
        margin-bottom: 32px;

        > img {
          margin-right: 10px;
        }

        > :nth-child(2) {
          font-size: 14px;
          color: #262626;
          line-height: 20px;
          margin-right: 24px;
        }

        > :nth-child(3) {
          font-size: 14px;
          color: #ea0b06;
          line-height: 20px;
          cursor: pointer;
        }
      }

      > .city {
        font-size: 14px;
        color: #262626;
        line-height: 20px;
        margin-bottom: 32px;
      }

      > .info {
        font-size: 14px;
        color: #262626;
        line-height: 25px;
        > p {
          > img {
            display: block;
            max-width: 912px;
          }
        }
      }
    }
  }

  > .details-right {
    width: 280px;

    > .top {
      background: #ffffff;
      padding: 32px 24px 0;

      > .text {
        font-size: 12px;
        margin-bottom: 12px;

        > :first-child {
          color: #8c8c8c;
        }

        > :last-child {
          color: #262626;
        }
      }

      > .com {
        font-size: 12px;
        color: #262626;
        line-height: 18px;
        padding-bottom: 25px;
      }

      > .button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 52px;
        border-top: 1px solid #f0f0f0;
        box-sizing: border-box;
        cursor: pointer;

        > img {
          margin-right: 6px;
        }

        > span {
          font-size: 14px;
          color: #ea0b06;
        }
      }
    }

    > .bottom {
      background: #ffffff;
      margin-top: 24px;

      > :first-child {
        height: 52px;
        padding-left: 16px;
        font-size: 16px;
        color: #262626;
        line-height: 52px;
        box-sizing: border-box;
        border-bottom: 1px solid #f0f0f0;
      }

      > .bottom-item {
        padding: 30px 16px;

        > :not(:last-child) {
          margin-bottom: 21px;
        }

        > div {
          display: flex;
          cursor: pointer;

          > img {
            display: block;
            width: 78px;
            height: 78px;
            border-radius: 4px;
            margin-right: 12px;
          }

          > div {
            > p {
              font-size: 14px;
              color: #666666;
              line-height: 21px;
              margin-bottom: 12px;

              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            > div {
              > :nth-child(1) {
                font-size: 14px;
                color: #ea0b06;
                line-height: 21px;
              }

              > :nth-child(2) {
                font-size: 20px;
                color: #ea0b06;
                line-height: 21px;
              }

              > :nth-child(3) {
                font-size: 12px;
                color: #666666;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
