<template>
  <div>
    <new-home v-if="tenant.homePageType === '1'"></new-home>
    <yunyuan-home v-if="tenant.homePageType === '2'"></yunyuan-home>
  </div>
</template>

<script>
import newHome from "@/page/newHome";
import yunyuanHome from "@/page/yunyuanHome";
import { mapGetters } from "vuex";
export default {
  name: "index",
  components: { newHome, yunyuanHome },
  computed: {
    ...mapGetters(["tenant"]),
  },
  created() {},
};
</script>

<style scoped></style>
