<template>
  <div>
    <home-banner />
    <recommend />
    <!--    <scenes/>-->
    <product />
    <result />
    <consult />
    <home-footer />
  </div>
</template>

<script>
import HomeBanner from "./HomeBanner.vue";
import recommend from "./recommend.vue";
import scenes from "./scenes.vue";
import product from "./product.vue";
import result from "./result.vue";
import consult from "./consult.vue";
import homeFooter from "./footer.vue";
export default {
  components: {
    HomeBanner,
    recommend,
    scenes,
    product,
    result,
    consult,
    homeFooter,
  },
};
</script>

<style></style>
