<template>
  <div class="home-develop-system-carousel">
    <div class="home-develop-system-title">
      <img alt="图片" :src="bg" />
      <div>
        <p>{{ title }}</p>
        <span>{{ desc }}</span>
      </div>
    </div>
    <div class="home-develop-system-carousel-box">
      <a-carousel arrows>
        <div
          slot="prevArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="left: 0; z-index: 1"
        >
          <span><a-icon type="left" /></span>
        </div>
        <div
          slot="nextArrow"
          slot-scope="props"
          class="custom-slick-arrow"
          style="right: 0"
        >
          <span><a-icon type="right" /></span>
        </div>
        <div
          class="home-develop-system-list"
          v-for="(i, ix) in stemPage"
          :key="ix"
        >
          <template v-for="(item, index) in systemData">
            <template v-if="index < (ix + 1) * 6 && index + 1 > ix * 6">
              <div :key="index">
                <p class="home-develop-system-list-label" v-if="item.marketing == 2"><span>推荐</span></p>
                <span class="home-develop-system-list-img">
                  <v-img :attachment-id="item.attmIds" :default-img="require('@/assets/img/kctp.png')" />
                </span>
                <p class="home-develop-system-list-title">{{item.name}}</p>
                <span class="home-develop-system-list-company">厂商：{{item.corpName}}</span>
                <div class="home-develop-system-list-tag" v-if="item.sceneTagName">
                  <template v-for="(e,i) in item.sceneTagName.split(',')">
                    <span v-if="i < 3" :key="i">{{e}}</span>
                  </template>
                </div>
                <p class="home-develop-system-info" @click="$router.push(`/replace/productInfo/dev?id=${item.id}`)">查看详情</p>
              </div>
            </template>
          </template>
        </div>
      </a-carousel>
    </div>
  </div>
</template>

<script>
import { getDevAlbumList } from "@/api/iot";
import VImg from "@/components/VImg";
export default {
  components:{
    VImg
  },
  props: ["title", "desc", "bg", "grade"],
  data() {
    return {
      systemData: [],
      stemPage: 1,
    };
  },
  mounted() {
    this.getDevAlbumList();
  },
  methods: {
    getDevAlbumList() {
      getDevAlbumList({ grade: this.grade }).then((res) => {
        this.systemData = res;
        this.loadsystemPage();
      });
    },
    loadsystemPage() {
      let num = 1;
      for (let index = 1; index < this.systemData.length; index++) {
        if (index % 6 == 0) {
          num++;
        }
      }
      this.stemPage = num;
    },
  },
};
</script>

<style lang="scss" scoped>
.home-develop-system-carousel {
  display: flex;
  .home-develop-system-title {
    width: 320px;
    height: 576px;
    color: #fff;
    position: relative;
    > img {
      display: block;
      width: 100%;
      height: 100%;
    }
    > div {
      padding: 40px 24px;
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: 2;
      > p {
        font-size: 30px;
        line-height: 42px;
        position: relative;
        margin-bottom: 36px;
        font-weight: 300;
        &::after {
          content: "";
          position: absolute;
          width: 60px;
          height: 4px;
          background: #ffffff;
          opacity: 0.5;
          left: 0;
          bottom: -12px;
        }
      }
      > span {
        font-size: 14px;
        font-weight: 300;
        text-align: justify;
      }
    }
  }
  .home-develop-system-carousel-box {
    width: 960px;
    .home-develop-system-list {
      display: flex !important;
      flex-wrap: wrap;
      height: 576px;
      overflow: hidden;
      background: #fff;
      > div {
        width: 320px;
        height: 288px;
        padding: 24px;
        position: relative;
        border-right: 1px solid #eee;
        border-bottom: 1px solid #eee;
        overflow: hidden;
        transition: all 0.3s;
        &:nth-child(3n) {
          border-right: 0;
        }
        .home-develop-system-list-label {
          background: url(../../../assets/img/devtj.png) no-repeat;
          background-size: 100% 100%;
          width: 46px;
          height: 46px;
          z-index: 2;
          position: absolute;
          top: 0;
          right: 0;
          > span {
            position: absolute;
            transform: rotate(45deg);
            font-size: 12px;
            color: #fff;
            top: 6px;
            left: 18px;
          }
        }
        .home-develop-system-list-img {
          width: 96px;
          height: 96px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-bottom: 20px;
          > img {
            max-width: 100%;
          }
        }
        .home-develop-system-list-title {
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          line-height: 16px;
          margin-bottom: 14px;
        }
        .home-develop-system-list-company {
          color: #8c8c8c;
          display: block;
          font-size: 12px;
          line-height: 12px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .home-develop-system-list-tag {
          display: flex;
          margin-top: 38px;
          flex-wrap: wrap;
          > span {
            height: 24px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            padding: 0 8px;
            line-height: 22px;
            font-size: 12px;
            margin-right: 4px;
            white-space: nowrap;
            margin-bottom: 4px;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        .home-develop-system-info {
          position: absolute;
          left: 0;
          right: 0;
          bottom: -40px;
          background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
          color: #fff;
          height: 40px;
          line-height: 40px;
          text-align: center;
          transition: all 0.3s;
          cursor: pointer;
        }
        &:hover {
          box-shadow: 0px 6px 24px 0px rgba(0, 0, 0, 0.12);
          > p {
            bottom: 0;
          }
        }
        &:nth-last-child(1),
        &:nth-last-child(2),
        &:nth-last-child(3) {
          border-bottom: 0;
        }
      }
    }
  }
}
</style>
<style>
.home-develop-system-carousel-box .ant-carousel,
.home-develop-system-carousel-box .ant-carousel .slick-slider,
.home-develop-system-carousel-box .ant-carousel .slick-list,
.home-develop-system-carousel-box .ant-carousel .slick-track {
  height: 100%;
}
.home-develop-system-carousel-box .ant-carousel .slick-list {
  padding-bottom: 68px;
}
.home-develop-system-carousel-box .ant-carousel .slick-prev,
.home-develop-system-carousel-box .ant-carousel .slick-next {
  width: auto;
  height: auto;
  background: rgba(000, 000, 000, 0.2);
  top: 39%;
}
.home-develop-system-carousel-box .ant-carousel .slick-prev > span,
.home-develop-system-carousel-box .ant-carousel .slick-next > span {
  width: 30px;
  height: 94px;
  background: rgba(000, 000, 000, 0.15);
  display: block;
  font-size: 16px;
  text-align: center;
  line-height: 94px;
  color: #fff;
}
.home-develop-system-carousel-box .ant-carousel .slick-dots-bottom {
  bottom: 40px;
}
.ant-carousel >>> .custom-slick-arrow {
  width: 25px;
  height: 25px;
  font-size: 25px;
  color: #fff;
  background-color: rgba(31, 45, 61, 0.11);
  opacity: 0.3;
}
.ant-carousel >>> .custom-slick-arrow:before {
  display: none;
}
.ant-carousel >>> .custom-slick-arrow:hover {
  opacity: 0.6;
}

.ant-carousel >>> .slick-slide h3 {
  color: #fff;
}
.home-develop-system-carousel-box
  .ant-carousel
  .slick-dots
  li.slick-active
  button {
  background: #EA0B06;
}
</style>
