<template>
  <div class="home-solution-advantage-list">
    <div v-for="item in val" :key="item.id">
      <v-img :attachment-id="item.attmIds" :default-img="require('@/assets/img/kaifa.png')" />
      <div>
        <p>{{ item.name }}</p>
        <span>{{ item.advDiscription }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  props: ["val"],
};
</script>

<style lang="scss" scoped>
.home-solution-advantage-list {
  display: flex;
  flex-wrap: wrap;
  > div {
    background: #fff;
    margin: 0 24px 24px 0;
    padding: 30px;
    display: flex;
    width: 628px;
    align-items: flex-start;
    > img {
      width: 48px;
      height: 48px;
      margin-right: 30px;
    }
    > div {
      flex: 1;
      > p {
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 10px;
      }
      > span {
        display: block;
        font-size: 16px;
        color: #8c8c8c;
        line-height: 22px;
      }
    }
    &:nth-child(2n) {
      margin-right: 0;
    }
  }
}
</style>