<template>
  <div>
    <YY-head></YY-head>
    <y-search :logo="yyLogo" :tabShow="true" @searchData="toSearch"></y-search>
    <y-y-menu></y-y-menu>
    <service-box></service-box>
    <provider-list></provider-list>
    <demand-list></demand-list>
    <information-box></information-box>
    <search-box></search-box>
  </div>
</template>

<script>
import YYhead from "./headBox";
import YSearch from "@/components/YSearch";
import yyLogo from "@/assets/img/yyLogo.png";
import YYMenu from "./menuBox";
import serviceBox from "@/page/yunyuanHome/serviceBox";
import providerList from "@/page/yunyuanHome/providerList";
import demandList from "@/page/yunyuanHome/demandList";
import informationBox from "@/page/yunyuanHome/informationBox";
import searchBox from "@/page/yunyuanHome/searchBox";
export default {
  name: "index",
  components: {
    "YY-head": YYhead,
    YSearch,
    YYMenu,
    serviceBox,
    providerList,
    demandList,
    informationBox,
    searchBox,
  },
  data() {
    return {
      yyLogo,
    };
  },
  methods: {
    toSearch(item, type) {
      if (type === "找服务") {
        this.$router.push({
          name: "service",
          params: { code: item.value },
        });
      }
      if (type === "找服务商") {
        this.$router.push({
          name: "treasury",
          params: { code: item.value },
        });
      }
      if (type === "找需求") {
        this.$router.push({
          name: "demand",
          params: { code: item.value },
        });
      }
    },
  },
};
</script>

<style scoped></style>
