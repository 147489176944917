import storage from "store";
import {
  ACCESS_TOKEN,
  REFRESH_TOKEN,
  SESSION_COMPANY_ID
} from "@/store/mutation-types";
import { getTokenByCode, getUserInfo, refreshToken } from "@/api/auth";
import { getJoinCompanyList } from "@/api/res-company";
import store from "@/store";

const user = {
  state: {
    access_token: "",
    refresh_token: "",
    permissions: [],
    info: null,
    company_id: "",
    company_list: []
  },
  mutations: {
    SET_ACCESS_TOKEN: (state, access_token) => {
      state.access_token = access_token;
    },
    SET_REFRESH_TOKEN: (state, refresh_token) => {
      state.refresh_token = refresh_token;
    },
    SET_INFO: (state, info) => {
      state.info = info;
    },
    SET_PERMISSIONS: (state, permissions) => {
      state.permissions = permissions;
    },
    SET_COMPANY_ID: (state, company_id) => {
      storage.set(SESSION_COMPANY_ID, company_id);
      state.company_id = company_id;
    },
    SET_COMPANY_LIST: (state, company_list) => {
      state.company_list = company_list;
    }
  },
  actions: {
    async CodeLogin({ commit }, data) {
      const res = await getTokenByCode({
        ...data,
        redirect_uri: store.getters.redirectUri
      });
      console.log(res);
      const accessToken = res.accessToken;
      const refreshToken = res.refreshToken;
      const expireIn = res.expireIn;
      const refreshTokenExpireIn = res.refreshTokenExpireIn;
      const tokenType = res.tokenType;
      storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000);
      storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000);
      commit("SET_ACCESS_TOKEN", accessToken);
      commit("SET_REFRESH_TOKEN", refreshToken);
      return accessToken;
    },
    // 登录
    RefreshToken({ commit }, refresh_token) {
      return refreshToken(refresh_token).then((res) => {
        const accessToken = res.accessToken;
        const refreshToken = res.refreshToken;
        const scope = res.scope;
        const id_token = res.id_token;
        const token_type = res.token_type;
        const expires_in = res.expires_in;
        storage.set(ACCESS_TOKEN, accessToken, 7 * 24 * 60 * 60 * 1000);
        storage.set(REFRESH_TOKEN, refreshToken, 7 * 24 * 60 * 60 * 1000);
        commit("SET_ACCESS_TOKEN", accessToken);
        commit("SET_REFRESH_TOKEN", refreshToken);
        return accessToken;
      });
    },
    // 获取用户信息
    async GetInfo({ commit }) {
      const userInfo = await getUserInfo();
      if (!userInfo) {
        commit("SET_INFO", null);
        commit("SET_PERMISSIONS", []);
        storage.remove(ACCESS_TOKEN);
        storage.remove(REFRESH_TOKEN);
        throw "您的账号已被删除";
      }
      commit("SET_INFO", userInfo);
      commit("SET_PERMISSIONS", ["user"]);
      return userInfo;
    },
    // 获取用户企业列表
    async UserCompany({ commit }) {
      const companyList = await getJoinCompanyList(["2"]);

      commit("SET_COMPANY_LIST", companyList);
      const companyId = storage.get(SESSION_COMPANY_ID);
      if (!companyList.length) {
        commit("SET_COMPANY_ID", null);
      } else if (companyList.map((e) => e.id).indexOf(companyId) === -1) {
        commit("SET_COMPANY_ID", companyList[0].id);
      } else {
        commit("SET_COMPANY_ID", companyId);
      }

      return companyList;
    },
    // 登出
    async RevokeToken({ commit }) {
      commit("SET_INFO", null);
      commit("SET_PERMISSIONS", []);
      storage.remove(ACCESS_TOKEN);
      storage.remove(REFRESH_TOKEN);
    }
  }
};

export default user;
