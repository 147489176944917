<template>
  <div class="activity-share" :class="`bg-${type}`">
    <div @mouseenter="connectShowBtn(true)"
         @mouseleave="connectShowBtn(false)">
      <div class="img-box"><img alt="图片" src="@/assets/img/fx1.png"/></div>
      <div v-if="shareShow">
        <span @click="shareToMicroblog()"><img alt="图片" src="@/assets/img/weibo.png"/></span>
        <span @click="shareToKj()"><img alt="图片" src="@/assets/img/kongjian.png"/></span>
        <span @click="shareToQQ()"><img alt="图片" src="@/assets/img/QQ.png"/></span>
        <a-popover placement="right" @mouseenter="showCode()"
        >
          <div class="wx-code-div" slot="content">
            <div id="qrcode" class="qrcode" ref="qrCodeUrl" style="width: 140px;height: 140px">
            </div>
          </div>
          <img alt="图片" class="wx-logo" type="primary" src="@/assets/img/weixin.png"/>
        </a-popover>
      </div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2"
import {getH5path} from "@/api/common"

let url = document.location; //获取当前网页地址
export default {
  props: ['name', 'val','type'],
  data() {
    return {
      shareShow: false,
      wxUrl: "",
      h5path:""
    }
  },
  mounted() {
    this.wxUrl = ""
    this.h5path = "";
    this.getWxH5path();
  },
  methods: {
    connectShowBtn(is) {
      this.shareShow = is;
    },
    getWxH5path() {
      getH5path().then(res => {
        this.h5path = res.url
      });
    },
    showCode() {
      setTimeout(() => {
        this.shareToWeixin()
      }, 300)
    },
    shareToWeixin() {
      /**
       鸿蒙方案
       /solution/info?id=1524556741960794113
       */
      if (this.val.id) {
        //课程
        this.wxUrl = this.h5path+"/solution/info?id=" + this.val.id;
        this.$nextTick(() => {
          document.getElementById("qrcode").innerHTML = "";
          new QRCode(this.$refs.qrCodeUrl, {
            text: this.wxUrl, // 需要转换为二维码的内容
            width: 140,
            height: 140,
            colorDark: '#000000',
            colorLight: '#ffffff',
            correctLevel: QRCode.CorrectLevel.H
          })
        })
      }
    },
    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.name);
      window.open(_shareUrl, "_blank");
    },
    shareToMicroblog() {
      var sharesinastring =
          "http://service.weibo.com/share/share.php?title=" +
          encodeURIComponent("「" + this.name + "」" + " 点这里" + url);
      window.open(sharesinastring, "_blank");
    },
    shareToKj() {
      window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(url)}&sharesource=qzone&title=${this.name}`)
    },
  },
}
</script>

<style lang="scss" scoped>
.bg-info{
  background: #fff;
}
.bg-dig{
  background: #f7f8fa;
}
.activity-share {
  position: absolute;
  left: -40px;
  top: 5.5px;
  border-radius: 20px;
  z-index: 10;
  .img-box {
    display: flex;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    box-shadow: 0px 1px 2px rgba(0,0,0,0.1);

  }

  > div {
    width: 40px;
    padding: 10px 0;
    transition: all .3s;

    > span {
      display: block;
      cursor: pointer;

      > img {
        display: block;
        margin: 0 auto;

        > div{
          > span{
            > img{
              width: 20px;
              height: 45px;
            }
          }
        }
      }
    }

    > div {
      > span {
        display: block;
        margin-top: 14px;
        cursor: pointer;

        > img {
          display: block;
          margin: 0 auto;
        }
      }
    }
  }
}

.wx-logo {
  display: block;
  margin: 0 auto;
  margin-top: 14px;
  cursor: pointer;
}


</style>
