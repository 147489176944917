<template>
  <div class="new-home-product-device">
    <div class="new-home-product-device-tag">
      <span
        :class="{ active: productType == 'soc' }"
        @click="productType = 'soc'"
        >SOC</span
      >
      <span
        :class="{ active: productType == 'dev' }"
        @click="productType = 'dev'"
        >鸿蒙开发板</span
      >
      <span
        :class="{ active: productType == 'iot' }"
        @click="productType = 'iot'"
        >IoT产品</span
      >
    </div>
    <div class="new-home-product-device-content">
      <div>
        <div v-for="(el, index) in devData" :key="index">
          <span>
            <v-img v-if="productType == 'soc'" :attachment-id="el.socImg" :default-img="require('@/assets/img/kctp.png')" />
            <v-img v-else-if="productType == 'dev'" :attachment-id="el.attmIds" :default-img="require('@/assets/img/kctp.png')" />
            <v-img v-else :attachment-id="el.productImg" :default-img="require('@/assets/img/kctp.png')" />
          </span>
          <div>
            <span>{{ el.name }}</span>
            <p v-if="productType != 'iot'">型号：{{ el.modelNum }}</p>
            <p v-if="productType == 'iot' && el.datIsv">厂商：{{ el.datIsv.name}}</p>
            <p v-else>厂商：{{ el.socCorpName ||  el.corpName}}</p>
            <div v-if="el.sceneTagName">
              <template v-for="(e, i) in el.sceneTagName.split(',')">
                <span v-if="i < 3" :key="i">{{ e }}</span>
              </template>
            </div>
            <a-button class="btn-red" @click="productInfo(el.id)">查看详情</a-button>
          </div>
        </div>
      </div>
      <p>
        <span @click="productAll">查看更多 <img src="@/assets/img/ckgdicon.png" alt="图片" /></span>
      </p>
    </div>
  </div>
</template>

<script>
import { getIotSocList, getHomeDev, getHomeProduct } from "@/api/iot";
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      devData: [],
      analyzeList: [],
      page: {
        current: 1,
        size: 9,
        pageSize: 1,
      },
      productType: "soc",
    };
  },
  watch: {
    productType: function (val) {
      this.loadDataList();
    },
  },
  mounted() {
    this.loadDataList();
  },
  methods: {
    // 列表
    loadDataList() {
      let { productType, page } = this;
      if (productType == "soc") {
        getIotSocList(page, {}).then((res) => {
          this.devData = res.records;
        });
      } else if (productType == "dev") {
        getHomeDev(page).then((res) => {
          console.log(res)
          this.devData = res.records;
        });
      } else {
        getHomeProduct(page, { status: "2" }).then((res) => {
          let data = res.records;
          data.forEach((element) => {
            element.discription = element.discription.replace(/●/g, "");
            element.discription = element.discription.replace(/1、/g, "");
            element.discription = element.discription.replace(/2、/g, "");
            element.discription = element.discription.replace(/3、/g, "");
          });
          this.devData = data;
        });
      }
    },
    productInfo(id){
      let type = this.productType
      if(type == 'iot'){
        this.$router.push({
            path: "/replace/info",
            query: { id },
        });
      }else{
        this.$router.push({
            path: `/replace/productInfo/${type}`,
            query: { id },
        });
      }
    },
    productAll(){
      let type = this.productType
      this.$router.push({
        name:'replace',
        params:{
          type
        }
      })
    }
  },
};
</script>

<style lang="scss" scoped>
.new-home-product-device {
  margin-bottom: 70px;
  .new-home-product-device-tag {
    border-bottom: 1px solid #e5e5e5;
    display: flex;
    justify-content: center;
    > span {
      height: 50px;
      font-size: 18px;
      font-weight: bold;
      line-height: 26px;
      color: #262626;
      width: 180px;
      text-align: center;
      border-bottom: 2px solid transparent;
      transition: all 0.3s;
      cursor: pointer;
      margin-right: 48px;
      &:last-child {
        margin-right: 0;
      }
      &.active {
        border-color: #ea0b06;
      }
    }
  }
  .new-home-product-device-content {
    width: 1280px;
    margin: 10px auto 0;
    > div {
      display: flex;
      flex-wrap: wrap;
      > div {
        transition: all 0.3s;
        width: 410px;
        height: 186px;
        background: #ffffff;
        margin-top: 25px;
        margin-right: 24px;
        display: flex;
        padding: 24px 16px 16px;
        cursor: pointer;
        overflow: hidden;
        &:nth-child(3n) {
          margin-right: 0;
        }
        > span {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 96px;
          height: 96px;
          margin-right: 24px;
          > img {
            display: block;
            max-width: 100%;
            max-height: 100%;
          }
        }
        > div {
          flex: 1;
          > span {
            width: 250px;
            display: block;
            font-size: 16px;
            line-height: 22px;
            color: #262626;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > p {
            font-size: 12px;
            line-height: 18px;
            color: #8c8c8c;
            margin-top: 8px;
            width: 250px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          > div {
            height: 32px;
            display: flex;
            margin-top: 32px;
            > span {
              padding: 0 8px;
              height: 24px;
              border: 1px solid #d1d8e4;
              border-radius: 2px;
              font-size: 12px;
              line-height: 22px;
              color: #62697d;
              margin-right: 6px;
              max-width: 70px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              &:last-child {
                margin-right: 0;
              }
            }
          }
          > button {
            margin-top: 32px;
            display: none;
            width: 208px;
          }
        }
        &:hover {
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
          > div {
            > div {
              display: none;
            }
            > button {
              display: block;
            }
          }
        }
      }
    }
    > p {
      display: flex;
      justify-content: center;
      margin-top: 36px;
      > span {
        display: flex;
        align-items: center;
        line-height: 22px;
        font-size: 16px;
        color: #ea0b06;
        cursor: pointer;
        > img {
          height: 12px;
          margin-left: 12px;
        }
      }
    }
  }
}
</style>
