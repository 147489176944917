<template>
  <div class="shi-xun-features">
    <h2>平台产品功能特性</h2>
    <p>以KaihongOS为底座的云-管-边-端一体化服务能力继承</p>
    <div class="shi-xun-swiper">
      <swiper ref="swiperThumbs" class="swiper" :options="swiperOptionThumbs">
        <swiper-slide v-for="(el, index) in featuresList" :key="index" class="features-swiper-list">
          <div class="features-swiper-info">
            <img :src="el.src" />
            <div>
              <p>{{ el.title }}</p>
              <span>{{ el.desc }}</span>
            </div>
          </div>
        </swiper-slide>
      </swiper>
      <div class="swiper-next">
        <a-icon type="right" />
      </div>
      <div class="swiper-prev">
        <a-icon type="left" />
      </div>
    </div>
    <div class="shi-xun-tag">
        <div>
            <span><img src="@/assets/img/featureskczy.png" /></span>
            <div>
                <span>丰富的培训课程资源</span>
                <p @click="()=> $router.push('/activity')">了解更多<a-icon type="right-circle" theme="filled"/></p>
            </div>
        </div>
        <div>
            <span><img src="@/assets/img/featuresjffw.png" /></span>
            <div>
                <span>可定制化国产物联网交付服务</span>
                <p @click="()=> $router.push('/gitIot')">了解更多<a-icon type="right-circle" theme="filled"/></p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    swiperOptionThumbs() {
      return {
        spaceBetween: 20,
        slidesPerView: 3,
        loop: true,
        navigation: {
          nextEl: ".swiper-next",
          prevEl: ".swiper-prev",
        },
      };
    },
  },
  data() {
    return {
      featuresList: [
        {
          title: "企业全场景应用创新",
          desc: "提供的一站式智能化设备实训教学，以产业智能化需求为导向，满足多行业全场景物联网应用开发学习和实战",
          src: require("@/assets/img/features1.png"),
        },
        {
          title: "开鸿智能边缘网关",
          desc: "搭载HarmonyOS系统通过软总线技术的统一协议标准，支持ZigBee、NB-IoT、BLE、RS485/232、Modbus、Opc等，灵活快速的实现跨平台设备的互联服务",
          src: require("@/assets/img/features2.png"),
        },
        {
          title: "真实物联网设备接入",
          desc: "系统采用的传感器和执行器全部为行业专用的真实设备，贴近实际业务场景应用，满足设备物联、数据采集分析和远程维护管理体验",
          src: require("@/assets/img/features3.png"),
        },
        {
          title: "可视化零/低代码开发",
          desc: "无缝集成设备属性、服务、事件等数据，提供了拖拉拽可视化应用开发、服务开发等一系列便携的物联网开发工具，学习高效经济地完成智能产品和服务开发",
          src: require("@/assets/img/features4.png"),
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
.shi-xun-features {
  padding: 64px 0;
  > h2 {
    font-size: 32px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.85);
    line-height: 45px;
    text-align: center;
    margin-bottom: 16px;
  }
  > p {
    font-size: 18px;
    color: rgba(0, 0, 0, 0.65);
    line-height: 28px;
    text-align: center;
    margin-bottom: 40px;
  }
  .shi-xun-swiper{
    position: relative;
    width: 1264px;
    margin: 0 auto;
    .features-swiper-info{
        position: relative;
        overflow: hidden;
        height: 208px;
        border: 1px solid #DDDDDD;
        >img{
            display: block;
            width: 100%;
            height: 100%;
            transition: ease .5s;
        }
        >div{
            position: absolute;
            z-index: 2;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 24px;
            >p{
                font-size: 16px;
                color: rgba(0,0,0,0.85);
                line-height: 22px;
                margin-bottom: 20px;
            }
            >span{
                display: block;
                width: 230px;
                font-size: 12px;
                color: rgba(0,0,0,0.65);
                line-height: 17px;
            }
        }
        &:hover{
            >img{
                transform: scale(1.1);
            }
        }
    }
    .swiper-next,
    .swiper-prev {
      position: absolute;
      top: 50%;
      margin-top: -30px;
      width: 48px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 4px 0px rgba(0,0,0,0.1);
      border-radius: 24px;
      color: #8B8F99;
      font-size: 14px;
      cursor: pointer;
      &:hover{
        >i{
          color: #EA0B06;
        }
      }
    }
    .swiper-prev {
      left: -60px;
    }
    .swiper-next {
      right: -60px;
    }
  }
  .shi-xun-tag{
    display: flex;
    width: 1264px;
    margin: 24px auto 0;
    >div{
        flex: 1;
        height: 160px;
        padding: 76px 32px 24px;
        &:first-child{
            background: url(../../assets/img/features5.jpg) no-repeat;
            background-size: 100% 100%;
        }
        &:last-child{
            margin-left: 24px;
            background: url(../../assets/img/features6.jpg) no-repeat;
            background-size: 100% 100%;
        }
        >span{
            display: flex;
            margin-bottom: 8px;
        }
        >div{
            display: flex;
            justify-content: space-between;
            align-items: center;
            color: #fff;
            >span{
                font-size: 18px;
                color: #FFFFFF;
                line-height: 25px;
            }
            >p{
                font-size: 12px;
                color: #FFFFFF;
                line-height: 16px;
                display: flex;
                align-items: center;
                cursor: pointer;
                >i{
                    margin-left: 4px;
                }
            }
        }
    }
  }
}
</style>
