<template>
  <div class="new-home-recommend">
    <strong>区域热门推荐</strong>
    <p>简单易用、精选、一站式区域热门组合推荐</p>
    <div class="new-home-recommend-content">
      <div class="new-home-recommend-tag">
        <div :class="{'active':recommendTagIndex == 1}" @mouseenter="recommendTagIndex = 1">
          <span></span>
          <div>
            <p>消费场景</p>
            <span>服务C端用户的消费驱动型应用场景</span>
          </div>
        </div>
        <div :class="{'active':recommendTagIndex == 2}" @mouseenter="recommendTagIndex = 2">
          <span></span>
          <div>
            <p>政府场景</p>
            <span>服务G端用户的政策驱动型应用场景</span>
          </div>
        </div>
        <div :class="{'active':recommendTagIndex == 3}" @mouseenter="recommendTagIndex = 3">
          <span></span>
          <div>
            <p>工业场景</p>
            <span>服务B端行业用户的生产驱动型应用场景</span>
          </div>
        </div>
      </div>
      <div class="new-home-recommend-info">
        <div :class="{'active':recommendTagIndex == 1}">
          <p>消费应用场景</p>
          <div>
            <div v-for="(el,index) in consumption" :key="index" @click="adUrl(el.adHref)">
              <v-img :attachment-id="el.adAttmId" :default-img="require('@/assets/img/banner7.jpg')" />
              <div>
                <p>{{el.title}}</p>
                <span>{{el.description}}</span>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'active':recommendTagIndex == 2}">
          <p>政府场景</p>
          <div>
            <div v-for="(el,index) in government" :key="index" @click="adUrl(el.adHref)">
              <v-img :attachment-id="el.adAttmId" :default-img="require('@/assets/img/banner7.jpg')" />
              <div>
                <p>{{el.title}}</p>
                <span>{{el.description}}</span>
              </div>
            </div>
          </div>
        </div>
        <div :class="{'active':recommendTagIndex == 3}">
          <p>工业场景</p>
          <div>
            <div v-for="(el,index) in industry" :key="index" @click="adUrl(el.adHref)">
              <v-img :attachment-id="el.adAttmId" :default-img="require('@/assets/img/banner7.jpg')" />
              <div>
                <p>{{el.title}}</p>
                <span>{{el.description}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAdvertiseList} from "@/api/advertise";
import VImg from "@/components/VImg";
export default {
  components:{
    VImg
  },
  data(){
    return{
      recommendTagIndex:1,
      consumption:[{
        img:require('@/assets/img/zhbg@2x@2x.png'),
        title:'智慧办公',
        desc:'基于开源鸿蒙智慧办公系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/yyyl.jpg'),
        title:'影音娱乐',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/lycx.jpg'),
        title:'旅游出行',
        desc:'基于开源鸿蒙智慧办公系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/znjj.png'),
        title:'智能家居',
        desc:'基于开源鸿蒙智慧办公系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/ydjk.png'),
        title:'运动健康',
        desc:'基于开源鸿蒙智慧办公系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      }],
      government:[{
        img:require('@/assets/img/tjr.png'),
        title:'金融',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjyy.png'),
        title:'医疗',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjny.png'),
        title:'能源',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjjy.png'),
        title:'教育',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjgjf.png'),
        title:'公检法',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjjt.png'),
        title:'交通',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      }],
      industry:[{
        img:require('@/assets/img/tjgyl.png'),
        title:'智慧供应链',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjzz.png'),
        title:'智能制造',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjzzzz.png'),
        title:'智能质检',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      },{
        img:require('@/assets/img/tjbh.png'),
        title:'环境保护',
        desc:'基于开源鸿蒙影音娱乐系统利用开源鸿蒙软硬件技术打通智慧办公系统、办公室…'
      }]
    }
  },
  mounted() {
    this.loadAdData()
  },
  methods:{
    loadAdData(){
      getAdvertiseList({adType:'HOME_CONSUMPTION_SCENE_ICON',validStatus: "1"}).then((list) => {
        console.log(list)
        this.consumption = list.records;
      });
      getAdvertiseList({adType:'HOME_GOVERNMENT_SCENE_ICON',validStatus: "1"}).then((list) => {
        console.log(list)
        this.government = list.records;
      });
      getAdvertiseList({adType:'HOME_INDUSTRY_SCENE_ICON',validStatus: "1"}).then((list) => {
        console.log(list)
        this.industry = list.records;
      });
    },
    adUrl(url){
      window.open(url,'_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
@keyframes cardFadeImg{0%{opacity:0}50%{opacity:0}to{opacity:1}}
.new-home-recommend{
    padding-top: 156px;
    padding-bottom: 70px;
    background: url(../../assets/img/tjbg@2x.png) no-repeat;
    background-size: 100% 100%;
    >strong{
        display: block;
        font-size: 32px;
        line-height: 45px;
        color: #262626;
        text-align: center;
        margin-bottom: 24px;
    }
    >p{
        font-size: 18px;
        line-height: 25px;
        color: #666;
        margin-bottom: 36px;
        text-align: center;
    }
    .new-home-recommend-content{
      width: 1280px;
      margin: 0 auto;
      display: flex;
      .new-home-recommend-tag{
        width: 284px;
        >div{
          height: 80px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #EFEFF2;
          position: relative;
          cursor: pointer;
          transition: height .5s;
          >span{
            background: url(../../assets/img/tjxz1@2x.png) no-repeat;
            background-size: auto 100%;
            height: 100%;
            z-index: 1;
            width: 100%;
            position: absolute;
            animation: cardFadeImg 1s ease;
            display: none;
          }
          >div{
            padding: 0 20px;
            position: relative;
            z-index: 2;
            >p{
              font-size: 18px;
              line-height: 25px;
              color: #262626;
            }
            >span{
              display: none;
              font-size: 14px;
              line-height: 20px;
              color: #666666;
              margin-top: 12px;
            }
          }
          &:last-child{
            border-bottom: 0;
          }
          &.active{
            height: 184px;
            border-bottom: 0;
            >span{
              display: block;
            }
            >div{
              >p{
                font-size: 24px;
                line-height: 32px;
                font-weight: bold;
                position: relative;
                &::after{
                  content: '';
                  position: absolute;
                  width: 4px;
                  height: 21px;
                  background: #EA0B06;
                  top: 6px;
                  left: -20px;
                }
              }
              >span{
                display: block;
              }
            }
          }
        }
      }
      .new-home-recommend-info{
        flex: 1;
        background: #fff;
        position: relative;
        >div{
          position: absolute;
          opacity: 0;
          transition: all .5s;
          width: 100%;
          &.active{
            opacity: 1;
            z-index: 2;
          }
          >p{
            line-height: 56px;
            font-size: 16px;
            font-weight: bold;
            color: #333333;
            padding: 0 24px;
            border-bottom: 1px solid #EFEFF2;
          }
          >div{
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            >div{
              width: 33.3%;
              cursor: pointer;
              border-bottom: 1px solid #EFEFF2;
              border-right: 1px solid #EFEFF2;
              padding: 24px;
              display: flex;
              height: 144px;
              &:nth-child(3n){
                border-right: 0;
              }
              &:nth-child(4),&:nth-child(5),&:nth-child(6){
                border-bottom:0
              }
              >img{
                display: block;
                width: 96px;
                height: 96px;
                margin-right: 16px;
              }
              >div{
                flex: 1;
                >p{
                  height: 22px;
                  font-size: 16px;
                  line-height: 22px;
                  color: #262626;
                  margin-bottom: 12px;
                }
                >span{
                  display: block;
                  font-size: 14px;
                  line-height: 20px;
                  color: #666666;
                  max-height: 60px;
                  overflow: hidden;
                }
              }
            }
          }
        }
      }
    }
}
</style>