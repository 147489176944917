<template>
  <div class="service">
    <service-banner
      :bigBanner="bigBanner"
      :smallUp="smallUp"
      :smallDown="smallDown"
    ></service-banner>

    <div class="service-filter">
      <div>
        <span>服务分类：</span>
        <div>
          <span
            v-for="item in serviceType"
            :key="item.id"
            :class="{ active: serviceTypeId === item.id }"
            @click="replaceSort(item.id)"
            >{{ item.name }}</span
          >
        </div>
      </div>

      <!--      <div>
              <span>二级分类：</span>
              <div>
              <span v-for="item in categorySubList" :key="item.id"
                    :class="{'active':categorySubId===item.id}"
                    @click="selectSubSort(item.id)">{{ item.name }}</span>
              </div>
            </div>-->

      <div>
        <span>交付方式：</span>
        <div style="height: 32px">
          <span
            v-for="item in categoryList"
            :key="item.id"
            :class="{ active: categoryDictId === item.itemValue }"
            @click="selectDictSort(item.itemValue)"
            >{{ item.itemText }}</span
          >
        </div>
      </div>

      <div>
        <span>服务价格：</span>
        <div>
          <span
            v-for="item in priceList"
            :key="item.id"
            :class="{ active: priceId === item.id }"
            @click="selectPrice(item.id)"
          >
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>

    <div class="service-select">
      <div class="service-select-slot">
        <span>共 {{ page.total }} 项服务</span>
        <p
          :class="{ active: sortKey === 'Default' }"
          @click="sortTab('Default')"
        >
          综合排序
        </p>
        <p
          :class="{ active: sortKey === 'PublishTime' }"
          @click="sortTab('PublishTime')"
        >
          热度
          <span>
            <a-icon
              :class="{
                active: sortKey === 'PublishTime' && sortType === 'asc',
              }"
              type="caret-up"
            />
            <a-icon
              :class="{
                active: sortKey === 'PublishTime' && sortType === 'desc',
              }"
              type="caret-down"
            />
          </span>
        </p>
        <p :class="{ active: sortKey === 'Price' }" @click="sortTab('Price')">
          价格
          <span>
            <a-icon
              :class="{
                active: sortKey === 'Price' && sortType === 'asc',
              }"
              type="caret-up"
            />
            <a-icon
              :class="{
                active: sortKey === 'Price' && sortType === 'desc',
              }"
              type="caret-down"
            />
          </span>
        </p>
      </div>

      <a-popover placement="bottomLeft" v-model="searchPopover">
        <template slot="content">
          <div class="home-nav-search-tag">
            <div>
              <p>历史搜索</p>
              <div>
                <span
                  :key="index"
                  @click="popularSearchBtn(item)"
                  v-for="(item, index) in searchData"
                  >{{ item }}</span
                >
              </div>
            </div>
            <div>
              <p>热门搜索</p>
              <div>
                <span @click="popularSearchBtn('医疗')">医疗</span>
                <span @click="popularSearchBtn('金融')">金融</span>
                <span @click="popularSearchBtn('教育')">教育</span>
              </div>
            </div>
          </div>
        </template>

        <a-input-search
          @blur="searchBlur"
          @focus="searchFocus"
          allowClear
          class="service-select-input"
          style="width: 360px"
          placeholder="搜索关键词"
          v-model="title"
          @search="searchScenes"
        >
          <a-button slot="enterButton">
            <img src="@/assets/img/search.png" alt="" />
          </a-button>
        </a-input-search>
      </a-popover>
    </div>

    <div class="service-list" :class="{ 'loading-style': loading }">
      <a-skeleton active :loading="loading" :paragraph="{ rows: 10 }">
        <div v-if="serviceList && serviceList.length > 0" class="service-list">
          <service-card
            class="service-card"
            v-for="item in serviceList"
            :key="item.id"
            @click.native="toDetails(item.id)"
            :val="item"
          >
          </service-card>
        </div>

        <div v-else style="padding: 100px 0; display: block">
          <a-empty />
        </div>
      </a-skeleton>
    </div>

    <div class="pageList">
      <pagination
        :size="page.size"
        :total="page.total"
        :current="page.current"
        @changes="pageChange"
      />
    </div>
  </div>
</template>

<script>
import service_banner from "@/page/service/components/service_banner";
import service_card from "@/page/service/components/service_card";
import pagination from "../components/pagination.vue";
import {
  getServiceLv1List,
  getServicePageList,
  getServiceLv2List,
} from "@/api/service";
import { mapActions, mapGetters } from "vuex";

export default {
  name: "service",
  components: {
    "service-banner": service_banner,
    "service-card": service_card,
    pagination,
  },
  data() {
    return {
      loading: true,
      searchPopover: false,
      title: "",
      searchData: [],
      bigBanner: [],
      smallUp: "",
      smallDown: "",
      sortKey: "Default",
      sortType: "",
      serviceTypeId: "",
      categorySubId: "",
      categoryDictId: "",
      priceId: "price",
      page: {
        current: 1,
        size: 12,
      },

      dataInfo: {
        serviceType: "",
        deliverType: "",
        orderField: "",
        orderAscOrDesc: "",
        pricingType: "",
        minPrice: "",
        maxPrice: "",
        keyWord: "",
      },

      serviceList: [],

      serviceType: [
        {
          id: "",
          name: "全部",
          pid: "",
        },
      ],

      //二级分类
      categorySubList: [
        {
          id: "",
          name: "全部",
          pid: "",
        },
      ],

      priceList: [
        {
          id: "price",
          name: "全部",
          price: "",
        },
        {
          id: "price0",
          name: "免费",
          price: "",
        },
        {
          id: "price1",
          name: "1-99",
          price: "",
        },
        {
          id: "price2",
          name: "100-9999",
          price: "",
        },
        {
          id: "price3",
          name: "10000以上",
          price: "",
        },
        {
          id: "price4",
          name: "议价定制",
          price: "",
        },
      ],
    };
  },

  computed: {
    ...mapGetters(["dict"]),
    categoryList() {
      let item = [
        {
          itemValue: "",
          itemText: "全部",
          pid: "",
        },
      ];
      if (this.dict.DELIVER_TYPE) {
        console.log(this.dict.DELIVER_TYPE, "dict");
        return [...item, ...this.dict.DELIVER_TYPE];
      } else {
        return item;
      }
    },
    code() {
      return this.$route.params.code;
    },
    l1Id() {
      return this.$route.params.l1Id;
    },
    l2Id() {
      return this.$route.params.l2Id;
    },
  },
  created() {},
  mounted() {
    /*this.title = this.code;
    this.dataInfo.keyWord = this.code;*/
    this.loadGetServiceLv1List();
    this.loadGetServiceLv2List();
    this.loadGetServicePageList();
    this.LoadDictData(["DELIVER_TYPE"]);
    this.LoadDictData(["BUY_TYPE"]);
    if (this.code) {
      this.title = this.code;
      this.searchScenes(this.title);
    }
    if (localStorage.getItem("serviceKey")) {
      this.searchData = JSON.parse(localStorage.getItem("serviceKey"));
    }
    if (this.l2Id) {
      this.serviceTypeId = this.l1Id;
      this.selectSubSort(this.l2Id);
    }
  },

  methods: {
    ...mapActions(["LoadDictData"]),

    //分页查询
    loadGetServicePageList() {
      this.loading = true;
      getServicePageList(this.page, this.dataInfo).then((res) => {
        this.loading = false;
        this.serviceList = res.records;
        this.page.total = res.total;
        this.dataInfo.keyWord = "";
      });
    },

    loadGetServiceLv1List() {
      getServiceLv1List().then((res) => {
        this.serviceType = [...this.serviceType, ...res];
      });
    },

    loadGetServiceLv2List(pid) {
      getServiceLv2List(pid).then((res) => {
        this.categorySubList = [
          {
            id: "",
            name: "全部",
            pid: "",
          },
        ];
        this.categorySubList = [...this.categorySubList, ...res];
      });
    },

    //服务分类选择
    replaceSort(id) {
      this.serviceTypeId = id;
      this.loadGetServiceLv2List(id);
      this.dataInfo.serviceType = id;
      (this.page = {
        current: 1,
        size: 12,
      }),
        this.loadGetServicePageList();
    },

    //交付方式选择
    selectDictSort(id) {
      this.categoryDictId = id;
      this.dataInfo.deliverType = id;
      (this.page = {
        current: 1,
        size: 12,
      }),
        this.loadGetServicePageList();
    },

    //二级分类选择
    selectSubSort(id) {
      this.categorySubId = id;
      this.dataInfo.serviceType = id;
      (this.page = {
        current: 1,
        size: 12,
      }),
        this.loadGetServicePageList();
    },

    //选择价格
    selectPrice(id) {
      this.priceId = id;

      switch (id) {
        case "price":
          this.dataInfo.pricingType = "";
          this.dataInfo.minPrice = "";
          this.dataInfo.maxPrice = "";
          break;
        case "price0":
          this.dataInfo.pricingType = "1";
          this.dataInfo.minPrice = 0;
          this.dataInfo.maxPrice = 1;
          break;
        case "price1":
          this.dataInfo.pricingType = "1";
          this.dataInfo.minPrice = 1;
          this.dataInfo.maxPrice = 99;
          break;
        case "price2":
          this.dataInfo.pricingType = "1";
          this.dataInfo.minPrice = 100;
          this.dataInfo.maxPrice = 9999;
          break;
        case "price3":
          this.dataInfo.pricingType = "1";
          this.dataInfo.minPrice = 10000;
          this.dataInfo.maxPrice = 1000000;
          break;
        case "price4":
          this.dataInfo.pricingType = "2";
          this.dataInfo.minPrice = null;
          this.dataInfo.maxPrice = null;
          break;
      }
      (this.page = {
        current: 1,
        size: 12,
      }),
        this.loadGetServicePageList();
    },

    //关键字搜索
    searchScenes(val) {
      let some = this.searchData.some((item) => {
        return item === val;
      });

      if (!some && val) {
        this.searchData.push(val);

        localStorage.setItem("serviceKey", JSON.stringify(this.searchData));
      }

      this.dataInfo.keyWord = val;
      this.page.current = 1;
      this.loadGetServicePageList();
    },

    popularSearchBtn(val) {
      this.title = val;

      this.dataInfo.keyWord = val;
      this.page.current = 1;
      this.loadGetServicePageList();
    },

    // 排序
    sortTab(sortKey) {
      this.sortKey = sortKey;
      if (sortKey === "Default") {
        this.sortKey = "Default";
        this.sortType = "";
        this.dataInfo.orderField = "";

        this.dataInfo.orderAscOrDesc = "";
      } else if (sortKey === "Price") {
        this.sortType = this.sortType === "desc" ? "asc" : "desc";

        this.dataInfo.orderField = "lowest_price";

        this.dataInfo.orderAscOrDesc = this.sortType;
      } else if (sortKey === "PublishTime") {
        this.sortType = this.sortType === "desc" ? "asc" : "desc";

        this.dataInfo.orderField = "viewed";

        this.dataInfo.orderAscOrDesc = this.sortType;
      }

      this.loadGetServicePageList();
    },

    // 分页
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetServicePageList();
    },

    searchFocus(e) {
      this.searchPopover = true;
    },

    searchBlur(e) {
      let _this = this;
      setTimeout(() => {
        _this.searchPopover = false;
      }, 300);
    },

    toDetails(id) {
      this.$router.push({
        path: "/service/details",
        query: { id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.service {
  width: 1280px;
  margin: 0 auto;
  padding: 24px 0 56px 0;
}

.service-filter {
  background: #fff;
  padding: 24px;
  margin-top: 24px;

  > div {
    display: flex;
    padding-bottom: 8px;
    border-bottom: 1px solid #f0f0f0;
    margin-bottom: 16px;

    > span {
      width: 70px;
      margin-right: 8px;
      line-height: 24px;
      white-space: nowrap;
    }

    > div {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      overflow: hidden;

      &.active {
        height: auto;
      }

      > span {
        cursor: pointer;
        line-height: 22px;
        margin-bottom: 8px;
        height: 24px;
        border: 1px solid transparent;
        border-radius: 2px;
        padding: 0 16px;
        text-align: center;
        margin-right: 8px;

        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }
      }
    }

    > p {
      width: 80px;
      height: 24px;
      background: #f5f5f5;
      border-radius: 2px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      margin-left: 8px;

      > i {
        color: #8c8c8c;
        margin-left: 2px;
        transition: all 0.3s;
      }
    }

    &:last-child {
      padding-bottom: 0;
      border: none;
      margin-bottom: 0;
    }
  }
}

.service-select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0;

  > .service-select-slot {
    display: flex;
    align-items: center;

    > span {
      color: #8c8c8c;
      line-height: 14px;
      margin-right: 16px;
      padding-right: 16px;
      border-right: 1px solid #f0f0f0;
    }

    > p {
      color: #595959;
      line-height: 14px;
      display: flex;
      align-items: center;
      margin-right: 24px;
      cursor: pointer;

      &.active {
        color: #ea0b06;
      }

      > span {
        margin-left: 6px;

        > i {
          display: block;
          font-size: 8px;
          color: #d9d9d9;

          &.active {
            color: #ea0b06;
          }
        }
      }
    }
  }
  > .service-select-input::v-deep {
    .ant-btn {
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #f5f5f5 !important;
      border-color: #d9d9d9 !important;
      color: #8c8c8c !important;

      > img {
        display: block;
      }
    }
  }
}

.service-list {
  .service-list {
    display: flex;
    flex-wrap: wrap;

    .service-card {
      margin: 0 23px 24px 0;
    }

    > :nth-child(3n) {
      margin: 0 0 24px 0;
    }
  }
}

.home-nav-search-tag {
  width: 308px;

  > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }

    > p {
      font-size: 14px;
      color: #8c8c8c;
      line-height: 12px;
      margin-bottom: 8px;
    }

    > div {
      display: flex;
      flex-wrap: wrap;

      > span {
        height: 32px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        padding: 0 16px;
        font-size: 12px;
        color: #262626;
        line-height: 30px;
        margin: 8px 8px 0 0;
        cursor: pointer;
      }
    }
  }
}

.pageList {
  margin-top: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #262626;
  line-height: 25px;

  > p {
    margin-right: 16px;
  }
}
</style>
