<template>
  <div>
    <a-modal
      v-model="visibleVal"
      title="我的方案"
      @cancel="seePlanCancel"
      width="800px"
    >
      <div>
        <div class="see-info-head">
          <span v-if="bidInfo.bidCompanyLogo">
            <v-img
              :attachment-id="bidInfo.bidCompanyLogo"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </span>
          <div v-else class="logoText">
            {{ bidInfo.bidCompanyName.slice(0, 4) }}
          </div>
          <div class="company-info">
            <strong>{{ bidInfo.bidCompanyName }}</strong>
            <p>
              <span><a-icon type="user" />{{ bidInfo.bidUserName }}</span>
              <span><a-icon type="mobile" />{{ bidInfo.bidContactPhone }}</span>
            </p>
          </div>
        </div>

        <div class="see-plan-info-body">
          <div class="see-info-content-tab">
            <span class="active">方案详情</span>
          </div>
          <div class="see-plan-date">
            <div>
              <p class="see-plan-title">预估成本</p>
              <span>{{
                bidInfo.bidPrice ? `￥${bidInfo.bidPrice}万元` : "--"
              }}</span>
            </div>
            <div>
              <p class="see-plan-title">提交时间</p>
              <span>{{ bidInfo.bidTime }}</span>
            </div>
          </div>
          <div class="see-plan-info">
            <p class="see-plan-title">方案说明</p>
            <span>{{ bidInfo.bidBrief }}</span>
          </div>
          <div class="see-plan-info">
            <p class="see-plan-title">相关附件</p>
            <div class="see-plan-annex">
              <v-attachment
                v-for="item in bidInfo.bidAttmIds"
                :key="item"
                :attachment-id="item"
              />
            </div>
          </div>
        </div>
      </div>
      <div slot="footer">
        <a-button class="btn-gray" @click="seePlanCancel">取 消</a-button>
        <a-button class="btn-red" @click="seePlanCancel">确 定</a-button>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { sceneMyBidInfo } from "@/api/cjkf/scn-scene";
import VAttachment from "@/components/VAttachment";
export default {
  components: { VAttachment },
  props: ["visible", "companyId"],
  data() {
    return {
      visibleVal: false,
      sceneId: "",
      bidInfo: "",
    };
  },
  watch: {
    visible: function (val) {
      this.visibleVal = val;
      if (val) {
        this.loadSceneMyBidInfo();
      }
    },
  },
  mounted() {
    this.visibleVal = this.visible;
    this.sceneId = this.$route.query.id;
  },
  methods: {
    seePlanCancel() {
      this.$emit("input", !this.visibleVal);
    },
    loadSceneMyBidInfo() {
      let obj = {};
      if (this.companyId) {
        obj.bidCompanyId = this.companyId;
      }
      sceneMyBidInfo(this.sceneId, obj).then((res) => {
        this.bidInfo = res;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.see-info-head {
  display: flex;
  margin-bottom: 24px;
  .logoText {
    width: 80px;
    height: 80px;
    background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
    border-radius: 4px;
    margin-right: 24px;
    color: #fff;
    font-size: 24px;
    padding: 7px 16px;
    line-height: 33px;
  }
  > span {
    width: 80px;
    height: 80px;
    margin-right: 24px;

    > img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  > .company-info {
    flex: 1;

    > strong {
      color: #262626;
      font-size: 20px;
      line-height: 20px;
      display: block;
      margin-bottom: 16px;
    }

    > p {
      display: flex;

      > span {
        display: flex;
        align-items: center;
        margin-right: 24px;
        color: #595959;
        line-height: 14px;

        > i {
          margin-right: 8px;
        }
      }
    }
  }
}

.see-info-content-tab {
  background: #fafafa;
  border-bottom: 1px solid #f0f0f0;
  display: flex;
  margin-bottom: 20px;

  > span {
    height: 48px;
    line-height: 48px;
    border-bottom: 2px solid transparent;
    margin-right: 40px;
    cursor: pointer;

    &.active {
      border-color: #ea0b06;
      color: #ea0b06;
    }
  }
}

.see-plan-info-body {
  .see-plan-title {
    line-height: 16px;
    color: #8c8c8c;
    margin-bottom: 24px;
    padding-left: 12px;
    border-left: 4px solid #ea0b06;
  }
  .see-plan-date {
    display: flex;
    margin-bottom: 40px;
    > div {
      flex: 1;
      > span {
        display: block;
        line-height: 20px;
        color: #262626;
      }
    }
  }
  .see-plan-info {
    margin-bottom: 40px;
    > span {
      line-height: 24px;
      color: #262626;
    }
    .see-plan-annex {
      > div {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        padding: 0 16px;
        margin-bottom: 16px;
        cursor: pointer;
        &:last-child {
          margin-bottom: 0;
        }
        > div {
          display: flex;
          align-items: center;
          > img {
            margin-right: 8px;
          }
          > p {
            max-width: 400px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        > span {
          color: #ea0b06;
          cursor: pointer;
          display: none;
        }
        &:hover {
          background: #f5f5f5;
          > span {
            display: block;
          }
        }
      }
    }
  }
}
</style>
