<template>
  <div class="activity-detail-body">
    <div class="activity-detail-head-body">
      <div>
        <div class="home-scenes-head-info">
          <span>
            <v-img :attachment-id="sceneInfo.coverAttmId" />
          </span>
          <div>
            <strong>
              {{ sceneInfo.title }}
              <span class="grey" v-if="overdue"> <i></i>已过期 </span>
              <span
                :class="[
                  { 6: 'green', 7: 'red', 8: 'blue' }[sceneInfo.sceneStatus] ||
                    'green',
                ]"
              >
                <i></i>
                {{ sceneInfo.sceneStatus | dict("SCENE_STATUS") }}
              </span>
            </strong>
            <p>
              <span>{{ sceneInfo.field | dict("SCENE_FIELD") }}</span>
              <span>{{ sceneInfo.industry | dict("SCENE_INDUSTRY") }}</span>
              <span v-if="sceneInfo.skill">{{
                sceneInfo.skill | dict("SCENE_SKILL")
              }}</span>
            </p>
            <div>
              <p>榜单编号：{{ sceneInfo.id }}</p>
              <p :title="sceneInfo.companyName">
                发布单位：{{ sceneInfo.companyName }}
              </p>
              <p :title="sceneInfo.associate">
                联合申报单位：{{ sceneInfo.associate }}
              </p>
              <p>预估启动建设年月：{{ sceneInfo.expectedStartDate }}</p>
              <p :title="sceneInfo.sceneAddress">
                场景地址：{{ sceneInfo.sceneAddress }}
              </p>
              <p>申请截止日期：{{ sceneInfo.applyDueDate }}</p>
            </div>
          </div>
        </div>
        <div class="home-scenes-head-price">
          <img src="@/assets/img/yzb.png" v-if="bidding" />
          <img src="@/assets/img/wzb.png" v-if="!bidding && noBidding" />
        </div>
      </div>
    </div>
    <div class="activity-detail-content-body">
      <div class="activity-detail-content-left-body">
        <div class="activity-detail-content-tab">
          <span
            :class="{ active: activityTabIndex == 0 }"
            @click="activityTabIndex = 0"
            >需求详情</span
          >
          <a-badge
            :class="{ active: activityTabIndex == 2 }"
            :count="1"
            :offset="[5, 15]"
            @click="activityTabIndex = 2"
            dot
            v-if="userInfo && userInfo.id === sceneInfo.appliedBy"
          >
            <span>揭榜记录({{ sceneInfo.bidCount }})</span>
          </a-badge>
        </div>
        <div class="activity-detail-content">
          <detail-content :info="sceneInfo" v-if="activityTabIndex == 0" />
          <enterprise-info v-if="activityTabIndex == 1" />
          <jiebanglist
            :id="sceneInfo.id"
            :status="sceneInfo.sceneStatus"
            @choose-bid="chooseBid"
            v-if="activityTabIndex == 2"
            v-model="sceneInfo.bidCount"
          />
        </div>
      </div>
      <div class="activity-detail-content-right-body">
        <!-- 我要揭榜 -->
        <a-button
          class="btn-red"
          v-if="
            !userInfo || (userInfo.id !== sceneInfo.appliedBy && !bidingShow)
          "
          :disabled="sceneInfo.sceneStatus == '8' && everyApply"
          @click="jiebangBtn"
          >我要揭榜
        </a-button>

        <div class="activity-detail-fx">
          <a-popover
            overlay-class-name="jb-contact-person-popover"
            placement="bottom"
            trigger="click"
          >
            <template slot="content">
              <div class="jb-contact-person-info-body">
                <p>需求联系人</p>
                <div v-if="userInfo && applyBid" class="jb-contact-person-info">
                  <p>
                    <span><img src="@/assets/img/yonghu.png" /></span>
                    {{ sceneInfo.contactPerson }}
                  </p>
                  <p>
                    <span><img src="@/assets/img/shouji.png" /></span>
                    {{ sceneInfo.contactPhone }}
                  </p>
                </div>
                <div v-else class="jb-contact-person-mask">
                  <p>查看联系人详细信息<br />请先揭榜</p>
                </div>
              </div>
            </template>
            <span class="jb-contact-person"
              ><img src="@/assets/img/yonghu.png"
            /></span>
          </a-popover>
          <p>
            分享至：
            <span @click="share('qzone')"
              ><img src="@/assets/img/fxkj.png"
            /></span>
            <span @click="share('qq')"
              ><img src="@/assets/img/fxqq.png"
            /></span>
            <span @click="share('db')"><img src="@/assets/img/db.png" /></span>
          </p>
        </div>
        <span @click="noUrl"></span>
        <div class="activity-detail-more">
          <div class="more-dynamic-title">
            <p>相似需求</p>
            <!-- <span>更多 ></span> -->
          </div>
          <div class="more-dynamic-list">
            <div
              :key="item.id"
              @click="similarScene(item.id)"
              v-for="item in similarSceneList"
            >
              <div>
                <p>{{ item.title }}</p>
                <!--                <span>￥{{ item.fundAmount }}万</span>-->
              </div>
              <span>{{ item.ownerName }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 我要揭榜 -->

    <company-list
      v-model="jiebangShow"
      @submit="sceneBid"
      :companyList="companyList"
      :sceneStatus="sceneInfo.sceneStatus"
    >
    </company-list>
  </div>
</template>

<script>
import detailContent from "./components/detailContent.vue";
import enterpriseInfo from "./components/enterpriseInfo.vue";
import jiebanglist from "./components/jiebanglist.vue";
import companyList from "@/page/homeScenes/components/companyList";

import { sceneDetail, similarSceneList } from "@/api/cjkf/scn-scene";
import { mapActions, mapGetters } from "vuex";
import VImg from "@/components/VImg";
import { getjeibangCompanyList } from "@/api/res-company";
// import { SCENE_STATUS_MAP } from "@/store/constant";

export default {
  components: {
    VImg,
    detailContent,
    enterpriseInfo,

    jiebanglist,
    companyList,
  },
  data() {
    return {
      activityTabIndex: 0,
      contactPersonShow: false,
      jiebangShow: false,
      sceneId: "",
      sceneInfo: {},
      similarSceneList: "",

      shared: this.$route.query.shared,
      companyList: [],
      bidding: false, //中标
      noBidding: false,
      applyBid: false, //待揭榜
      overdue: false, //过期
      everyApply: false, //全部待揭榜
    };
  },
  computed: {
    ...mapGetters(["userInfo", "companyId", "companyInfo"]),
    bidingShow() {
      return this.sceneInfo.sceneStatus == "8" && this.everyApply;
    },
  },
  mounted() {
    this.sceneId = this.$route.query.id;
    this.loadSceneDetail();
    this.loadSimilarSceneList();
    if (this.userInfo) {
      this.loadGetCompanyList();
    }
    this.LoadDictData(["SCENE_SKILL", "SCENE_INDUSTRY", "SCENE_FIELD"]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadGetCompanyList() {
      getjeibangCompanyList(this.sceneId).then((res) => {
        this.companyList = res;
        this.applyBid = this.companyList.some((el) => {
          return el.statusForCurrentUser !== "SCN_STATUS_FOR_BIDDER_01";
        });

        this.everyApply = this.companyList.every((el) => {
          return el.statusForCurrentUser === "SCN_STATUS_FOR_BIDDER_01";
        });

        if (!this.applyBid) {
          this.overdue = this.compare(this.sceneInfo.applyDueDate);
        }

        this.bidding = this.companyList.some((el) => {
          return el.statusForCurrentUser === "SCN_STATUS_FOR_BIDDER_03";
        });

        this.noBidding = this.companyList.some((el) => {
          return el.statusForCurrentUser === "SCN_STATUS_FOR_BIDDER_04";
        });
      });
    },

    compare(time) {
      const nowDate = new Date();
      const newDate = new Date(time);
      return nowDate > newDate;
    },

    noUrl() {
      this.$message.warning("暂无跳转链接！");
    },
    // 详情
    async loadSceneDetail() {
      let obj = {};
      this.sceneInfo = await sceneDetail(this.sceneId, obj);
    },
    async loadSimilarSceneList() {
      const { records } = await similarSceneList(this.sceneId, {
        current: 1,
        size: 5,
      });
      this.similarSceneList = records;
    },
    similarScene(id) {
      this.sceneId = id;
      this.loadSceneDetail();
    },
    sceneBid(val) {
      if (val) {
        this.loadSceneDetail();
        this.loadGetCompanyList();
      }
    },
    share(type) {
      let sceneInfo = this.sceneInfo;
      let url =
        window.location.origin +
        window.location.pathname +
        `?id=${this.sceneId}&shared=${type}`;
      //qq空间分享接口
      if (type === "qzone") {
        window.open(
          `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}`
        );
      }
      //qq好友接口的传参
      if (type === "qq") {
        window.open(
          `http://connect.qq.com/widget/shareqq/index.html?url=` +
            encodeURIComponent(window.location.href) +
            `?sharesource=qzone&title=${sceneInfo.title}&pics=图片地址&summary=${sceneInfo.description}`
        );
      }
      if (type === "db") {
        window.open(
          `http://shuo.douban.com/!service/share?href=` +
            encodeURIComponent(window.location.href) +
            `&name=${sceneInfo.title}&text=${sceneInfo.description}&image=图片地址&starid=0&aid=0&style=11`
        );
      }
    },
    //   w我要揭榜
    jiebangBtn() {
      if (!this.userInfo) {
        this.$message.warning("您还未登录！");
        return;
      }

      if (this.companyList.length == 0) {
        this.$message.warning("您必须是企业所有者且企业认证通过！");
        return;
      }

      this.jiebangShow = !this.jiebangShow;
    },

    chooseBid() {
      this.loadSceneDetail();
    },
  },
};
</script>

<style lang="scss" scoped>
.activity-detail-head-body {
  background: #fff;

  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;

    .home-scenes-head-info {
      display: flex;
      width: 85%;

      > span {
        width: 210px;
        height: 120px;
        margin-right: 24px;

        > img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }

      > div {
        flex: 1;

        > strong {
          display: flex;
          align-items: center;
          font-size: 20px;
          line-height: 20px;
          margin-bottom: 16px;

          > span {
            height: 24px;
            border-radius: 2px;
            padding: 0 8px;
            color: #fff;
            font-size: 12px;
            display: flex;
            align-items: center;
            margin-left: 16px;
            font-weight: 400;

            > i {
              width: 6px;
              height: 6px;
              background: #ffffff;
              border-radius: 50%;
              margin-right: 8px;
            }

            &.green {
              background: #52c41a;
            }

            &.grey {
              background: rgb(140, 140, 140);
            }

            &.red {
              background: #ff4d4f;
            }

            &.blue {
              background: #1890ff;
            }
          }
        }

        > p {
          display: flex;

          > span {
            height: 22px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            line-height: 20px;
            padding: 0 8px;
            font-size: 12px;
            margin-right: 8px;
          }
        }

        > div {
          width: 100%;
          display: flex;
          flex-wrap: wrap;

          > p {
            width: 33.3%;
            line-height: 14px;
            color: #8c8c8c;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 16px;

            > span {
              color: #ea0b06;
            }
          }
        }
      }
    }

    .home-scenes-head-price {
      position: relative;

      > img {
        position: absolute;
        top: 0;
        left: -160px;
      }

      > p {
        display: flex;
        align-items: flex-end;
        font-size: 30px;
        line-height: 30px;
        color: #ea0b06;
        margin-bottom: 12px;

        > span {
          font-size: 16px;
          line-height: 16px;
        }
      }

      > span {
        font-size: 12px;
        line-height: 12px;
        display: block;
        text-align: right;
        color: #8c8c8c;
      }
    }
  }
}

.activity-detail-content-body {
  display: flex;
  width: 1200px;
  margin: 24px auto 0;
  padding-bottom: 40px;

  .activity-detail-content-left-body {
    background: #fff;
    flex: 1;

    .activity-detail-content-tab {
      display: flex;
      padding: 0 24px;
      border-bottom: 1px solid #f0f0f0;

      > span {
        height: 48px;
        margin-right: 40px;
        line-height: 48px;
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
          border-color: #ea0b06;
          color: #ea0b06;
        }
      }
    }

    .activity-detail-content {
      padding: 24px;
    }
  }

  .activity-detail-content-right-body {
    width: 280px;
    margin-left: 24px;

    > button {
      width: 100%;
      height: 48px;
      border-radius: 2px;
      margin-bottom: 30px;
      color: #fff;
    }

    .upload-detail-btn {
      width: 100%;
      height: 48px;
      background: #ea0b06 !important;
      border-color: #ea0b06 !important;
      border-radius: 2px;
      margin-bottom: 30px;
      color: #fff;
    }

    .activity-detail-fx {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      > span {
        font-size: 12px;
        color: #8c8c8c;
      }

      .jb-contact-person {
        width: 40px;
        height: 40px;
        background: #ffffff;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }

      > p {
        display: flex;
        align-items: center;

        > span {
          margin-left: 6px;
          cursor: pointer;

          > img {
            display: block;
          }
        }
      }
    }

    > span {
      display: block;
      cursor: pointer;
      margin-bottom: 24px;

      > img {
        display: block;
      }
    }

    .activity-detail-more {
      background: #fff;

      .more-dynamic-title {
        height: 48px;
        padding: 0 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #f0f0f0;

        > p {
          font-size: 16px;
        }

        > span {
          font-size: 12px;
          color: #8c8c8c;
        }
      }

      .more-dynamic-list {
        padding: 0 16px;

        > div {
          cursor: pointer;
          border-bottom: 1px solid #f0f0f0;
          padding: 16px 0;

          > div {
            display: flex;
            justify-content: space-between;

            > p {
              max-width: 70%;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              line-height: 14px;
              margin-bottom: 16px;
            }

            > span {
              color: #ea0b06;
              line-height: 14px;
            }
          }

          > span {
            margin-right: 16px;
            font-size: 12px;
            line-height: 12px;
            color: #8c8c8c;
            display: flex;
            align-items: center;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.activity-detail-head-fixed-body {
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  background: #fff;
  box-shadow: 0 3px 12px 0 rgba(0, 0, 0, 0.08);

  > div {
    width: 1200px;
    margin: 0 auto;
    padding: 12px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    > strong {
      font-size: 20px;
      line-height: 20px;
    }

    > p {
      display: flex;
      align-items: center;

      > span {
        font-size: 12px;
        color: #8c8c8c;
        display: flex;
        align-items: center;
        margin-right: 24px;

        > i {
          margin-right: 8px;
        }
      }

      > button {
        width: 280px;
        height: 40px;
        background: #ea0b06 !important;
        border-color: #ea0b06 !important;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
}

.jb-contact-person-info-body {
  height: 160px;
  width: 240px;
  position: relative;
  padding: 0 24px 24px 24px;

  > p {
    width: 120px;
    height: 32px;
    text-align: center;
    line-height: 32px;
    background: url(../../assets/img/jbbj.png) no-repeat;
    background-size: 100% 100%;
    color: #fff;
    margin: 0 auto 24px;
  }

  .jb-contact-person-info {
    > p {
      display: flex;
      align-items: center;
      margin-bottom: 16px;

      &:last-child {
        margin-bottom: 0;
      }

      > span {
        width: 32px;
        height: 32px;
        background: #f0f8ff;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 16px;

        > img {
          max-width: 100%;
        }
      }
    }
  }

  .jb-contact-person-mask {
    position: absolute;
    top: 32px;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 2;
    background: rgba(255, 255, 255, 0.7);
    padding-top: 22px;

    > p {
      line-height: 22px;
      text-align: center;
      margin-bottom: 14px;
    }

    > span {
      display: block;
      width: 80px;
      height: 32px;
      text-align: center;
      line-height: 30px;
      border: 1px solid #ea0b06;
      border-radius: 2px;
      margin: 0 auto;
      color: #ea0b06;
      cursor: pointer;
    }
  }
}
</style>
<style>
.jb-contact-person-popover .ant-popover-inner-content {
  padding: 0;
}

.before-content-body {
  background: #f7f7f7;
}

.activity-detail-content-right-body .ant-upload {
  display: block;
}

.btn-disabled {
  color: #ffffff !important;
  background: #cccccc !important;
  border-color: #cccccc !important;
}

.btn-disabled:hover,
.btn-red:focus,
.btn-red:active,
.btn-red.active {
  color: #ffffff !important;
  border-color: #cccccc !important;
}
</style>
