import { PortalRequest as request } from "@/utils/request";

// 1级分类
export const getServiceLv1List = () => {
    return request({
        url: `/ser-service-type/lv1-type-list`,
        method: "get",
    });
};

// 2级分类
export const getServiceLv2List = (pid) => {
    return request({
        url: `/ser-service-type/lv2-type-list`,
        method: "get",
        params: {
            pid:pid,
        },
    });
};





//服务市场分页查询
export const getServicePageList = (params,data) => {
    return request({
        url: `/ser-service-market/page-list`,
        method: "post",
        params: params,
        data,
    });
};


// 服务市场详情
export const getServiceInfo = (id) => {
    return request({
        url: `/ser-service-market/info/${id}`,
        method: "get",
    });
};


// 服务商详情
export const getServiceIsvInfo = (id) => {
    return request({
        url: `/ser-service-market/isv-info/${id}`,
        method: "get",
    });
};


// 获取服务提供商
export const getServiceIsvList = (id) => {
    return request({
        url: `/ser-service-market/isv-list`,
        method: "get",
    });
};
