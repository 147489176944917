<template>
  <div class="shi-xun-scene-box">
    <div>
        <h2>项目实践与应用场景</h2>
        <p>面向大、小家电和运动健康行业，覆盖上百个典型场景实践</p>
        <div class="shi-xun-scene-experience">
            <div>
                <img src="@/assets/img/sceneicon1.png"/>
                <div>
                    <h4>无硬件虚拟体验</h4>
                    <p>完成账号<span>注册认证</span>后，可进入平台创建虚拟设备，进入实训平台体验</p>
                    <span @click="() => $router.push('/login')">立即体验<a-icon type="right" /></span>
                </div>
            </div>
            <div>
                <img src="@/assets/img/sceneicon2.png"/>
                <div>
                    <h4>有硬件开发体验</h4>
                    <p>完成账号<span>注册认证</span>后，可进入平台接入自有硬件设备，进入实训平台体验；我们还提供了开鸿<span>开发板免费申请</span>使用福利，均可在实训平台快速体验</p>
                    <span @click="() => $router.push('/login')">立即体验<a-icon type="right" /></span>
                </div>
            </div>
        </div>
        <div class="shi-xun-scene-process">
            <p>开发流程</p>
            <div>
                <div>
                    <span><img src="@/assets/img/scenelc1.png"/></span>
                    <p>创建项目和实例</p>
                    <img src="@/assets/img/sceneicon3.png"/>
                </div>
                <div>
                    <span><img src="@/assets/img/scenelc2.png"/></span>
                    <p>接入产品和设备</p>
                    <img src="@/assets/img/sceneicon3.png"/>
                </div>
                <div>
                    <span><img src="@/assets/img/scenelc3.png"/></span>
                    <p>模拟设备数据</p>
                    <img src="@/assets/img/sceneicon3.png"/>
                </div>
                <div>
                    <span><img src="@/assets/img/scenelc4.png"/></span>
                    <p>IoT应用开发</p>
                    <img src="@/assets/img/sceneicon3.png"/>
                </div>
                <div>
                    <span><img src="@/assets/img/scenelc5.png"/></span>
                    <p>项目应用发布</p>
                </div>
            </div>
        </div>
    </div>
    <div>
        <h2>智能产品生命周期全场景</h2>
        <p>在产品迈向智能化的迫切需求下，企业急需对研发制造、营销、运营服务等环节，基于创新技术引入，实现全场景的产品全生命周期管理</p>
        <div class="shi-xun-scene-product">
            <span :class="{'active':productTag == 1}" @click="productTag = 1">产品连接管理</span>
            <span :class="{'active':productTag == 2}" @click="productTag = 2">产品制造</span>
            <span :class="{'active':productTag == 3}" @click="productTag = 3">产品营销</span>
            <span :class="{'active':productTag == 4}" @click="productTag = 4">产品创新运营</span>
        </div>
        <div class="shi-xun-scene-product-content">
            <img src="@/assets/img/cpljgl.png" v-if="productTag == 1"/>
            <img src="@/assets/img/cpzz.png" v-else-if="productTag == 2"/>
            <img src="@/assets/img/cpyx.png" v-else-if="productTag == 3"/>
            <img src="@/assets/img/cxyy.png" v-else-if="productTag == 4"/>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return{
            productTag:1
        }
    }
}
</script>

<style lang="scss" scoped>
.shi-xun-scene-box{
    width: 1264px;
    margin: 0 auto;
    >div{
        padding: 64px 0;
        >h2{
            font-size: 32px;
            font-weight: 600;
            color: rgba(0,0,0,0.85);
            line-height: 45px;
            text-align: center;
        }
        >p{
            font-size: 18px;
            color: rgba(0,0,0,0.65);
            line-height: 28px;
            text-align: center;
        }
        .shi-xun-scene-experience{
            height: 343px;
            background: url(../../assets/img/scenebg1.png) no-repeat;
            background-size: 100% 100%;
            padding: 57px 109px;
            margin-bottom: 24px;
            margin-top: 70px;
            >div{
                display: flex;
                align-items: flex-start;
                margin-bottom: 40px;
                &:last-child{
                    margin-bottom: 0;
                }
                >img{
                    margin-right: 24px;
                }
                >div{
                    >h4{
                        font-size: 16px;
                        color: rgba(0,0,0,0.85);
                        line-height: 22px;
                        margin-bottom: 16px;
                    }
                    >p{
                        font-size: 14px;
                        color: rgba(0,0,0,0.45);
                        line-height: 20px;
                        margin-bottom: 16px;
                        >span{
                            color: #EA0B06;
                            cursor: pointer;
                        }
                    }
                    >span{
                        display: inline-block;
                        font-size: 14px;
                        color: rgba(0,0,0,0.65);
                        line-height: 20px;
                        cursor: pointer;
                        >i{
                            font-size: 12px;
                            margin-left: 12px;
                        }
                        &:hover{
                            color: #EA0B06;
                        }
                    }
                }
            }
        }
        .shi-xun-scene-process{
            box-shadow: 0px 0px 15px 0px rgba(0,0,0,0.08);
            padding: 40px 140px;
            >p{
                font-size: 24px;
                color: rgba(0,0,0,0.85);
                line-height: 33px;
                text-align: center;
                margin-bottom: 40px;
            }
            >div{
                display: flex;
                justify-content: space-between;
                >div{
                    position: relative;
                    >span{
                        display: flex;
                        justify-content: center;
                        margin-bottom: 20px;
                    }
                    >p{
                        font-size: 14px;
                        color: rgba(0,0,0,0.85);
                        line-height: 24px;
                        text-align: center;
                    }
                    >img{
                        position: absolute;
                        top: 19px;
                        right: -90px;
                    }
                }
            }
        }
        .shi-xun-scene-product{
            display: flex;
            justify-content: center;
            margin-top: 48px;
            border-bottom: 1px solid #E5E5E5;
            >span{
                height: 50px;
                border-bottom: 2px solid transparent;
                margin-right: 72px;
                font-size: 18px;
                color: rgba(0,0,0,0.85);
                line-height: 25px;
                cursor: pointer;
                transition: all .3s;
                &:last-child{
                    margin-right: 0;
                }
                &:hover{
                    color: #EA0B06;
                    border-color: #EA0B06;
                }
                &.active{
                    color: #EA0B06;
                    border-color: #EA0B06;
                }
            }
        }
        .shi-xun-scene-product-content{
            margin-top: 40px;
            >img{
                display: block;
                max-width: 100%;
            }
        }
    }
}
</style>