<template>
  <div class="demandList">
    <div class="demandList-con">
      <p>精选、优质的服务商</p>
      <div class="content">
        <div class="con-left">
          <div>
            <img src="@/assets/img/demandList1.png" alt="" />
            <div>按行业分类</div>
          </div>
          <div>
            <label
              v-for="(item, index) in typeList"
              :key="index"
              @click="changeList(item.itemValue)"
              :class="{
                'text-color': dataInfo.industryType === item.itemValue,
              }"
            >
              {{ item.itemText }}
              <input name="typeList" :checked="item.bool" type="radio"
            /></label>
          </div>
        </div>
        <div class="con-right">
          <p @click="toTreasury">查看全部服务商 ></p>
          <div class="company-list">
            <demand-card
              v-for="(item, index) in storeList"
              :key="item.id"
              :val="item"
              :color-num="colorNum(index)"
            ></demand-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import demandCard from "@/page/yunyuanHome/components/providerCard";
import { getStoreHotHome, getStorePageList } from "@/api/treasury";
export default {
  name: "demandList",
  components: {
    demandCard,
  },
  data() {
    return {
      pageSize: 7,
      typeList: [],
      page: {
        current: 1,
        size: 6,
      },
      dataInfo: {
        queryModel: "ISV_LIBRARY",
        industryType: "",
        companyScaleType: "",
      },
      storeList: [],
    };
  },
  mounted() {
    this.loadGetStoreHotHome();
  },
  methods: {
    //按顺序取颜色
    colorNum(index) {
      let id = Number(index) + 1;
      if (id <= 4) {
        return id;
      } else {
        let num = id - parseInt(String(id / 4)) * 4;
        if (num === 0) num = 4;
        return num;
      }
    },
    loadGetStoreHotHome() {
      getStoreHotHome(this.pageSize).then((res) => {
        this.typeList = res;
        this.dataInfo.industryType = res[0].itemValue;
        this.loadGetStorePageList();
        for (let id in this.typeList) {
          this.typeList[id].bool = id == 0;
        }
      });
    },
    loadGetStorePageList() {
      getStorePageList(this.page, this.dataInfo).then((res) => {
        this.storeList = res.records;
      });
    },
    toTreasury() {
      this.$router.push("/treasury");
    },
    changeList(id) {
      this.dataInfo.industryType = id;
      this.loadGetStorePageList();
    },
  },
};
</script>

<style scoped lang="scss">
.demandList {
  width: 100%;
  height: 757px;
  background: url("../../assets/img/demandBg.png") no-repeat;
  background-size: cover;
}
.demandList-con {
  width: 1280px;
  margin: 0 auto;
  padding-top: 70px;
  > p {
    font-size: 32px;
    font-weight: 500;
    color: #262626;
    line-height: 45px;
    text-align: center;
  }
}
.content {
  display: flex;
}

.con-left {
  width: 222px;
  margin-top: 42px;
  > :first-child {
    display: flex;
    > img {
      margin-right: 17px;
    }
    > div {
      font-size: 16px;
      color: #262626;
      line-height: 22px;
    }
  }
  > :last-child {
    width: 100%;
    height: 368px;
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-left: 1px solid rgba(0, 0, 0, 0.2);
    > label {
      flex: 1;
      margin-left: 32px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      input {
        display: none;
      }
    }

    /*  > label:has(:checked)::before {
      position: absolute;
      content: "";
      top: 50%;
      left: -34px;
      transform: translateY(-50%);
      width: 3px;
      height: 32px;
      background: #ea0b00;
    }*/
    > .text-color::before {
      position: absolute;
      content: "";
      top: 50%;
      left: -34px;
      transform: translateY(-50%);
      width: 3px;
      height: 32px;
      background: #ea0b00;
    }
  }
}

.con-right {
  flex: 1;
  > p {
    font-size: 18px;
    color: #262626;
    line-height: 25px;
    margin: 24px 0 23px 360px;
    cursor: pointer;
    &:hover {
      color: #ea0b06;
    }
  }
  .company-list {
    display: flex;
    flex-wrap: wrap;
    > :not(:nth-child(3n)) {
      margin-right: 24px;
    }
    > div {
      margin-top: 27px;
    }
  }
}
</style>
