<template>
  <a-modal
    @cancel="guanbi"
    title="我要揭榜"
    v-model="visibleBool"
    width="750px"
    :footer="null"
    class="modal"
  >
    <div class="company-body" v-show="!jb_Bool">
      <div class="company-list" v-for="item in companyList" :key="item.id">
        <div>
          <div v-if="item.companyLogoAttmId" class="img">
            <v-img
              :attachment-id="item.companyLogoAttmId"
              :default-img="require('@/assets/img/kctp.png')"
            />
          </div>
          <div v-else class="logoText">{{ item.companyName.slice(0, 4) }}</div>
          <div class="info">
            <p>{{ item.companyName }}</p>
            <div>
              <span>成立时间：{{ item.establishmentDate }}</span>
              <span>所属行业：{{ item.industryType | dict("INDUSTRY") }}</span>
            </div>
          </div>
          <img
            src="@/assets/img/yzb.png"
            v-if="item.statusForCurrentUser === 'SCN_STATUS_FOR_BIDDER_03'"
            alt=""
          />
          <img
            src="@/assets/img/wzb.png"
            alt=""
            v-if="item.statusForCurrentUser === 'SCN_STATUS_FOR_BIDDER_04'"
          />
        </div>
        <a-button
          @click="unvile(item.id)"
          class="btn-red"
          v-if="
            sceneStatus == '6' &&
            item.statusForCurrentUser === 'SCN_STATUS_FOR_BIDDER_01'
          "
          >揭榜</a-button
        >
        <a-button
          @click="detail(item.id)"
          v-if="item.statusForCurrentUser !== 'SCN_STATUS_FOR_BIDDER_01'"
          >方案详情</a-button
        >
      </div>
    </div>

    <div v-show="jb_Bool" class="form">
      <div class="jiebang-info-body">
        <div>
          <span>预估成本：</span>
          <div style="display: flex; align-items: center">
            <a-input-number
              :max="999999"
              :min="0"
              placeholder="请填写预估成本(正数，保留小数点后两位)"
              style="width: 100%"
              v-model="submitSceneData.bidPrice"
            />
            <span style="margin-left: 10px">万</span>
          </div>
        </div>
        <div>
          <span>方案说明：</span>
          <div>
            <a-textarea
              placeholder="请填写您的方案说明"
              style="width: 100%; height: 120px"
              v-model="submitSceneData.bidBrief"
            />
          </div>
        </div>
        <div>
          <span>相关附件：</span>
          <div>
            <v-multi-upload
              file-type-code="2022"
              v-model="submitSceneData.bidAttmIds"
            />
            <div class="jiebang-info-annex">
              <div :key="item.attachmentId" v-for="(item, index) in uploadInfo">
                <div>
                  <img src="@/assets/img/fujian.png" />
                  <p>{{ item.fileName }}</p>
                </div>
                <span @click="delAnnex(index)">删除</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div slot="footer" style="display: flex; justify-content: flex-end">
        <a-button @click="guanbi" class="btn-gray" style="margin-right: 20px"
          >取 消</a-button
        >
        <a-button @click="submitBid" class="btn-red">提 交</a-button>
      </div>
    </div>

    <see-plan
      :companyId="companyId"
      :visible="seePlanShow"
      v-model="seePlanShow"
    />
  </a-modal>
</template>

<script>
import VImg from "@/components/VImg";

import { mapActions, mapGetters } from "vuex";
import { sceneBid } from "@/api/cjkf/scn-scene";
import VMultiUpload from "@/components/VMultiUpload";
import seePlan from "./seePlan.vue";

export default {
  name: "companyList",
  model: {
    prop: "visible",
    event: "change",
  },
  props: ["visible", "companyList", "sceneStatus"],
  components: {
    VImg,
    VMultiUpload,
    seePlan,
  },
  data() {
    return {
      seePlanShow: false,
      visibleBool: false,
      sceneId: "",
      jb_Bool: false,
      companyId: "",
      submitSceneData: {
        bidPrice: undefined,
        bidBrief: "",
        bidAttmIds: [],
      },
      uploadInfo: [],
    };
  },
  watch: {
    visible(val) {
      this.visibleBool = val;
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },

  mounted() {
    this.sceneId = this.$route.query.id;
    this.LoadDictData(["INDUSTRY"]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),

    guanbi() {
      this.visibleBool = false;
      this.jb_Bool = false;
      this.$emit("change", false);
      this.submitSceneData = {
        bidPrice: undefined,
        bidBrief: "",
        bidAttmIds: [],
      };
    },
    unvile(id) {
      this.jb_Bool = true;
      this.companyId = id;
    },
    detail(id) {
      this.seePlanShow = true;
      this.companyId = id;
    },
    submitBid() {
      if (!this.submitSceneData.bidBrief) {
        this.$message.warning("请填写方案说明");
        return;
      }
      sceneBid({
        ...this.submitSceneData,
        sceneId: this.sceneId,
        bidCompanyId: this.companyId,
      }).then(() => {
        this.$emit("submit", true);
        this.guanbi();
        this.$message.success("提交成功");
        this.loadGetCompanyList();
      });
    },

    upload(val) {
      if (val) {
        this.uploadInfo.push(val.data);
        this.submitSceneData.attachmentIds.push(val.data.attachmentId);
      }
    },
    delAnnex(index) {
      this.uploadInfo.splice(index, 1);
      this.submitSceneData.attachmentIds.splice(index, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.modal {
  &::v-deep {
    .ant-modal-body {
      padding: 0;
    }
  }
}

.company-body {
  height: 451px;
  overflow-y: auto;
  > :not(:last-child) {
    border-bottom: 1px solid #f0f0f0;
  }
}

.logoText {
  width: 64px;
  height: 64px;
  background: linear-gradient(90deg, #ea0b06 0%, #ff726f 100%);
  border-radius: 4px;
  margin-right: 24px;
  color: #fff;
  font-size: 20px;
  padding: 4px 12px;
  line-height: 28px;
}

.company-list {
  padding: 24px;
  display: flex;
  justify-content: space-between;
  > :first-child {
    display: flex;
    > .img {
      width: 64px;
      height: 64px;
      background: #000000;
      border-radius: 4px;
      margin-right: 24px;
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        border-radius: 4px;
      }
    }
    > .info {
      > p {
        font-size: 16px;
        color: #262626;
        margin-bottom: 16px;
      }
      > div {
        font-size: 14px;
        color: #8c8c8c;
        > :first-child {
          margin-right: 20px;
        }
      }
    }
    > img {
      display: block;
      height: 40px;
      margin-left: 10px;
    }
  }
  > :last-child {
    margin-top: 16px;
  }
}

.form {
  padding: 24px;
}

.jiebang-info-body {
  > p {
    line-height: 20px;
    font-size: 14px;
    margin-bottom: 10px;
    color: #262626;
    padding-left: 80px;
    > span {
      color: #ea0b06;
    }
  }
  > div {
    display: flex;
    margin-bottom: 24px;

    > span {
      width: 70px;
      margin-right: 10px;
      line-height: 32px;
    }

    > div {
      flex: 1;
    }
  }
}

.jiebang-info-annex {
  margin-top: 16px;

  > div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 32px;
    padding: 0 16px;
    margin-bottom: 16px;
    cursor: pointer;

    &:last-child {
      margin-bottom: 0;
    }

    > div {
      display: flex;
      align-items: center;

      > img {
        margin-right: 8px;
      }

      > p {
        max-width: 400px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
    }

    > span {
      color: #ea0b06;
      cursor: pointer;
      display: none;
    }

    &:hover {
      background: #f5f5f5;

      > span {
        display: block;
      }
    }
  }
}
</style>
