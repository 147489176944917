<template>
  <div class="home-course-box">
    <div class="home-course-type">
      <span>课程分类：</span>
      <div>
        <span :class="{'active':courseTypeId == ''}" @click="courseType('')">全部</span>
        <span v-for="item in dict.COURSE_TYPE" :key="item.id" :class="{'active':courseTypeId == item.itemValue}"
              @click="courseType(item.itemValue)">{{ item.itemText }}</span>
      </div>
    </div>
    <div class="home-course-filter">
      <div>
        <span :class="{'active':courseSortVal == ''}" @click="courseSort('')">综合排序</span>
        <span :class="{'active':courseSortVal == 'viewed'}" @click="courseSort('viewed')">人气优先</span>
        <span :class="{'active':courseSortVal == 'published_time'}" @click="courseSort('published_time')">时间优先</span>
      </div>
      <a-input-search
          placeholder="搜索关键词"
          @search="onSearch"
          style="width: 320px"
          v-model="courseName"
      >
        <a-button slot="enterButton">
          <a-icon type="search"/>
        </a-button>
      </a-input-search>
    </div>
    <div :class="{'loading-style':loading}">
      <a-skeleton avatar :loading="loading" :paragraph="{ rows: 10 }">
        <div class="home-course-list" v-if="courseList != ''">
          <div v-for="(item, index) in courseList" :key="index"
               @click="courseInfo(item.id,item.vodStatus,item.crsCourseActivityIds)">
            <div class="home-course-list-img">
              <span v-if="!item.vodStatus">推荐</span>
              <v-img :attachment-id="item.courseCoverAttmId" :default-img="require('@/assets/img/kctp.png')"/>
              <img src="@/assets/img/crs_lock.png" v-if="item.vodStatus && item.videoType==1" id="crs-lock"/>
            </div>
            <div class="home-course-list-info">
              <span>{{ item.courseName }}  </span>
              <p><span>共{{ item.chapterTotal }}节课</span><span>{{ item.viewed ? item.viewed : 0 }}人浏览</span></p>
            </div>
          </div>
        </div>
        <div v-else style="padding:100px 0;display:block">
          <a-empty/>
        </div>
      </a-skeleton>
    </div>
    <pagination
        :total="page.total"
        :current="page.current"
        :size="page.size"
        @changes="pageChange"
    />
  </div>
</template>

<script>
import {getCourseList, verificationEnroll} from "@/api/activity";
import VImg from "@/components/VImg";
import pagination from "../components/pagination.vue";
import {mapGetters, mapActions} from "vuex";

export default {
  components: {
    pagination,
    VImg
  },
  data() {
    return {
      courseList: [],
      page: {
        current: 1,
        size: 12,
        total: 0,
      },
      courseSortVal: '',
      courseName: '',
      courseTypeId: '',
      loading: false
    };
  },
  computed: mapGetters(["dict", "userInfo", "settings"]),
  mounted() {
    this.LoadDictData(["COURSE_TYPE"]);
    this.loadGetCourseList()
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    // 列表
    loadGetCourseList() {
      let {page, courseName, courseSortVal, courseTypeId, courseList} = this
      let data = {
        courseName,
        orderField: courseSortVal,
        ascOrDesc: 'desc',
        courseTypeId
      }
      if (courseList == '') {
        this.loading = true
      }
      getCourseList(page, data).then((res) => {
        this.courseList = res.records
        this.page.total = res.total
        this.loading = false
      })
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetCourseList();
    },
    // 排序
    courseSort(val) {
      this.courseSortVal = val
      this.loadGetCourseList();
    },
    courseType(id) {
      this.page.current = 1
      this.courseTypeId = id
      this.loadGetCourseList();
    },
    onSearch() {
      this.loadGetCourseList();
    },
    courseInfo(id, vodStatus, activityIds) {


      if (vodStatus) {
        //有配置点播权限
        if (!this.userInfo) {
          this.$message.warning("您还未登录！");
          this.$router.push('/login');
          return;
        }

        //判断点播权限
        if (activityIds && this.userInfo.phone) {
          verificationEnroll(activityIds).then((res) => {
            if (!res) {
              this.$message.warning("很抱歉，该课程您无权查看~");
              return;
            } else {
              this.$router.push({
                path: "/activity/courseInfo",
                query: {id},
              });
            }
          });
        }
      } else {
        this.$router.push({
          path: "/activity/courseInfo",
          query: {id},
        });
      }

    },
  },
};
</script>

<style lang="scss" scoped>
.home-course-box {
  .home-course-type {
    display: flex;
    border-bottom: 1px solid #f0f0f0;
    padding-bottom: 20px;
    margin-bottom: 16px;

    > span {
      white-space: nowrap;
      line-height: 32px;
    }

    > div {
      flex: 1;
      display: flex;
      flex-wrap: wrap;

      > span {
        min-width: 80px;
        padding: 0 16px;
        height: 32px;
        border: 1px solid #d9d9d9;
        border-radius: 2px;
        cursor: pointer;
        text-align: center;
        line-height: 30px;
        margin: 0 16px 12px 0;

        &.active {
          border-color: #EA0B06;
          color: #EA0B06;
        }
      }
    }
  }

  .home-course-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;

    > div {
      display: flex;

      > span {
        color: #8c8c8c;
        cursor: pointer;
        margin-right: 32px;

        &.active {
          color: #EA0B06;
        }
      }
    }
  }

  .home-course-list {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 40px;

    > div {
      width: 302px;
      margin: 24px 24px 0 0;
      transition: all 0.3s;
      background: #fff;
      cursor: pointer;

      &:nth-child(4n) {
        margin-right: 0;
      }

      .home-course-list-img {
        position: relative;
        height: 180px;

        > span {
          position: absolute;
          top: -4px;
          left: 8px;
          width: 52px;
          height: 32px;
          background: url(../../assets/img/kcicon.png) no-repeat;
          background-size: 100% 100%;
          color: #fff;
          line-height: 32px;
          text-align: center;
        }

        > img {
          width: 100%;
          height: 100%;
        }
      }

      .home-course-list-info {
        padding: 16px 16px 24px;

        > span {
          display: block;
          line-height: 14px;
          margin-bottom: 22px;
          transition: all 0.3s;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }

        > p {
          display: flex;
          justify-content: space-between;

          > span {
            color: #bfbfbf;
            line-height: 12px;
            font-size: 12px;
          }
        }
      }

      &:hover {
        box-shadow: 0px 4px 24px 0px rgba(0, 65, 152, 0.08);

        .home-course-list-info {
          > span {
            color: #EA0B06;
          }
        }
      }
    }
  }

  .home-course-page {
    text-align: center;
  }
}

#crs-lock {
  position: absolute;
  width: 30px;
  height: 30px;
  right: 5%;
  top: 75%;
}
</style>
