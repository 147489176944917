import "regenerator-runtime/runtime";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./permission";
import { VueAxios } from "./utils/request";
import "./utils/lazy_use";
import "../src/assets/css/base.css";
import VueLazyload from "vue-lazyload";
import moment from "moment";

import VueDPlayer from "vue-dplayer";
import "vue-dplayer/dist/vue-dplayer.css";

Vue.use(VueDPlayer);

import sensors from "@/utils/sensors.js";
Vue.prototype.$sensors = sensors;

Vue.prototype.$openPage = (url) => {
  window.open(url);
};
Vue.prototype.$toPage = (url) => {
  window.location.href = url;
};

Vue.config.productionTip = false;
Vue.use(VueAxios);
Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: require("@/assets/img/kctp.png"),
  loading: require("@/assets/img/kctp.png"),
  attempt: 1,
});

Vue.filter("dict", (value, code) => {
  if (!value) {
    return null;
  }
  return store.getters.dictText(code, value);
});

Vue.filter("buyTypeDict", (value, code) => {
  if (!value) {
    return null;
  }
  return store.getters.dictText(code, value).replace("按", "");
});

Vue.filter("moment", function (dataStr, pattern = "YYYY-MM-DD HH:mm:ss") {
  return dataStr && moment(dataStr).format(pattern);
});

Vue.filter("lastTime", function (dataStr) {
  return dataStr && moment(dataStr).fromNow();
});
Vue.filter("capitalize", function (value) {
  if (!value) return "";
  value = value.toString();
  return value.substring(0, 3) + "****" + value.substring(value.length - 4);
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
