<template>
  <div class="home-result-info">
    <div class="home-result-info-head">
      <div>
        <span
          ><v-img
            :attachment-id="resultInfo.coverAttmId"
            :default-img="require('@/assets/img/kctp.png')"
        /></span>
        <div>
          <div class="home-result-info-title">
            <span>{{ resultInfo.acvName }}</span>
            <p>
              分享至：
              <img
                alt="图片"
                @click="shareToMicroblog()"
                src="@/assets/img/wbd.png"
              />
              <img
                alt="图片"
                @click="shareToKj()"
                src="@/assets/img/qqkj.png"
              />
              <img alt="图片" @click="shareToQQ()" src="@/assets/img/qqd.png" />
            </p>
          </div>
          <div class="home-result-info-lable"></div>
          <div class="home-result-info-date">
            <p>
              成果编号：<span>{{ resultInfo.id }}</span>
            </p>
            <p>
              发布时间：<span>{{ resultInfo.publishDate }}</span>
            </p>
          </div>
          <a-button class="btn-red" size="large" @click="resultConsult"
            >我有国产化需求</a-button
          >
        </div>
      </div>
    </div>
    <div class="home-result-info-box">
      <div class="home-result-info-content">
        <div class="home-result-info-text">
          <span>场景建设内容</span>
          <div v-html="resultInfo.content" />
        </div>
        <div class="home-result-info-text">
          <span>实施效果</span>
          <div v-html="resultInfo.effect" />
        </div>
        <div class="home-result-info-annex" v-if="resultInfo.attachments != ''">
          <span>其他说明材料</span>
          <div v-for="item in resultInfo.attachments" :key="item">
            <p>
              <img alt="图片" src="@/assets/img/fujian.png" />
              <span>{{ item.fileName }}</span>
            </p>
            <div>
              <span @click="download(item.id)">下载</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let url = document.location; //获取当前网页地址
import { getAchievementList, getAchievementInfo } from "@/api/achievement";
import VImg from "@/components/VImg";
import { mapGetters } from "vuex";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      resultList: [],
      page: {
        current: 1,
        size: 4,
      },
      resultInfo: "",
    };
  },
  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  mounted() {
    this.loadGetAchievementInfo();
    this.loadGetAchievementList();
  },
  methods: {
    loadGetAchievementInfo() {
      getAchievementInfo(this.id).then((res) => {
        this.resultInfo = res;
      });
    },
    loadGetAchievementList() {
      getAchievementList(this.page, { sortKey: "viewed" }).then((res) => {
        this.resultList = res.records;
      });
    },
    resultConsult() {
      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userScenes/add`,
        "_blank"
      );
    },
    resultInfoBtn(id) {
      let _this = this;
      this.$router.push({
        path: "/result/info",
        query: { id },
      });
      setTimeout(() => {
        _this.loadGetAchievementInfo();
      }, 100);
    },
    download(id) {
      if (!this.isLogin) {
        this.$message.warning("您还未登录！");
        return;
      }
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${id}`);
    },
    // 分享
    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.resultInfo.acvName);
      window.open(_shareUrl, "_blank");
    },
    shareToMicroblog() {
      var sharesinastring =
        "http://service.weibo.com/share/share.php?title=" +
        encodeURIComponent(
          "「" + this.resultInfo.acvName + "」" + " 点这里" + url
        );
      window.open(sharesinastring, "_blank");
    },
    shareToKj() {
      let { resultInfo } = this;
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
          url
        )}&sharesource=qzone&title=${resultInfo.acvName}`
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.home-result-info {
  .home-result-info-head {
    background: #fff;
    border-top: 1px solid #e2e8f0;
    > div {
      width: 1280px;
      margin: 0 auto;
      display: flex;
      padding: 34px 0;
      > span {
        width: 300px;
        height: 168px;
        margin-right: 40px;
        > img {
          width: 100%;
          height: 100%;
        }
      }
      > div {
        flex: 1;
        .home-result-info-title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 30px;
          > span {
            font-size: 24px;
            line-height: 24px;
          }
          > p {
            display: flex;
            align-items: center;
            > img {
              margin-left: 16px;
              cursor: pointer;
            }
          }
        }
        .home-result-info-lable {
          display: flex;
          margin: 16px 0;
          > span {
            height: 24px;
            border: 1px solid #d9d9d9;
            border-radius: 2px;
            line-height: 22px;
            padding: 0 8px;
            font-size: 12px;
            margin-right: 8px;
          }
        }
        .home-result-info-date {
          font-size: 14px;
          display: flex;
          margin-bottom: 36px;
          > p {
            line-height: 12px;
            color: #8c8c8c;
            margin-right: 12px;
            > span {
              color: #262626;
            }
          }
        }
        > button {
          width: 256px;
          font-size: 14px;
        }
      }
    }
  }
  .home-result-info-box {
    width: 1280px;
    margin: 0 auto;
    padding: 24px 0 56px;
    .home-result-info-content {
      background: #fff;
      padding: 24px;
      > div {
        margin-bottom: 30px;
        > span {
          display: block;
          line-height: 18px;
          font-size: 18px;
          font-weight: bold;
          margin-bottom: 24px;
        }
      }
      .home-result-info-text {
        > p {
          line-height: 22px;
          text-align: justify;
          > img {
            max-width: 100%;
          }
        }
      }
      .home-result-info-annex {
        > div {
          display: flex;
          align-items: center;
          > p {
            display: flex;
            align-items: center;
            margin-right: 24px;
            > img {
              margin-right: 16px;
            }
            > span {
              line-height: 14px;
              max-width: 300px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
          > div {
            display: flex;
            > span {
              cursor: pointer;
              margin-left: 16px;
              line-height: 14px;
              color: #ea0b06;
              &:first-child {
                margin-left: 0;
              }
            }
          }
        }
      }
      .home-result-info-type {
        > p {
          display: inline-block;
          height: 32px;
          border: 1px solid #d9d9d9;
          border-radius: 2px;
          padding: 0 16px;
          line-height: 30px;
        }
      }
      .home-result-info-plan {
        margin-bottom: 0;
        > div {
          > div {
            margin-top: 24px;
            border-bottom: 1px solid #f0f0f0;
            padding-bottom: 24px;
            &:first-child {
              margin-top: 0;
            }
            > span {
              font-size: 16px;
              line-height: 16px;
              display: block;
              margin-bottom: 20px;
            }
            > p {
              line-height: 22px;
              color: #8c8c8c;
            }
          }
        }
      }
    }
    .home-result-info-all {
      width: 256px;
      margin-left: 24px;
      background: #fff;
      > p {
        height: 56px;
        line-height: 56px;
        border-bottom: 1px solid #f0f0f0;
        padding: 0 16px;
        font-size: 16px;
      }
      > div {
        padding: 16px 16px 0;
        > div {
          cursor: pointer;
          margin-bottom: 24px;
          > div {
            position: relative;
            height: 126px;
            > img {
              width: 100%;
              height: 100%;
            }
            > span {
              position: absolute;
              top: 8px;
              left: 8px;
              display: inline-block;
              height: 24px;
              border: 1px solid rgba(255, 255, 255, 0.8);
              border-radius: 2px;
              background: rgba(0, 0, 0, 0.4);
              padding: 0 8px;
              line-height: 22px;
              font-size: 12px;
              color: #fff;
            }
          }
          > p {
            line-height: 14px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-top: 16px;
          }
        }
      }
    }
  }
}
</style>
