<template>
  <div class="demand-info">
    <p class="title" v-if="describe">需求描述</p>
    <div class="text" v-html="describe"></div>
    <p class="title" v-if="ids && ids.length > 0">应标附件</p>
    <service-attachment v-for="el in ids" :key="el" :attachment-id="el" />
  </div>
</template>

<script>
import serviceAttachment from "@/page/service/components/serviceAttachment";
export default {
  name: "demandInfo",
  props: ["describe", "ids"],
  components: {
    serviceAttachment,
  },
  data() {
    return {};
  },
  mounted() {
    if (this.describe) {
      this.describe = this.richText(this.describe);
    }
  },
  methods: {
    //富文本处理
    richText(text) {
      const regex = new RegExp("<img", "gi");

      return text.replace(
        regex,
        `<img style="display:block;max-width: 928px;margin: 0 auto"`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.demand-info {
  padding: 0 24px 24px 24px;
  min-height: 472px;
}
.details {
  margin: 16px 0 32px 0;
}
.text {
  font-size: 14px;
  color: #595959;
  line-height: 22px;
}
.title {
  font-size: 14px;
  font-weight: bold;
  color: #262626;
  line-height: 22px;
  margin: 32px 0 16px 0;
}
</style>
