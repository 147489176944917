<template>
  <div class="demand-list">
    <div class="list-body">
      <p>全面、精选、优质的热门需求</p>
      <div @click="toDemand">查看全部需求 ></div>
      <div>
        <div>
          <img src="@/assets/img/zhongbiao.png" alt="" />
          <div class="transition-list">
            <transition-group name="list-complete" tag="div">
              <div
                class="item list-complete-item pro-panel"
                v-for="el in latestList"
                :key="el.id"
              >
                <p class="news">
                  恭喜<span>{{ `【${el.bidCompanyName}】` }}</span
                  >成功中标，中标金额：
                </p>
                <p class="price">¥{{ el.bidPrice }}</p>
              </div>
            </transition-group>
          </div>
        </div>
        <div>
          今日已更新<span>{{ info.bidCountByToday }}</span
          >条招标信息
        </div>
      </div>
      <div class="list-box">
        <demand-card
          v-for="item in demandlist"
          :key="item.id"
          :val="item"
        ></demand-card>
      </div>
    </div>
  </div>
</template>

<script>
import demandCard from "@/page/yunyuanHome/components/demandCard";
import {
  getDemandPageList,
  getDemandBidInfo,
  getLatestList,
} from "@/api/demand";
export default {
  name: "demandList",
  data() {
    return {
      page: {
        current: 1,
        size: 9,
      },
      demandlist: [],
      info: "",
      latestList: [],
      removeitem: [],
      timer: "",
    };
  },
  components: {
    demandCard,
  },
  mounted() {
    this.loadGetDemandPageList();
    this.loadGetDemandBidInfo();
    this.loadGetLatestList();
    let count = 4000;
    if (!this.timer) {
      this.timer = setInterval(() => {
        if (this.latestList.length > 1) {
          this.remove();
          this.$nextTick().then(() => {
            this.add();
          });
        }
      }, count);
    }
  },
  methods: {
    loadGetDemandPageList() {
      getDemandPageList(this.page, { isServerHome: true }).then((res) => {
        this.demandlist = res.records;
      });
    },
    loadGetDemandBidInfo() {
      getDemandBidInfo().then((res) => {
        this.info = res;
        let name = this.info.bidCompanyName;
        this.info.bidCompanyName = name.replace(
          /^(.{2})(.{3})(.*)$/,
          "$1****$3"
        );
      });
    },
    loadGetLatestList() {
      getLatestList().then((res) => {
        this.latestList = res;
        console.log(this.latestList, " this.latestList");
        this.latestList.forEach((item) => {
          item.bidCompanyName = item.bidCompanyName.replace(
            /^(.{2})(.{3})(.*)$/,
            "$1****$3"
          );
        });
      });
    },
    toDemand() {
      this.$router.push("/demand");
    },
    add: function () {
      if (this.latestList && this.latestList.length) {
        const item = { ...this.removeitem[0] };
        // item.ix = this.nextNum++;
        this.latestList.push(item);
      }
    },
    remove: function () {
      this.removeitem = this.latestList.splice(0, 1);
    },
  },
};
</script>

<style scoped lang="scss">
.demand-list {
  width: 100%;
  height: 1106px;
  background: #ffffff;
}
.list-body {
  width: 1280px;
  margin: 0 auto;
  height: 100%;
  padding: 90px 0 70px 0;
  > p {
    font-size: 32px;
    font-weight: 500;
    color: #262626;
    line-height: 45px;
    text-align: center;
    margin-bottom: 24px;
  }
  > :nth-child(2) {
    text-align: center;
    font-size: 18px;
    color: #262626;
    line-height: 25px;
    cursor: pointer;
    margin-bottom: 36px;
    &:hover {
      color: #ea0b06;
    }
  }
  > :nth-child(3) {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 24px;
    background: linear-gradient(90deg, #ffeeee 0%, #ffeeee 100%);
    font-size: 14px;
    color: #595959;
    line-height: 20px;
    span,
    .price {
      color: #ea0b06;
    }
    > :first-child {
      display: flex;
      align-items: center;

      > img {
        margin-right: 48px;
      }
      .news {
        margin-right: 48px;
      }
    }
  }
  > :nth-child(4) {
    display: flex;
    flex-wrap: wrap;
    > div {
      margin-top: 16px;
    }
    > :not(:nth-child(3n)) {
      margin-right: 16px;
    }
  }
  .transition-list {
    height: 20px;
    overflow: hidden;
  }
  .list-complete-item {
    transition: all 1s;
    height: 20px;
    display: flex;
  }
  .list-complete-leave-to {
    opacity: 0;
    transform: translateY(-80px);
  }
  .list-complete-leave-active {
    position: absolute;
  }
}
</style>
