<template>
  <div class="home-solution-case-list" v-if="val != ''">
    <template v-for="(item,index) in val">
      <div :key="item.id" v-if="index < 1">
        <span><v-img :attachment-id="item.attmIds" :default-img="require('@/assets/img/kctp.png')" /></span>
        <div>
          <p>{{item.name}}</p>
          <span>{{item.caseDiscription}}</span>
        </div>
      </div>
    </template>
  </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";

export default {
  components:{
    VImg
  },
  props: ["val"],
  data() {
    return {
      wxUrl: "",
      h5path: ""
    };
  },
  mounted() {
    this.wxUrl = ""
    this.h5path = "";
    this.getWxH5path();
  },
  methods: {
    consult() {
      /*
      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }
      this.$router.push({
        name: "userScenesAdd",
        params: {
          type: "add",
          info: "",
        },
      });*/
      window.open(
          `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userScenes/add`,
          "_blank"
      );
    },

  }
};
</script>

<style lang="scss" scoped>
.home-solution-case-list {
  > div {
      display: flex;
      > div {
        flex:1;
        padding: 24px 40px;
        > p {
          font-size: 20px;
          line-height: 26px;
          margin-bottom: 20px;
          color: #333;
        }
        > span {
          font-size: 16px;
          display: block;
          line-height: 24px;
          color: #666;
          text-align: justify;
        }
      }
      >span{
        width: 630px;
        height: 354px;
        >img{
            width: 100%;
            height: 100%;
        }
      }

    }

}


</style>
<style>
.home-solution-case-text{
  max-width: 598px;
}
</style>
