<template>
  <div class="home-solution-provider-list" v-if="val">
    <div v-for="item in providerData" :key="item.id">
      <span>
        <v-img
          v-if="item.attachmentList"
          :attachment-id="item.attachmentList ? item.attachmentList[0].id : ''"
          :default-img="require('@/assets/img/nogs.png')"
        />
        <span
          v-else
          :class="{
            'provider-color1': item.randNum == 1,
            'provider-color2': item.randNum == 2,
            'provider-color3': item.randNum == 3,
            'provider-color4': item.randNum == 4,
          }"
          >{{ item.isvName.substr(0, 1) }}</span
        >
      </span>
      <p>{{ item.isvName }}</p>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  props: ["val"],
  data() {
    return {};
  },
  mounted() {},
  computed: {
    providerData: function () {
      let { val } = this;
      if (val) {
        val.forEach((element) => {
          element.randNum = Math.floor(Math.random() * 4) + 1;
        });
        return val;
      }
    },
  },
  methods: {
  },
};
</script>

<style lang="scss" scoped>
.home-solution-provider-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  > div {
    margin: 42px 24px 0 0;
    border: 1px solid #eaedf2;
    width: 302px;
    padding: 32px 0;
    > span {
      height: 60px;
      display: block;
      margin-bottom: 20px;
      > img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: 0 auto;
      }
      > span {
        display: flex;
        width: 60px;
        height: 100%;
        font-size: 32px;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        border-radius: 4px;
        &.provider-color1 {
          background: rgba(55, 210, 179, 0.15);
          border: 1px solid #37d2b3;
          color: #37d2b3;
        }
        &.provider-color2 {
          background: rgba(82, 175, 252, 0.15);
          border: 1px solid #52affc;
          color: #52affc;
        }
        &.provider-color3 {
          background: rgba(254, 190, 124, 0.15);
          border: 1px solid #febe7c;
          color: #febe7c;
        }
        &.provider-color4 {
          background: rgba(188, 160, 219, 0.15);
          border: 1px solid #bca0db;
          color: #bca0db;
        }
      }
    }
    > p {
      line-height: 20px;
      text-align: center;
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
  }
}
</style>