<template>
  <div class="home-result">
    <div class="home-result-head">

      <img src="../../assets/img/yinyong.gif" class="img" />

      <div>
        <span>应用成果</span>
        <p>各类数智转型的场景案例示范</p>
      </div>
    </div>
    <div class="home-result-box">
      <div class="home-result-sort">
        <span>共 {{ page.total }} 个内容</span>
        <p>
          <span
            :class="{ active: condition.sortKey === 'normal' }"
            @click="condition.sortKey = 'normal'"
            >综合排序</span
          >
          <span
            :class="{ active: condition.sortKey === 'viewed' }"
            @click="condition.sortKey = 'viewed'"
            >人气优先</span
          >
          <span
            :class="{ active: condition.sortKey === 'publishDate' }"
            @click="condition.sortKey = 'publishDate'"
            >时间优先</span
          >
        </p>
      </div>
      <div :class="{'loading-style':loading}">
          <a-skeleton avatar :loading="loading" :paragraph="{ rows: 10 }">
            <div class="home-result-content">
              <template v-if="resultList != ''">
                <div
                  class="home-result-list"
                  v-for="(item, index) in resultList"
                  :key="index"
                  @click="resultInfo(item.id)"
                >
                  <div class="home-result-list-info">
                    <span
                      ><v-img
                        :attachment-id="item.coverAttmId"
                        :default-img="require('@/assets/img/cmrt.png')"
                    /></span>
                    <div>
                      <span>{{ item.acvName }}</span>
                      <div>
                        <span
                          v-for="i in item.acvFields"
                          :key="i"
                          style="margin-right: 8px"
                          >{{ i | dict("FIELD_TYPE") }}</span
                        >
                      </div>
                      <p><span v-html="item.content"></span></p>
                    </div>
                  </div>
                  <div class="home-result-list-date">
                    <span>{{ item.publishDate || item.updateTime | moment('YYYY-MM-DD')}} 发布</span>
                    <p>{{ item.viewed ? item.viewed : 0 }}人浏览</p>
                  </div>
                </div>
              </template>
              <div v-else style="padding:100px 0;display:block">
                <a-empty />
              </div>
            </div>
          </a-skeleton>
      </div>

      <pagination
        :total="page.total"
        :current="page.current"
        @changes="pageChange"
      />
    </div>
  </div>
</template>

<script>
import { getAchievementList } from "@/api/achievement";
import pagination from "../components/pagination.vue";
import VImg from "@/components/VImg";
import { mapActions } from "vuex";
export default {
  components: {
    pagination,
    VImg,
  },
  data() {
    return {
      resultList: [],
      page: {
        current: 1,
        size: 10,
        total: 0,
      },
      condition: {
        sortKey: "normal",
      },
      loading:false
    };
  },
  mounted() {
    this.loadGetAchievementList();
    this.LoadDictData(["FIELD_TYPE"]);
  },
  methods: {
    ...mapActions(["LoadDictData"]),
    loadGetAchievementList() {
      const { condition,resultList } = this;
      if(resultList == ''){
        this.loading = true
      }
      getAchievementList(this.page, condition).then((res) => {
        this.resultList = res.records;
        this.page.total = res.total;
        this.loading = false
      });
    },
    pageChange(val) {
      this.page.size = val.pageSize;
      this.page.current = val.current;
      this.loadGetAchievementList();
    },
    resultInfo(id) {
      this.$router.push({
        path: "/result/info",
        query: { id },
      });
    },
  },
  watch: {
    "condition.sortKey"(val, old) {
      this.loadGetAchievementList();
    },
  },
};
</script>

<style lang="scss" scoped>
.home-result {
  .home-result-head {
    height: 320px;
    position: relative;
    z-index: 1;
    >.img{
      max-width: 100%;
      height: 100%;
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%,-50%);
      z-index: 1;
    }
    >div{
      position: absolute;
      top: 0;
      left: 50%;
      bottom: 0;
      width: 650px;
      margin-left: -640px;
      padding-top: 119px;
      z-index: 10;
      > span {
        line-height: 22px;
        font-size: 38px;
        display: block;
        font-weight: bold;
        color: #262626;
        margin-bottom: 24px;
      }

      > p {
        font-size: 18px;
        font-weight: 500;
        color: #262626;
        line-height: 22px;
      }
    }


  }
  .home-result-box {
    width: 1280px;
    margin: 0 auto;
    padding: 24px 0 56px;
    .home-result-sort {
      display: flex;
      color: #8c8c8c;
      > span {
        line-height: 14px;
        font-size: 14px;
        padding-right: 10px;
        border-right: 1px solid #8c8c8c;
      }
      > p {
        display: flex;
        > span {
          cursor: pointer;
          line-height: 14px;
          margin-left: 30px;
          transition: all 0.3s;
          &.active {
            color: #EA0B06;
          }
        }
      }
    }
    .home-result-content {
      display: flex;
      flex-wrap: wrap;
      margin-bottom: 24px;
      .home-result-list {
        background: #fff;
        margin: 24px 24px 0 0;
        background: #fff;
        width: 628px;
        cursor: pointer;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .home-result-list-info {
          padding: 24px;
          display: flex;
          border-bottom: 1px solid #f0f0f0;
          > span {
            width: 159px;
            height: 90px;
            margin-right: 24px;
            > img {
              width: 100%;
              height: 100%;
            }
          }
          > div {
            flex: 1;
            > span {
              font-size: 16px;
              line-height: 16px;
              margin-bottom: 16px;
              display: block;
            }
            > div {
              display: flex;
              margin-bottom: 20px;
              > span {
                height: 20px;
                border: 1px solid #d9d9d9;
                border-radius: 2px;
                line-height: 18px;
                padding: 0 8px;
                font-size: 12px;
              }
            }
            > p {
              max-height: 44px;
              font-size: 12px;
              color: #595959;
              line-height: 22px;
              height: 44px;
              overflow: hidden;
              text-align: justify;
            }
          }
        }
        .home-result-list-date {
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 56px;
          font-size: 12px;
          padding: 0 24px;
          > span {
            color: #8c8c8c;
          }
          > p {
            color: #bfbfbf;
            display: flex;
            align-items: center;
            > span {
              height: 14px;
              width: 1px;
              background: #bfbfbf;
              margin: 0 10px;
            }
          }
        }
      }
    }
    .home-result-page {
      text-align: center;
      margin-top: 40px;
    }
  }
}
</style>
<style>
.home-result-list-info > div > p img {
  max-width: 100%;
}
</style>
