<template>
  <div class="new-home-product-solution">
    <strong>好用、精选、场景化的自主创新解决方案</strong>
    <p>基于鸿蒙化改造和数字化转型方案拆解的知识图谱展示助力技改升级服务</p>
    <div>
      <div class="new-home-product-solution-tag">
        <p>解决方案推荐</p>
        <div>
          <div
            v-for="el in categoryData"
            :key="el.id"
            @mouseenter="categoryTag(el.id)"
            :class="{ active: el.id == categoryId }"
          >
            <span></span>
            <p>{{ el.name }}</p>
          </div>
        </div>
        <span @click="$router.push('/solution')">查看更多<img src="@/assets/img/ckgdicon.png" alt="图片" /></span>
      </div>
      <div class="new-home-product-solution-list">
        <div v-for="(el,index) in solutionData" :key="index">
          <img v-if="el.marketing == 2" src="@/assets/img/fntj.png" alt="图片" />
          <span>
            <v-img :attachment-id="el.solutionImg" :default-img="require('@/assets/img/kctp.png')" />
          </span>
          <strong>{{el.name}}</strong>
          <div>
            <template v-for="(item,i) in el.tag">
              <span :key="i" v-if="i < 3">{{ item }}</span>
            </template>
          </div>
          <p>{{el.solutionDiscription}}</p>
          <a-button class="btn-red" @click="solutionInfo(el.id,el.hotType)">查看详情</a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getSolutionList } from "@/api/solution";
import VImg from "@/components/VImg";
export default {
  components: {
    VImg,
  },
  data() {
    return {
      categoryData: [
        {
          name: "旅游出行",
          id:'1500000000000000013'
        },
        {
          name: "金融",
          id:'1500000000000000021'
        },
        {
          name: "党政",
          id:'1500000000000000022'
        },
        {
          name: "能源",
          id:'1500000000000000023'
        },
        {
          name: "公检法",
          id:'1500000000000000025'
        },
        {
          name: "工业环境保护",
          id:'1500000000000000034'
        },
        {
          name: "交通",
          id:'1500000000000000026'
        },
      ],
      categoryId: "1500000000000000013",
      solutionData:[],
      page: {
        current: 1,
        size: 9,
        total: 0,
      },
    };
  },
  mounted(){
    this.loadGetSolutionList()
  },
  methods: {
    categoryTag(val) {
      this.categoryId = val;
      this.loadGetSolutionList()
    },
    // 解决方案列表
    loadGetSolutionList() {
      let { page,categoryId } = this;
      let data = {
        status: "",
        marketing: '',
        tag: "",
        category:categoryId,
      };
      getSolutionList(page, data).then((res) => {
        let data = res.records
        data.forEach(item=>{
          if(item.tag){
            item.tag = item.tag.split(',').slice(0,3)
          }
        })
        this.solutionData = data;
      });
    },
    solutionInfo(id,type) {
      // type  1 常规方案，2 3 鸿蒙方案， 4 小红书方案 5 普通方案 6数字方案
      if(type == 2 || type == 3){
        this.$router.push({
          path: "/solution/info",
          query: { id },
        });
      }else if(type == 1){
        this.$router.push({
          path: "/solution/internet_things",
          query: { id },
        });
      }else if(type == 4 || type == 5){
        this.$router.push({
          path: "/solution/ordinary_program",
          query: { id },
        });
      }else if(type == 6){
        this.$router.push({
          path: "/solution/digitizing",
          query: { id },
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes cardFadeImg {
  0% {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
.new-home-product-solution {
  margin-bottom: 70px;
  > strong {
    display: block;
    font-size: 32px;
    line-height: 45px;
    color: #262626;
    text-align: center;
    margin-bottom: 24px;
  }
  > p {
    font-size: 18px;
    line-height: 25px;
    color: #666;
    margin-bottom: 36px;
    text-align: center;
  }
  > div {
    width: 1280px;
    margin: 0 auto;
    display: flex;
    box-shadow: 0px 0px 6px rgba(0,0,0,0.1600);
    background: #fff;
    align-items: flex-start;
    .new-home-product-solution-tag {
      width: 284px;
      height: 756px;
      background: url(../../../assets/img/solutiontagbg.jpg) no-repeat;
      background-size: 100% 100%;
      position: relative;
      > p {
        height: 80px;
        font-size: 24px;
        font-weight: bold;
        line-height: 80px;
        color: #333333;
        padding: 0 20px;
      }
      > div {
        > div {
          height: 73px;
          display: flex;
          align-items: center;
          position: relative;
          cursor: pointer;
          > p {
            font-size: 18px;
            color: #262626;
            padding: 0 16px;
            border-left: 4px solid transparent;
            line-height: 20px;
            transition: all 0.5s;
            position: relative;
            z-index: 2;
          }
          > span {
            background: url(../../../assets/img/solutionszbg.png) no-repeat;
            background-size: auto 100%;
            height: 100%;
            z-index: 1;
            width: 100%;
            position: absolute;
            animation: cardFadeImg 0.5s ease;
            display: none;
          }
          &.active {
            > p {
              border-color: #ea0b06;
            }
            > span {
              display: block;
            }
          }
        }
      }
      > span {
        position: absolute;
        left: 32px;
        bottom: 24px;
        display: flex;
        align-items: center;
        font-size: 16px;
        line-height: 22px;
        color: #ea0b06;
        cursor: pointer;
        > img {
          height: 12px;
          margin-left: 12px;
          transition: all 0.5s;
        }
        &:hover {
          > img {
            margin-left: 20px;
          }
        }
      }
    }
    .new-home-product-solution-list {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      background: #fff;
      > div {
        width: 332px;
        height: 252px;
        position: relative;
        border-right: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        transition: all 0.3s;
        padding: 24px;
        cursor: pointer;
        overflow: hidden;
        &:nth-child(3n) {
          border-right: 0;
        }
        > img {
          position: absolute;
          top: 0;
          right: 0;
          width: 51px;
          z-index: 2;
        }
        > span {
          width: 150px;
          height: 84px;
          display: block;
          margin-bottom: 10px;
          > img {
            width: 100%;
            height: 100%;
            display: block;
          }
        }
        > strong {
          font-size: 14px;
          line-height: 20px;
          color: #262626;
          margin-bottom: 14px;
          display: block;
        }
        > div {
          display: flex;
          margin-bottom: 16px;
          > span {
            padding: 0 6px;
            height: 20px;
            border: 1px solid #d1d8e4;
            border-radius: 1px;
            line-height: 18px;
            font-size: 12px;
            color: #62697d;
            margin-right: 6px;
            max-width: 66px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &:last-child {
              margin-right: 0;
            }
          }
        }
        > p {
          font-size: 12px;
          line-height: 18px;
          color: #666666;
          max-height: 36px;
          overflow: hidden;
        }
        > button {
          width: 100%;
          border-radius: 4px;
          display: none;
        }
        &:hover {
          box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
          > p {
            display: none;
          }
          > button {
            display: block;
          }
        }
      }
    }
  }
}
</style>