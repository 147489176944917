<template>
  <a-modal
      width="548px"
      v-model="visible"
      wrap-class-name="consult-form-modal"
      :title="null"
      :footer="null"
      @cancel="cancel"
  >
    <div class="service-form">
      <p>我要咨询</p>
      <a-form-model
          ref="ruleForm"
          :model="form"
          :rules="rules"
          :label-col="labelCol"
          :wrapper-col="wrapperCol"
      >
        <a-form-model-item
            ref="consultTitle"
            label="咨询主题"
            prop="consultTitle"
        >
          <a-input
              v-model="form.consultTitle"
              placeholder="请输入咨询主题"
              @blur="
                () => {
                  $refs.consultTitle.onFieldBlur();
                }
              "
          />
        </a-form-model-item>
        <a-form-model-item label="具体描述" prop="consultDesc">
          <a-input
              v-model="form.consultDesc"
              placeholder="请输入您想咨询的详细内容"
              type="textarea"
              :auto-size="{ minRows: 4, maxRows: 4 }"
          />
        </a-form-model-item>
        <a-form-model-item
            ref="contactName"
            label="联系人"
            prop="contactName"
        >
          <a-input
              v-model="form.contactName"
              placeholder="请输入真实姓名"
              :max-length="4"
              @blur="
                () => {
                  $refs.contactName.onFieldBlur();
                }
              "
          />
        </a-form-model-item>
        <a-form-model-item
            ref="contactPhone"
            label="联系电话"
            prop="contactPhone"
        >
          <a-input
              v-model="form.contactPhone"
              placeholder="请输入联系电话"
              :max-length="11"
              @blur="
                () => {
                  $refs.contactPhone.onFieldBlur();
                }
              "
          />
        </a-form-model-item>
        <a-form-model-item
            v-if="is_contactPhoneCode"
            ref="contactPhoneCode"
            label="验证码"
            prop="contactPhoneCode"
        >
          <a-input
              v-model="form.contactPhoneCode"
              placeholder="请输入短信验证码"
              @blur="
                () => {
                  $refs.contactPhoneCode.onFieldBlur();
                }
              "
          >
            <template slot="suffix">
              <v-count-down
                  :trigger="() => sendMobileCode()"
                  :phone="form.contactPhone"
              >
                <template #default>获取验证码</template>
                <template #loading="scope"
                >{{ scope.seconds }}秒后重新发送</template
                >
              </v-count-down>
            </template>
          </a-input>
        </a-form-model-item>
        <a-form-model-item :wrapper-col="{ span: 14, offset: 6 }">
          <a-button class="btn-red" @click="onSubmit" style="width:100px">提 交</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
  </a-modal>
</template>

<script>
import VCountDown from "@/components/VCountDown";
import {sendMobile} from "@/api/common";
import {getConsultCreateService} from "@/api/news";
export default {
  name: "service_form",
  props:['name','show','serviceId'],
  data(){
    const validatorPhone = (rule, value, callback) => {
      if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号格式不正确!"));
      } else {
        callback();
      }
    };
    return{
      visible: false,
      is_contactPhoneCode:true,
      labelCol: { span: 6 },
      wrapperCol: { span: 18 },
      isCode: true,
      form: {
        consultTitle: "",
        consultDesc: "",
        contactName: "",
        contactPhone: "",
        contactPhoneCode: "",
        annex: "",
        serviceId: "",
      },
      rules: {
        consultTitle: [
          { required: true, message: "请输入咨询主题", trigger: "blur" },
        ],
        contactName: [
          { required: true, message: "请输入真实姓名", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, validator: validatorPhone, trigger: "blur" },
        ],
        contactPhoneCode: [
          { required: true, message: "请输入短信验证码", trigger: "blur" },
        ],
      },
    }
  },
  watch:{
    show:function(val){
      this.visible = val
    },
    name:function (val){
      this.form.consultTitle=val
    },
    serviceId:function (val){
      this.form.serviceId=val
    }
  },

  components:{
    VCountDown
  },
  methods:{
    sendMobileCode() {
      const { contactPhone } = this.form;
      sendMobile(contactPhone).then(() => {
        this.$message.success("发送成功！");
      });
    },

    onSubmit() {
      let { form } = this;
      let _this = this;
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          let data = {
            consultSource:'鸿联云',
            consultTitle: form.consultTitle,
            consultContent: form.consultDesc,
            contactDetail: form.contactPhone,
            contactPerson: form.contactName,
            consultAttmId: form.annex,
            contactCompany: "",
            serviceId:form.serviceId
          };
          getConsultCreateService(data).then((res) => {
            _this.$message.success("提交成功！");
            _this.$emit('input',false)
            _this.$refs.ruleForm.resetFields();
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },


    cancel() {
      this.visible = false
      this.$emit('input',false)
    },
  }
}
</script>

<style scoped lang="scss">
.service-form{
  width: 548px;
  >p{
    font-size: 20px;
    color: rgba(0,0,0,0.85);
    font-weight: 600;
    text-align: center;
    line-height: 28px;
    padding-top: 24px;
    margin-bottom: 24px;
  }
  >form{
    background: #fff;
    padding: 24px 42px 24px 42px;
    ::v-deep{
      .ant-form-item-label{
        width: 100px;
      }
    }
  }
}
</style>

<style>
.consult-form-modal .ant-modal-body{
  padding: 0;
}
.consult-form-modal  .ant-form-item{
  margin-bottom: 16px;
}
.consult-form-modal  .ant-form-item:last-child{
  margin-bottom: 0;
}

</style>
