<template>
  <div class="demand-fenxiang">
    <div @click="shareToMicroblog()">
      <img alt="图片" src="@/assets/img/weibo.png" />分享到微博
    </div>

    <div @click="shareToQQ()">
      <img alt="图片" src="@/assets/img/QQ.png" />分享到QQ
    </div>
    <div @click="shareToKj()">
      <img alt="图片" src="@/assets/img/kongjian.png" />分享到QQ空间
    </div>
  </div>
</template>

<script>
let url = document.location;
import QRCode from "qrcodejs2";
import { getH5path } from "@/api/common";

export default {
  name: "fenxiang",
  props: ["val"],
  data() {
    return {
      shareShow: false,
      wxUrl: "",
      h5path: "",
    };
  },
  mounted() {
    this.wxUrl = "";
    this.h5path = "";
    this.getWxH5path();
  },
  methods: {
    connectShowBtn(is) {
      this.shareShow = is;
    },
    getWxH5path() {
      getH5path().then((res) => {
        this.h5path = res.url;
      });
    },
    showCode() {
      setTimeout(() => {
        this.shareToWeixin();
      }, 300);
    },
    shareToWeixin() {
      /**
       鸿蒙方案
       /solution/info?id=1524556741960794113
       */
      if (this.val.id) {
        //课程
        this.wxUrl = this.h5path + "/solution/info?id=" + this.val.id;
        this.$nextTick(() => {
          document.getElementById("qrcode").innerHTML = "";
          new QRCode(this.$refs.qrCodeUrl, {
            text: this.wxUrl, // 需要转换为二维码的内容
            width: 140,
            height: 140,
            colorDark: "#000000",
            colorLight: "#ffffff",
            correctLevel: QRCode.CorrectLevel.H,
          });
        });
      }
    },
    shareToQQ() {
      var _shareUrl = "https://connect.qq.com/widget/shareqq/index.html?";
      _shareUrl += "url=" + encodeURIComponent(url);
      _shareUrl += "&sharesource=qzone";
      _shareUrl += "&title=" + encodeURIComponent(this.name);
      window.open(_shareUrl, "_blank");
    },
    shareToMicroblog() {
      var sharesinastring =
        "http://service.weibo.com/share/share.php?title=" +
        encodeURIComponent("「" + this.name + "」" + " 点这里" + url);
      window.open(sharesinastring, "_blank");
    },
    shareToKj() {
      window.open(
        `https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${encodeURIComponent(
          url
        )}&sharesource=qzone&title=${this.name}`
      );
    },
  },
};
</script>

<style scoped lang="scss">
.demand-fenxiang {
  /* padding: 17px 24px;*/
  width: 178px;
  height: 111px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  font-size: 12px;
  color: #595959;
  line-height: 18px;
  img {
    width: 20px;
    margin-right: 8px;
  }
  > div {
    cursor: pointer;
  }
  > .wxbox {
    cursor: pointer;
  }
}
</style>
