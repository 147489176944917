<template>
  <div
    class="information-card"
    @click="$router.push(`/policy/info?id=${val.id}`)"
    @mouseenter="connectShow = true"
    @mouseleave="connectShow = false"
  >
    <div>
      <div>{{ val.newsDate.slice(8, 10) }}</div>
      <div>/</div>
      <div>
        <div>{{ val.newsDate.slice(5, 7) }}月</div>
        <div>{{ val.newsDate.slice(0, 4) }}</div>
      </div>
    </div>
    <div>
      <div class="title">
        {{ val.newsName }}
      </div>
      <p>
        {{ val.summary }}
      </p>
    </div>
    <a-icon
      v-show="!connectShow"
      class="icon"
      type="right"
      style="font-size: 22px"
    />
    <img
      v-show="connectShow"
      class="icon img"
      src="@/assets/img/selected.png"
      alt=""
    />
  </div>
</template>

<script>
export default {
  name: "informationCard",
  props: ["val"],
  data() {
    return {
      connectShow: false,
    };
  },
};
</script>

<style scoped lang="scss">
.information-card {
  width: 936px;
  height: 172px;
  margin-top: 16px;
  background: #ffffff;
  display: flex;
  padding: 32px 86px 32px 25px;
  position: relative;
  cursor: pointer;
  &:hover {
    .title {
      color: #ea0b06;
    }
    /*  .icon {
      color: #ea0b06;
    }*/
  }
  > :first-child {
    display: flex;
    align-items: center;
    margin-right: 24px;
    > :nth-child(1) {
      font-size: 40px;
      font-weight: 500;
      color: #262626;
      line-height: 40px;
      margin-right: 8px;
    }
    > :nth-child(2) {
      font-size: 40px;
      color: rgba(0, 0, 0, 0.25);
      line-height: 40px;
      margin-right: 8px;
    }
    > :nth-child(3) {
      font-size: 14px;
      line-height: 21px;
      > :first-child {
        color: #262626;
      }
      > :last-child {
        color: rgba(140, 140, 140, 0.65);
      }
    }
  }
  > :nth-child(2) {
    padding-left: 24px;
    position: relative;
    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 1px;
      height: 108px;
      background: #000000;
      opacity: 0.09;
    }
    > div {
      width: 658px;
      font-size: 16px;
      color: #262626;
      line-height: 24px;
      margin-bottom: 12px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
    > p {
      width: 658px;
      font-size: 14px;
      color: #595959;
      line-height: 24px;
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      line-clamp: 3;
      -webkit-box-orient: vertical;
    }
  }
  .icon {
    position: absolute;
    top: 66px;
    right: 24px;
  }
}
</style>
