<template>
  <div class="service-banner">
    <div class="banner-left">
      <a-carousel autoplay>
        <div
          class="banner-item"
          v-for="(i, index) in bigBanner"
          :key="index"
          @click="toadvertise(i.adHref)"
        >
          <v-img
            :attachment-id="i.adAttmId"
            :defaultImg="require('@/assets/img/kctp.png')"
          />
        </div>
      </a-carousel>
    </div>

    <div class="banner-right">
      <div>
        <a-carousel autoplay>
          <div class="banner-samll" @click="toadvertise(upData.adHref)">
            <v-img
              :attachment-id="upData ? upData.adAttmId : ''"
              :defaultImg="require('@/assets/img/kctp.png')"
            />
          </div>
        </a-carousel>
      </div>

      <div>
        <a-carousel autoplay>
          <div class="banner-samll" @click="toadvertise(downData.adHref)">
            <v-img
              :attachment-id="downData ? downData.adAttmId : ''"
              :defaultImg="require('@/assets/img/kctp.png')"
            />
          </div>
        </a-carousel>
      </div>
    </div>
  </div>
</template>

<script>
import VImg from "@/components/VImg";
import { getAdvertiseList } from "@/api/advertise";
export default {
  name: "service_banner",
  data() {
    return {
      bannerSmallInfo1: true,
      bannerSmallInfo2: true,
      bigBanner: [],
      smallUp: [],
      smallDown: [],
      upData: "",
      downData: "",
    };
  },

  components: {
    VImg,
  },

  created() {
    this.loadGetAdvertise();
  },

  methods: {
    toadvertise(url) {
      if (url) {
        window.open(url);
      }
    },

    //广告
    loadGetAdvertise() {
      let params = {
        adTypeList: [
          "SERVICE_MARKET_BIG_BANNER",
          "SERVICE_MARKET_SMALL_BANNER_UP",
          "SERVICE_MARKET_SMALL_BANNER_DOWN",
        ],
        validStatus: "1",
      };

      getAdvertiseList(params).then((res) => {
        this.bigBanner = res.records.filter((item) => {
          return item.adType === "SERVICE_MARKET_BIG_BANNER";
        });

        if (this.bigBanner.length == 0) {
          this.bigBanner = [
            {
              adAttmId: "",
            },
          ];
        }

        let upList = res.records.filter((item) => {
          return item.adType === "SERVICE_MARKET_SMALL_BANNER_UP";
        });

        this.upData = upList[0];

        let downList = res.records.filter((item) => {
          return item.adType === "SERVICE_MARKET_SMALL_BANNER_DOWN";
        });

        this.downData = downList[0];
      });
    },
  },
};
</script>

<style scoped lang="scss">
.service-banner {
  width: 1280px;
  display: flex;
}

.banner-left {
  width: 848px;
  height: 320px;
  //padding: 56px 0 0 40px;
  position: relative;
  cursor: pointer;
  .banner-item {
    display: block;
    width: 848px;
    height: 320px;
    position: relative;
    z-index: 1;
    > img {
      display: block;
      width: 848px;
      height: 320px;
    }
  }

  .banner-con {
    cursor: pointer;
    position: absolute;
    top: 56px;
    left: 40px;
    > :nth-child(1) {
      font-size: 28px;
      color: #262626;
      line-height: 25px;
      margin-bottom: 16px;
    }
    > :nth-child(2) {
      font-size: 14px;
      color: #262626;
      line-height: 25px;
      margin-bottom: 72px;
    }
  }
}

.banner-right {
  margin-left: 24px;
  > :first-child {
    margin-bottom: 24px;
  }
  > :last-child {
  }

  > div {
    width: 408px;
    height: 148px;
    //padding: 28px 0 0 24px;
    position: relative;
    cursor: pointer;
    .banner-samll {
      display: block;
      width: 408px;
      height: 148px;
      position: relative;
      z-index: 1;
      > img {
        display: block;
        width: 408px;
        height: 148px;
      }
    }

    .banner-small-con {
      position: absolute;
      top: 28px;
      left: 24px;
      > :nth-child(1) {
        font-size: 18px;
        color: #262626;
        line-height: 25px;
        margin-bottom: 12px;
      }
      > :nth-child(2) {
        font-size: 12px;
        color: #666666;
        line-height: 25px;
        margin-bottom: 24px;
      }
      > img {
        display: block;
        width: 24px;
        height: 14px;
      }
    }
  }
}
</style>
