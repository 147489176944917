<template>
  <div class="search-box">
    <div class="search-content">
      <p>立即搜索服务或发布需求</p>
      <div>
        <div class="box-left">
          <div class="title-info">
            <img src="@/assets/img/searchBox1.png" alt="" />
            <div>
              <p>从众多服务市场中挑选满意服务</p>
              <p>自由挑选、结果丰富</p>
            </div>
          </div>
          <div class="left-bottom">
            <a-input-search
              v-model="code"
              placeholder="小程序开发"
              size="large"
              @search="onSearch"
              style="width: 456px"
              class="search-inp"
              :maxLength="20"
            >
              <a-button class="btn-red" slot="enterButton">立即搜索</a-button>
            </a-input-search>
            <div class="text">
              <div><span>1.</span>搜索<span>&nbsp;>&nbsp;</span></div>
              <div><span>2.</span>挑选服务<span>&nbsp;>&nbsp;</span></div>
              <div><span>3.</span>服务咨询<span>&nbsp;>&nbsp;</span></div>
              <div><span>4.</span>下单合作</div>
            </div>
          </div>
        </div>
        <div class="box-right">
          <div class="title-info">
            <img src="@/assets/img/searchBox2.png" alt="" />
            <div>
              <p>发布需求，吸引优质供应商投标</p>
              <p>省事省力、高效精准</p>
            </div>
          </div>
          <div class="right-bottom">
            <a-button class="right-btn" @click="userCenter">发布需求</a-button>
            <div class="text">
              <div><span>1.</span>填写需求<span>&nbsp;>&nbsp;</span></div>
              <div><span>2.</span>平台审核<span>&nbsp;>&nbsp;</span></div>
              <div><span>3.</span>服务商投标 <span>&nbsp;>&nbsp;</span></div>
              <div><span>4.</span>企业选标、定标</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "searchBox",
  data() {
    return {
      code: "",
    };
  },
  computed: {
    ...mapGetters(["userInfo", "companyList"]),
  },
  methods: {
    onSearch() {
      if (this.code) {
        this.$router.push({
          name: "service",
          params: { code: this.code },
        });
      }
    },
    userCenter() {
      if (this.userInfo) {
        if (this.companyList && this.companyList.length > 0) {
          window.open(
            `${process.env.VUE_APP_ACCOUNT_PREFIX}/demandManage/demand`,
            "_blank"
          );
        } else {
          window.open(
            `${process.env.VUE_APP_ACCOUNT_PREFIX}/?type=1`,
            "_blank"
          );
        }
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search-box {
  width: 100%;
  height: 416px;
  background: url("../../assets/img/searchBoxBg.png") no-repeat;
  background-size: cover;
}
.search-content {
  width: 1280px;
  margin: 0 auto;
  padding-top: 70px;
  > p {
    font-size: 32px;
    font-weight: 500;
    color: #262626;
    line-height: 48px;
    text-align: center;
    margin-bottom: 34px;
  }
  > div {
    padding: 40px 56px;
    height: 264px;
    background: #ffffff;
    display: flex;
  }
  .title-info {
    display: flex;
    align-items: center;
    > img {
      display: block;
      width: 60px;
      height: 60px;
      margin-right: 16px;
    }
    > div {
      > :first-child {
        font-size: 24px;
        font-weight: 500;
        color: #262626;
        line-height: 48px;
      }
      > :last-child {
        font-size: 14px;
        color: #595959;
        line-height: 20px;
      }
    }
  }
  .text {
    display: flex;
    font-size: 14px;
    color: #595959;
    line-height: 20px;
    > div {
      > span {
        color: #ea0b06;
      }
    }
  }
  .box-left {
    margin-right: 52px;
    .left-bottom {
      margin-left: 76px;
      &::v-deep {
        .ant-input {
          border-radius: 0 !important;
        }
      }
    }
    .search-inp {
      margin-top: 32px;
      margin-bottom: 24px;
    }
  }
  .box-right {
    padding-left: 52px;
    border-left: 1px solid #e8e8e8;
    > .right-bottom {
      > button {
        width: 104px;
        height: 40px;
        margin-top: 32px;
        margin-bottom: 24px;
        border-color: #ea0b06 !important;
        color: #ea0b06 !important;
      }
    }
  }
}
</style>
