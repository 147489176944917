<template>
  <div class="demand-card" @click="toDemand(val.id)">
    <div>
      <div class="hot">HOT</div>
      <div class="title">
        {{ val.name }}
      </div>
    </div>
    <div>
      {{ val.demandDescribe }}
    </div>
    <div>
      <div class="info">
        <div>
          <span>预算金额：</span><span>¥</span><span>{{ val.fundAmount }}</span>
        </div>
        <div>
          <img src="@/assets/img/jianzhu.png" />
          <span>{{ val.companyName }}</span>
        </div>
      </div>
      <img
        v-if="val.demandStatus === '4'"
        src="@/assets/img/zhaobiao.png"
        alt=""
      />
      <img
        v-if="val.demandStatus === '6'"
        src="@/assets/img/dinbiao.png"
        alt=""
      />
    </div>
  </div>
</template>

<script>
import VIcon from "@/components/VIcon";
import VImg from "@/components/VImg";
export default {
  name: "demandCard",
  props: ["val"],
  components: {
    VImg,
    VIcon,
  },
  methods: {
    toDemand(id) {
      this.$router.push({
        path: "/demand/detail",
        query: { id },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.demand-card {
  width: 416px;
  height: 232px;
  background: #f6f7fa;
  padding: 24px 24px 16px;
  cursor: pointer;
  position: relative;
  &:hover {
    box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.15);
  }
  > :first-child {
    display: flex;
    align-items: center;
    > .hot {
      width: 42px;
      height: 20px;
      background: #ea0b06;
      border-radius: 2px;
      font-size: 12px;
      color: #ffffff;
      line-height: 20px;
      text-align: center;
      margin-right: 8px;
    }
    > .title {
      flex: 1;
      font-size: 18px;
      font-weight: 500;
      color: #262626;
      line-height: 28px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
  > :nth-child(2) {
    margin: 12px 0 15px 0;
    font-size: 14px;
    color: #595959;
    line-height: 20px;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  > :nth-child(3) {
    display: flex;
    justify-content: space-between;
    > .info {
      position: absolute;
      left: 24px;
      bottom: 24px;
      > :first-child {
        margin-bottom: 36px;
        > :nth-child(1) {
          font-size: 12px;
          color: #595959;
          line-height: 17px;
          margin-right: 10px;
        }
        > :nth-child(2) {
          font-size: 14px;
          color: #ea0b06;
          line-height: 20px;
        }
        > :nth-child(3) {
          font-size: 20px;
          color: #ea0b06;
          line-height: 28px;
        }
      }
      > :last-child {
        font-size: 14px;
        color: #8c8c8c;
        line-height: 20px;
        > span {
          margin-left: 8px;
          vertical-align: middle;
        }
      }
    }
    > img {
      position: absolute;
      right: 12px;
      bottom: 16px;
    }
  }
}
</style>
