<template>
  <div class="home-iot-info">
    <div class="home-iot-info-head">
      <a-breadcrumb separator=">">
        <a-breadcrumb-item>
          <router-link to="/replace">国产替代</router-link>
        </a-breadcrumb-item>
        <a-breadcrumb-item>IoT产品详情</a-breadcrumb-item>
      </a-breadcrumb>
    </div>
    <div class="home-iot-info-content-box">
      <div class="home-iot-info-content">
        <div class="home-iot-info-head">
          <div class="home-iot-info-head-img">
            <span
              ><v-img
                :attachment-id="iotInfo.productImg"
                :default-img="require('@/assets/img/kctp.png')"
            /></span>
          </div>
          <div class="home-iot-info-head-detail">
            <div class="home-iot-info-head-title">
              <span>
                <template v-if="iotInfo.isHarmony == 1">
                  <span v-if="iotInfo.unhmState == '01'">迁移沟通中</span>
                  <span v-if="iotInfo.unhmState == '02'">产品研发中</span>
                  <span v-if="iotInfo.unhmState == '03'">版本测试中</span>
                </template>
                {{ iotInfo.name }}
              </span>
              <div>
                <p @click="focusProduct">{{ isFocus ? "已关注" : "关注" }}</p>
              </div>
            </div>
            <div class="home-iot-info-head-desc">
              <div class="home-iot-info-head-desc-text">
                <div v-if="iotInfo.sceneTagName">
                  <template v-for="(e, i) in iotInfo.sceneTagName.split(',')">
                    <span v-if="i < 2" :key="i">{{ e }}</span>
                  </template>
                </div>
                <p>
                  <span>分类：</span>{{ iotInfo.categoryName.split(",")[0] }}
                </p>
              </div>
              <div class="home-iot-info-head-desc-chip">
                <p>
                  <span>开发板</span
                  ><strong
                    @click="iotProductInfo(iotInfo.datDevboard.id, 'dev')"
                    >{{ iotInfo.devboardName }}</strong
                  >
                </p>
                <p>
                  <span>SOC</span>
                  <template v-if="iotInfo.datSocList">
                    <strong
                      v-for="i in iotInfo.datSocList"
                      :key="i.id"
                      @click="iotProductInfo(i.id, 'soc')"
                      >{{ i.name }}</strong
                    >
                  </template>
                </p>
                <p>
                  <span>OS</span>
                  <template v-if="iotInfo.datOsList">
                    <strong
                      v-for="i in iotInfo.datOsList"
                      :key="i.id"
                      @click="iotProductInfo(i.id, 'os')"
                      >{{ i.name }}</strong
                    >
                  </template>
                </p>
              </div>
            </div>
            <div class="home-iot-info-head-share">
              <div class="home-iot-info-head-button">
                <a-button class="btn-red" size="large" @click="consult"
                  >我有国产化需求</a-button
                >
                <a-button
                  size="large"
                  v-if="iotInfo.isHarmony == 0"
                  @click="analyzeShow = true"
                  >国产替代分析</a-button
                >
              </div>
              <share :val="iotInfo" />
            </div>
          </div>
        </div>
        <div class="home-iot-info-desc">
          <div>
            <p>硬件信息</p>
            <div>
              <p>
                <span>入网方式：</span
                >{{ iotInfo.connMode ? iotInfo.connMode : "其他" }}
              </p>
              <p>
                <span>网络协议：</span
                >{{
                  iotInfo.networkProtocol ? iotInfo.networkProtocol : "其他"
                }}
              </p>
              <p>
                <span>加密方式：</span
                >{{ iotInfo.cipherMode ? iotInfo.cipherMode : "其他" }}
              </p>
              <p>
                <span>硬件尺寸：</span
                >{{ iotInfo.hardwareSize ? iotInfo.hardwareSize : "其他" }}
              </p>
            </div>
          </div>
          <div>
            <p>产品介绍</p>
            <div>
              <span v-html="iotInfo.discription"></span>
            </div>
          </div>

          <div v-if="useGuide != ''">
            <p>使用指南</p>
            <div>
              <template v-for="item in useGuide">
                <div :key="item.id">
                  <p>
                    <img alt="图片" src="@/assets/img/fjicon.png" />
                    <span>{{ item.fileName }}</span>
                  </p>
                  <div>
                    <span
                      :data-id="item.id"
                      @click="download(item.fileName, item.id)"
                      >下载</span
                    >
                  </div>
                </div>
              </template>
            </div>
          </div>
          <template v-if="iotInfo.isHarmony == 1">
            <div v-if="iotInfo.hmAdv">
              <p>鸿蒙优势</p>
              <div>
                <span v-html="iotInfo.hmAdv"></span>
              </div>
            </div>
            <div v-if="iotInfo.hmFrameworkImg">
              <p>鸿蒙架构</p>
              <div>
                <v-img
                  :attachment-id="iotInfo.hmFrameworkImg"
                  :default-img="require('@/assets/img/kctp.png')"
                />
              </div>
            </div>
            <div v-if="iotInfo.hmCase">
              <p>鸿蒙方案</p>
              <div>
                <span v-html="iotInfo.hmCase"></span>
              </div>
            </div>
          </template>
          <div v-if="iotInfo.applyVideoUrl">
            <p>应用使用小视频</p>
            <v-video-con
                v-if="iotInfo.applyVideoType === '1'"
                :video-id="iotInfo.applyVideoUrl"
            ></v-video-con>
            <div class="video-text" v-else>
              <a target="_blank" :href="iotInfo.applyVideoUrl">立即查看视频 <a-icon style="font-size: 20px;margin-left: 5px" type="play-circle" /></a>
            </div>
          </div>
          <div v-if="iotInfo.resolveVideoUrl">
            <p>硬件拆解小视频</p>
            <v-video-con
                v-if="iotInfo.resolveVideoType === '1'"
                :video-id="iotInfo.resolveVideoUrl"
            ></v-video-con>
            <div class="video-text" v-else>
             <a target="_blank" :href="iotInfo.resolveVideoUrl">立即查看视频 <a-icon style="font-size: 20px;margin-left:5px" type="play-circle" /></a>
            </div>
          </div>
        </div>
      </div>
      <div class="home-iot-info-all">
        <div class="home-iot-info-enterprise" v-if="iotInfo.datIsv">
          <p>
            <v-img
              v-if="iotInfo.datIsv.isvImg"
              :attachment-id="iotInfo.datIsv.isvImg"
              :default-img="require('@/assets/img/mrlogo.png')"
            />
            <strong
              v-else
              :class="{
                'enterprise-color1': randNum == 1,
                'enterprise-color2': randNum == 2,
                'enterprise-color3': randNum == 3,
                'enterprise-color4': randNum == 4,
              }"
              >{{ iotInfo.datIsv.name.substr(0, 1) }}</strong
            >
            <span>
              {{ iotInfo.datIsv.name }}
            </span>
          </p>
          <div>
            <div>
              <span>联系电话</span>
              <p>
                {{
                  iotInfo.datIsv.linkPhone
                    ? iotInfo.datIsv.linkPhone
                    : "********"
                }}
              </p>
            </div>
            <div>
              <span>企业地址</span>
              <p>
                {{ iotInfo.datIsv.address }}
              </p>
            </div>
          </div>
        </div>
        <div class="home-iot-info-all-list">
          <p>更多推荐</p>
          <div>
            <div
              v-for="item in replaceList"
              :key="item.id"
              @click="replaceInfoBtn(item.id)"
            >
              <v-img
                :attachment-id="item.productImg"
                :default-img="require('@/assets/img/kctp.png')"
              />
              <p>{{ item.name }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <analyze-modal
      :show="analyzeShow"
      v-if="analyzeShow"
      :val="iotInfo"
      v-model="analyzeShow"
    />
  </div>
</template>

<script>
import analyzeModal from "./analyzeModal.vue";
import { getIotProductInfo, getIotProductList } from "@/api/iot";
import { getAddFocus, getIsFocus } from "@/api/collect";
import VImg from "@/components/VImg";
import { mapGetters } from "vuex";
import share from "../components/share.vue";
import VVideoCon from "@/components/VVideoCon";
export default {
  components: {
    analyzeModal,
    VImg,
    share,
    VVideoCon,
  },
  computed: {
    ...mapGetters(["isLogin"]),
    id: function () {
      return this.$route.query.id;
    },
  },
  data() {
    return {
      analyzeShow: false,
      iotInfo: "",
      replaceList: [],
      linkPhoneShow: false,
      isFocus: false,
      useGuide: [],
      randNum: 0,
    };
  },
  mounted() {
    this.loadGetIotProductInfo();
    this.loadGetIotProductList();
    this.randNumFun();
  },
  methods: {
    randNumFun() {
      this.randNum = Math.floor(Math.random() * 4) + 1;
    },
    loadGetIotProductInfo() {
      getIotProductInfo(this.id).then((res) => {
        if (res.hmAdv != "" && res.hmAdv != null) {
          res.hmAdv = res.hmAdv.replace(/<[^>]*>/g, "");
        }
        if (res.hmCase != "" && res.hmCase != null) {
          res.hmCase = res.hmCase.replace(/<[^>]*>/g, "");
        }
        this.iotInfo = res;
        if (res.attachmentList != "") {
          res.attachmentList.forEach((element) => {
            if (element.attachmentType == "300004") {
              this.useGuide.push(element);
            }
          });
        }
        this.loadgetIsFocus();
      });
    },
    // 列表
    loadGetIotProductList() {
      let page = {
        current: 1,
        size: 4,
      };
      getIotProductList(page, { status: "2" }).then((res) => {
        this.replaceList = res.records;
      });
    },
    // 国产替代咨询
    consult() {
      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }
      window.open(
        `${process.env.VUE_APP_ACCOUNT_PREFIX}/userLayout/userScenes/add`,
        "_blank"
      );
      // this.$router.push({
      //   name: "userScenesAdd",
      //   params: {
      //     type: "add",
      //     info: "",
      //   },
      // });
    },
    // 详情
    replaceInfoBtn(id) {
      let _this = this;
      this.$router.push({
        path: "/replace/info",
        query: { id },
      });
      setTimeout(() => {
        _this.loadGetIotProductInfo();
      }, 100);
    },
    iotProductInfo(id, type) {
      this.$router.push({
        path: `/replace/productInfo/${type}`,
        query: { id },
      });
    },
    // 关注
    loadgetIsFocus() {
      let { iotInfo } = this;
      getIsFocus(iotInfo.id).then((res) => {
        this.isFocus = res;
      });
    },
    focusProduct() {
      if (!this.isLogin) {
        this.$message.warning("您还未登录");
        return;
      }
      let { iotInfo, isFocus } = this;
      let data = {
        recordId: iotInfo.id,
        coverAttmId: iotInfo.productImg,
        productName: iotInfo.name,
        focusType: "5",
      };
      if (isFocus) {
        let _this = this;
        this.$confirm({
          title: "您确定要取消收藏吗?",
          onOk() {
            getAddFocus(data).then((res) => {
              _this.$message.success("取消成功！");
              _this.loadgetIsFocus();
            });
          },
          onCancel() {},
          class: "test",
        });
      } else {
        getAddFocus(data).then((res) => {
          this.$message.success("关注成功！");
          this.loadgetIsFocus();
        });
      }
    },
    download(name, id) {
      if (!this.isLogin) {
        this.$message.warning("您还未登录！");
        return;
      }
      window.open(`${process.env.VUE_APP_API_PORTAL_URL}/file/download/${id}`);
    },
  },
};
</script>

<style lang="scss" scoped>
/*a:hover {
  color: #ea0b06 !important;
}*/
.video-text {
  > a {
    font-size: 14px;
    line-height: 24px;
    color: #262626;
    display: flex;
    align-items: center;
    &:hover {
      color: #4282fb;
    }
  }
}

.home-iot-info {
  width: 1280px;
  margin: 0 auto;
  padding: 24px 0 56px;
  .home-iot-info-head {
    margin-bottom: 24px;
  }
  .home-iot-info-content-box {
    display: flex;
    .home-iot-info-content {
      width: 1000px;
      margin-right: 24px;
      .home-iot-info-head {
        background: #fff;
        margin-bottom: 24px;
        padding: 24px;
        display: flex;
        .home-iot-info-head-img {
          width: 240px;
          margin-right: 32px;
          > span {
            display: block;
            height: 240px;
            > img {
              width: 100%;
              height: 100%;
              display: block;
            }
          }
          > div {
            display: flex;
            margin-top: 8px;
            > span {
              width: 54px;
              height: 54px;
              margin-right: 8px;
              cursor: pointer;
              > img {
                width: 100%;
                height: 100%;
                display: block;
              }
              &:last-child {
                margin-right: 0;
              }
            }
          }
        }
        .home-iot-info-head-detail {
          flex: 1;
          .home-iot-info-head-title {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 28px;
            > span {
              font-size: 24px;
              line-height: 32px;
              display: flex;
              align-items: center;
              > span {
                color: #fff;
                background: linear-gradient(90deg, #ea4243 0%, #ff8b4c 100%);
                font-size: 14px;
                padding: 0 12px;
                line-height: 24px;
                margin-right: 12px;
                border-radius: 2px;
              }
            }
            > div {
              display: flex;
              > p {
                width: 80px;
                height: 32px;
                background: #ea0b06;
                border-radius: 3px;
                text-align: center;
                line-height: 32px;
                color: #fff;
                margin-right: 16px;
                cursor: pointer;
              }
              > span {
                cursor: pointer;
              }
            }
          }
          .home-iot-info-head-desc {
            display: flex;
            margin-bottom: 50px;
            .home-iot-info-head-desc-text {
              width: 200px;
              > div {
                display: flex;
                flex-wrap: wrap;
                margin-bottom: 12px;
                > span {
                  height: 20px;
                  background: #f6f6f6;
                  border-radius: 3px;
                  padding: 0 12px;
                  line-height: 20px;
                  color: #666;
                  font-size: 12px;
                  margin-right: 10px;
                  margin-bottom: 12px;
                  &:last-child {
                    margin-right: 0;
                  }
                }
              }
              > p {
                line-height: 19px;
                display: flex;
                margin-bottom: 9px;
                &:last-child {
                  margin-bottom: 0;
                }
                > span {
                  color: #999;
                }
              }
            }
            .home-iot-info-head-desc-chip {
              padding: 16px 24px;
              background: url(../../assets/img/bg5.png) no-repeat;
              background-size: 100% 100%;
              flex: 1;
              > p {
                display: flex;
                line-height: 14px;
                margin-bottom: 20px;
                &:last-child {
                  margin-bottom: 0;
                }
                > span {
                  width: 74px;
                  margin-right: 10px;
                  color: #8c8c8c;
                }
                > strong {
                  font-weight: 500;
                  color: #ea0b06;
                  cursor: pointer;
                }
              }
            }
          }
          .home-iot-info-head-share {
            display: flex;
            justify-content: space-between;
            align-items: center;
            .home-iot-info-head-button {
              > button {
                font-size: 16px;
                &:first-child {
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
      .home-iot-info-desc {
        background: #fff;
        padding: 24px 24px 24px 8px;
        > div {
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          > p {
            border-left: 4px solid #ea0b06;
            padding-left: 10px;
            font-size: 18px;
            line-height: 20px;
            margin-bottom: 24px;
            font-weight: bold;
          }
          > div {
            padding-left: 14px;
            display: flex;
            flex-wrap: wrap;
            > p {
              width: 50%;
              display: flex;
              line-height: 19px;
              font-size: 14px;
              margin-bottom: 9px;
              > span {
                color: #8c8c8c;
                white-space: nowrap;
              }
              &:nth-last-child(1),
              &:nth-last-child(2) {
                margin-bottom: 0;
              }
            }
            > span {
              width: 100%;
              display: block;
              line-height: 22px;
              text-align: justify;
              > img {
                max-width: 100%;
              }
            }
            > div {
              display: flex;
              align-items: center;
              > p {
                display: flex;
                align-items: center;
                margin-right: 24px;
                > img {
                  margin-right: 8px;
                }
                > span {
                  max-width: 290px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  line-height: 14px;
                }
              }
              > div {
                display: flex;
                > span {
                  cursor: pointer;
                  color: #ea0b06;
                  line-height: 14px;
                  margin-right: 16px;
                }
              }
            }
          }
        }
      }
    }
    .home-iot-info-all {
      width: 256px;
      .home-iot-info-enterprise {
        padding: 24px 16px;
        background: #fff;
        > p {
          display: flex;
          align-items: center;
          padding-bottom: 16px;
          border-bottom: 1px solid #f0f0f0;
          > img {
            width: 56px;
            height: 56px;
            margin-right: 16px;
          }
          > strong {
            width: 56px;
            height: 56px;
            display: flex;
            font-size: 32px;
            align-items: center;
            justify-content: center;
            margin-right: 16px;
            font-weight: 500;
          }
          > span {
            flex: 1;
            line-height: 22px;
          }
        }
        > div {
          > div {
            display: flex;
            margin-top: 12px;
            font-size: 12px;
            > span {
              white-space: nowrap;
              margin-right: 16px;
              line-height: 20px;
              color: #8c8c8c;
            }
            > p {
              flex: 1;
              display: flex;
              line-height: 20px;
              > span {
                color: #ea0b06;
                // margin-left: 8px;
                cursor: pointer;
              }
            }
          }
        }
      }
      .home-iot-info-all-list {
        background: #fff;
        margin-top: 16px;
        > p {
          height: 56px;
          line-height: 56px;
          padding: 0 16px;
          border-bottom: 1px solid #f0f0f0;
          font-size: 16px;
        }
        > div {
          padding: 0 16px 16px;
          > div {
            margin-top: 16px;
            position: relative;
            cursor: pointer;
            > img {
              width: 100%;
              height: 224px;
              display: block;
            }
            > p {
              position: absolute;
              left: 0;
              bottom: 0;
              right: 0;
              line-height: 32px;
              color: #fff;
              padding: 0 16px;
              z-index: 2;
              background: rgba(0, 0, 0, 0.4);
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
</style>
<style>
.home-activity-info-head .ant-breadcrumb a:hover {
  color: #ea0b06;
}
.home-iot-info-desc img {
  max-width: 100%;
}
</style>
