<template>
  <div class="projectCard">
    <div>
      <div class="title">{{ val.caseName }}</div>
      <div class="info">
        <div>
          <img src="@/assets/img/gou.png" alt="" />
          <span>采购方：</span>
          <span>{{ val.purchaseBy }}</span>
        </div>
        <div>
          <img src="@/assets/img/gou.png" alt="" />
          <span>交付周期：</span>
          <span>{{ val.deliverCycle || "一" }}</span>
        </div>
      </div>
    </div>
    <div class="price">
      <span>交付金额：</span>
      <span v-if="val.transactionAmount">￥</span>
      <span>{{ val.transactionAmount }}</span>
    </div>
    <div class="seeInfo" @click="visible = true">查看详情 ></div>
    <a-modal
      class="modal"
      :title="null"
      :footer="null"
      :visible="visible"
      @cancel="visible = false"
    >
      <div class="info-title">
        <div>{{ val.caseName }}</div>
        <div v-if="val.summary">
          <div>采购方：{{ val.purchaseBy }}</div>
          <div>交付周期：{{ val.deliverCycle || "一" }}</div>
        </div>
      </div>
      <div class="info-body" v-if="val.summary">
        <div>项目概况</div>
        <div>{{ val.summary }}</div>
      </div>
      <div v-else style="padding-top: 244px; display: block">
        <a-empty />
      </div>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: "projectCard",
  props: ["val"],
  data() {
    return {
      visible: false,
    };
  },
  mounted() {},
  components: {},
};
</script>

<style scoped lang="scss">
.projectCard {
  width: 426px;
  min-height: 213px;
  background: #ffffff;
  padding: 26px 25px 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  > .price {
    margin-top: 40px;
    > :first-child {
      font-size: 12px;
      line-height: 21px;
      color: #666666;
    }
    > :nth-child(2) {
      font-size: 14px;
      line-height: 21px;
      color: #ea0b06;
    }
    > :last-child {
      font-size: 20px;
      font-weight: bold;
      line-height: 21px;
      color: #ea0b06;
    }
  }
  > .seeInfo {
    position: absolute;
    bottom: 24px;
    right: 24px;
    font-size: 14px;
    line-height: 20px;
    color: #ea0b06;
    cursor: pointer;
  }
  .title {
    font-size: 18px;
    font-weight: bold;
    line-height: 28px;
    color: #262626;
    margin-bottom: 24px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-all;
  }

  .info {
    > :nth-child(1) {
      margin-bottom: 12px;
    }
    > div {
      > img {
        display: inline-block;
        margin-right: 8px;
      }
      > span {
        font-size: 12px;
        line-height: 17px;
        color: #262626;
        vertical-align: middle;
      }
    }
  }
}

.modal::v-deep {
  .ant-modal {
    width: 960px !important;
  }
  .ant-modal-content {
    width: 960px;
    height: 720px;
    overflow-y: auto;
  }
  .ant-modal-close-x {
    width: 65px;
    height: 65px;
    line-height: 65px;
    margin-top: 8px;
  }
}

.info-title {
  > :first-child {
    font-size: 24px;
    color: rgba(0, 0, 0, 0.85);
    margin-right: 41px;
    margin-bottom: 16px;
  }
  > :last-child {
    font-size: 12px;
    color: rgba(89, 89, 89, 0.85);
    display: flex;
    > :first-child {
      margin-right: 56px;
    }
    > :last-child {
      width: 671px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      word-break: break-all;
    }
  }
}

.info-body {
  margin-top: 24px;
  > :first-child {
    font-size: 20px;
    font-weight: bold;
    line-height: 28px;
    color: rgba(0, 0, 0, 0.85);
    position: relative;
    margin-left: 16px;
    margin-bottom: 32px;
    &::before {
      content: "";
      position: absolute;
      top: 4px;
      left: -16px;
      width: 4px;
      height: 20px;
      background: #ea0b06;
    }
  }
}
</style>
